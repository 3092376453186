import React from "react";
import { connect } from "react-redux";
import {
  getPlayerSearchList,
  setPaginationFirstValue,
  setPaginationSecondValue,
  setRecordsShow,
  setIsVisible,
  setFormData,
  setDocsData,
  setBtagKey,
  setAlertPopper,
  setBtagAff,
  getTags
} from "../playerTable/playerSearchTableSlice";
import {
  getDecimalNumber,
  getDateFormate,
  getBirthDate,
  formatMoney,
  Currency,
  getUTCDate,
  cleanObject,
  sortingFilter,
  hideMobile,
  hideData,
  hideEmail,
  getFromLocalStorage,
  removeFromLocalStorage,
  getDateTimeFormate,
  MomentUTCDateTimeToday,
  MomentUTCDateTimeEnd,
} from "../../sharedfiles/helper";
import "./scroll.css";
import { exportPDFC } from "../../sharedfiles/jsPdfCommon";
import Vpagination from "../../sharedfiles/Vpagination";
import Input from "../Common/common_inputs/Input";
import Select from "../Common/common_inputs/Select_DDL";
import Datepicker from "../Common/common_inputs/DatePicker";
import { currency, AccountStatus, headers, sortable, TagNames, KycStatus, userType, info, roleData, PlayerSearchFilter } from "../Common/data/Players/PlayersData";
import { PlayerSearchData, playerSearchDataTwo } from "../Common/data/mapData";
import Table from "../Common/table/Table";
import ButtonGroup from "../Common/Buttons/ButtonGroup";
import Skelton from "../Common/skelton/Skelton";
import AlertMsg from "./AlertMsg";
import { fetchSportsList } from "../createbonusnew/createbonusnewslice";
import { PLAYER_MODULE } from "../../sharedfiles/tooltipMsgs";
class playerSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNumber: [],
      itemsperpage: "",
      username: "",
      firstName: "",
      lastName: "",
      email: "",
      customerId: "",
      ipAddress: "",
      phoneNumber: "",
      referralCode: "",
      country: "",
      datepicker: "",
      brand: "",
      playersignment: "",
      stackFactor: "",
      deposits: "",
      cgr: "",
      accountStatus: "",
      recentPlayer: "",
      data: [],
      closeIcon: true,
      currency: "",
      registrationDate: "",
      input: {},
      errors: {},
      value: "",
      document: "",
      dob: "",
      activePage: 1,
      totalPages: "",
      payloadData: {},
      csvData: [],
      pdfBody: [],
      tagName: "",
      playerBtag: "",
      affiliateBtag: "",
      viewBtag: false,
      sortFields: {
        "Customer ID": false,
        DOB: false,
        "Referred By": false,
        "Country": false,
        Currency: false,
        "Player Segment": false,
        StakeFactor: false,
        [`Deposits ${Currency()}`]: false,
        GGR: false,
        "Registration Date": false,
        "Account Status": false,
        key: "",
        direction: "",
        keyType: ""
      },
      masking: false,
      headers: headers,
      state: "",
      focusState: false,
      kycStatus: "",
      sports: "",
      userType: "",
      role: '',
      dateFilters: {
        fromDate: '',
        toDate: ''
      },
      formErrors: {
        fromDate: '',
        toDate: ''
      }
    };

    this.onChangeBrand = this.onChangeBrand.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onChangeNum = this.onChangeNum.bind(this);
    this.onChangeCustomerId = this.onChangeCustomerId.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.exportPDF = this.exportPDF.bind(this);
    this.callApi = this.callApi.bind(this);
    this.updateTableToDocs = this.updateTableToDocs.bind(this);
    this.resetCsvData = this.resetCsvData.bind(this);
    // this.onKeyUp = this.onKeyUp.bind(this);
    this.handlePickerCaller = this.handlePickerCaller.bind(this);
    this.playerserachdata = this.playerserachdata.bind(this);
    this.callFetchFunc = this.callFetchFunc.bind(this);
    this.onReject = this.onReject.bind(this);
    this.onAccept = this.onAccept.bind(this);
  }

  callApi(pageNumber, itemsPerPage, data) {
    const isAffiliate = localStorage.getItem("isAffiliate")
    const { ...filterData } = data;
    const obj = {
      accountStatus: this.state.accountStatus === "ACTIVE" ? "ACTIVE" : this.state.accountStatus, ...filterData
    };
    if (isAffiliate === null || isAffiliate === "null") {
      let payload = cleanObject(obj)
      this.props.dispatch(setIsVisible(false));
      this.props.dispatch(
        getPlayerSearchList(pageNumber - 1, itemsPerPage, payload)
      );
    } else {
      const objj = {
        accountStatus: this.state.accountStatus === "ACTIVE" ? "ACTIVE" : this.state.accountStatus,
        affiliateBtag: isAffiliate,
        ...filterData
      };
      const payload = cleanObject(objj)
      this.props.dispatch(
        getPlayerSearchList(pageNumber - 1, itemsPerPage, objj)
      );
    }
  }

  emptyState() {
    this.setState({
      username: "",
      firstName: "",
      lastName: "",
      email: "",
      customerId: "",
      ipAddress: "",
      phoneNumber: "",
      referralCode: "",
      country: "",
      datepicker: "",
      brand: "",
      playersignment: "",
      stackFactor: "",
      deposits: "",
      cgr: "",
      accountStatus: "",
      recentPlayer: "",
      currency: "",
      document: "",
      dob: "",
      registrationDate: "",
      // payloadData: {},
      itemsperpage: "",
      csvData: [],
      pdfBody: [],
      errors: {},
      tagName: "",
      playerBtag: "",
      affiliateBtag: "",
      // viewBtag: false,
      state: "",
      focusState: false,
      kycStatus: "",
      sports: "",
      userType: "",
      role: '',
      dateFilters: {
        fromDate: '',
        toDate: ''
      },
      formErrors: {
        fromDate: '',
        toDate: ''
      }
    });
    this.setState({ data: [] });
  }

  resetButton(e) {
    this.props.dispatch(setBtagKey(false));
    this.btagToggler();
    this.emptyState();
    this.setState({
      payloadData: {},
      sortFields: {
        "Customer ID": false,
        DOB: false,
        "Referred By": false,
        "Country": false,
        Currency: false,
        "Player Segment": false,
        StakeFactor: false,
        [`Deposits ${Currency()}`]: false,
        GGR: false,
        "Registration Date": false,
        "Account Status": false,
        key: "",
        direction: "",
        keyType: ""
      }
    });
    this.props.dispatch(setRecordsShow([1, 25]));
    this.props.dispatch(setPaginationFirstValue(1));
    this.props.dispatch(setPaginationSecondValue(25));
    this.props.dispatch(setFormData({}));

    const isAffiliate = localStorage.getItem("isAffiliate")
    if (isAffiliate === null || isAffiliate === 'null') {
      this.props.dispatch(
        getPlayerSearchList(0, 25, {})
      );
    }
    else {
      this.props.dispatch(getPlayerSearchList(0, 25, { affiliateBtag: isAffiliate }))
      this.setState({ viewBtag: false });

    }
    this.props.dispatch(setFormData(new Object()));
    removeFromLocalStorage("AffUserBtag")
  }


  headers = [
    [
      "Customer ID",
      "User Name",
      "First Name",
      "Last Name",
      "Affiliate Btag",
      "Email",
      "DOB",
      "Last LoginIP",
      "Referred By",
      "Phone",
      "State",
      "Country",
      "Currency",
      "Player Segment",
      "StakeFactor",
      "Registration Date",
      `Deposits ${Currency()}`,
      "GGR",
      "Account Status",
    ],
  ];

  //+ '(' +Currency.NGN+')',
  tableStyles = {
    // 16: "text-right",
  };
  exportPDF() {
    const title = "Player Search Report";
    exportPDFC(title, [this.state.headers], this.state.pdfBody);
  }


  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
    this.formDataToSliceSetter(event.target.name, event.target.value);
    if (event.target.name === "tagName") {
      if (event.target.value === "most_bet_sports") {
        const sslList = this.props.sslList;
        const sportsSource = sslList && Array.isArray(sslList) && sslList.length > 0
        !sportsSource && this.props.dispatch(fetchSportsList());
      } else {
        this.setState({ sports: "" });
        const updatedFormData = { ...this.props.formData, ["sports"]: "", [event.target.name]: event.target.value }
        this.props.dispatch(setFormData(updatedFormData));
      }
    }
    // if (event.target.name === "state") {
    //   this.setState({ isTofocus: false });
    // }
  };

  affiliateBtagHandle = (e) => {
    // this.setState({ affiliateBtag: e.target.value })
    this.props.dispatch(setBtagAff(e.target.value))
    this.formDataToSliceSetter("affiliateBtag", e.target.value)
  }

  onChangeBrand(e) {
    this.setState({ brand: e.target.value });
    this.formDataToSliceSetter("brand", e.target.value)
  }

  onChangeNum(e) {
    const num = /^[0-9\b]+$/;
    if (e.target.value === "" || num.test(e.target.value)) {
      this.setState({ phoneNumber: e.target.value });
      this.formDataToSliceSetter("phoneNumber", e.target.value)
    }
  }
  onChangeCustomerId(e) {
    const cid = /^[0-9\b]+$/;
    if (e.target.value === "" || cid.test(e.target.value)) {
      this.setState({ customerId: e.target.value });
      this.formDataToSliceSetter("customerId", e.target.value)
    }
  }
  playerserachdata() {
    return {
      userName: this.state.username ? this.state.username.toLowerCase() : "",
      firstName: this.state.firstName ? this.state.firstName.toLowerCase() : "",
      lastName: this.state.lastName ? this.state.lastName.toLowerCase() : "",
      email: this.state.email ? this.state.email.toLowerCase() : "",
      userId: this.state.customerId,
      ipaddress: this.state.ipAddress,
      phone: this.state.phoneNumber,
      referralCode: this.state.referralCode,
      dob: this.state.dob ? getBirthDate(this.state.dob) : null,
      currency: this.state.currency,
      accountStatus: this.state.accountStatus,
      registrationStartDate: this.state.registrationDate ? MomentUTCDateTimeToday(this.state.registrationDate) : null,
      registrationEndDate: this.state.registrationDate ? MomentUTCDateTimeEnd(this.state.registrationDate) : null,
      affiliateBtag: this.state.playerBtag,
      tagName: this.state.tagName,
      state: this.state.state,
      kycStatus: this.state.kycStatus,
      sports: this.state.sports,
      userType: this.state.userType,
      role: this.state.role,
      registrationStartDate: MomentUTCDateTimeToday(this.state.dateFilters.fromDate),
      registrationEndDate: MomentUTCDateTimeEnd(this.state.dateFilters.toDate)
    };
  }

  callFun = (data) => {
    const isAffiliate = localStorage.getItem("isAffiliate")

    const { ...filterData } = data
    const obj = {
      ...filterData,
      affiliateBtag: isAffiliate
    }
    const payloadAff = cleanObject(obj)
    const payload = cleanObject(data)
    if (isAffiliate === null || isAffiliate === "null") {
      this.props.dispatch(getPlayerSearchList(0, 25, payload));
    } else {
      this.props.dispatch(getPlayerSearchList(0, 25, payloadAff));
    }
  };
  onSubmit(e) {
    e.preventDefault();
    if (this.validator()) return;
    const payload = cleanObject(this.playerserachdata());
    // this.props.dispatch(setFormData({...payload}));
    this.setState({ payloadData: payload });
    if (Object.keys(payload).length > 0) {
      if (Object.keys(this.state.errors).length === 0) {
        this.setState({ focusState: false });
        this.props.dispatch(setIsVisible(false));
        this.callFun(payload);
        this.props.dispatch(setPaginationFirstValue(1))
        this.props.dispatch(setPaginationSecondValue(25))
        this.props.dispatch(setRecordsShow([1, 25]))
      }
    }

  }



  updateTableToDocs(key = "", sortType = "", keyType = "") {
    // const sortKeys={key: this.state.sortFields.key, sortType: this.state.sortFields.direction}
    setTimeout(() => {
      const data =
        this.props.playerData?.data !== undefined &&
        this.props.playerData?.data !== null &&
        this.props.playerData?.data &&
        Array.isArray(this.props.playerData?.data) &&
        // this.props.playerData?.data.map((p) => [
        sortingFilter(this.props.playerData?.data, { key, sortType, keyType }).map((p) => {
          return [
            p.registrationDate ? getDateTimeFormate(p.registrationDate) : "-",
            p.userId,
            this.state.masking && Number(p.userName) ? hideMobile(p.userName) : p.userName,
            this.state.masking && Number(p.firstName) ? hideMobile(p.firstName) : p.firstName,
            this.state.masking && Number(p.lastName) ? hideMobile(p.lastName) : p.lastName,
            p.role,
            p.affliateBtag,
            this.state.masking ? hideEmail(p.email) : p.email,
            p.dob ? getDateFormate(p.dob) : "-",
            this.state.masking ? hideData(p.signUpIp) : p.signUpIp,
            p.referralCode,
            this.state.masking ? hideMobile(p.phone) : p.phone,
            p.state,
            this.state.masking ? hideData(p.country) : p.country,
            p.currency,
            p.playerSegment,
            getDecimalNumber(p.stakeFactor),
            formatMoney(p.deposit),
            formatMoney(p.ggr),
            p.updatedByUserName || '-',
            p?.accountStatus?.toUpperCase(),
          ]
        });
      const filterData = this.state.masking && data.map((record) => record.filter((v, ind) => ind !== 8)) // DOB should be hide
      const outputData = this.state.masking ? filterData : data
      this.setState({ pdfBody: outputData });
      const csvData = outputData?.length > 0 ? [this.state.headers, info, ...outputData,] : [this.state.headers, info, []];
      this.setState({
        csvData: csvData,
      });
      this.props.dispatch(setDocsData(csvData));

    }, 300);
  }
  resetCsvData() {
    this.setState({
      csvData: [],
      pdfBody: [],
    });
  }
  callFetchFunc(e) {
    if (e.keyCode === 13) {
      this.onSubmit(e);
    }
  }

  componentDidMount() {
    document.addEventListener("keydown", this.callFetchFunc);
    const fromViewPage = getFromLocalStorage("AffUserBtag");
    const isAffiliate = localStorage.getItem("isAffiliate");
    const dataExist = Object.keys(this.props?.playerData).length > 0 && this.props.playerData?.data?.length > 0;
    const isFormDataExist = Object.keys(this.props.formData).length;
    this.props.dispatch(getTags())
    if ("toDate" in this.props.formData) {
      this.setState(prevState => ({
        dateFilters: {
          ...prevState.dateFilters,
          fromDate: this.props.formData["fromDate"],
          toDate: this.props.formData["toDate"],
        }
      }));

    }
    const props = this.props
    if (!dataExist && !isFormDataExist) {
      this.setState({ accountStatus: "ACTIVE" })
      let payload = { accountStatus: "ACTIVE" };
      this.props.dispatch(setFormData({ ...this.props.formData, accountStatus: "ACTIVE" }));

      if (props.btagKey) {
        payload.affiliateBtag = fromViewPage;
      }
      if (isAffiliate !== null && isAffiliate !== "null") {
        payload.affiliateBtag = isAffiliate;
      }
      this.props.dispatch(setIsVisible(false));
      this.callApi(1, 25, payload);
    } else {
      const csvDataExist = this.props?.docsData && Array.isArray(this.props.docsData) && this.props.docsData.length > 0;
      let pdfData = [];

      if (csvDataExist) {
        pdfData = this.props?.docsData.slice(1, this.props?.docsData?.length);
      }

      this.setState({
        csvData: this.props.docsData,
        pdfBody: pdfData,
      });
    }

    this.stateSetter();
    setTimeout(() => this.btagToggler(), 400);
    this.btagToggler();

    if (localStorage.getItem("isMasking") === "DATA_MASKING") {
      this.setState({ masking: true });
      const v = this.state.headers.filter((key) => key !== "DOB");
      this.setState({ headers: v });
    } else {
      this.setState({ masking: false });
    }
  }

  handlePickerCaller(date, name) {
    if (name === "registrationDate") {
      this.setState({ registrationDate: date })
      this.formDataToSliceSetter("registrationDate", date)
    }
    if (name === "dob") {
      this.setState({ dob: date })
      this.formDataToSliceSetter("dob", date)
    }
  }
  componentDidUpdate(prevProps) {

    if (prevProps.playerData !== this.props.playerData) {
      this.updateTableToDocs();
    }
    if (prevProps.formData !== this.props.formData) {
      this.stateSetter();
    }
    if (prevProps.isAffiliateUser !== this.props.isAffiliateUser || prevProps.isSubAffiliateUser !== this.props.isSubAffiliateUser) {
      this.btagToggler()
    }
  }
  stateSetter() {
    const isFormDataExist = Object.keys(this.props.formData).length
    if (isFormDataExist) {
      Object.keys(this.props.formData).map(key => this.setState({ [key]: this.props.formData[key] }))
    }
  }
  formDataToSliceSetter(key, val) {
    const newPayload = { ...this.props.formData, [key]: val }
    this.props.dispatch(setFormData(newPayload));
  }
  componentWillUnmount() {
    document.removeEventListener('keydown', this.callFetchFunc, false);
  }

  btagToggler() {
    let valid = false;
    const props = this.props;
    if (props.btagKey && (props.isAffiliateUser || props.isSubAffiliateUser)) {
      valid = true;
    } else {
      valid = false;

    }
    if (!props.isAffiliateUser && !props.isSubAffiliateUser) {
      valid = true;
    }
    this.setState({ viewBtag: valid });

    return valid;
  }

  handleSortFields(item, key) {
    const intializing = {
      "Customer ID": false,
      DOB: false,
      "Referred By": false,
      "Country": false,
      Currency: false,
      "Player Segment": false,
      StakeFactor: false,
      Deposits: false,
      GGR: false,
      "Registration Date": false,
      "Account Status": false,
      key: "",
      direction: "",
      keyType: ""
    }
    const direction = !this.state.sortFields[key] ? "ASCENDING" : "DESCENDING";

    this.setState({
      sortFields: {
        ...intializing,
        [key]: !this.state.sortFields[key],
        key: item.key,
        direction: direction,
        keyType: item.type,
      }
    });
    this.updateTableToDocs(item.key, direction, item.type);
  }

  onReject(e) {
    this.setState({ focusState: false });
    this.closeAlert();
  }

  onAccept(e) {
    this.setState({ focusState: true });
    this.closeAlert();
  }
  closeAlert() {
    this.props.dispatch(setAlertPopper(false));
  }

  errorSetter = (name, value) => {
    this.setState((prevState) => ({
      formErrors: {
        ...prevState.formErrors,
        [name]: value,
      },
    }));
  };

  validator = () => {
    const { fromDate, toDate } = this.state.dateFilters;
    let error = false;

    if (toDate && !fromDate) {
      this.errorSetter("fromDate", "Start Date is required");
      error = true;
    } else {
      this.errorSetter("fromDate", "");
    }

    return error;
  };


  dateHandler = (name, value) => {
    this.formDataToSliceSetter(name, value);

    if (name === "fromDate") {
      const errorMsg = value ? "" : "Start Date is required";
      this.errorSetter("fromDate", errorMsg);
    }
    this.setState((prevState) => ({
      dateFilters: {
        ...prevState.dateFilters,
        [name]: value,
      },
    }));
  };


  render() {
    const { dateFilters, formErrors } = this.state;
    const isAffiliate = localStorage.getItem("isAffiliate")
    return (
      <div className='CMS-tab-panel active' id='CMS-betting'>
        <div className='CMS-tabContent'>
          {this.props.alertPopper ? <AlertMsg onReject={this.onReject} onAccept={this.onAccept} /> : null}
          <div className='CMS-box CMS-box-content'>
            <div className='row d-flex justify-content-around'>
              {PlayerSearchData.map((item, ind) => {
                const values = [
                  this.state.username,
                  this.state.firstName,
                  this.state.lastName,
                  this.state.email,
                ];
                return (
                  <React.Fragment key={item.id} >
                    <div
                      className='col-md-4 col-lg-3 col-xl-2 '

                    >
                      <Input
                        title={item.title}
                        name={item.name}
                        value={values[ind]}
                        placeholder={item.placeholder}
                        setValue={(e) => this.handleChange(e)}
                        regex={item.regex}
                        maxLen={item.maxLength}
                        ids={item.ids}
                        info={item.info}
                        show={true}
                      />
                    </div>
                  </React.Fragment>
                );
              })}
              <div className='col-md-4 col-lg-3 col-xl-2'>
                <Datepicker
                  title={"Date of Birth"}
                  name={"dob"}
                  value={this.state.dob}
                  errors={this.state.errors.birthDate}
                  setValue={(e, name) => this.handlePickerCaller(e, name)}
                  ids={"PlayerDOB"}
                  info={PLAYER_MODULE.DOB}
                />
              </div>
            </div>
            <div
              className='row d-flex justify-content-around'
              style={{ minHeight: "104px" }}
            >
              {playerSearchDataTwo.map((item, ind) => {
                const values = [
                  this.state.customerId,
                  this.state.ipAddress,
                  this.state.referralCode,
                  this.state.phoneNumber,
                ];
                return (
                  <React.Fragment key={item.id}>
                    <div className='col-md-4 col-lg-3 col-xl-2' >
                      <Input
                        title={item.title}
                        name={item.name}
                        value={values[ind]}
                        placeholder={item.placeholder}
                        setValue={(e) => this.handleChange(e)}
                        regex={item.regex}
                        maxLen={item.maxLength}
                        ids={item.ids}
                        info={item.info}
                        show={true}
                      />
                    </div>
                  </React.Fragment>
                );
              })}
              <div className='col-md-4 col-lg-3 col-xl-2'>
                <Select
                  title={"Currency"}
                  name={"currency"}
                  value={this.state.currency}
                  setValue={(val) => this.handleChange(val)}
                  options={currency}
                  onSubmit={(e) => this.onSubmit(e)}
                  ids={"PlayerCurrency"}
                  info={PLAYER_MODULE.Currency}
                />
              </div>
            </div>
            <div className='row d-flex justify-content-start'>
              <div
                className='col-md-4 col-lg-3 col-xl-2 ms'
              // style={marginStart}
              >
                <Select
                  title={"Account Status"}
                  name={"accountStatus"}
                  value={this.state.accountStatus}
                  setValue={(val) => this.handleChange(val)}
                  options={AccountStatus}
                  onSubmit={(e) => this.onSubmit(e)}
                  ids={"PlayerAccSts"}
                  info={PLAYER_MODULE.Account_Status}
                />
              </div>

              {PlayerSearchFilter.map((item, ind) => {
                const minDate = item.name === "toDate" ? dateFilters.fromDate : null;
                const maxDate = item.name === "fromDate" ? dateFilters.toDate || new Date() : new Date();
                return (
                  <React.Fragment key={item.id}>
                    <div className="col-md-4 col-lg-3 col-xl-2 ms">
                      <Datepicker
                        title={item.title}
                        name={item.name}
                        value={dateFilters[item.name]}
                        errorMsg={formErrors[item.name]}
                        minDate={minDate}
                        maxDate={maxDate}
                        setValue={(value, name) => this.dateHandler(name, value)}
                        ids={item.ids}
                        info={item.info}
                      />
                    </div>
                  </React.Fragment>
                );
              })}
              {/* <div
                className='col-md-4 col-lg-3 col-xl-2 ms'
              // style={marginLeft}
              >
                <Datepicker
                  title={"Registration Date"}
                  name={"registrationDate"}
                  value={this.state.registrationDate}
                  errors={""}
                  setValue={(e, name) => this.handlePickerCaller(e, name)}
                  maxDate={new Date()}
                  ids={"PlayerRegDate"}
                  info={PLAYER_MODULE.Reg_Date}
                />
              </div> */}
              <div className='col-md-4 col-lg-3 col-xl-2 ms'>
                <Select
                  title={"Tag Name"}
                  name={"tagName"}
                  value={this.state.tagName}
                  setValue={(val) => this.handleChange(val)}
                  options={this.props.tagName}
                  onSubmit={(e) => this.onSubmit(e)}
                  ids={"PlayerTag"}
                  info={PLAYER_MODULE.Tag_Name}
                />
              </div>
              {this.state.tagName === "most_bet_sports" ?
                <div className='col-md-4 col-lg-3 col-xl-2 ms'>
                  <Select
                    title={"Sport"}
                    name={"sports"}
                    value={this.state.sports}
                    setValue={(val) => this.handleChange(val)}
                    options={this.props.sslList}
                    onSubmit={(e) => this.onSubmit(e)}
                    ids={"playerSport"}
                    info={PLAYER_MODULE.Sports}
                  />
                </div> : null
              }
              {isAffiliate === null || isAffiliate === "null" ?
                <div className='col-md-4 col-lg-3 col-xl-2 ms'>
                  <Input
                    title={"Btag"}
                    name={"playerBtag"}
                    value={this.state.playerBtag}
                    setValue={(e) => this.handleChange(e)}
                    placeholder={"Btag"}
                    ids={"PlayerBtag"}
                    info={PLAYER_MODULE.Btag}
                    show={true}
                  />
                </div> : this.state.viewBtag ? <div className='col-md-4 col-lg-3 col-xl-2 ms'>
                  <Input
                    title={"Btag"}
                    name={"affiliateBtag"}
                    value={this.props.btagAff}
                    setValue={(e) => this.affiliateBtagHandle(e)}
                    placeholder={"Btag"}
                    ids={"PlayerAffBtag"}
                    disabled={this.props.btagKey}
                    info={PLAYER_MODULE.Btag}
                    show={true}
                  />
                </div> : ""}
              <div className='col-md-4 col-lg-3 col-xl-2 ms'>
                <Input
                  title={"State"}
                  name={"state"}
                  value={this.state.state}
                  setValue={(e) => this.handleChange(e)}
                  placeholder={"Enter State Name"}
                  ids={"stateName"}
                  // ref={this.stateRef}
                  isTofocus={this.state.focusState}
                  info={PLAYER_MODULE.State}
                  show={true}
                />
              </div>
              {
                this.props.kycPermission ?
                  <div className='col-md-4 col-lg-3 col-xl-2 ms'>
                    <Select
                      title={"KYC Status"}
                      name={"kycStatus"}
                      value={this.state.kycStatus}
                      setValue={(val) => this.handleChange(val)}
                      options={KycStatus}
                      onSubmit={(e) => this.onSubmit(e)}
                      info={PLAYER_MODULE.KYC_Status}
                      ids={"kycstatus"}
                    />
                  </div> : null
              }
              <div className='col-md-4 col-lg-3 col-xl-2 ms'>
                <Select
                  title={"User Type"}
                  name={"userType"}
                  value={this.state.userType}
                  setValue={(val) => this.handleChange(val)}
                  options={userType}
                  info={PLAYER_MODULE.User_Type}
                  onSubmit={(e) => this.onSubmit(e)}
                  ids={"usertype"}
                />
              </div>
              <div className='col-md-4 col-lg-3 col-xl-2 ms'>
                <Select
                  title={"Role"}
                  name={"role"}
                  value={this.state.role}
                  setValue={(val) => this.handleChange(val)}
                  options={roleData}
                  info={PLAYER_MODULE.roles}
                  onSubmit={(e) => this.onSubmit(e)}
                  ids={"playerRole"}
                />
              </div>
            </div>

            <div className='mt-20'></div>
            <div className='row'>
              <ButtonGroup
                groupNames={["Search", "Reset"]}
                idsearch={"PlayerSearch"}
                idreset={"PlayerReset"}
                submit={(e) => this.onSubmit(e)}
                reset={(e) => this.resetButton(e)}
              />
            </div>
          </div>
          <div className='CMS-filter-result' id='result'>
            <div className='CMS-pagination'>
              <Vpagination
                data={this.props.playerData.data}
                totalRecords={this.props.playerData.totalRecords}
                paginationFirstValue={this.props.paginationFirstValue}
                paginationSecondValue={this.props.paginationSecondValue}
                setPaginationFirstValue={setPaginationFirstValue}
                setPaginationSecondValue={setPaginationSecondValue}
                recordsShow={this.props.recordsShow}
                setRecordsShow={setRecordsShow}
                isVisible={this.props.isVisible}
                setIsVisible={setIsVisible}
                csvData={this.state.csvData}
                exportPDF={this.exportPDF}
                callApi={this.callApi}
                csvFileName={"Player Search Report"}
                payloadData={this.state.payloadData}
                resetCsvData={this.resetCsvData}
              />
            </div>
            {
              this.props.loader ? <Skelton /> :
                <Table
                  tableData={this.state.csvData}
                  linkIndex={1}
                  styles={this.tableStyles}
                  sortables={sortable}
                  handleSortFields={(item, key) => this.handleSortFields(item, key)}
                  sortingOrder={this.state.sortFields}
                // sortKeys={sortKeys}
                />
            }
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    displayValue: state.sidebar.displayValue,
    paginationFirstValue: state.playersearch.paginationFirstValue,
    paginationSecondValue: state.playersearch.paginationSecondValue,
    errorMessage: state.playersearch.errorMessage,
    playerData: state.playersearch.playerData,
    recordsShow: state.playersearch.recordsShow,
    tagName: state.playersearch.tagName,
    userId: state.playersearch.userId,
    isVisible: state.playersearch.isVisible,
    formData: state.playersearch.formData,
    docsData: state.playersearch.docsData,
    affiliateBtag: state.playersearch.affiliateBtag,
    btagKey: state.playersearch.btagKey,
    isAffiliateUser: state.loginSlice.isAffiliateUser,
    isSubAffiliateUser: state.loginSlice.isSubAffiliateUser,
    loader: state.playersearch.loader,
    affiliateUsers: state.affiliateSlice.affiliateUsers,
    alertPopper: state.playersearch.alertPopper,
    kycPermission: state.dashboard.kycPermission,
    sslList: state.bonus.sslList,
    affBtag: state.loginSlice.affBtag,
    btagAff: state.playersearch.btagAff,
  };
}
export default connect(mapStateToProps)(playerSearch);
