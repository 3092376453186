import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useDispatch, useSelector } from "react-redux";
import {
  getApprovePending,
  setOpenDialog,
  updateAgent,
  setIsVisible,
  setPage_becoff,
  setItems_becoff,
  setRecords_bcoff,
  setIsVisible_becoff,
  setDocsData_bcoff
} from "./AffiliateSlice";
import Vpagination from "../../sharedfiles/Vpagination";
import { exportPDFC } from "../../sharedfiles/jsPdfCommon";
import { generateKey, getDateTimeFormate } from "../../sharedfiles/helper";
import Dialog__Main from "../Common/Dialog/Dialog__Main";
import AgentEditDialog from "./AgentEditDialog";
import { COMMON_CONSTANTS, KYC_CONSTANTS } from "../../sharedfiles/Constants";
import No__Data__Found from "../Common/table/No__Data__Found";
import { BecomeAffInfo } from "../../sharedfiles/tooltipMsgs";
import Tooltips from "../Common/Tooltip";
const BecomeAfiliate = (props) => {
  const [error, setError] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [pdfBody, setPdfBody] = useState([]);
  const [AffiliateId, setAffiliateId] = useState("");
  const [affiliateInput, setAffiliateInput] = useState({
    payoutCycle: "",
    setupCost: "",
    commissionPercentage: "",
  });
  const dispatch = useDispatch();

  const pageFirstVal = useSelector(
    (state) => state.affiliateSlice.page_becoff
  );
  const pageSecondVal = useSelector(
    (state) => state.affiliateSlice.items_becoff
  );
  const recordsToShow = useSelector(
    (state) => state.affiliateSlice.records_bcoff
  );
  const listPendingApprove = useSelector(
    (state) => state.affiliateSlice.listPendingApprove
  );
  const viewOnlyuser = useSelector((state) => state.dashboard.viewOnlyuser)


  useEffect(() => {
    const dataExist = Object.keys(props?.listPendingApprove).length > 0
    if (!dataExist) {
      props.dispatch(
        getApprovePending(
          pageFirstVal - 1,
          pageSecondVal
        )
      );
    } else {
      const csvDataExist = props?.docsData_bcoff && Array.isArray(props.docsData_bcoff) && props.docsData_bcoff.length > 0
      let pdfData = [];
      if (csvDataExist) {
        pdfData = props?.docsData_bcoff.slice(1, props?.docsData_bcoff?.length);
      }
      setCsvData(props.docsData_bcoff);
      setPdfBody(pdfData);
    }

  }, []);

  const handleConfirmPopUp = (item, status) => {
    if (status === "DECLINED") {
      const data = {
        affiliateRequestId: item,
        affiliateStatus: status,
      };
      props.dispatch(updateAgent(data));
      setTimeout(() => {
        props.dispatch(
          getApprovePending(
            pageFirstVal - 1,
            pageSecondVal
          )
        );
      }, 500);
    }
    if (status === "APPROVED") {
      setAffiliateId(item);
      props.dispatch(setOpenDialog(true));
    }
  };

  const closeDialog = () => {
    props.dispatch(setOpenDialog(false));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const newError = error.filter((err) => name !== err);
    setError(newError);
    setAffiliateInput((data) => {
      return { ...data, [name]: value };
    });
    if (value === "") {
      setError([...error, name]);
    }
  };
  const onSave = (e) => {
    const inputValue = Object.keys(affiliateInput);
    const err = [];

    inputValue.map((value) => affiliateInput[value] === "" && err.push(value));
    setError(err);
    if (
      affiliateInput.payoutCycle != "" &&
      affiliateInput.commissionPercentage != "" &&
      affiliateInput.setupCost != ""
    ) {
      const agentData = {
        affiliateRequestId: AffiliateId,
        affiliateStatus: "APPROVED",
        commissionPercentage: affiliateInput.commissionPercentage,
        payoutCycle: affiliateInput.payoutCycle,
        setupCost: affiliateInput.setupCost,
      };
      props.dispatch(updateAgent(agentData));
      props.dispatch(setOpenDialog(false));
      setTimeout(() => {
        props.dispatch(
          getApprovePending(
            pageFirstVal - 1,
            pageSecondVal
          )
        );
      }, 500);
    }
  };
  const headers = [
    [
      "First Name",
      "Last Name",
      "Phone Number",
      "Whatsapp Number",
      "Email Address",
      "Location City",
      "Gender",
      "DOB",
      "Created Date",
      "Updated Date",
      "Updated By",
      "User Name",
      "Channel"
    ],
  ];
  const tbHeaders = [
    "First Name",
    "Last Name",
    "Phone Number",
    "Whatsapp Number",
    "Email Address",
    "Location City",
    "Gender",
    "DOB",
    "Created Date",
    "Updated Date",
    "Updated By",
    "User Name",
    "Channel",
    "Action"
  ]
  const exportPDF = () => {
    const title = "Become An Affiliate  Report";
    const styles = {
      4: {
        halign: "right",
        valign: "middle",
      },
    };
    exportPDFC(title, headers, pdfBody, styles);
  };
  useEffect(() => {
    if (props.listPendingApprove && Object.keys(props.listPendingApprove).length > 0) {
      const data =
        props.listPendingApprove &&
          props.listPendingApprove?.data &&
          Array.isArray(props.listPendingApprove?.data) ?
          props.listPendingApprove?.data?.map((p) => [
            p.firstName,
            p.lastName,
            p.phoneNumber,
            p.whatsappNumber,
            p.email,
            p.locationCity,
            p.gender,
            p.dob,
            p.createdDate ? getDateTimeFormate(p.createdDate) : "-",
            p.updatedDate ? getDateTimeFormate(p.updatedDate) : "-",
            p.updatedBy,
            p.userName,
            p.channel
          ]) : [];

      setPdfBody(data);
      const csvData = data && Array.isArray(data) && data.length > 0 ? [headers[0], ...data] : [headers[0], []]
      setCsvData(csvData);
      dispatch(setDocsData_bcoff(csvData));
    }
  }, [props.listPendingApprove]);

  const callPageApi = (pageNumber, itemsPerPage) => {
    props.dispatch(setIsVisible(false));
    dispatch(getApprovePending(pageNumber - 1, itemsPerPage));
  };

  return (
    <React.Fragment>
      <Dialog__Main
        dOpen={props.openDialog}
        dClose={closeDialog}
        title={"Update Agent"}
      >
        <AgentEditDialog
          filedsHandler={handleChange}
          onSave={onSave}
          agentEditError={error}
          affiliateInput={affiliateInput}
        />
      </Dialog__Main>

      <div className='CMS-tab-panel active'>
        <div className='CMS-tabContent'>
          <div className='CMS-filter-result' id='result'>
            <div className='CMS-pagination'>
              <Vpagination
                data={listPendingApprove?.data}
                totalRecords={listPendingApprove?.totalRecords}
                paginationFirstValue={pageFirstVal}
                paginationSecondValue={pageSecondVal}
                setPaginationFirstValue={setPage_becoff}
                setPaginationSecondValue={setItems_becoff}
                recordsShow={recordsToShow}
                setRecordsShow={setRecords_bcoff}
                isVisible={props.isVisible}
                setIsVisible={setIsVisible_becoff}
                csvData={csvData}
                exportPDF={exportPDF}
                callApi={callPageApi}
                csvFileName={"Become an Affiliate  Report"}
              />
            </div>
            <div className='CMS-box'>
              <div className='CMS-table CMS-table-triped'>
                <table>
                  <thead>
                    <tr>
                      {tbHeaders.map((item, index) => (
                        <th>
                          <div className='d-flex justify-content-center align-items-center'>
                            <p className='mx-3'>{item}</p>
                            <Tooltips info={`${BecomeAffInfo[index]}`} />
                          </div>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {props.listPendingApprove &&
                      props.listPendingApprove?.data &&
                      props.listPendingApprove.data !== undefined &&
                      props.listPendingApprove?.data.length > 0 ? (
                      props?.listPendingApprove?.data.map((item, index) => {
                        return (
                          <tr key={generateKey(index)}>
                            <td>{item.firstName}</td>
                            <td>{item.lastName}</td>
                            <td>{item.phoneNumber}</td>
                            <td>{item.whatsappNumber}</td>
                            <td>{item.email}</td>
                            <td>{item.locationCity}</td>
                            <td>{item.gender}</td>
                            <td>{item.dob}</td>
                            <td>{item.createdDate ? getDateTimeFormate(item.createdDate) : "-"}</td>
                            <td>{item.updatedDate ? getDateTimeFormate(item.updatedDate) : "-"}</td>
                            <td>{item.updatedBy}</td>
                            <td>{item.userName}</td>
                            <td>{item.channel?.join(',')}</td>
                            <td className='text-center'>
                              <button
                                className={`CMS-btn CMS-btnSmall CMS-btnStatus CMS-statusActive ${viewOnlyuser ? 'disable-button' : ''}`}
                                id="becomeAffapprove"
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  handleConfirmPopUp(item.id, "APPROVED")
                                }
                              >
                                <p>Approve</p>
                              </button>

                              <div className='mb-10' bis_skin_checked='1'></div>

                              <button
                                className={`CMS-btn CMS-btnSmall CMS-btnStatus CMS-statusClosed ${viewOnlyuser ? 'disable-button' : ''}`}
                                id="becomeAffdecline"
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  handleConfirmPopUp(item.id, "DECLINED")
                                }
                              >
                                <p>Decline</p>
                              </button>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <No__Data__Found colspan={22} />
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    listPendingApprove: state.affiliateSlice.listPendingApprove,
    openDialog: state.affiliateSlice.openDialog,
    isVisible: state.affiliateSlice.isVisible_becoff,
    docsData_bcoff: state.affiliateSlice.docsData_bcoff,
  };
};
export default connect(mapStateToProps)(BecomeAfiliate);
