import { createSlice } from "@reduxjs/toolkit";
import { userWatchListApis } from "../../sharedfiles/EndpointConfig";
import { apiCaller } from "../../api/apiCaller";
import { messageEnhancer, toaster } from "../../sharedfiles/helper";

export const CreateWatchListSlice = createSlice({
    name: "CreateWatchListSlice",
    initialState: {
        getAutoWatchlistData: [],
        paginationFirstValue: 1,
        paginationSecondValue: 25,
        recordsShow: [1, 25],
        isVisible: false,
        betType: [],
    },
    reducers: {
        setGetAutoWatchlist: (state, action) => {
            state.getAutoWatchlistData = action.payload
        },
        setPaginationFirstValue: (state, action) => {
            state.paginationFirstValue = action.payload;
        },
        setPaginationSecondValue: (state, action) => {
            state.paginationSecondValue = action.payload;
        },
        setRecordsShow: (state, action) => {
            state.recordsShow = action.payload;
        },
        setIsVisible: (state, action) => {
            state.isVisible = action.payload;
        },
        setBetType: (state, action) => {
            state.betType = action.payload;
        },
    }
})

export const {
    setGetAutoWatchlist,
    setPaginationFirstValue,
    setPaginationSecondValue,
    setRecordsShow,
    setIsVisible,
    setBetType
} = CreateWatchListSlice.actions

export const addAutoWatchList = (params) => (dispatch) => {
    let url = userWatchListApis.addAutomatedWatchlist
    apiCaller(url, "POST", params).then((res) => {
        if (res.status === 200) {
            toaster.success(messageEnhancer(res.msg))
        }
    })
}

export const getAutoWatchList = (pageNum, itemsPerPage) => (dispatch) => {
    let url = userWatchListApis.getAutomatedWatchlist(pageNum, itemsPerPage)
    apiCaller(url, "GET", {}).then((res) => {
        if (res.status === 200) {
            dispatch(setGetAutoWatchlist(res.data))
            dispatch(setIsVisible(true));
        }
    }).catch(err => {
        dispatch(setIsVisible(true));
    })
}

export const getBetTypes = () => (dispatch) => {
    let url = userWatchListApis.getBetTypes
    apiCaller(url, "GET", {}).then((res) => {
        if (res.status === 200) {
            dispatch(setBetType(res.data))
        }
    }).catch(err => {
        console.error(err);
    })
}

export const handleActivation = (obj) => () => {
    let url = userWatchListApis.getActivation;
    return apiCaller(url, "PUT", obj)
        .then((res) => {
            if (res.status === 200) {
                toaster.success(messageEnhancer(res.msg));
            }
        })
        .catch((err) => {
            console.error(err);
        });
};


export default CreateWatchListSlice.reducer;
