import { createSlice } from "@reduxjs/toolkit";
import { apiCaller } from "../../api/apiCaller";
import { playerapi } from "../../sharedfiles/EndpointConfig";

export const NewPlayerSlice = createSlice({
  name: "newplayer",
  initialState: {
    newPlayerData: [],
    paginationFirstValue: 1,
    paginationSecondValue: 25,
    recordsShow: [1, 25],
    activePages: [1, "current"],
    pagesShow: [1, 2],
    isVisible: false,
    formData:{},
    docsData: [],
    getTags: []
  },
  reducers: {
    setNewPlayerData: (state, action) => {
      state.newPlayerData = action.payload;
    },
    setPaginationFirstValue: (state, action) => {
      state.paginationFirstValue = action.payload;
    },
    setPaginationSecondValue: (state, action) => {
      state.paginationSecondValue = action.payload;
    },
    resetNewPlayerData: (state, action) => {
      state.newPlayerData = [];
    },
    setActivePages: (state, action) => {
      state.activePages = action.payload;
    },
    setShowPages: (state, action) => {
      state.pagesShow = action.payload;
    },
    setRecordsShow: (state, action) => {
      state.recordsShow = action.payload;
    },
    setIsVisible: (state, action) => {
      state.isVisible = action.payload;
    },
    setFormData: (state, action) => {
      state.formData = action.payload;
    },
    setDocsData: (state, action) => {
      state.docsData = action.payload;
    },
    setGetTags: (state, action) => {
      state.getTags = action.payload;
    },
  },
});
export const {
  setNewPlayerData,
  setPaginationFirstValue,
  setPaginationSecondValue,
  resetNewPlayerData,
  setActivePages,
  setShowPages,
  setRecordsShow,
  setIsVisible,
  setFormData,
  setDocsData,
  setGetTags,
} = NewPlayerSlice.actions;

const clean = (obj) => {
  for (var propName in obj) {
    if (
      obj[propName] == null ||
      obj[propName] === undefined ||
      obj[propName] === "" ||
      obj[propName] === 0 ||
      obj[propName] == "NaN-aN-aNT23:59:59.517Z" ||
      obj[propName] == "NaN-aN-aNT00:00:00.517Z"
    ) {
      delete obj[propName];
    }
  }
  return obj;
};

export const getNewPlayer =
  (pagenumbervalue, itemperpagevalue, params) =>
    (dispatch) => {
      // dispatch(resetNewPlayerData());
      const obj = clean(params);
      var url =
        `${playerapi.getNewPlayer}` +
        "?itemsPerPage=" +
        itemperpagevalue +
        "&pageNumber=" +
        pagenumbervalue;

      apiCaller(url, "POST", obj).then((response) => {
        if (response !== null && response) {
          dispatch(setNewPlayerData(response.data));
          dispatch(setIsVisible(true));
          if (response.status == 200) {
          }
        }
      }).catch(err => {
        dispatch(setNewPlayerData([]));
        dispatch(setIsVisible(true));
      });
    };

export const getNewtags = (params) => (dispatch) => {
  const url = `${playerapi.newPlayerTagType(params)}`;
  const payloadData = {
    tagType: params,
  };

  apiCaller(url, "POST", payloadData)
    .then((response) => {
      if (response !== null && response) {
        const formattedTags = response?.map((tag, index) => ({
          id: index + 1,
          label: tag,
          value: tag,
        }));
        dispatch(setGetTags(formattedTags));
      }
    })
    .catch((err) => {
      console.log("error", err);
    });
};

export default NewPlayerSlice.reducer;
