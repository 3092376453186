import React, { useState, useEffect } from 'react'
import Input from '../Common/common_inputs/Input'
import { communication_module } from '../../sharedfiles/tooltipMsgs'
import Select from '../Common/common_inputs/Select_DDL'
import { accMsgContentType, messageFormatType, promopriority } from '../Common/data/mapData'
import Tooltips from '../Common/Tooltip'
import { useDispatch, useSelector } from 'react-redux'
import { getNotyTemplateByType, updateNotyTemplate } from './TemplateSlice'
import MultipleSelectDDL from '../Common/common_inputs/MultipleSelectDDL'
import No__Data__Found from '../Common/table/No__Data__Found'

const AccountMsg = () => {
    const [title, setTitle] = useState('')
    const [contentType, setContentType] = useState('')
    const [priority, setPriority] = useState('')
    const [msgFormatType, setMsgFormatType] = useState('')
    const [shortMsg, setShortMsg] = useState('')
    const [longMsg, setLongMsg] = useState('')
    const dispatch = useDispatch()
    const [contentTypeErr, setContentTypeErr] = useState(false);
    const [shouldReset, setShouldReset] = useState(false)
    const [typeErr, setTypeErr] = useState(false)
    const [priorityErr, setPriorityErr] = useState(false)
    const viewOnlyuser = useSelector((state) => state.dashboard.viewOnlyuser)

    const templateData = useSelector((p) => p.TemplateSlice.templateData)
    const onIpHandler = (e) => {
        const { name, value } = e.target
        if (name === "title") {
            setTitle(value);
        }
        if (name === "shortMsg") {
            setShortMsg(value)
        }
        if (name === 'longMsg') {
            setLongMsg(value)
        }
    }

    useEffect(() => {
        if (contentType !== "") {
            setTitle(templateData?.title)
            setShortMsg(templateData?.description)
            setLongMsg(templateData?.message)
            setMsgFormatType(templateData?.messageType)
            setPriority(templateData?.notificationPriority)
        }
    }, [templateData])

    const selectHandler = (e) => {
        const { name, value } = e.target

        if (name === "contentType") {
            setContentType(value)
            setContentTypeErr(false)
            const payload = {
                notificationType: value
            }
            dispatch(getNotyTemplateByType(payload));
        }
    }

    const onClear = () => {
        setTitle('')
        setContentType('')
        setPriority('')
        setMsgFormatType('')
        setShortMsg('')
        setLongMsg('')
        setShouldReset(true)
        setContentTypeErr('')
    }

    const validation = () => {
        let isValid = true;
        if (contentType === "") {
            setContentTypeErr(true)
            isValid = false;
        }
        if (priority === "") {
            setPriorityErr(true)
            isValid = false;
        }
        if (msgFormatType === "") {
            setTypeErr(true)
            isValid = false;
        }
        return isValid;
    }

    const onSave = () => {
        if (validation()) {
            const obj = {
                "bannerName": "",
                "bannerPath": "",
                "actionButtonLabels": [],
                "actionButtonUrls": [],
                "description": shortMsg,
                "htmlTemplate": longMsg,
                "id": templateData?.id,
                "message": longMsg,
                "messageType": msgFormatType,
                "notificationPriority": priority,
                "notificationType": contentType,
                "title": title,
            };

            dispatch(updateNotyTemplate(obj));

            setContentTypeErr(false);
            setPriorityErr(false);
            setTypeErr(false);
        }
    }

    const handleSelectedValues = (selected) => {
        setMsgFormatType(selected)
        setTypeErr(false)
        setShouldReset(false)
    }
    const handlePrioritySelect = (selected) => {
        setPriority(selected[0])
        setPriorityErr(false);
        setShouldReset(false)
    }
    return (
        <React.Fragment>
            <div className='CMS-tab-panel active' id='CMS-betting'></div>
            <div className='CMS-tabContent'>
                <div className='CMS-box CMS-box-content'>
                    <div className="row">
                        <div className='col-3' >
                            <Select
                                title={"Content Type"}
                                name={"contentType"}
                                value={contentType}
                                setValue={(val) => selectHandler(val)}
                                options={accMsgContentType}
                                info={communication_module.content_type}
                                ids={"accountMsgContent"}
                                errorMsg={contentTypeErr && "Content Type Required"}
                            />
                        </div>
                        <div className='col-3'>
                            <Input
                                title={"Title (50 Characters)"}
                                name={"title"}
                                value={title}
                                placeholder={"Enter..."}
                                setValue={(e) => onIpHandler(e)}
                                ids={"accountMsgTittle"}
                                maxLen={50}
                                info={communication_module.accountMsg_Tittle}
                                show={true}
                            />
                        </div>

                        <div className='col-3'>
                            <div className='CMS-formGroup'>
                                <div className='CMS-formLabel'>
                                    {"Priority"}
                                    &nbsp;&nbsp;
                                    <Tooltips info={communication_module.priority} />
                                </div>
                                <MultipleSelectDDL
                                    options={promopriority}
                                    onSelect={(value) => handlePrioritySelect(value)}
                                    shouldReset={shouldReset}
                                    initialSelected={templateData?.notificationPriority}
                                    onlyOneSelection
                                />
                                {priorityErr && (
                                    <div className='CMS-form-errorMsg'>
                                        {"Please select the priority"}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='col-3'>
                            <div className='CMS-formGroup'>
                                <div className='CMS-formLabel'>
                                    {"Message Format Type"}
                                    &nbsp;&nbsp;
                                    <Tooltips info={communication_module.msgformat_type} />
                                </div>
                                <MultipleSelectDDL
                                    options={messageFormatType}
                                    onSelect={(value) => handleSelectedValues(value)}
                                    shouldReset={shouldReset}
                                    initialSelected={templateData?.messageType}

                                />
                                {typeErr && (
                                    <div className='CMS-form-errorMsg'>
                                        {"Please select your Message format Type"}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className='col-6'>
                            <div className='CMS-formGroup'>
                                <div className='CMS-formLabel'>
                                    {communication_module.shortMsg}
                                    &nbsp;&nbsp;
                                    <Tooltips info={communication_module.shortMsg} />
                                </div>
                                <div className='CMS-formControl-group'>
                                    <div className='CMS-formControl'>
                                        <textarea
                                            rows='2'
                                            cols='50'
                                            name='shortMsg'
                                            id='shortMsg'
                                            value={shortMsg}
                                            onChange={(e) => onIpHandler(e)}
                                            maxlength='144'
                                        ></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-6'>
                            <div className='CMS-formGroup'>
                                <div className='CMS-formLabel'>
                                    {communication_module.longMsgIp}
                                    &nbsp;&nbsp;
                                    <Tooltips info={communication_module.longMsg} />
                                </div>
                                <div className='CMS-formControl-group'>
                                    <div className='CMS-formControl'>
                                        <textarea
                                            rows='2'
                                            cols='50'
                                            name='longMsg'
                                            id='longMsg'
                                            value={longMsg}
                                            maxlength='320'
                                            onChange={(e) => onIpHandler(e)}
                                        ></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="CMS-table">
                        <table className='width-20 float-right' >
                            <thead>
                                <tr>
                                    <th>Label</th>
                                    <th>Field</th>
                                </tr>
                            </thead>
                            <tbody>
                                {templateData?.dynamicNotificationData &&
                                    templateData?.dynamicNotificationData.length > 0 ? (
                                    templateData?.dynamicNotificationData.map((p) => {
                                        return (
                                            <tr>
                                                <td>{p.label}</td>
                                                <td>{p.field}</td>
                                            </tr>
                                        )
                                    })
                                ) : (
                                    <No__Data__Found />
                                )}
                            </tbody>
                        </table>
                    </div>
                    <div className='mt-20'></div>
                    <div className="row">
                        <div className='col-12'>
                            <div className="CMS-btnContainer">

                                <button
                                    className={`CMS-btn CMS-btnQuaternary active CMS-btnMedium ${viewOnlyuser ? 'disable-button' : ''}`}
                                    onClick={(e) => onSave(e)}
                                >
                                    Save
                                </button>
                                <button
                                    className={`CMS-btn CMS-btnSecondary active CMS-btnMedium ${viewOnlyuser ? 'disable-button' : ''}`}
                                    onClick={() => onClear()}
                                >
                                    Clear All
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment >
    )
}

export default AccountMsg