import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  getBrandList,
  addBrandKey,
  updateKey,
  setBrandPaginationPage,
  setBrandPaginationPageCount,
  setBrandRecordShow,
  setIsVisible,
} from "../Brand config/BrandConfigSlice";
import { generateKey, getDateTimeFormate } from "../../sharedfiles/helper";
import Dialog__Main from "../Common/Dialog/Dialog__Main";
import Tooltips from "../Common/Tooltip";
import { BRAND_KEY, COMMON_CONSTANTS, CREATEROLE_CONSTANTS } from "../../sharedfiles/Constants";
import No__Data__Found from "../Common/table/No__Data__Found";
import { BrandData, BrandKeyList } from "../../sharedfiles/tooltipMsgs";
import Vpagination from "../../sharedfiles/Vpagination";
import { exportPDFC } from "../../sharedfiles/jsPdfCommon";
const BrandsListDialog = (props) => {
  const {
    brandPaginationPage,
    brandPaginationPageCount,
    brandRecordShow,
    brandPrevPayload,
    isVisible,
    brandTotalRecord
  } = useSelector((state) => state.brandconfig);
  const dispatch = useDispatch();
  const [keyName, setKeyName] = useState("");
  const [keyStatus, setKeyStatus] = useState("");
  const [updateStatus, setUpdateStatus] = useState("");
  const [nameError, setNameError] = useState(false);
  const [statusError, setStatusError] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [pdfBody, setPdfBody] = useState([]);

  const handleKeyName = (e) => {
    const { value } = e.target;
    value !== "" ? setNameError(false) : setNameError(true);
    setKeyName(e.target.value);
  };

  const handleKeyStatus = (e) => {
    const { value } = e.target;
    value !== "" ? setStatusError(false) : setStatusError(true);
    setKeyStatus(e.target.value);
  };

  const submitBrandDetails = (e) => {
    e.preventDefault();
    if (keyName != "" && keyStatus != "") {
      const data = {
        brandId: props.brandId,
        name: keyName,
        status: keyStatus,
      };
      dispatch(addBrandKey(data));
      dispatch(getBrandList({ brandPaginationPage: brandPaginationPage - 1, brandPaginationPageCount: 25 }));
      setKeyName("")
      setKeyStatus("")
    }
    if (keyName == "") {
      setNameError(true)
    }
    if (keyStatus == "") {
      setStatusError(true)
    }
  };

  const keyUpdate = (e, item) => {
    setUpdateStatus(e.target.value);
    let status = "";
    if (e.target.value == `ACTIVE${item.apiSecretKey}`) {
      status = "ACTIVE";
    } else if (e.target.value == `IN_ACTIVE${item.apiSecretKey}`) {
      status = "IN_ACTIVE";
    }
    const data = {
      apiKey: item.apiKey,
      name: item.keyName,
      status,
    };
    dispatch(updateKey(data));
    dispatch(getBrandList({ brandPaginationPage: brandPaginationPage - 1, brandPaginationPageCount: 25 }));

  };

  useEffect(() => {
    const params = {
      pageNum: 0,
      noItems: 25,
    };
    const dataExist = Object(props.brandList).length > 0

    // if (props.openDialog == true) {
    //   dispatch(getBrandList(params));
    // }
    if (!dataExist) {
      dispatch(getBrandList({ brandPaginationPage: brandPaginationPage - 1, brandPaginationPageCount: 25 }));
    }
  }, []);

  const headers = [
    [
      "Api Key",
      "Key Name",
      "Brand Id",
      "Added By",
      "Api Secret Key",
      "Key Status",
      "Created Date",
      "Updated Date",
      "Created By",
      "Updated By",
      "Action"
    ]
  ]

  function callApi(brandPaginationPage, brandPaginationPageCount) {
    dispatch(setIsVisible(false));
    dispatch(getBrandList({ brandPaginationPage: brandPaginationPage - 1, brandPaginationPageCount: brandPaginationPageCount }));
  };

  useEffect(() => {
    formTableData(props.brandList)
  }, [props.brandList]);

  function formTableData(rawData) {
    const bodyData =
      Object.keys(rawData).length > 0 &&
        Array.isArray(rawData) &&
        rawData?.length > 0
        ? rawData?.map((item) => {
          return [
            item.apiKey,
            item.keyName,
            item.brandId,
            item.addedBy,
            item.apiSecretKey,
            item.createdDate,
            item.updatedDate,
            item.addedBy,
            item.updatedBy
          ];
        })
        : [];

    setPdfBody(bodyData);
    const CsvData = [headers[0], ...bodyData];
    setCsvData(CsvData);
  }
  function exportPDF() {
    const title = "Brand Lists Report";
    exportPDFC(title, headers, pdfBody, {});
  }

  return (
    <>
      <Dialog__Main dOpen={props.openDialog} dClose={props.setOpenDialog} width={"xl"} title={"Brand Key"}>
        <div className="row d-flex justify-content-between align-items-center">
          <div className="col">
            <div className="row">
              <div className="col">
                <div className="CMS-formGroup">
                  <div className="CMS-formLabel">
                    {BRAND_KEY.keyName}
                    &nbsp;&nbsp;
                    <Tooltips info={BrandData.key_Name} />
                  </div>
                  <div className="CMS-formControl-group">
                    <input
                      type="text"
                      className="form-control"
                      id="keyname"
                      name="KeyName"
                      placeholder="Key Name"
                      value={keyName}
                      onInput={handleKeyName}
                    />
                    {nameError && (
                      <div className="text-danger">
                        {BRAND_KEY.empty_keyName_msg}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="CMS-formGroup">
                  <div className="CMS-formLabel">
                    {BRAND_KEY.key_Status}
                    &nbsp;&nbsp;
                    <Tooltips info={BrandData.key_Status} />
                  </div>
                  <div className="CMS-dropdown CMS-formControl">
                    <div className="CMS-select">
                      <select
                        name="keyStatus"
                        id="keystatus"
                        value={keyStatus}
                        onChange={handleKeyStatus}
                        className="pointer"
                      >
                        <option value="">Select</option>
                        <option value="ACTIVE">active</option>
                        <option value="IN_ACTIVE">inactive</option>
                      </select>
                      {statusError && (
                        <div className="text-danger">
                          {BRAND_KEY.empty_keyErr_msg}{" "}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-2">
            <button
              className="CMS-btn CMS-btnSecondary active CMS-btnMedium "
              id="keyAdd"
              onClick={submitBrandDetails}
            >
              Add
            </button>
          </div>
        </div>
        <div className="mt-20"></div>
        <div className="CMS-filter-result" id="result">
          <div className="dialog-box3" style={{ minHeight: "250px" }}>
            <div className="CMS-pagination">
              <div className="mb-20"></div>
              <Vpagination
                data={props.brandList}
                totalRecords={brandTotalRecord}
                paginationFirstValue={brandPaginationPage}
                paginationSecondValue={brandPaginationPageCount}
                setPaginationFirstValue={setBrandPaginationPage}
                setPaginationSecondValue={setBrandPaginationPageCount}
                recordsShow={brandRecordShow}
                setRecordsShow={setBrandRecordShow}
                isVisible={isVisible}
                setIsVisible={setIsVisible}
                csvData={csvData}
                exportPDF={exportPDF}
                callApi={callApi}
                csvFileName={"Account History report"}
              />
            </div>
            <div className="CMS-box CMS-table CMS-table-triped">

              <table className="text-center">
                <thead>
                  <tr>
                    {headers[0].map((item, index) => (
                      <th>
                        <div className='d-flex justify-content-center align-items-center'>
                          <p className='mx-3'>{item}</p>
                          <Tooltips info={`${BrandKeyList[index]}`} />
                        </div>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {props.brandList &&
                    Array.isArray(props.brandList) &&
                    Object(props.brandList).length > 0 &&
                    props.brandList.length > 0 ? (
                    props.brandList.map((item, ind) => {
                      return (
                        <tr key={generateKey(ind)}>
                          <td>{item.apiKey}</td>
                          <td>{item.keyName}</td>
                          <td>{item.brandId}</td>
                          <td>{item.addedBy}</td>
                          <td>{item.apiSecretKey}</td>
                          <td>{item.keyStatus}</td>
                          <td>{getDateTimeFormate(item.createdDate)}</td>
                          <td>{getDateTimeFormate(item.updatedDate)}</td>
                          <td>{item.addedBy}</td>
                          <td>{item.updatedBy}</td>
                          <td>
                            <div className="CMS-number-of-files CMS-select">
                              <select
                                id="accountStatus"
                                name="accountStatus"
                                key={item.apiSecretKey}
                                value={updateStatus}
                                onChange={(e) => keyUpdate(e, item)}
                              >
                                <option value="">Select</option>
                                <option value={`ACTIVE${item.apiSecretKey}`}>
                                  ACTIVE
                                </option>
                                <option value={`IN_ACTIVE${item.apiSecretKey}`}>
                                  IN_ACTIVE
                                </option>
                              </select>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <No__Data__Found colspan={8} />
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Dialog__Main>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    brandList: state.brandconfig.brandList,
  };
};

export default connect(mapStateToProps)(BrandsListDialog);
