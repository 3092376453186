import React, { useRef } from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Dialog__Main from "../Common/Dialog/Dialog__Main";
import Tooltips from "../Common/Tooltip";
import {
  RollBack,
  ViewAstra,
  manualSettlement,
  setIsBetDetailsVisible,
  setTicketId,
  setViewBetDetailsLoading,
  setViewBetDetailsPage,
  setViewBetDetailsRecordShow,
  setViewBetdetailsPageCount,
  viewBetDetails,
  viewBetDetailsOnAstra,
} from "./CyrusUiSlice";
import Skelton from "../Common/skelton/Skelton";
import Select from "../Common/common_inputs/Select_DDL";
import {
  headers,
  manualSettleOptions,
  toolTipsBetDetails,
  ViewAstraBetDeatilsHeader,
  ViewAstraBetDeatilsPDF,
  ViewAstraBetDetailsTooltips,
} from "./CyrusUiCommon";
import {
  getDateTimeFormateInvalidDate,
} from "../../sharedfiles/helper";
import No__Data__Found from "../Common/table/No__Data__Found";
import Vpagination from "../../sharedfiles/Vpagination";
import { exportPDFC } from "../../sharedfiles/jsPdfCommon";

const BetDetails = () => {
  const { betSelection, isBetDetailsVisible, ticketId, viewAstraBetDetails, viewBetDetailsLoading, viewBetDetailsPage, viewBetdetailsPageCount, viewBetDetailsRecordShow } = useSelector(
    (state) => state.cyrusuislice
  );
  const buttonRef = useRef(null);
  const dispatch = useDispatch();
  const [selectionID, setselectionID] = useState('');
  const [manualSettle, setManualSettle] = useState(false);
  const [manualType, setManualType] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [viewAstraOnBet, setViewAstraOnBet] = useState(false);
  const [pdfBody, setPdfBody] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const viewOnlyuser = useSelector((state) => state.dashboard.viewOnlyuser)


  function handleManualSettle(selectionId) {
    setManualSettle(true);
    setselectionID(selectionId)
  }

  function handleManualChange(e) {
    const { name, value } = e.target;
    setManualType(value);
  }

  useEffect(() => {
    dispatch(setIsBetDetailsVisible(false))
    let bet_tid = ticketId;
    if (!bet_tid) {
      bet_tid = localStorage.getItem("bet_settlement_tid");
      dispatch(setTicketId(bet_tid));
    }
    dispatch(viewBetDetails(bet_tid));
  }, [ticketId]);
  useEffect(() => {
    formTableData(viewAstraBetDetails)
  }, [viewAstraBetDetails]);

  function onUpload() {
    const payload = {
      selectionStatus: manualType,
      selectionId: selectionID,
      betId: ticketId,
    };
    dispatch(manualSettlement(payload));
    setManualSettle(false);
    setManualType("");
    setTimeout(() => {
      let bet_tid = ticketId
      dispatch(viewBetDetails(bet_tid))
    }, 2000);
  }

  function handleManualClose() {
    setManualSettle(false);
  }

  function handleRollback(selectionId) {
    setIsDisabled(true);
    dispatch(RollBack(ticketId, selectionId));
    setTimeout(() => {
      // setIsDisabled(false);
      let bet_tid = ticketId
      dispatch(viewBetDetails(bet_tid))
    }, 2000);
  }
  function formTableData(rawData = { totalRecords: "", data: [] }) {
    const bodyData =
      Object.keys(rawData).length > 0 &&
        Array.isArray(rawData.data) &&
        rawData?.data?.length > 0
        ? rawData?.data?.map((item) => {
          return [
            item?.betSelectionId,
            item?.outcomeName,
            item?.outcomeResult,
            item?.odds,
            item?.sportsName,
            item?.country,
            item?.tournamentName,
            item?.marketName,
            item?.competitionName,
            getDateTimeFormateInvalidDate(item?.competitionStartDate),
          ];
        })
        : [];

    setPdfBody(bodyData);
    const CsvData = [ViewAstraBetDeatilsPDF[0], ...bodyData];
    setCsvData(CsvData);
  }
  function handleViewDetails() {
    dispatch(viewBetDetailsOnAstra({ betId: ticketId, page: 0, pageCount: 25 }))
    setViewAstraOnBet(true);
  }
  function closeViewAstraBetDetails() {
    setViewAstraOnBet(false);
  }

  function callApiBetDetails() {
    dispatch(setViewBetDetailsLoading(false));

    dispatch(
      viewBetDetailsOnAstra({
        page: viewBetDetailsPage - 1,
        pageCount: viewBetdetailsPageCount,
        betId: ticketId,
      })
    );
  }
  function exportPDF() {
    const title = "Bet Settlement Report";
    exportPDFC(title, ViewAstraBetDeatilsPDF, pdfBody, {});
  }

  return (
    <>

      <Dialog__Main
        title={"Manual SettleMent"}
        dOpen={manualSettle}
        dClose={handleManualClose}
        width={"xs"}
        ids={"manualSettle"}
      >
        <Select
          title={"Manual Settlement"}
          name={"manual"}
          value={manualType}
          setValue={handleManualChange}
          options={manualSettleOptions}
          ids={"manualSettle"}
          info={"Showing Manual Settlement"}
        />
        <button
          onClick={(e) => onUpload(e)}
          className='CMS-btn CMS-btnSecondary active CMS-btnMedium'
          type='button'
          id='searchBetDetails'
        >
          Settle
        </button>
      </Dialog__Main>
      <Dialog__Main
        title={"VIEW ASTRA"}
        dOpen={viewAstraOnBet}
        dClose={() => closeViewAstraBetDetails()}
        width={"700px"}
      >
        <div className="CMS-filter-result" id="result">
          <div className="CMS-pagination">
            <Vpagination
              data={viewAstraBetDetails?.data}
              totalRecords={viewAstraBetDetails?.totalRecords}
              paginationFirstValue={viewBetDetailsPage}
              paginationSecondValue={viewBetdetailsPageCount}
              setPaginationFirstValue={setViewBetDetailsPage}
              setPaginationSecondValue={setViewBetdetailsPageCount}
              recordsShow={viewBetDetailsRecordShow}
              setRecordsShow={setViewBetDetailsRecordShow}
              isVisible={viewBetDetailsLoading}
              setIsVisible={setViewBetDetailsLoading}
              csvData={csvData}
              exportPDF={exportPDF}
              callApi={callApiBetDetails}
              csvFileName={"Bet Settlement Report"}
            />
          </div>
        </div>
        {!viewBetDetailsLoading ? (<Skelton vLen={5} hLen={7} />) : (<div className="CMS-table CMS-table-triped">
          <table>
            <thead>
              <tr>
                {ViewAstraBetDeatilsHeader.map((val, ind) => (
                  <th key={ind}>
                    <div className="d-flex justify-content-center align-items-center">
                      <p style={{ whiteSpace: "nowrap" }} className="mx-3">
                        {val}
                      </p>
                      <Tooltips info={ViewAstraBetDetailsTooltips[ind]} />
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {viewAstraBetDetails !== null &&
                viewAstraBetDetails !== undefined &&
                Array.isArray(viewAstraBetDetails.data) &&
                viewAstraBetDetails?.data?.length > 0 ? (
                viewAstraBetDetails?.data?.map((item, ind) => {
                  return (<tr key={ind}>
                    <td>{item?.betSelectionId}</td>
                    <td>{item?.outcomeName ? item?.outcomeName : '-'}</td>
                    <td>{item?.outcomeResult}</td>
                    <td>{item?.odds}</td>
                    <td>{item?.sportsName}</td>
                    <td>{item?.country}</td>
                    <td>{item?.tournamentName}</td>
                    <td>{item?.marketName}</td>
                    <td>{item?.competitionName}</td>
                    <td>{getDateTimeFormateInvalidDate(item?.competitionStartDate)}</td>
                  </tr>
                  );
                })
              ) : (
                <No__Data__Found colspan={50} />
              )}
            </tbody>
          </table>
        </div>)}
      </Dialog__Main>

      <div className="CMS-tab-panel active" id="CMS-betting">
        <div className="d-flex justify-content-end">
          <div className="CMS-tabContent">
            <button
              onClick={handleViewDetails}
              ref={buttonRef}
              disabled={isDisabled}
              className={` CMS-btn CMS-btnSecondary active CMS-btnSmall pointer`}
            >
              View Bet details on Astra
            </button>
          </div>
        </div>
        <div className="CMS-filter-result" id="result">
          {!isBetDetailsVisible ? (
            <Skelton vLen={5} hLen={7} />
          ) : (
            <div className="CMS-box">
              <div className="CMS-table CMS-table-triped">
                <table>
                  <thead>
                    <tr>
                      {headers?.map((item, index) => (
                        <th key={index}>
                          <div className="d-flex justify-content-center align-items-center">
                            <p
                              style={{ whiteSpace: "nowrap" }}
                              className="mx-3"
                            >
                              {item}
                            </p>
                            <Tooltips
                              key={index * 100}
                              info={toolTipsBetDetails[index]}
                            />
                          </div>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {betSelection !== null &&
                      betSelection !== undefined &&
                      Array.isArray(betSelection.betParts) &&
                      betSelection?.betParts?.length > 0 ? (
                      betSelection?.betParts?.map((val, ind) => {
                        return (
                          <tr>
                            <td>{val?.selectionId}</td>
                            <td>{val?.outcomeId}</td>
                            <td>{val?.outcomeName}</td>
                            <td>{val?.outcomeResult}</td>
                            <td>{val?.odds}</td>
                            <td>{val?.sportName}</td>
                            <td>{val?.country}</td>
                            <td>{val?.tournamentName}</td>
                            <td>{val?.marketId}</td>
                            <td>{val?.marketName}</td>
                            <td>{val?.competitionName}</td>
                            <td>{val?.competitionId}</td>
                            <td>
                              {getDateTimeFormateInvalidDate(
                                val?.competitionStartDate
                              )}
                            </td>
                            <td>{val?.competitionStatus}</td>
                            <td>
                              {val?.isoutrights ? "true" : "false"}
                            </td>
                            <td>{val?.matchStatus}</td>
                            <td>{val?.currentMatchStatus}</td>
                            <td>
                              {!!!val?.ticketTimeScore
                                ? val?.ticketTimeScore
                                : "-"}
                            </td>
                            <td>{"-"}</td>
                            <td>{val?.ticketTimeProbability}</td>
                            <td>{val?.currentTimeProbability}</td>
                            <td>{val?.settlementType ? val?.settlementType : '-'}</td>
                            <td>{val?.updatedBy ? val?.updatedBy : '-'}</td>
                            <td>
                              {getDateTimeFormateInvalidDate(val?.updatedOn)}
                            </td>
                            <td>
                              <button
                                onClick={() => handleManualSettle(val?.selectionId)}
                                className={`CMS-btn CMS-btnSecondary active CMS-btnSmall pointer ${(!val?.isManualSettlementAvailable || viewOnlyuser)
                                  ? "disable-button"
                                  : ""
                                  }`}
                              >
                                Manual Settlement
                              </button>
                              <button
                                onClick={() =>
                                  handleRollback(val?.selectionId)
                                }
                                ref={buttonRef}
                                disabled={isDisabled}
                                className={`mt-10 CMS-btn CMS-btnSecondary active CMS-btnSmall pointer ${(!val?.isRolloverAvailable || viewOnlyuser)
                                  ? "disable-button"
                                  : ""
                                  }`}
                              >
                                Roll Back
                              </button>

                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <No__Data__Found colspan={20} />
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>

      </div>
    </>
  );
};

export default BetDetails;
