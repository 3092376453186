import { regexType } from "../../../../sharedfiles/helper";
const freebetArray = ["", "FREE_BET"];
const allTypeArray = ["", "AUTO", "MANUAL", "DIRECT"];
const nonFreebetArray = ["", "DEPOSIT", "REFERRAL", "REGISTER"];
const optAuto = ["", "AUTO", "MANUAL", "DIRECT"];

const optManual = ["", "MANUAL", "DIRECT"];

export const bonusTypeData = [
  {
    id: 1,
    label: "FREEBET",
    value: "FREE_BET",
  },
  {
    id: 2,
    label: "DEPOSIT",
    value: "DEPOSIT",
  },
  {
    id: 3,
    label: "REFERRAL",
    value: "REFERRAL",
  },
  {
    id: 4,
    label: "REGISTER",
    value: "REGISTER",
  },
];
export const optDdlData = {
  id: 2,
  title: "Opt-In",
  name: "option",
  errorMsg: "Opt-In is required",
  // visible: nonFreebetArray,
  options: [
    {
      id: 1,
      label: "Auto",
      value: "AUTO",
    },
    {
      id: 2,
      label: "Manual",
      value: "MANUAL",
    },
    {
      id: 3,
      label: "Direct Bonus",
      value: "DIRECT",
    },
  ],
};

export const dateTypeData = [
  {
    id: 1,
    title: "Start Date",
    placeholder: "Start Date",
    name: "startDate",
    errorMsg: "Start Date is required",
    visible: allTypeArray,
  },
  {
    id: 2,
    title: "End Date",
    placeholder: "End Date",
    name: "validUntil",
    errorMsg: "End Date is required",
    visible: allTypeArray,
  },
];

export const bonusCreationData = [
  {
    id: 8,
    ids: "Apply Expiry",
    title: "Apply Expiry",
    placeholder: "Apply Expiry",
    type: "text",
    name: "applyExpiry",
    regex: regexType.numeric,
    maxLength: 3,
    errorMsg: "Apply Expiry is required",
    visible: allTypeArray,
    info: 'Makes it easier for you to understand how long the bonus is valid. Eg: 30 Days'
  },
  {
    id: 234,
    ids: "bonusName",
    title: "Bonus Name",
    placeholder: "Bonus Name",
    type: "text",
    name: "bonusName",
    regex: regexType.multiWord,
    maxLength: 15,
    errorMsg: "Bonus Name is required",
    visible: optManual,
    info: "The name to be given to the bonus for creating"
  },
]

export const bonusAllocationIpData = [
  {
    id: 4,
    ids: "Min. Dep. Amount",
    title: "Min. Dep. Amount",
    placeholder: "Min. Dep. Amount",
    type: "text",
    name: "minBonus",
    regex: regexType.numeric,
    maxLength: 15,
    errorMsg: "Min. Dep. Amount is required",
    visible: allTypeArray,
    info: "Minimum amount to be deposited for the bonus to be applicable"
  },
  {
    id: 1890,
    ids: "bonusAmountRef",
    title: "Bonus Amount Ref",
    placeholder: "Bonus Amount",
    type: "text",
    name: "bonusAmountRef",
    regex: regexType.numeric,
    maxLength: 8,
    errorMsg: "bonus Amount requried",
    visible: optAuto,
    info: "The amount to be given to the bonus"
  },
  {
    id: 1,
    ids: "bonusAmount",
    title: "Bonus Amount",
    placeholder: "Bonus Amount",
    type: "text",
    name: "bonusAmount",
    regex: regexType.numeric,
    maxLength: 8,
    errorMsg: "bonus Amount requried",
    visible: optManual,
    info: "The amount to be given to the bonus"
  },
  {
    id: 9087,
    ids: "MinDeposit",
    title: "Min. Deposit",
    placeholder: "Min Deposit",
    type: "text",
    name: "minDepositRef",
    regex: regexType.numeric,
    maxLength: 15,
    errorMsg: "Min Deposit  is required",
    visible: optAuto,
    info: "Minimum amount to be deposited for the bonus to be applicable"
  },
  {
    id: 6,
    ids: "DepRelease %",
    title: "Deposit Release %",
    placeholder: "Deposit Release %",
    type: "text",
    name: "bonusPercentage",
    regex: regexType.numeric,
    maxLength: 15,
    errorMsg: "Deposit Release % is required",
    visible: allTypeArray,
    info: 'The percentage amount to be released as Bonus based on Deposit  Eg: 100'
  },
  {
    id: 7935,
    ids: "releaseCriteria",
    title: "Release Criteria",
    name: "depoReleaseCriteria",
    errorMsg: "Release Criteria is required",
    visible: optAuto,
    type: "ddl",
    info: 'The amount to be released from Locked to Unlocked Bonus by the specified percentage',
    options: [
      {
        id: 1,
        label: "Rollover criteria",
        value: "ROLL_OVER_CRITERIA",
      },
      {
        id: 2,
        label: "wagering criteria",
        value: "WAGER_CRITERIA",
      },
    ]
  },
  {
    id: 605,
    ids: "ReleaseCriteria",
    title: "Release Criteria",
    placeholder: "Wager Criteria %",
    type: "text",
    name: "releaseCriteria",
    // regex: regexType.numeric,
    regex: regexType.numeric,
    maxLength: 15,
    errorMsg: "Release Criteria  is required",
    visible: optAuto,
    info: 'The amount to be released from Locked to Unlocked Bonus by the specified percentage.'
  },
  {
    id: 5,
    ids: "Max. Bonus Amount",
    title: "Max. Bonus Amount",
    placeholder: "Max Bonus Amount",
    type: "text",
    name: "maxBonus",
    regex: regexType.numeric,
    maxLength: 15,
    errorMsg: "Max. Bonus Amount is required",
    visible: allTypeArray,
    info: "maximum bonus amount to be given to the specific Bonus"
  },
  {
    id: 6,
    ids: "CashbackMinStake",
    title: "Cashback Min. Stake",
    placeholder: "Cashback Min. Stake",
    type: "text",
    name: "cashbackMinStake",
    regex: regexType.numeric,
    maxLength: 15,
    errorMsg: "Cashback Min. Stake is required",
    visible: allTypeArray,
    info: "minimum stake amount to be given to the specific Bonus"
  },
  {
    id: 7,
    ids: "CashbackMinOdds",
    title: "Cashback Min. Odds",
    placeholder: "Cashback Min. Odds",
    type: "text",
    name: "cashbackMinOdds",
    regex: regexType.decimalNumber,
    maxLength: 15,
    errorMsg: "Cashback Min. Odds is required",
    visible: allTypeArray,
    info: "minimum odds to be given to the specific Bonus"
  },
  {
    id: 8,
    ids: "CashbackNoLegs",
    title: "Cashback No Of Legs",
    placeholder: "Cashback No Of Legs",
    type: "text",
    name: "cashbackNoLegs",
    regex: regexType.numeric,
    maxLength: 15,
    errorMsg: "Cashback No Of Legs is required",
    visible: allTypeArray,
    info: "Number of selections to be added to the Bet slip for the bonus to be applicable"
  },
  {
    id: 9,
    ids: "CashbackPercentage",
    title: "Cashback %",
    placeholder: "Cashback %",
    type: "text",
    name: "cashbackPercentage",
    regex: regexType.upto100,
    // maxLength: 15,
    errorMsg: "Cashback Percentage is Required",
    visible: allTypeArray,
    info: "percentage to be given to the specific Bonus"
  },
  {
    id: 10,
    ids: "maxCashbackBonusAmount",
    title: "Max. Cashback Bonus Amount",
    placeholder: "Max. Cashback Bonus Amount",
    type: "text",
    name: "maxCashbackBonus",
    regex: regexType.numeric,
    // maxLength: 15,
    errorMsg: "Cashback Bonus Amount is Required",
    visible: allTypeArray,
    info: "Bonus Amount to be given to the specific Bonus"
  },

]

export const bonusUsageData = [
  {
    id: 2305,
    ids: "createbonusNumberOfLegs",
    title: "Number of Legs",
    placeholder: "",
    name: "numberOfLegs",
    errorMsg: "Number Of Legs is required",
    visible: allTypeArray,
    type: "text",
    info: "Number of selections to be added to the Bet slip for the bonus to be applicable"
  },
  {
    id: 999,
    ids: "maxWinPercent",
    title: "Max Winning %",
    placeholder: "Max Winning %",
    type: "text",
    name: "maxWinPercent",
    regex: regexType.numeric,
    maxLength: 8,
    visible: allTypeArray,
    info: "Specifies the maximum percentage that the user can win using bonus amount"
  },
  {
    id: 1000,
    ids: "maxWinAmount",
    title: "Max Win Amount",
    placeholder: "Max Win Amount",
    type: "text",
    name: "maxWinAmount",
    regex: regexType.numeric,
    maxLength: 8,
    visible: allTypeArray,
    info: "Specifies the maximum amount that the user can win using bonus amount"
  },


  {
    id: 9067,
    ids: "MinStakeRef",
    title: "Min. Stake",
    placeholder: "Min Stake",
    type: "text",
    name: "minStakeRef",
    regex: regexType.numeric,
    maxLength: 15,
    errorMsg: "Min Stake  is required",
    visible: optAuto,
    info: "Minimum amount to be wagered for the bonus to be applicable"
  },
  {
    id: 2375,
    ids: "createbonusminstack",
    title: "Min Stake Amount",
    placeholder: "Min Stake Amount",
    name: "minStake",
    errorMsg: "Min stake  is required",
    regex: regexType.numeric,
    visible: optAuto,
    type: "text",
    info: "Minimum amount to be wagered for the bonus to be applicable"
  },
  {
    id: 890,
    ids: "BonusPayoutAuto",
    title: "Bonus Payout % ",
    placeholder: "Bonus Payout %",
    type: "text",
    name: "bonusPayout",
    // regex: regexType.numeric,
    regex: regexType.numeric,
    maxLength: 15,
    errorMsg: "Bonus Payout % is required",
    visible: optAuto,
    info: 'The percentage of settlement amount the user will receive'
  },
  {
    id: 9,
    ids: "createOddsThreshold",
    title: "Odds Threshold",
    placeholder: "Odds Threshold",
    type: "text",
    name: "oddsThreshold",
    // regex: regexType.numeric,
    regex: regexType.decimalNumber,
    maxLength: 15,
    errorMsg: "Odds Threshold is required",
    visible: allTypeArray,
    info: "Minimum odds to be added to the bet selections on the Betslip for the bonus to be applicable"
  },
  {
    id: 2345,
    ids: "oddThrosholdAuto",
    title: "Odds Threshold",
    placeholder: "Odds Threshold",
    type: "text",
    name: "oddsThresh",
    // regex: regexType.numeric,
    regex: regexType.decimalNumber,
    maxLength: 15,
    errorMsg: "Odds Threshold is required",
    visible: optAuto,
    info: "Minimum odds to be added to the bet selections on the Betslip for the bonus to be applicable"
  },
]
export const inputFieldData = [
  {
    id: 112,
    ids: "bonusType",
    title: "Bonus Type",
    errorMsg: "Bonus Type is Required",
    visible: allTypeArray,
    type: "ddl",
    name: "bonusType",
    info: "Create bonus type based on user attributes Eg: Register/Deposit",
    options: [
      {
        id: 1,
        label: "DEPOSIT",
        value: "DEPOSIT",
      },

      {
        id: 2,
        label: "REGISTER",
        value: "REGISTER",
      },
      {
        id: 3,
        label: "REFERRAL",
        value: "REFERRAL",
      },
      {
        id: 4,
        label: "ONE CUT",
        value: "ONECUT",
      },
      {
        id: 5,
        label: "TWO CUT",
        value: "TWOCUT",
      },
      {
        id: 6,
        label: "CASHBACK BONUS",
        value: "CASHBACK",
      },
      {
        id: 7,
        label: "FREEBET",
        value: "FREE_BET",
      },
      {
        id: 8,
        label: "COUPON CODE",
        value: "COUPONCODE",
      },
      {
        id: 9,
        label: "GOODWILL BONUS",
        value: "GOODWILL",
      },
    ],
  },

  {
    id: 234475,
    ids: "createbonusStartDate",
    title: "Start Date",
    placeholder: "Start Date",
    name: "startDate",
    errorMsg: "Start Date is required",
    visible: allTypeArray,
    type: "date",
    info: "Makes it easier for you to understand when will be the bonus Start Date"
  },
  {
    id: 235,
    ids: "createbonusEndDate",
    title: "End Date",
    placeholder: "End Date",
    name: "validUntil",
    errorMsg: "End Date is required",
    visible: allTypeArray,
    type: "date",
    info: "Makes it easier for you to understand when will be the bonus End Date"
  },
  {
    id: 3,
    ids: "applicableTo",
    title: "Applicable To",
    name: "applicableSports",
    errorMsg: "Applicable To is required",
    visible: allTypeArray,
    type: "ddl",
    info: "Select bonus categories  Eg: Sports/Casino/Global",
    options: [
      {
        id: 1,
        label: "GLOBAL",
        value: "ALL",
      },
      {
        id: 2,
        label: "SPORTS",
        value: "SPORTS",
      },
      {
        id: 3,
        label: "CASINO",
        value: "CASINO",
      },
      {
        id: 4,
        label: "NE GAMES",
        value: "NE_GAMES",
      },
    ],
  },

  {
    id: 789,
    ids: "tags",
    title: "Tags",
    name: "tags",
    errorMsg: "Tags is required",
    visible: allTypeArray,
    type: "ddl",
    info: 'Type of Tag which attributes to the user',
    options: [
      {
        id: 1,
        label: "SignUp Bonus",
        value: "register_dir",
      },
      {
        id: 2,
        label: " First Deposit",
        value: "deposit_first",
      },
      {
        id: 3,
        label: "Second deposit",
        value: "deposit_second",
      },
      {
        id: 4,
        label: "Third deposit",
        value: "deposit_third",
      },
      {
        id: 5,
        label: "Forth Deposit",
        value: "deposit_fourth",
      },
      {
        id: 6,
        label: "Deposit Reload",
        value: "deposit_reload",
      },
      {
        id: 7,
        label: "Deposit Code",
        value: "deposit_reload",
      },
      {
        id: 8,
        label: "SignUp Code",
        value: "register_dir",
      },
      {
        id: 9,
        label: "One Cut",
        value: "one_cut",
      },
      {
        id: 10,
        label: "Two Cut",
        value: "two cut",
      },
      {
        id: 11,
        label: "REFERRAL",
        value: "register_refer",
      },
      {
        id: 12,
        label: "Goodwill Bonus",
        value: "goodwill_bonus",
      },
      {
        id: 13,
        label: "Cashback Bonus",
        value: "cash_back",
      },

    ],
  },
  {
    id: 987,
    ids: "bonusCode",
    title: "Bonus Code",
    placeholder: "Bonus Code",
    type: "textcode",
    name: "bonusCode",
    // regex: regexType.alphaNumeric,
    // maxLength: 8,
    errorMsg: "bonus code requried",
    visible: optManual,
    info: "certain code (mixture of no. & letters) generated for particular bonus Eg:0PSXPNUK"
  },
  // {
  //   id: 7934,
  //   ids: "channel",
  //   title: "Channel",
  //   name: "channel",
  //   errorMsg: "Channel is required",
  //   visible: optAuto,
  //   type: "ddl",
  //   info: 'Select the channel type Eg:Desktop/Mobile',
  //   options: [
  //     {
  //       id: 1,
  //       label: "APP",
  //       value: "APP",
  //     },
  //     {
  //       id: 2,
  //       label: "MOBILE WEB",
  //       value: "MOBILE_WEB",
  //     },
  //   ]
  // },

];

export const selectFieldData = [
  // {
  //   id: 1,
  //   title: "Freebet Status",
  //   name: "freebetStatus",
  //   errorMsg: "Freebet Status is required",
  //   visible: freebetArray,
  //   options: [
  //     {
  //       id: 1,
  //       label: "ACTIVE",
  //       value: "ACTIVE",
  //     },
  //     {
  //       id: 2,
  //       label: "INACTIVE",
  //       value: "IN_ACTIVE",
  //     },
  //   ],
  // },
  // {
  //   id: 2,
  //   title: "Opt-In",
  //   name: "option",
  //   errorMsg: "Opt-In is required",
  //   visible: nonFreebetArray,
  //   options: [
  //     {
  //       id: 1,
  //       label: "Auto",
  //       value: "AUTO",
  //     },
  //     {
  //       id: 2,
  //       label: "Manual",
  //       value: "MANUAL",
  //     },
  //   ],
  // },
  // {
  //   id: 3,
  //   title: "Applicable To",
  //   name: "applicableSports",
  //   errorMsg: "Applicable To is required",
  //   visible: freebetArray,
  //   options: [
  //     {
  //       id: 1,
  //       label: "GLOBAL",
  //       value: "ALL",
  //     },
  //     {
  //       id: 2,
  //       label: "SPORTS",
  //       value: "SPORTS",
  //     },
  //     {
  //       id: 3,
  //       label: "CASINO",
  //       value: "CASINO",
  //     },
  //   ],
  // },
  // {
  //   id: 4,
  //   title: "Tags",
  //   name: "tags",
  //   errorMsg: "Tags is required",
  //   visible: nonFreebetArray,
  //   options: [
  //     {
  //       id: 1,
  //       label: "SignUp Bonus",
  //       value: "register_dir",
  //     },
  //     {
  //       id: 2,
  //       label: " First Deposit",
  //       value: "deposit_first",
  //     },
  //     {
  //       id: 3,
  //       label: "second deposit",
  //       value: "deposit_second",
  //     },
  //     {
  //       id: 4,
  //       label: "Third deposit",
  //       value: "deposit_third",
  //     },
  //     {
  //       id: 5,
  //       label: "Forth Deposit",
  //       value: "deposit_fourth",
  //     },
  //     {
  //       id: 6,
  //       label: "Deposit Reload",
  //       value: "deposit_reload",
  //     },
  //   ],
  // },
];

export const filterDdl = [
  {
    id: 1,
    ids: "filterType",
    title: "Bonus Type",
    name: "filterBonusType",
    info: 'Filter the bonuses based on user attributes Eg: Register/Deposit',
    options: [
      {
        id: 1,
        label: "Freebet",
        value: "FREE_BET",
      },
      {
        id: 2,
        label: "Deposit",
        value: "DEPOSIT",
      },

      {
        id: 3,
        label: "Register",
        value: "REGISTER",
      },
      {
        id: 4,
        label: "Referral",
        value: "REFERRAL",
      },
      {
        id: 5,
        label: "One Cut",
        value: "ONECUT",
      },
      {
        id: 6,
        label: "Two Cut",
        value: "TWOCUT",
      },

      {
        id: 7,
        label: "Coupon Code",
        value: "COUPONCODE",
      },
      {
        id: 8,
        label: "Goodwill",
        value: "GOODWILL",
      },
      {
        id: 9,
        label: "Cashback",
        value: "CASHBACK",
      },
    ],
  },
  {
    id: 2,
    ids: "filterStatus",
    title: "Bonus Status",
    name: "filterBonusStatus",
    info: "filter the Bonuses according to bonus status Eg:active/inactive/expired",
    options: [
      {
        id: 1,
        label: "Active",
        value: "ACTIVE",
      },
      {
        id: 2,
        label: "In Active",
        value: "IN_ACTIVE",
      },
      {
        id: 3,
        label: "Expired",
        value: "EXPIRED",
      },
    ],
  },
  {
    id: 3,
    ids: "allocationType",
    title: "Allocation Type",
    name: "filterAllocationType",
    info: "Filter the bonuses according to allocationType Eg:Auto/Manual",
    options: [
      {
        id: 1,
        label: "Auto",
        value: "AUTO",
      },
      {
        id: 2,
        label: "Manual",
        value: "MANUAL",
      },
    ],
  },
];

export const bonusAllocationData =
{
  id: 1,
  title: "Bonus Allocation",
  info: 'Unlocked%: Awarded bonus amount can be used instantly, Locked%: The bonus amount awarded to the user which needs to be released based on wagering criteria percentage',
  type: "text",
  fields: [
    {
      id: 1,
      ids: "BonusUnlocked",
      name: "instantBonus",
      placeholder: "Unlocked %",
      type: "text",
      regex: /^[0-9][0-9]?$|^100$/,
      // regex: regexType.maxNumber(100),
      maxLength: 3,
      errorMsg: "Unlocked % is required",
      visible: true,
      maxValue: "100",
      tittle: "Unlocked %",
    },
    {
      id: 2,
      ids: "BonusLocked",
      name: "wagerBonus",
      placeholder: "Locked %",
      type: "text",
      regex: regexType.numeric,
      maxLength: 3,
      errorMsg: "Locked % is required",
      visible: true,
      disabled: true,
      tittle: "Locked %",
    },
  ],
}
export const rolloverBAdata =
{
  id: 1,
  title: "Bonus Allocation",
  info: 'Unlocked%: Awarded bonus amount can be used instantly, Locked%: The bonus amount awarded to the user which needs to be released based on wagering criteria percentage',
  type: "text",
  fields: [
    {
      id: 1,
      ids: "DepoBonusUnlocked",
      name: "depoUnlockedBonus",
      placeholder: "Unlocked %",
      type: "text",
      regex: /^[0-9][0-9]?$|^100$/,
      // regex: regexType.maxNumber(100),
      maxLength: 3,
      errorMsg: "Unlocked % is required",
      disabled: true,
      visible: true,
      maxValue: "100",
      tittle: "Unlocked %",
    },
    {
      id: 2,
      ids: "DepoBonusLocked",
      name: "depoLockedBonus",
      placeholder: "Locked %",
      type: "text",
      regex: regexType.numeric,
      maxLength: 3,
      errorMsg: "Locked % is required",
      visible: true,
      disabled: true,
      tittle: "Locked %",
    },
  ],
}
export const allocationCriteria = [
  {
    id: 1,
    title: "Bonus Allocation",
    info: 'Unlocked%: Awarded bonus amount can be used instantly, Locked%: The bonus amount awarded to the user which needs to be released based on wagering criteria percentage',
    type: "text",
    fields: [
      {
        id: 1,
        ids: "instantBonus",
        name: "instantBonus",
        placeholder: "Unlocked %",
        type: "text",
        regex: /^[0-9][0-9]?$|^100$/,
        // regex: regexType.maxNumber(100),
        maxLength: 3,
        errorMsg: "Unlocked % is required",
        visible: true,
        maxValue: "100",
      },
      {
        id: 2,
        ids: "wagerBonus",
        name: "wagerBonus",
        placeholder: "Locked %",
        type: "text",
        regex: regexType.numeric,
        maxLength: 3,
        errorMsg: "Locked % is required",
        visible: true,
        disabled: true,
      },
    ],
  },
  {
    id: 2,
    title: "Release Criteria",
    type: "release",
    fields: [
      // {
      //   id: 1,
      //   name: "depositCriteria",
      //   placeholder: "Deposit Criteria %",
      //   type: "text",
      //   regex: regexType.decimalNumeric,
      //   // maxLength: 4,
      //   errorMsg: "Deposit Criteria % is required",
      //   visible: true,
      // },
      {
        id: 2,
        ids: "wagerCriteria",
        name: "wagerCriteria",
        placeholder: "Wager Criteria %",
        type: "text",
        regex: regexType.decimalNumeric,
        // maxLength: 4,
        errorMsg: "Wager Criteria % is required",
        visible: true,
      },
    ],
  },
];

export const releaseCriteriaData = {
  id: 2,
  title: "Release Criteria",
  info: "The amount to be released from Locked to Unlocked Bonus by the specified percentage",
  type: "release",
  fields: [
    // {
    //   id: 1,
    //   name: "depositCriteria",
    //   placeholder: "Deposit Criteria %",
    //   type: "text",
    //   regex: regexType.decimalNumeric,
    //   // maxLength: 4,
    //   errorMsg: "Deposit Criteria % is required",
    //   visible: true,
    // },
    {
      id: 2,
      ids: "WagerCriteria%",
      name: "wagerCriteria",
      placeholder: "Wager Criteria %",
      type: "text",
      regex: regexType.decimalNumeric,
      // maxLength: 4,
      tittle: "Wager Criteria %",
      errorMsg: "Wager Criteria % is required",
      visible: true,
    },
    {
      id: 3001,
      ids: "unlockNumberOfLegs",
      tittle: "RC Number of Legs",
      placeholder: "Unlock Number of Legs",
      name: "unlockNumberOfLegs",
      errorMsg: "Unlock Number Of Legs is required",
      visible: true,
      type: "text",
    },
    {
      id: 3002,
      ids: "unlockOddsThreshold",
      tittle: "RC OddsThreshold",
      placeholder: "Unlock OddsThreshold",
      name: "unlockOddsThreshold",
      errorMsg: "Unlock OddsThreshold is required",
      visible: true,
      regex: regexType.decimalNumber,
      type: "text",
    }
  ],
};
export const rolloverTimesData = {
  id: 2,
  title: "Rollover Times",
  info: "How many times will the deposit be rolled over",
  type: "rollover",
  fields: [
    {
      id: 1,
      ids: "rolloverTime",
      name: "rolloverTime",
      tittle: "Rollover Times",
      placeholder: "Rollover Times",
      type: "text",
      regex: regexType.notZero,
      // maxLength: 4,
      errorMsg: "Rollover Times is required",
      visible: true,
    },
    {
      id: 3003,
      ids: "rollNumberOfLegs",
      tittle: "RC Number of Legs",
      placeholder: "Unlock Number of Legs",
      name: "rollNumberOfLegs",
      errorMsg: "Unlock Number Of Legs is required",
      visible: true,
      type: "text",
    },
    {
      id: 3004,
      ids: "rollOddsThreshold",
      tittle: "RC OddsThreshold",
      placeholder: "Unlock OddsThreshold",
      name: "rollOddsThreshold",
      errorMsg: "Unlock OddsThreshold is required",
      visible: true,
      regex: regexType.decimalNumber,
      type: "text",
    }
  ],
};
export const referalAllocationData =
{
  id: 3,
  title: "Referral Allocation",
  info: "Referrer%: The percentage bonus to be released to Referrer, Referee%: The percentage bonus to be released to Referee, Recurring Referrer%: The percentage bonus to be released to Referrer on multiple references",
  type: "referal",
  fields: [
    {
      id: 1,
      name: "Referrer",
      placeholder: "Referrer %",
      type: "text",
      regex: regexType.decimalNumeric,
      // maxLength: 4,
      errorMsg: "Referrer % is required",
      visible: true,
    },
    {
      id: 2,
      name: "Referee",
      placeholder: "Referee %",
      type: "text",
      regex: regexType.decimalNumeric,
      // maxLength: 4,
      errorMsg: "Referee % is required",
      visible: true,
    },
    {
      id: 3,
      name: "Recurring",
      placeholder: "Recurring Referrer %",
      type: "text",
      regex: regexType.decimalNumeric,
      // maxLength: 4,
      errorMsg: "Recurring Referrer % is required",
      visible: true,
    },
  ],
}

export const errors = [
  "bonusType",
  "bonusCode",
  "tags",
  "startDate",
  "validUntil",
  "bonusPercentage",
  "bonusAliases",
  "bonusDescription",
  "depositCriteria",
  "instantBonus",
  "maxBonus",
  "minBonus",
  "wagerBonus",
  "wagerCriteria",
  "promoCode",
  "settlementType",
  "applicableSports",
  "bonusType",
  "oddsThreshold",
  "applyExpiry",
  "option",
  "bonusName",
  "freebetAmount",
  "freebetStatus",
  "sportsSelected",
  "bonusAmount",
  "oddsThresh",
  "bonusPayout",
  "numberOfLegs",
  "minStake",

  "minDepositRef",
  "Recurring",
  "Referrer",
  "Referee",
  "minStakeRef",
  "releaseCriteria",
  "channel",
  "bonusAmountRef",
  "wagerCriteria",
  "unlockNumberOfLegs",
  "unlockOddsThreshold",
  "rolloverTime",
  "rollNumberOfLegs",
  "rollOddsThreshold",
  "unlockApplicableType",
  "rollOverApplicableType",
  "providerName",
  "gameName",
  'releaseProviders',
  "releaseGameName",
  "rollOverProvider",
  "rollOverGameName",
  "cashbackBetType"
];

export const freebetFields = [
  "option",
  "bonusType",
  "bonusName",
  // "freebetAmount",
  "applyExpiry",
  "oddsThreshold",
  "startDate",
  "validUntil",
  // "freebetStatus",
  "applicableSports",
  "sportsSelected",
  // "maxBonus",
  "bonusAmount",
  "numberOfLegs",
  // "bonusPercentage"
];

export const autoDeposit = [
  "option",
  "bonusType",
  // "freebetName",
  // "freebetAmount",
  "applyExpiry",
  "oddsThreshold",
  "startDate",
  "validUntil",
  // "freebetStatus",
  "applicableSports",
  "tags",
  "bonusPercentage",
  "instantBonus", //unlocked %
  "wagerBonus", //locked %
  // "depositCriteria",
  "wagerCriteria",
  "maxBonus",
  "minBonus",
  "numberOfLegs",
  "rolloverTime",
  "depoReleaseCriteria",
  "wagerCriteria",
  "unlockNumberOfLegs",
  "unlockOddsThreshold",
  "rolloverTime",
  "rollNumberOfLegs",
  "rollOddsThreshold",
  "unlockApplicableType",
  "rollOverApplicableType",
  "providerName",
  "gameName",
  'releaseProviders',
  "releaseGameName",
  "rollOverProvider",
  "rollOverGameName",
];
export const autoRegister = [
  "option",
  "bonusType",
  // "freebetName",
  // "freebetAmount",
  "applyExpiry",
  "oddsThreshold",
  "startDate",
  "validUntil",
  // "freebetStatus",
  "applicableSports",
  "tags",
  //  "bonusPercentage",
  "instantBonus", //unlocked %
  "wagerBonus", //locked %
  // "depositCriteria",
  "wagerCriteria",
  "maxBonus",
  "numberOfLegs",
  "wagerCriteria",
  "unlockNumberOfLegs",
  "unlockOddsThreshold",
  "rollNumberOfLegs",
  "rollOddsThreshold",
  "unlockApplicableType",
  "providerName",
  "gameName",
  'releaseProviders',
  "releaseGameName",
  "rollOverProvider",
  "rollOverGameName",
];
export const oneTwoCut = [
  "oddsThresh",
  "bonusPayout",
  "numberOfLegs",
  "minStake",
  "bonusType",
  "startDate",
  "validUntil",
  "tags",
  "option",
  "applyExpiry",
  "applicableSports",
  "providerName",
  "gameName",
  'releaseProviders',
  "releaseGameName",
  "rollOverProvider",
  "rollOverGameName",
]
export const nonFreebetFields = [
  "bonusType",
  "bonusCode",
  "maxBonus",
  "minBonus",
  "bonusPercentage",
  // "bonusDescription",
  "applyExpiry",
  "oddsThreshold",
  "startDate",
  "validUntil",
  "option",
  "tags",
  // "sportsSelected",
  "instantBonus", //unlocked %
  "wagerBonus", //locked %
  // "depositCriteria",
  "wagerCriteria",
  "applicableSports",
  "maxBonus",
  "numberOfLegs",
  "wagerCriteria",
  "unlockNumberOfLegs",
  "unlockOddsThreshold",
  "rolloverTime",
  "rollNumberOfLegs",
  "rollOddsThreshold",
  "unlockApplicableType",
  "rollOverApplicableType",
  "providerName",
  "gameName",
  'releaseProviders',
  "releaseGameName",
  "rollOverProvider",
  "rollOverGameName",
];
export const referalField = [
  "bonusType",
  "applicableSports",
  "maxBonus",
  "oddsThreshold",
  "startDate",
  "validUntil",
  "option",
  "tags",
  "bonusPercentage",
  "instantBonus", //unlocked %
  "wagerBonus", //locked %
  "releaseCriteria",
  "minStakeRef",
  "minDepositRef",
  // "channel",
  "Recurring",
  "Referrer",
  "Referee",
  "bonusAmountRef",
  "applyExpiry",
  "numberOfLegs",
  "providerName",
  "gameName",
  'releaseProviders',
  "releaseGameName",
  "rollOverProvider",
  "rollOverGameName",
];

export const goodWillBonus = [
  "bonusType",
  "startDate",
  "validUntil",
  "numberOfLegs",
  "applicableSports",
  "bonusAmount",
  "minStakeRef",
  "applyExpiry",
  "tags",
  "oddsThreshold",
  "bonusName",
  "depoReleaseCriteria",
  "wagerCriteria",
  "unlockNumberOfLegs",
  "unlockOddsThreshold",
  "rolloverTime",
  "rollNumberOfLegs",
  "rollOddsThreshold",
  "unlockApplicableType",
  "rollOverApplicableType",
  "providerName",
  "gameName",
  'releaseProviders',
  "releaseGameName",
  "rollOverProvider",
  "rollOverGameName",
]

export const cashbackBonus = [
  "bonusType",
  "startDate",
  "validUntil",
  "applicableSports",
  "tags",
  "applyExpiry",
  "providerName",
  "gameName",
  "bonusAppFor",
  "bettingType",
  "cashbackFrequency",
  "cashbackType",
  "depoReleaseCriteria",
  "cashbackMinStake",
  "cashbackMinOdds",
  "cashbackNoLegs",
  "cashbackPercentage",
  "maxCashbackBonus",
  "numberOfLegs",
  'releaseProviders',
  "releaseGameName",
  "rollOverProvider",
  "rollOverGameName",
  "oddsThreshold",
  "maxBonus",
  "cashbackBetType"
]

export const sportApplicable = [
  { id: 1, label: "GLOBAL", value: "ALL" },
  { id: 2, label: "SPORTS", value: "SPORTS" },
  { id: 3, label: "CASINO", value: "CASINO" },
  { id: 4, label: "NE GAMES", value: "NE_GAMES" },
]

export const bonusAppforOpt = [
  { id: 1, label: "Win", value: "Won" },
  { id: 2, label: "Loss", value: "Lost" },
]

export const bettingTypeOpt = [
  { id: 1, label: "CUMULATIVE", value: "CUMULATIVE" },
  { id: 2, label: "PERBET", value: "PER_BET" },
]

export const cashbackFrequencyOpt = [
  { id: 1, label: "Daily", value: "DAILY" },
  { id: 2, label: "Weekly", value: "WEEKLY" },
  { id: 3, label: "Monthly", value: "MONTHLY" },
]

export const cashbackTypeOpt = [
  { id: 1, label: "Real Cash", value: "REAL_CASH" },
  { id: 2, label: "Bonus Cash", value: "BONUS_CASH" },
]

export const CasbackBetType = [
  { id: 1, label: "Combi", value: "Combi" },
  { id: 2, label: "Custom", value: "Custom" },
  { id: 3, label: "Fancy", value: "Fancy" },
  { id: 4, label: "Multi", value: "Multi" },
  { id: 5, label: "Single", value: "Single" },
  { id: 6, label: "Split", value: "Split" },
  { id: 7, label: "System ", value: "System" },
]
export const addTbeaderInfo = [
  "Showing Sport",
  "Showing Country",
  "Showing League",
  "Showing Events",
  "Showing Remove",
]

export const addTbheader = [
  "Sport",
  "Country",
  "League",
  "Events",
  "Remove",
];