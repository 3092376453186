import io from "socket.io-client";
let socket = null;
const NOTIFICATION_SOCKET_DEV_END_POINT = 'https://dev-astraws.sportsit-tech.net'
const NOTIFICATION_SOCKET_PROD_END_POINT = 'https://astraws.negroup-tech.net'

let endPoint = ''
if (process.env.REACT_APP_SW_ENV === "Production") {
    endPoint = NOTIFICATION_SOCKET_PROD_END_POINT
} else if (process.env.REACT_APP_REPORTS === "dev") {
    endPoint = NOTIFICATION_SOCKET_DEV_END_POINT
}

export const subscribeNotification = (userId) => {
    const token = localStorage.getItem("token")
    if (socket && token?.length > 0) {
        const brandId = localStorage.getItem("brandId")
        try {
            socket.emit(
                "subscribe",
                { event: "notification", token: token, userId: userId, brandId: brandId }
            );
        } catch (err) {
            console.error(err.toString());
        }
    }
};
export const socketConnect = () => {
    socket = io(endPoint, {
        transports: ["websocket"],
    });

    socket.on("notification", function (update) {
        const event = new CustomEvent("notificationUpdates", {
            detail: {
                message: update.message,
                title: update.title,
            },
        });
        window.dispatchEvent(event);
    });

    socket.on("connect_error", function (err) {
        console.error(`connect_error due to ${err.message}`);
    });
    subscribeNotification();
};

socketConnect();