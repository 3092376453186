import React, { useState, useEffect } from "react";
import Tooltips from "../Common/Tooltip";
import { COMMON_CONSTANTS, PRIMARYPLAYER_CONSTANTS } from "../../sharedfiles/Constants";
import { COMMON_MSGS } from "../../sharedfiles/tooltipMsgs";
import { getCommentHistory, getUserWatchList, getWatchUsersActivity, removeUserWatchList, setActivityFirstPage, setActivityIsVisible, setActivityRecordShow, setActivitySecondPage, setDocsData, setHistoryFirstPage, setHistoryIsVisible, setHistoryRecordShow, setHistorySecondPage, setPdfData, setPrevFormData, setWatchListFirstPage, setWatchListIsVisible, setWatchListRecord, setWatchListSecondPage } from "./userWatchListSlice";
import { useDispatch, useSelector } from "react-redux";
import { cleanObject, formatMoney, getDateTimeFormate } from "../../sharedfiles/helper";
import { Link, useHistory } from "react-router-dom";
import No__Data__Found from "../Common/table/No__Data__Found";
import { userWatchListData, userWatchListDate } from "../Common/data/mapData";
import Input from "../Common/common_inputs/Input";
import ButtonGroup from "../Common/Buttons/ButtonGroup";
import Vpagination from "../../sharedfiles/Vpagination";
import { exportPDFC } from "../../sharedfiles/jsPdfCommon";
import CommentBox from "../Common/CommentBox";
import Dialog__Main from "../Common/Dialog/Dialog__Main";
import Table from "../Common/table/Table";
import ConfirmDialog from "../Common/Dialog/ConfirmDialog";
import { getPlayer, updateplayer, userControl } from "../primaryplayer/PrimaryPlayerSlice";
import { getBetHistorytableList } from "../BetHistory/betHistorySlice";
import { getPaymentSearchtableList } from "../PaymentSearch/paymentSearchSlice";
import { WatchlistTypes } from "../Common/data/BettingHistory/BettinghistoryData";
import Select from "../Common/common_inputs/Select_DDL";


const UsersWatchlist = () => {

    const dispatch = useDispatch()
    const history = useHistory();
    const [formData, setFormData] = useState({
        customerId: "",
        userName: "",
        watchlistType: ""
    })
    const [watchListCsvData, setWatchListCsvData] = useState([])
    const [watchListPdfData, setWatchListPdfData] = useState([])
    const [comment, setComment] = useState('')
    const [emptyErr, setEmptyErr] = useState(false)
    const [popUp, setPopUp] = useState(false)
    const [removeId, setRemoveId] = useState()

    const [activityPopUp, setActivityPopUp] = useState(false)
    const [activityaTbData, setActivityaTbData] = useState('')
    const [activityId, setActivityID] = useState()
    const [activityCSV, setActivityCSV] = useState([])
    const [activityPDF, setActivityPDF] = useState([])

    const [historyPop, setHistoryPop] = useState(false)
    const [historyTbData, setHistoryTbData] = useState('')
    const [historyId, setHistoryId] = useState('')
    const [historyCSV, setHistoryCSV] = useState([])
    const [historyPDF, setHistoryPDF] = useState([])

    const [openAction, setOpenAction] = useState(false)
    const [openconfirmModal, setOpenConfirmModal] = useState(false);
    const [activateModal, setActivateModal] = useState(false);

    const watchListUsers = useSelector((p) => p.UserWatchListSlice.watchListUsers)
    const watchListFirstPage = useSelector((p) => p.UserWatchListSlice.watchListFirstPage)
    const watchListSecondPage = useSelector((p) => p.UserWatchListSlice.watchListSecondPage)
    const watchLIstIsVisible = useSelector((p) => p.UserWatchListSlice.watchLIstIsVisible)
    const watchListRecord = useSelector((p) => p.UserWatchListSlice.watchListRecord)

    const userActivity = useSelector((p) => p.UserWatchListSlice.userActivity)
    const activityFirstPage = useSelector((p) => p.UserWatchListSlice.activityFirstPage)
    const activitySecondPage = useSelector((p) => p.UserWatchListSlice.activitySecondPage)
    const activityRecordShow = useSelector((p) => p.UserWatchListSlice.activityRecordShow)
    const activityIsVisible = useSelector((p) => p.UserWatchListSlice.activityIsVisible)

    const commentHistoryData = useSelector((p) => p.UserWatchListSlice.commentHistoryData)
    const historyFirstPage = useSelector((p) => p.UserWatchListSlice.historyFirstPage)
    const historySecondPage = useSelector((p) => p.UserWatchListSlice.historySecondPage)
    const historyRecordShow = useSelector((p) => p.UserWatchListSlice.historyRecordShow)
    const historyIsVisible = useSelector((p) => p.UserWatchListSlice.historyIsVisible)
    const playerInfo = useSelector((p) => p.primaryplayer.playerData)
    const { prevFormData, docsData, pdfData } = useSelector((p) => p.UserWatchListSlice)
    const viewOnlyuser = useSelector((state) => state.dashboard.viewOnlyuser)

    const watchListMainHeader = [
        "Customer ID",
        "User Name",
        "First Name",
        "Last Name",
        // "Total Count",
        "Created By",
        "Updated By",
        "Created Date",
        "Updated Date",
        "Comments",
        "Actions",
        "Activity"
    ]
    const watchListMainInfo = [
        "Showing Customer ID",
        "Showing User Name",
        "Showing First Name",
        "Showing Last Name",
        // "Showing Total Count",
        "Showing Created By",
        "Showing Updated By",
        "Showing Created Date",
        "Showing Updated Date",
        "Showing the latest Comments",
        "Showing Actions",
        "Showing Activity"
    ]

    const handleSearchIp = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => {
            return {
                ...prevState,
                [name]: value,
            };
        });
        formDataToSliceSetter(name, value);
        const updatedFormData = { ...prevFormData, [name]: value };
        dispatch(setPrevFormData(updatedFormData));
    };

    useEffect(() => {
        dispatch(setPrevFormData(prevFormData));
        setFormData({
            customerId: "",
            userName: ""
        })
        const dataExist = Object.keys(userActivity).length > 0
        if (!dataExist) {
            dispatch(getUserWatchList({ isWatched: true }, 0, 25))
        } else {
            setWatchListPdfData(pdfData);
            setWatchListCsvData(docsData);
        }
        stateSetter();
        dispatch(setWatchListIsVisible(true));
        dispatch(getWatchUsersActivity({}, 0, 25))
        dispatch(setActivityIsVisible(true))
        dispatch(setHistoryIsVisible(true))


    }, [])

    const stateSetter = () => {
        const isFormDataExist = Object.keys(prevFormData).length
        if (isFormDataExist) {
            Object.entries(prevFormData).map(([key, value]) => setFormData((prevState) => {
                return {
                    ...prevState,
                    [key]: value,
                }
            }))
        }
    }

    const formDataToSliceSetter = (key, val) => {
        const newEntry = { ...prevFormData, [key]: val }
        dispatch(setPrevFormData(newEntry));
    }

    const handleClickSearch = (e) => {
        e.preventDefault();
        const payload = {
            isWatched: true,
            userId: formData.customerId,
            userName: formData.userName,
            AutoWatchActivityType: formData.watchlistType
        }
        const finalPayload = cleanObject(payload)
        dispatch(getUserWatchList(finalPayload, 0, 25))
    }

    const resetButton = () => {
        setFormData({
            customerId: "",
            userName: "",
            watchlistType: ""
        })
        dispatch(setPrevFormData({}))
        dispatch(getUserWatchList({ isWatched: true }, 0, 25))
    }

    const theader = [
        [
            "Customer ID",
            "User Name",
            "First Name",
            "Last Name",
            // "Total Count",
            "Created By",
            "Updated By",
            "Created Date",
            "Updated Date",
            "Comments",
        ]
    ]

    const update = () => {
        const tbody = watchListUsers && watchListUsers.data &&
            watchListUsers.data.length > 0 ? watchListUsers.data.map((item) => [
                item.userId,
                item.userName,
                item.firstName,
                item.lastName,
                // item.totalCount,
                item.createdBy,
                item.createdBy,
                getDateTimeFormate(item.createdDate),
                getDateTimeFormate(item.updatedDate),
                item.comments
            ]) : []
        setWatchListPdfData(tbody);
        dispatch(setPdfData(tbody));
        const data = Array.isArray(tbody) && tbody.length > 0 ? [theader[0], ...tbody] : [theader[0], []];
        setWatchListCsvData(data);
        dispatch(setDocsData(data));
    }

    const watchListExportPdf = () => {
        const title = "User Watch List report";
        exportPDFC(title, theader, watchListPdfData);
    }

    const callApi = (pageNumber, itemsPerPage) => {
        const data = {
            isWatched: true,
        }
        dispatch(setWatchListIsVisible(true));
        dispatch(getUserWatchList(
            data,
            pageNumber - 1,
            itemsPerPage
        ))
    }

    useEffect(() => {
        update()
    }, [watchListUsers])

    const confirmRemove = () => {
        if (comment === "") {
            setEmptyErr(true)
        } else {
            dispatch(removeUserWatchList(removeId, { comments: comment }))
            setPopUp(false)
            setEmptyErr(false)
            setOpenAction(false)
        }
        setTimeout(() => {
            setComment('')
        }, 500)
    }

    const removePopClose = () => {
        setPopUp(false)
        setEmptyErr(false)
    }

    const onIpChange = (e) => {
        const { name, value } = e.target
        setComment(value)
        value === '' ? setEmptyErr(true) : setEmptyErr(false)
    }
    const userActivityHandle = (id) => {
        setActivityIsVisible(true)
        setActivityPopUp(true)
        setActivityID(id)
        const payload = {
            userId: id
        }
        dispatch(getWatchUsersActivity(payload, 0, 25))
    }
    useEffect(() => {
        activityUpdate()
    }, [userActivity])

    const activityHeader = [
        [
            "Customer ID",
            "First Name",
            "Last Name",
            "Total Deposit",
            "Total Withdrawal",
            "Total Stakes",
            "Total Payout",
            "Liability of the user (Potential Payout)",
            "Transaction Id",
            "Transaction Type"
        ]
    ]
    const activityTbHeader = [

        "Customer ID",
        "First Name",
        "Last Name",
        "Total Deposit",
        "Total Withdrawal",
        "Total Stakes",
        "Total Payout",
        "Liability of the user (Potential Payout)",
        "Transaction Id",
        "Transaction Type"

    ]
    const activityinfo = [
        "Showing Customer ID",
        "Showing First Name",
        "Showing Last Name",
        "Showing Total Deposit",
        "Showing Total Withdrawal",
        "Showing Total Stakes",
        "Showing Total Payout",
        "Showing Liability of the user (Potential Payout)",
        "Showing Transaction Id",
        "Showing Transaction Type"
    ]
    const activityUpdate = () => {

        const tbody = userActivity && userActivity.data &&
            userActivity.data.length > 0 ? userActivity.data.map((item) => [
                item.userId,
                item.firstName,
                item.lastName,
                formatMoney(item.totalDeposits),
                formatMoney(item.totalWithdrawals),
                formatMoney(item.totalStake),
                formatMoney(item.totalPayout),
                formatMoney(item.potentialPayout),
                item.transactionId,
                item.transactionType
            ]) : []

        setActivityaTbData([activityTbHeader, activityinfo, ...tbody])
        setActivityPDF(tbody)
        const data = Array.isArray(tbody) && tbody.length > 0 ? [activityHeader[0], ...tbody] : [activityHeader[0], []];
        setActivityCSV(data);
    }
    const callApiActivity = (pageNumber, itemsPerPage) => {
        const data = {
            userId: activityId
        }
        dispatch(setActivityIsVisible(true));
        dispatch(getWatchUsersActivity(data, pageNumber - 1, itemsPerPage))
    }

    const activityExportPDF = () => {
        const title = "User activity Report";
        const styles = {
            marginTop: "10px"
        };
        exportPDFC(title, activityHeader, activityPDF, styles);
    }
    const tableStyles = {
        0: "text-center"
    };

    const historyHandle = (id) => {
        setHistoryId(id)
        setHistoryPop(true)
        dispatch(setHistoryIsVisible(true))
        dispatch(getCommentHistory(id, 0, 25))
        historyUpdate()
    }

    const historyHeader = [
        [
            "Customer ID",
            "Category",
            "Comments",
            "Added By",
            "Created Date"
        ]
    ]

    const historyUpdate = () => {

        const historyTbHead = [
            "Customer ID",
            "Category",
            "Comments",
            "Added By",
            "Created Date"
        ]
        const historyinfo = [
            "Showing Customer ID",
            "Showing Category",
            "Showing Comments",
            "Showing Added By",
            "Showing Created Date"
        ]
        const tbody = commentHistoryData && commentHistoryData.data &&
            commentHistoryData.data.length > 0 ? commentHistoryData.data.map((item) =>
                [
                    item.userId,
                    item.category,
                    item.comments,
                    item.addedBy,
                    getDateTimeFormate(item.createdDate)
                ]
            ) : []
        setHistoryTbData([historyTbHead, historyinfo, ...tbody])
        setHistoryPDF(tbody);

        const data = Array.isArray(tbody) && tbody.length > 0 ? [historyHeader[0], ...tbody] : [historyHeader[0], []];
        setHistoryCSV(data);
    }
    const historyExportPDF = () => {
        const title = "Comment History Report";
        const styles = {
            marginTop: "10px"
        };
        exportPDFC(title, historyHeader, historyPDF, styles);
    }

    const historyCallApi = (pageNumber, itemsPerPage) => {
        dispatch(setHistoryIsVisible(true))
        dispatch(getCommentHistory(historyId, pageNumber - 1, itemsPerPage))
    }

    const onTakeAction = (id) => {
        setRemoveId(id)
        setOpenAction(true)
        dispatch(getPlayer({ user_id: id }))
    }
    useEffect(() => {
        historyUpdate()
    }, [commentHistoryData])

    const updateDeactivate = (id) => {
        setTimeout(() => dispatch(getPlayer({ user_id: id })), 300);
    };
    const onSaveDeactive = () => {
        dispatch(userControl(removeId, "SUSPENDED"));
        updateDeactivate(removeId);
    };
    const onActivate = () => {
        dispatch(userControl(removeId, "ACTIVE"));
        updateDeactivate(removeId);
    };

    const onMarkFraud = () => {
        const payload = {
            userType: "FRAUD_USER"
        }
        dispatch(updateplayer(removeId, payload))
        updateDeactivate(removeId)
        setOpenAction(false)
    }
    return (
        <React.Fragment>
            <Dialog__Main
                title={"Take Actions"}
                dOpen={openAction}
                dClose={() => setOpenAction(false)}
                width={"xs"}
                ids={"takeActions"}
            >
                <div className='CMS-btnContainer'>
                    <button
                        className='CMS-btnStatus CMS-statusClosed CMS-btnSmall pointer'
                        type='button'
                        id='useractivity'
                        onClick={() => setPopUp(true)}
                    >
                        Remove
                    </button>
                </div>
                <div className='mt-10'></div>
                {playerInfo?.accountStatus === "ACTIVE" ? (
                    <div className='CMS-btnContainer'>
                        <div
                            className='CMS-btnStatus CMS-statusInfo CMS-offline pointer'
                            id='accDeactivate'
                            onClick={() => setOpenConfirmModal(true)}
                        >
                            {" "}
                            Deactivate
                        </div>
                    </div>
                ) : (
                    <div className='CMS-btnContainer'>
                        <div
                            className='CMS-btnStatus CMS-statusActive pointer'
                            onClick={() => setActivateModal(true)}
                            id='accActivate'
                        >
                            {" "}
                            Activate
                        </div>
                    </div>
                )}
                <div className='mt-10'></div>

                {playerInfo?.userType === "FRAUD_USER" ?
                    <div className='CMS-btnContainer'>
                        <button
                            className=' CMS-btnStatus CMS-statusLapsed_30 pointer'
                            type='button'
                            id='useractivity'
                            disabled
                        >
                            Fraud
                        </button>
                    </div> :
                    <div className='CMS-btnContainer'>
                        <button
                            className='CMS-btnStatus CMS-statusSelf-excluded CMS-btnSmall pointer'
                            type='button'
                            id='useractivity'
                            onClick={() => onMarkFraud()}
                        >
                            Mark as Fraud
                        </button>
                    </div>}
            </Dialog__Main>
            <ConfirmDialog
                title={"Do you want to Deactivate ?"}
                verify={() => onSaveDeactive()}
                cancel={() => setOpenConfirmModal(false)}
                open={openconfirmModal}
                close={() => setOpenConfirmModal(false)}
                key={"deactivateUser"}
                width={"sm"}
            />
            <ConfirmDialog
                title={"Do you want to Activate ?"}
                verify={() => onActivate()}
                cancel={() => setActivateModal(false)}
                open={activateModal}
                close={() => setActivateModal(false)}
                key={"activateUser"}
                width={"sm"}
            />
            <Dialog__Main
                title={"Add Comment"}
                dOpen={popUp}
                dClose={() => removePopClose()}
                width={"xs"}
                ids={"showwatchlist"}
            >
                <CommentBox
                    placeholder={"Mention Your Comment Here"}
                    value={comment}
                    errorMsg={emptyErr && "Please Mention Your commnet"}
                    onIpChange={(e) => onIpChange(e)}
                    onSave={(e) => confirmRemove(e)}
                    onCancel={() => removePopClose()}
                    width={"sm"}
                />
            </Dialog__Main>

            <Dialog__Main
                title={"User Activities"}
                dOpen={activityPopUp}
                dClose={() => setActivityPopUp(false)}
                width={"700px"}
                ids={"showwatchlist"}
            >
                <div className='CMS-filter-result' id='result'>
                    <div className="CMS-pagination">
                        <Vpagination
                            data={userActivity.data}
                            totalRecords={userActivity.totalRecords}
                            paginationFirstValue={activityFirstPage}
                            paginationSecondValue={activitySecondPage}
                            setPaginationFirstValue={setActivityFirstPage}
                            setPaginationSecondValue={setActivitySecondPage}
                            recordsShow={activityRecordShow}
                            setRecordsShow={setActivityRecordShow}
                            isVisible={activityIsVisible}
                            setIsVisible={setActivityIsVisible}
                            csvData={activityCSV}
                            exportPDF={activityExportPDF}
                            callApi={callApiActivity}
                            csvFileName={"User activity Report"}
                            reloadRequired={false}
                        />
                    </div>
                </div>
                <div className='CMS-box'>
                    <div className='CMS-table CMS-table-triped CMS-tableActions'>
                        <table>
                            <thead>
                                <tr>
                                    {activityTbHeader.map((item, index) => (
                                        <th>
                                            <div className='d-flex justify-content-center align-items-center'>
                                                <p className='mx-3'>{item}</p>
                                                <Tooltips info={`${activityinfo[index]}`} />
                                            </div>
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {userActivity && userActivity.data
                                    && userActivity.data.length > 0 ? (
                                    userActivity.data.map((item, index) => {
                                        return (
                                            <tr>
                                                <td>
                                                    <Link
                                                        to={`/customerid/${item.userId}`}
                                                        style={{ color: "#2d91fc" }}
                                                    >
                                                        {item.userId}
                                                    </Link>
                                                </td>
                                                <td>{item.firstName}</td>
                                                <td>{item.lastName}</td>
                                                <td>{formatMoney(item.totalDeposits)}</td>
                                                <td>{formatMoney(item.totalWithdrawals)}</td>
                                                <td>{formatMoney(item.totalStake)}</td>
                                                <td>{formatMoney(item.totalPayout)}</td>
                                                <td>{formatMoney(item.potentialPayout)}</td>
                                                <td>
                                                    {item.transactionType === "DEPOSIT" ||
                                                        item.transactionType === "WITHDRAW" ?
                                                        <Link
                                                            to={{
                                                                pathname: "/paymentsearch",
                                                                state: {
                                                                    id: item.transactionId,
                                                                },
                                                            }}
                                                        >
                                                            {item.transactionId}
                                                        </Link> :
                                                        <Link
                                                            to={{
                                                                pathname: "/betHistory",
                                                                state: {
                                                                    id: item.transactionId,
                                                                },
                                                            }}
                                                        >
                                                            {item.transactionId}
                                                        </Link>
                                                    }
                                                </td>
                                                <td>{item.transactionType}</td>
                                            </tr>
                                        );
                                    })
                                ) : (
                                    <No__Data__Found colspan={50} />
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </Dialog__Main>

            <Dialog__Main
                title={"Comment History"}
                dOpen={historyPop}
                dClose={() => setHistoryPop(false)}
                width={"700px"}
                ids={"cmntHistory"}
            >

                <div className='CMS-filter-result' id='result'>
                    <div className="CMS-pagination">
                        <Vpagination
                            data={commentHistoryData.data}
                            totalRecords={commentHistoryData.totalRecords}
                            paginationFirstValue={historyFirstPage}
                            paginationSecondValue={historySecondPage}
                            setPaginationFirstValue={setHistoryFirstPage}
                            setPaginationSecondValue={setHistorySecondPage}
                            recordsShow={historyRecordShow}
                            setRecordsShow={setHistoryRecordShow}
                            isVisible={historyIsVisible}
                            setIsVisible={setHistoryIsVisible}
                            csvData={historyCSV}
                            exportPDF={historyExportPDF}
                            callApi={historyCallApi}
                            csvFileName={"Comment History Report"}
                            reloadRequired={false}
                        />
                    </div>
                </div>
                <Table
                    tableData={historyTbData}
                    linkIndex={0}
                    styles={tableStyles}
                />
            </Dialog__Main>
            <div className='CMS-tab-panel active'>
                <div className='CMS-tabContent'>
                    <div className='CMS-box CMS-box-content'>
                        <div className='row '>
                            {userWatchListData.map((item, ind) => {
                                return (
                                    <React.Fragment key={item.id} >
                                        <div
                                            className='col-3'
                                        >
                                            <Input
                                                title={item.title}
                                                name={item.name}
                                                value={formData[item.name]}
                                                placeholder={item.placeholder}
                                                setValue={(e) => handleSearchIp(e)}
                                                regex={item.regex}
                                                maxLen={item.maxLength}
                                                ids={item.ids}
                                                info={item.info}
                                                show={true}
                                            />
                                        </div>
                                    </React.Fragment>
                                );
                            })}
                            <div className='col-3'>
                                <Select
                                    title={"Watchlist Type"}
                                    name={"watchlistType"}
                                    ids={"watchlistType"}
                                    value={formData.watchlistType}
                                    setValue={(val) => handleSearchIp(val)}
                                    options={WatchlistTypes}
                                    info={"Filter out the data as per Watchlist type"}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <ButtonGroup
                                groupNames={["Search", "Reset"]}
                                idsearch={"watchlistsearch"}
                                idreset={"watchlistreset"}
                                submit={(e) => handleClickSearch(e)}
                                reset={() => resetButton()}
                            />
                        </div>
                    </div>
                    <div className="CMS-filter-result" id="result">
                        <div className="CMS-pagination">
                            <Vpagination
                                data={watchListUsers.data}
                                totalRecords={watchListUsers.totalRecords}
                                paginationFirstValue={watchListFirstPage}
                                paginationSecondValue={watchListSecondPage}
                                setPaginationFirstValue={setWatchListFirstPage}
                                setPaginationSecondValue={setWatchListSecondPage}
                                recordsShow={watchListRecord}
                                setRecordsShow={setWatchListRecord}
                                isVisible={watchLIstIsVisible}
                                setIsVisible={setWatchListIsVisible}
                                csvData={watchListCsvData}
                                exportPDF={watchListExportPdf}
                                callApi={callApi}
                                csvFileName={"User Watchlist Report"}
                            />
                        </div>
                    </div>
                    <div className='CMS-box'>
                        <div className='CMS-table CMS-table-triped CMS-tableActions'>
                            <table>
                                <thead>
                                    <tr>
                                        {watchListMainHeader.map((item, index) => (
                                            <th>
                                                <div className='d-flex justify-content-center align-items-center'>
                                                    <p className='mx-3'>{item}</p>
                                                    <Tooltips info={`${watchListMainInfo[index]}`} />
                                                </div>
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {watchListUsers && watchListUsers.data &&
                                        watchListUsers.data.length > 0 ? (
                                        watchListUsers.data.map((item, index) => {
                                            return (
                                                <tr>
                                                    <td>
                                                        <Link
                                                            to={`/customerid/${item.userId}`}
                                                            style={{ color: "#2d91fc" }}
                                                        >
                                                            {item.userId}
                                                        </Link>
                                                    </td>
                                                    <td>{item.userName}</td>
                                                    <td>{item.firstName}</td>
                                                    <td>{item.lastName}</td>
                                                    {/* <td>{item.totalCount}</td> */}
                                                    <td>{item.createdBy}</td>
                                                    <td>{item.createdBy}</td>
                                                    <td>{getDateTimeFormate(item.createdDate)}</td>
                                                    <td>{getDateTimeFormate(item.updatedDate)}</td>
                                                    <td>{item.comments}</td>
                                                    <td>

                                                        <button
                                                            className={`CMS-btn CMS-btnSecondary active CMS-btnSmall pointer ${viewOnlyuser ? 'disable-button' : ''}`}
                                                            type='button'
                                                            id='useractivity'
                                                            onClick={() => onTakeAction(item.userId)}
                                                        >
                                                            Take Actions
                                                        </button>
                                                    </td>
                                                    <td>
                                                        <button
                                                            className='CMS-btnStatus CMS-statusActive CMS-btnSmall pointer mt-10'
                                                            type='button'
                                                            id='useractivity'
                                                            onClick={() => userActivityHandle(item.userId)}
                                                        >
                                                            User Activities
                                                        </button>
                                                        <button
                                                            className='CMS-btnStatus CMS-btnSecondary CMS-statusHistory active CMS-btnSmall pointer mt-10'
                                                            type='button'
                                                            id='useractivity'
                                                            onClick={() => historyHandle(item.userId)}
                                                        >
                                                            Comment History
                                                        </button>
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    ) : (
                                        <No__Data__Found colspan={50} />
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default UsersWatchlist