import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Tooltips from '../Common/Tooltip'
import { COMMON_MSGS, boosted_odds, communication_module } from '../../sharedfiles/tooltipMsgs';
import Input from '../Common/common_inputs/Input';
import { COMMON_CONSTANTS } from '../../sharedfiles/Constants';
import { getAllUserTags } from '../CreatePromoMsg/CreatePromoSlice';
import { combiDateIps, combiSuggestionIP, combiSuggestionIP1 } from '../Common/data/mapData';
import Datepicker from '../Common/common_inputs/DatePicker';
import { getAllCountriesBySport, getAllMarketsByEvent, getAllSportsByBrand, getEventsBySportCountryLeague, getLeaguesBysportAndCountry, getOddsByOutcomeId, getOutcomesByMarketOrSubmarket, getSubMarketByMarketEvent } from '../BoostedOdds/boostedOddsSlice';
import { MomentUTCDateTime, MoneyFormat2, extractedTime, formatMoney, getDateTimeFormate, regexType } from '../../sharedfiles/helper';
import Select from '../Common/common_inputs/Select_DDL';
import { createConfig } from './combiSlice';

function Combisuggestions() {

    const dispatch = useDispatch()

    const [toggle, setToggle] = useState(false)
    const [isToggled, setIsToggle] = useState(true);
    const [formData, setFormData] = useState({
        combiName: '',
        userTag: '',
        minOdds: '',
        minStake: '',
        liabilityLimit: '',
        maxStake: ''
    })
    const currentDateTime = new Date();
    const [dateFields, setDateField] = useState({
        startDate: currentDateTime,
        endDate: '',
    })
    const [dateErr, setDateErr] = useState({
        startDateErr: '',
        endDateErr: '',
    })
    const [minTime, setMinTime] = useState(new Date());
    const [maxTime, setMaxTime] = useState(new Date(new Date().setHours(23, 59)));
    const [minDate, setMinDate] = useState(new Date());
    const [maxDate, setMaxDate] = useState(new Date());
    const [maxAPiData, setMaxAPiData] = useState(new Date());

    const [sports, setSports] = useState();
    const [sportId, setSportId] = useState("");
    const [countries, setCountries] = useState("");
    const [countryId, setCountryId] = useState("");
    const [leagues, setLeagues] = useState("");
    const [events, setEvent] = useState("");
    const [eventId, setEventId] = useState("");
    const [market, setMarket] = useState("");
    const [marketId, setMarketId] = useState("");
    const [subMarket, setSubMarket] = useState("");
    const [subMarketId, setSubMarketId] = useState("");
    const [outcomes, setOutcomes] = useState("");
    const [odds, setOdds] = useState("");

    const [tableShow, setTableShow] = useState(false);
    const [addTbData, setAddTbData] = useState([]);
    const [manualTbheader, setManualTbheader] = useState([]);

    const [error, setError] = useState([]);
    const [minOddErr, setMinOddErr] = useState('')
    const [tagErr, setTagErr] = useState(false)
    const [selectedEventIds, setSelectedEventIds] = useState([])
    const [disable, setDisable] = useState(false)
    const [createCombiEnable, setCreateCombiEnable] = useState(true)
    const [eventStartDate, setEventStartDate] = useState(null);
    const sportByBrand = useSelector((p) => p.BoostedOddsSlice.sportByBrand);
    const countryBySport = useSelector((p) => p.BoostedOddsSlice.countryBySport);
    const leagueBySportCountry = useSelector(
        (p) => p.BoostedOddsSlice.leagueBySportCountry
    );
    const eventsbyLeagues = useSelector(
        (p) => p.BoostedOddsSlice.eventBySportCountryLeague
    );
    const marketbyEvent = useSelector((p) => p.BoostedOddsSlice.marketByEvent);
    const subMarketByMarket = useSelector((p) => p.BoostedOddsSlice.subMarket);
    const outcomesOpt = useSelector((p) => p.BoostedOddsSlice.outComes);
    const oddsByOutcome = useSelector((p) => p.BoostedOddsSlice.oddsByOutcome);

    const alluserTags = useSelector((p) => p.CreatePromoSlice.userTags)

    const newSportsArray = sportByBrand.map((sport) => ({
        id: sport.sportId,
        label: sport.sportName,
        value: sport.sportId,
    }));

    const newCountryArray = countryBySport.countries
        ? countryBySport.countries.map((country) => ({
            id: country.countryId,
            label: country.countryName,
            value: country.countryId,
        }))
        : [];

    const LeagueArray = leagueBySportCountry.leagues
        ? leagueBySportCountry.leagues.map((league) => ({
            id: league.leagueId,
            label: league.leagueName,
            value: league.leagueId,
        }))
        : [];
    const EventArray = eventsbyLeagues.events
        ? eventsbyLeagues.events.map((event) => ({
            id: event.eventId,
            label: event.eventName,
            value: event.eventId,
            eventTime: event.eventStartDate,
        }))
        : [];

    const MarketArray = marketbyEvent.markets
        ? marketbyEvent.markets.map((event) => ({
            id: event.marketId,
            label: event.marketName,
            value: event.marketId,
        }))
        : [];

    const subMarketArray = subMarketByMarket.subMarkets
        ? subMarketByMarket.subMarkets.map((subm) => ({
            id: subm.subMarketName,
            label: subm.subMarketName,
            value: subm.subMarketName,
        }))
        : [];

    const outcomesArray = outcomesOpt.outComes
        ? outcomesOpt.outComes.map((p) => ({
            id: p.outComeId,
            label: p.outComeName,
            value: p.outComeId,
        }))
        : [];

    const onSelection = (e) => {
        const { name, value } = e.target;
        if (name === "sports") {
            setSports(value);
            setError(error.filter(error => error !== name));
            dispatch(getAllCountriesBySport(value));
            setSportId(value);
        }
        if (name === "countries") {
            setCountries(value);
            setError(error.filter(error => error !== name));
            dispatch(getLeaguesBysportAndCountry(sportId, value));
            setCountryId(value);
        }
        if (name === "leagues") {
            setLeagues(value);
            setError(error.filter(error => error !== name));
            dispatch(getEventsBySportCountryLeague(sportId, countryId, value));
        }
        if (name === "events") {
            setEvent(value);
            setError(error.filter(error => error !== name));
            dispatch(getAllMarketsByEvent(value));
            setEventId(value);
            setMarket('')
            setSubMarket('')
            setOutcomes('')
            setOdds('')
            const selectedEvent = EventArray.find((event) => event.value === value);
            if (selectedEvent) {
                const convertedTime = new Date(selectedEvent.eventTime);
                const prevEndTime = dateFields.endDate;
                setEventStartDate(convertedTime);
                if (!prevEndTime || convertedTime < prevEndTime) {
                    setDateField(prevState => ({
                        ...prevState,
                        endDate: convertedTime,
                    }));
                }
                // Update other state variables as needed
                setMaxDate(convertedTime);
                setMaxAPiData(convertedTime);
                setMaxTime(convertedTime);
                setMinDate(new Date());
                // setTimeDisable(false);
                // const minTime = new Date();
                // minTime.setHours(0, 0, 0, 0);
                // setMinTime(minTime);
            } else {
                setDateField({ ...dateFields, endDate: "" });
            }
        }
        if (name === "market") {
            setMarket(value);
            setError(error.filter(error => error !== name));
            dispatch(getSubMarketByMarketEvent(eventId, value));
            dispatch(getOutcomesByMarketOrSubmarket(eventId, value));
            setMarketId(value);
        }
        if (name === "subMarket") {
            setSubMarket(value);
            // setSubMarketErr(false);
            dispatch(getOutcomesByMarketOrSubmarket(eventId, marketId, value));
            setSubMarketId(value);
        }
        if (name === "outcomes") {
            setOutcomes(value);
            setError(error.filter(error => error !== name));
            const subMarketVal = subMarket === 0 ? null : subMarket;
            dispatch(getOddsByOutcomeId(eventId, marketId, value, subMarketVal));
        }
    };



    const toggled = (e) => {
        setIsToggle(!isToggled)
        isToggled ? setToggle(false) : setToggle(true)
        // setIsDisabled(e.target.checked);
        if (e.target.checked) {
            setToggle(true)
            setFormData({ ...formData, userTag: '' })
            setTagErr(false)
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        setFormData((prevState) => {
            return {
                ...prevState,
                [name]: value
            }
        })
        if (name === "liabilityLimit") {
            setError(error.filter((item) => item !== name));
        }
        if (name === "maxStake") {
            setError(error.filter((item) => item !== name));
        }
        if (name === 'combiName') {
            setError(error.filter((item) => item !== name));
        }
        // if (name === 'userTag') {
        //     setTagErr(false)
        // }
        if (name === 'userTag' && value !== '') {
            setToggle(false);
            setIsToggle(false);
            setTagErr(false)
        }
        if (name === "maxStake" || name === "minStake") {
            const minStake = parseFloat(formData.minStake);
            const maxStake = parseFloat(value);

            if (minStake > maxStake) {
                setError((prevState) => [...prevState, "maxStakeMinLimit"]);
            } else {
                setError((prevState) => prevState.filter((item) => item !== "maxStakeMinLimit"));
            }
        }
    }
    useEffect(() => {
        dispatch(getAllUserTags())
        dispatch(getAllSportsByBrand());
        // setIsToggle(true)
    }, [])

    const dateErrHandle = (key, err) => {
        setDateErr((prev) => {
            return {
                ...prev,
                [key]: err
            }
        })
    }

    const dateHandle = (value, name) => {
        if (name == "endDate") {
            setMaxDate(value);
        }
        if (name == "startDate") {
            setMinDate(value);
        }
        setDateField((prevState) => {
            return {
                ...prevState,
                [name]: value,
            };
        });
        if (value instanceof Date && !isNaN(value)) {
            const today = new Date();
            const isToday = value.toDateString() === today.toDateString();
            const selectedEvent = EventArray.find((event) => event.value === events);
            const eventDateTime = new Date(selectedEvent?.eventTime);
            const eventTimestamp = eventDateTime.getTime();
            const valueTimestamp = value.getTime();
            const startDateVal = value.toDateString();
            const startDateApiVal = eventDateTime.toDateString();

            if (name === "endDate") {
                if (valueTimestamp === eventTimestamp) {
                    setMaxTime(eventDateTime);
                } else if (startDateVal === dateFields?.startDate.toDateString()) {
                    setMaxTime(new Date(value));
                } else if (value < eventDateTime && value > isToday) {
                    const maxTime = new Date();
                    maxTime.setHours(23, 59, 59, 999);
                    setMaxTime(maxTime);
                    const minTime = new Date();
                    minTime.setHours(0, 0, 0, 0);
                    setMinTime(minTime);
                } else {
                    setMaxTime(new Date(0, 0, 0, 23, 59));
                }
            }

            if (name === "startDate") {
                if (startDateVal === startDateApiVal) {
                    setMaxTime(dateFields.endDate);
                    const minTime = new Date();
                    minTime.setHours(0, 0, 0, 0);
                    setMinTime(minTime);
                } else if (value < eventDateTime && value > today) {
                    const maxTime = new Date();
                    maxTime.setHours(23, 59, 59, 999);
                    setMaxTime(maxTime);
                    const minTime = new Date();
                    minTime.setHours(0, 0, 0, 0);
                    setMinTime(minTime);
                } else {
                    setMaxTime(new Date(0, 0, 0, 23, 59));
                }
            }

            if (isToday) {
                setMinTime(new Date());
                setMaxTime(new Date(new Date().setHours(23, 59)));
            }
        } else {
            setMinTime(new Date());
            setMaxTime(new Date(new Date().setHours(23, 59)));
        }
    }

    const addTbheader = [
        "Sport",
        "Country",
        "League",
        "Events",
        "Market",
        "Sub Market",
        "Outcome",
        "SR Odds",
        "Remove",
    ];

    const addTbeaderInfo = [
        "Showing Combi Name",
        "Showing User Tag",
        "Showing Min Limit",
        "Showing Min Stake",
        "Showing Liability Limit",
        "Showing Max Stake",
        "Showing Start Date & Time",
        "Showing End Date & Time",
        "Showing Sport",
        "Showing Country",
        "Showing League",
        "Showing Events",
        "Showing Market",
        "Showing Sub Markey",
        "Showing Outcome",
        "Showing SR Odds",
        "Showing Remove",
    ]

    //PAYLOAD DATA && ADD TABLE MAP DATA//
    const sportArray = newSportsArray?.map((obj) => {
        if (obj?.id == sports) {
            return obj?.label;
        }
    });
    const filteredsportName = sportArray.filter((name) => name !== undefined);
    const sportName = filteredsportName[0];

    const countryArray = newCountryArray?.map((obj) => {
        if (obj?.id == countries) {
            return obj?.label;
        }
    });
    const filteredCountryArray = countryArray.filter(
        (name) => name !== undefined
    );
    const countryName = filteredCountryArray[0];

    const leagueArray = LeagueArray?.map((obj) => {
        if (obj?.id == leagues) {
            return obj?.label;
        }
    });
    const filteredleagueArray = leagueArray.filter((name) => name !== undefined);
    const leagueName = filteredleagueArray[0];

    const marketNameArray = MarketArray?.map((obj) => {
        if (obj?.id == market) {
            return obj?.label;
        }
    });
    const filteredmarketName = marketNameArray.filter(
        (name) => name !== undefined
    );
    const marketName = filteredmarketName[0];

    const outComeNameArray = outcomesArray?.map((obj) => {
        if (obj?.id == outcomes) {
            return obj?.label;
        }
    });
    const filteredoutComeName = outComeNameArray.filter(
        (name) => name !== undefined
    );
    const outComeName = filteredoutComeName[0];

    const eventNameArray = EventArray?.map((obj) => {
        if (obj?.id == events) {
            return obj?.label;
        }
    });
    const filteredeventNameArray = eventNameArray.filter(
        (name) => name !== undefined
    );
    const eventName = filteredeventNameArray[0];
    const allStates = {
        sportId: sports,
        sportName: sportName,
        countryId: countries,
        countryName: countryName,
        leagueId: leagues,
        leagueName: leagueName,
        eventName: "event",
        marketId: market,
        marketName: marketName,
        eventId: events,
        eventName: eventName,
        outComeId: outcomes,
        outComeName: outComeName,
        subMarketId: subMarket,
        subMarketName: subMarket,
        odds: odds,
        eventStartDate:eventStartDate
    };
    //PAYLOAD DATA && ADD TABLE MAP DATA//


    const onAddEmpty = () => {
        setSports("")
        setCountries("")
        setLeagues("")
        setEvent("")
        setMarket("")
        setSubMarket("")
        setOutcomes("")
        setOdds("")
        setSportId("")
        setCountryId("")
        setEventId("")
        setMarketId("")
    }

    const onAddValidation = () => {

        let isValid = true
        if (dateFields.startDate === '' || dateFields.startDate === null) {
            dateErrHandle("startDateErr", "Start Date Required");
            isValid = false;
        } else {
            dateErrHandle("startDateErr", "");
        }
        if (dateFields.endDate === '' || dateFields.endDate === null) {
            dateErrHandle("endDateErr", "End Date Required");
            isValid = false;
        } else {
            dateErrHandle("endDateErr", "");
        }

        if (formData.minOdds > odds) {
            setMinOddErr("Your Selection is not Valid");
            isValid = false;
        } else {
            setMinOddErr('')
        }
        return isValid;

    }


    const onAdd = () => {
        const errors = [];
        const fieldsToValidate = [
            { field: sportId, name: "sports" },
            { field: countryId, name: "countries" },
            { field: leagues, name: "leagues" },
            { field: eventId, name: "events" },
            { field: marketId, name: "market" },
            { field: outcomes, name: "outcomes" },
        ];

        fieldsToValidate.forEach(({ field, name }) => {
            if (field === '') {
                errors.push(name);
            }
        });

        if (addTbData.length >= 14) {
            setDisable(true)
        } else {
            setDisable(false)
        }

        if (selectedEventIds.includes(eventId)) {
            errors.push("duplicateEvent");
        }

        setError(errors);
        if (errors.length === 0) {
            if (onAddValidation()) {
                setTableShow(true);
                setManualTbheader(addTbheader);
                setAddTbData((prevData) => [...prevData, allStates]);
                setSelectedEventIds((prevIds) => [...prevIds, eventId]);
                // onAddEmpty()
            }
        }
    }

    function findMinOdd(arr) {
        let minOdd = Infinity;
        for (let obj of arr) {
            const odd = parseFloat(obj.odds);
            if (odd < minOdd) {
                minOdd = odd;
            }
        }
        return minOdd;
    }


    const onCreateCombi = () => {
        let errors = [];
        const minOdd = parseFloat(formData.minOdds);
        setCreateCombiEnable(false)
        const validations = [
            { field: formData.liabilityLimit, errorName: "liabilityLimit" },
            { field: formData.maxStake, errorName: "maxStake" },
            { field: formData.combiName, errorName: "combiName" },
            { condition: addTbData.length < 3, errorName: "dataMinLength" },
            { condition: addTbData.length > 15, errorName: "dataMaxLength" },
            // { condition: formData.maxStake > 1000, errorName: "maxStakeMaxLimit" },
        ];

        if (isToggled === false && formData.userTag === '') {
            setTagErr(true)
        }
        validations.forEach(({ field, condition, errorName }) => {
            if (typeof condition !== 'undefined') {
                if (condition) {
                    errors.push(errorName);
                }
            } else if (field === '') {
                errors.push(errorName);
            }
        });
        if (formData.minOdds === '') {
            setMinOddErr('Min Odds Required');
        } else {
            for (let obj of addTbData) {
                const odd = parseFloat(obj.odds);
                if (minOdd > odd) {
                    setMinOddErr("Your Selection is not Valid");
                    return;
                }
            }
            setMinOddErr('');
        }

        const stratDateTime = extractedTime(dateFields.startDate);
        const endDateTime = extractedTime(dateFields.endDate);

        const stratDateObj = new Date(dateFields.startDate);
        const endDateObj = new Date(dateFields.endDate);

        if (stratDateObj.getDate() === endDateObj.getDate()) {
            if (stratDateTime > endDateTime) {
                dateErrHandle("startDateErr", "Start Date Time should not be higher than End Date Time");
            }
        }
        setError(errors);
        const isAnyFieldEmpty = Object.values(formData).some((value) => value.trim() === '');
        const allFieldsNotEmptyIfToggled = isToggled ? isAnyFieldEmpty === true : isAnyFieldEmpty === false
        if (errors.length === 0 && !minOddErr && !tagErr && allFieldsNotEmptyIfToggled) {
            const transformedPayload = addTbData.map(item => ({
                countryId: item.countryId,
                countryName: item.countryName,
                eventId: item.eventId,
                eventName: item.eventName,
                leagueId: item.leagueId,
                leagueName: item.leagueName,
                marketId: item.marketId,
                marketName: item.marketName,
                odds: item.odds,
                outComeId: item.outComeId,
                outComeName: item.outComeName,
                sportId: item.sportId,
                sportName: item.sportName,
                subMarketId: item.subMarketId,
                subMarketName: item.subMarketName,
                eventStartDate: MomentUTCDateTime(item.eventStartDate),
            }));
            const finalPayload = {
                accumulatorSelections: transformedPayload,
                client: "string",
                endDate: MomentUTCDateTime(dateFields.endDate),
                isAllUsers: isToggled,
                liabilityLimit: MoneyFormat2(formData.liabilityLimit),
                maxStake: MoneyFormat2(formData.maxStake),
                minOdds: formData.minOdds,
                minStake: MoneyFormat2(formData.minStake),
                startDate: MomentUTCDateTime(dateFields.startDate),
                title: formData.combiName,
                userTags: formData.userTag ? [formData.userTag] : null
            };
            dispatch(createConfig(finalPayload))
            setTimeout(() => {
                onCancel()
            }, 2000);
        }
        setTimeout(() => {
            setCreateCombiEnable(true)
        }, 2000);
    }

    const onCancel = () => {
        setFormData({
            combiName: '',
            userTag: '',
            minOdds: '',
            minStake: '',
            liabilityLimit: '',
            maxStake: ''
        })
        setDateField({
            startDate: new Date(),
            endDate: '',
        })
        setDateErr({
            startDate: '',
            endDate: '',
        })
        setSports("")
        setCountries("");
        setLeagues("");
        setEvent("");
        setMarket("");
        setSubMarket("");
        setOutcomes("");
        setOdds("");
        setError([]);
        setAddTbData([]);
        setManualTbheader([]);
        setMinOddErr('')
        setOdds('')
        dateErrHandle("startDateErr", '')
        dateErrHandle("endDateErr", '')
        setSelectedEventIds([])
        setDisable(false)
        setSportId("")
        setCountryId("")
        setEventId("")
        setMarketId("")
        setTagErr(false)
    }

    const onDelete = (ind) => {
        let deletedItems = selectedEventIds.filter((ele) => ele === ind)
        setSelectedEventIds(deletedItems)
        setAddTbData((prevData) => prevData.filter((item, index) => index !== ind));
    }
    useEffect(() => {
        setOdds(oddsByOutcome.odds);
    }, [oddsByOutcome]);

    return (
        <React.Fragment>
            <div className='CMS-tab-panel active' id='CMS-combiSuggestion'>
                <div className='CMS-tabContent'>
                    <div className='CMS-box CMS-box-content'>
                        <div className="row">
                            <div className='col-3'>
                                <div className='CMS-formGroup formGroup-inline'>
                                    <div className='CMS-formLabel mb-0 mr-10'>
                                        All Users
                                        &nbsp;&nbsp;
                                        <Tooltips info={COMMON_MSGS.AllUsers} />
                                    </div>
                                    <div className='CMS-toggleSwitch'>
                                        <input
                                            id='toggleActive'
                                            name='toggleActive'
                                            value={toggle}
                                            checked={isToggled}
                                            type='checkbox'
                                            onChange={toggled}
                                        />
                                        <label for='toggleActive'></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className='col-3 '>
                                <Input
                                    title={"Combi Name"}
                                    name={"combiName"}
                                    value={formData.combiName}
                                    setValue={(e) => handleChange(e)}
                                    placeholder={"Combi Name"}
                                    ids={"combiName"}
                                    info={"Enter Your Combi Name"}
                                    show={true}
                                    maxLen={20}
                                    errorMsg={error.includes('combiName') && "Combi Name is Required"}
                                />
                            </div>
                            <div className='col-3'>
                                <div className='CMS-formGroup'>
                                    <div className='CMS-formLabel'>
                                        User Tag
                                        &nbsp;&nbsp;
                                        <Tooltips info={communication_module.userTag} />
                                    </div>
                                    <div className='CMS-dropdown CMS-formControl'>
                                        <div className='CMS-select'>
                                            <select
                                                name='userTag'
                                                value={formData.userTag}
                                                onChange={(val) => handleChange(val)}
                                                className="pointer"
                                                disabled={isToggled}
                                            >
                                                <option value=''>{COMMON_CONSTANTS.ddlOpt_select}</option>
                                                {Object.keys(alluserTags).length >
                                                    0 &&
                                                    alluserTags
                                                        .map((item, index) => {
                                                            return (
                                                                <option
                                                                    key={index}
                                                                    value={item}
                                                                >
                                                                    {item}
                                                                </option>
                                                            );
                                                        })}
                                            </select>
                                        </div>
                                        {tagErr ? (
                                            <div className='CMS-form-errorMsg'>
                                                {"User Tag Required"}
                                            </div>
                                        ) : ''}
                                    </div>
                                </div>
                            </div>
                            {
                                combiSuggestionIP.map((item, ind) => {
                                    return (
                                        <>
                                            <div className='col-3'>
                                                <Input
                                                    title={item.title}
                                                    name={item.name}
                                                    value={formData[item.name]}
                                                    placeholder={item.placeholder}
                                                    setValue={(e) => handleChange(e)}
                                                    regex={item.regex}
                                                    // maxLen={item.maxLength}
                                                    ids={item.ids}
                                                    info={item.info}
                                                    show={true}
                                                    errorMsg={
                                                        item.name === 'maxStake' && error.includes('maxStakeMinLimit')
                                                            ? 'Minimum stake should not be greater than Maximum stake'
                                                            // : (item.name === 'maxStake' && error.includes('maxStakeMaxLimit'))
                                                            //     ? 'Maximum stake should not be more than 1000'
                                                            : (item.name.includes('minOdds') ? minOddErr : error.includes(item.name) && item.errorMsg)
                                                    }
                                                />
                                            </div>

                                        </>
                                    )
                                })
                            }
                            {
                                combiDateIps.map((item, index) => {
                                    const values = [dateFields.startDate, dateFields.endDate]
                                    const dateErrs = [dateErr.startDateErr, dateErr.endDateErr];

                                    const startDate = [new Date(), maxDate];
                                    const endDate = [minDate, maxAPiData];
                                    return (
                                        <div className='col-3'>
                                            <Datepicker
                                                title={item.title}
                                                name={item.name}
                                                ids={item.ids}
                                                value={values[index]}
                                                setValue={(value, name) =>
                                                    dateHandle(value, name)
                                                }
                                                showTimeSelect
                                                minTime={minTime}
                                                maxTime={maxTime}
                                                minDate={index == 0 ? startDate[0] : endDate[0]}
                                                maxDate={index == 0 ? startDate[1] : endDate[1]}
                                                info={item.info}
                                                // errorMsg={item.name.includes("startDate") ? timeErr : item.name.includes("endDate") ? endDateErr : ''}
                                                errorMsg={dateErrs[index]}
                                            />
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                    <div className='CMS-box CMS-box-content'>
                        <div className="row">
                            <div className="col-md-4 col-lg-3">
                                <Select
                                    title={"Sports"}
                                    name={"sports"}
                                    ids={"boostedSports"}
                                    value={sports}
                                    setValue={(val) => onSelection(val)}
                                    options={newSportsArray}
                                    info={boosted_odds.Sports}
                                    errorMsg={error.includes("sports") && "Please select the sport"}
                                />
                            </div>
                            <div className="col-md-4 col-lg-3">
                                <Select
                                    title={"Countries"}
                                    name={"countries"}
                                    ids={"boostedCountries"}
                                    value={countries}
                                    setValue={(val) => onSelection(val)}
                                    options={newCountryArray}
                                    info={boosted_odds.Countries}
                                    errorMsg={error.includes("countries") && "Please select the country"}
                                />
                            </div>
                            <div className="col-md-4 col-lg-3">
                                <Select
                                    title={"Leagues"}
                                    name={"leagues"}
                                    ids={"boostedleagues"}
                                    value={leagues}
                                    setValue={(val) => onSelection(val)}
                                    options={LeagueArray}
                                    info={boosted_odds.Leagues}
                                    errorMsg={error.includes("leagues") && "Please select the league"}
                                />
                            </div>
                            <div className="col-md-4 col-lg-3">
                                <Select
                                    title={"Events"}
                                    name={"events"}
                                    ids={"boostedevents"}
                                    value={events}
                                    setValue={(val) => onSelection(val)}
                                    options={EventArray}
                                    info={boosted_odds.Events}
                                    errorMsg={error.includes("events") && "Please select the event"}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4 col-lg-3">
                                <Select
                                    title={"Markets"}
                                    name={"market"}
                                    ids={"boostedmarket"}
                                    value={market}
                                    setValue={(val) => onSelection(val)}
                                    options={MarketArray}
                                    info={boosted_odds.Markets}
                                    errorMsg={error.includes("market") && "Please select Market"}
                                />
                            </div>
                            <div className="col-md-4 col-lg-3">
                                <Select
                                    title={"Submarket"}
                                    name={"subMarket"}
                                    ids={"boostedsubMarkett"}
                                    value={subMarket}
                                    setValue={(val) => onSelection(val)}
                                    options={subMarketArray}
                                    info={boosted_odds.Markets}
                                />
                            </div>
                            <div className="col-md-4 col-lg-3">
                                <Select
                                    title={"Outcomes"}
                                    name={"outcomes"}
                                    ids={"boostedoutcomes"}
                                    value={outcomes}
                                    setValue={(val) => onSelection(val)}
                                    options={outcomesArray}
                                    info={boosted_odds.Outcomes}
                                    errorMsg={error.includes("outcomes") && "Please select Outcomes"}
                                />
                            </div>
                            <div className="col-md-4 col-lg-3 Input_Disabled">
                                <Input
                                    title={"Odds"}
                                    name={"Odds"}
                                    value={odds}
                                    placeholder={"Odds"}
                                    setValue={(e) => handleChange(e)}
                                    regex={regexType.decimalNumber}
                                    ids={"boostedOdds"}
                                    info={boosted_odds.Odds}
                                    show={true}
                                    className={"Input_Disabled"}
                                    disabled
                                />
                            </div>
                        </div>
                        <div className="mt-30"></div>
                        <div className="row">
                            <div className="col-12">
                                <div className="CMS-btnContainer">
                                    <button
                                        onClick={() => onAdd()}
                                        className={`CMS-btn CMS-btnSecondary active CMS-btnMedium ${disable && addTbData?.length > 14 ? 'disable-button' : ''}`}
                                        type={"button"}
                                    >
                                        Add
                                    </button>
                                    <button
                                        onClick={() => onCreateCombi()}
                                        className={`CMS-btn CMS-btnSecondary active CMS-btnMedium ${!createCombiEnable ? 'disable-button' : ''}`}
                                        type={"button"}
                                    >
                                        Create
                                    </button>
                                    <button
                                        onClick={() => onCancel()}
                                        className="CMS-btn CMS-btnQuaternary active CMS-btnMedium"
                                        type={"button"}
                                    >
                                        Cancel
                                    </button>
                                </div>
                                <div className='CMS-form-errorMsg CMS-combierrorMsg'>
                                    <b>{error.includes('dataMinLength') ? "Minimum selections should be 3" : error.includes('dataMaxLength') ? "Maximum selections should be 15" : ''}</b>
                                </div>
                                <div className='CMS-form-errorMsg CMS-combierrorMsg'>
                                    <b>{error.includes('duplicateEvent') ? "Event is already added" : ''}</b>
                                </div>
                            </div>
                        </div>

                        <div className="mt-30"></div>

                        {tableShow ? (
                            <div className="row">
                                <div className="CMS-table CMS-table-triped CMS-combi-table">
                                    <table>
                                        <thead>
                                            <tr>
                                                {manualTbheader.map((item, index) => (
                                                    <th>
                                                        <div className="d-flex justify-content-center align-items-center">
                                                            <p className="mx-3">{item}</p>
                                                            <Tooltips info={`${addTbeaderInfo[index]}`} />
                                                        </div>
                                                    </th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {addTbData.map((item, ind) => {
                                                return (
                                                    <tr>
                                                        <td>{item.sportName}</td>
                                                        <td> {item.countryName}</td>
                                                        <td>{item.leagueName}</td>
                                                        <td>{item.eventName}</td>
                                                        <td>{item.marketName}</td>
                                                        <td>{item.subMarketName}</td>
                                                        <td>{item.outComeName}</td>
                                                        <td>{item.odds}</td>
                                                        <td>
                                                            <span
                                                                className="material-icons edit pointer"
                                                                style={{ color: "red" }}
                                                                onClick={() => onDelete(ind)}
                                                            >
                                                                delete
                                                            </span>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Combisuggestions