import { NearMeDisabledOutlined } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { COMMON_CONSTANTS, PAYMENT_CONSTANTS } from '../../sharedfiles/Constants';
import { MoneyFormat1, MoneyFormat2, regexType } from '../../sharedfiles/helper';
import { Payments } from '../../sharedfiles/tooltipMsgs';
import Input from '../Common/common_inputs/Input';
import Select from '../Common/common_inputs/Select_DDL';
import { options, depositLimitData, withdrawLimitData } from "../Common/data/paymentLimits/paymentLimitData";
import Tooltips from '../Common/Tooltip';
import { getPaymentLimits, setDisableBtn, updatePaymentLimits, updateWithdrawPayment, withdrawPaymentMethods } from './paymentLimitSlice';
import MethodDDL from './methodDDL';
import { getPaymetProviders } from '../PaymentSearch/paymentSearchSlice';

function PaymentLimits() {
    const [type, setType] = useState("deposit");
    const fieldsData = type === "deposit" ? depositLimitData : withdrawLimitData;
    const [depositLimit, setDepositLimit] = useState({
        depositMinLimit: "",
        depositMaxLimit: "",
    });
    const [withdrawLimit, setWithdrawLimit] = useState({
        withdrawMinLimit: "",
        withdrawMaxLimit: "",
    });
    const [errors, setErrors] = useState([]);
    const [limitError, setLimitError] = useState({
        depoLimitErr: "",
        withLimitErr: ""
    })
    const dispatch = useDispatch();
    const incomingLimits = useSelector((state) => state.paymentLimit.paymentsLimit);
    const tdsPermission = useSelector((state) => state.dashboard.tdsPermission)
    const platformTaxPermission = useSelector((state) => state.dashboard.platformTaxPermission)
    const nftTradePremission = useSelector((state) => state.dashboard.nftTradeFeePremission)
    const stakeTaxPermit = useSelector((state) => state.dashboard.stakeTaxPermission)
    const winTaxPermit = useSelector((state) => state.dashboard.winTaxPermission)
    const { disableBtn, withdrawPayments } = useSelector((state) => state.paymentLimit)
    const [tax, setTax] = useState("")
    const [tds, setTds] = useState("")
    const [ipValue, setIpValue] = useState("")
    const [isToggled, setIsToggle] = useState("");
    const [toggle, setToggle] = useState(true)
    const [selected, setSelected] = useState("")
    const [emptyErr, setEmptyErr] = useState("")
    const [tdsErr, setTdsErr] = useState("")
    const [taxErr, setTaxErr] = useState("")
    const [disable, setDisable] = useState(false)
    const [selectedOption, setSelectedOption] = useState(options[0]);
    const [stakeTax, setStakeTax] = useState('')
    const [winningTax, setWinningTax] = useState('')
    const [stakeTaxErr, setStakeTaxErr] = useState('')
    const [winningTaxErr, setWinningTaxErr] = useState('')
    const [nftTradeFee, setnftTradeFee] = useState(0)
    const [gameTax, setGameTax] = useState(0);
    const [gameTaxErr, setGameTaxErr] = useState();
    const [withdrawPayment, setWithdrawPayment] = useState('')
    const emptyMsg = "Please enter valid input"
    const zeroMsg = "Value Must be greater than 0"
    const btnErr = "Please select any of the above option"
    const regex = regexType.oneDecimal
    const regex2 = regexType.numericPercentage
    const only4Digit = regexType.numOnly4Digit
    const paymentProvider = useSelector((p) => p.paymentSearchSlice.paymentProvider)
    const viewOnlyuser = useSelector((state) => state.dashboard.viewOnlyuser)
    const limitErrSettor = (key, err) => {
        setLimitError((prev) => {
            return {
                ...prev,
                [key]: err
            }
        });
    }
    const filedsHandler = (event, type) => {
        const { name, value } = event.target;
        type === "type" && setType(value);
        if (type === "deposit") {
            setDepositLimit((prev) => {
                return { ...prev, [name]: value }
            });

            if (name === "depositMinLimit") {
                if (Number(value) > Number(depositLimit.depositMaxLimit)) {
                    limitErrSettor("depoLimitErr", "Max limit can't be less than Min limit")
                } else {
                    limitErrSettor("depoLimitErr", "")
                }
            } else {
                if (Number(depositLimit.depositMinLimit) > Number(value)) {
                    limitErrSettor("depoLimitErr", "Max limit can't be less than Min limit")
                } else limitErrSettor("depoLimitErr", "");
            }
        }
        if (type === "withdrawal") {
            setWithdrawLimit((prev) => {
                return { ...prev, [name]: value }
            });

            if (name === "withdrawMinLimit") {
                if (Number(value) > Number(withdrawLimit.withdrawMaxLimit)) {
                    limitErrSettor("withLimitErr", "Max limit can't be less than Min limit")
                } else {
                    limitErrSettor("withLimitErr", "")
                }
            } else {
                if (Number(withdrawLimit.withdrawMinLimit) > Number(value)) {
                    limitErrSettor("withLimitErr", "Max limit can't be less than Min limit")
                } else limitErrSettor("withLimitErr", "");
            }
        }
        if (value === "") {
            setErrors(errors.length > 0 ? (Prev) => [...Prev, name] : (Prev) => [Prev, name])
        }
        else {
            const updated = value && errors.length > 0 && errors.filter(v => v !== name);
            setErrors(updated);
        }

    }


    const validator = (payload) => {
        const err = [];
        const keys = Object.entries(payload);
        keys.map(([name, value]) => value === "" && err.push(name));
        setErrors(err);

        const depLimitErr = Number(depositLimit.depositMinLimit) > Number(depositLimit.depositMaxLimit);
        if (depLimitErr) limitErrSettor("depoLimitErr", "Max limit can't be less than Min limit");

        const withLimitErr = Number(withdrawLimit.withdrawMinLimit) > Number(withdrawLimit.withdrawMaxLimit);
        if (withLimitErr) limitErrSettor("withLimitErr", "Max limit can't be less than Min limit");

        const limitErr = type === "deposit" ? depLimitErr : withLimitErr
        return Object.keys(err).length === 0 && !limitErr;
    }

    const errorExist = () => {
        if (type === "deposit") {
            return limitError.depoLimitErr !== "" && limitError.depoLimitErr
        }
        if (type === "withdrawal") {
            return limitError.withLimitErr !== "" && limitError.withLimitErr
        }
    }

    const onSubmit = (e) => {
        const payload = type === "deposit" ? depositLimit : withdrawLimit;
        if (validator(payload)) {
            const keys = Object.keys(payload);
            const updatedPayload = {
                [keys[0]]: payload[keys[0]] * 100,
                [keys[1]]: payload[keys[1]] * 100
            }
            const incData = incomingLimits[0];
            const prevData = {
                depositMinLimit: incData.depositMinLimit,
                depositMaxLimit: incData.depositMaxLimit,
                withdrawMinLimit: incData.withdrawMinLimit,
                withdrawMaxLimit: incData.withdrawMaxLimit,
            }
            const requested = { ...prevData, ...updatedPayload };
            dispatch(updatePaymentLimits(requested));
            setTimeout(() => dispatch(getPaymentLimits()), 600);
            setDisable(true)
        }
    }

    //mounting point
    useEffect(() => {
        let mounting = true;
        if (mounting) {
            dispatch(getPaymentLimits());
            dispatch(getPaymetProviders())
            dispatch(withdrawPaymentMethods())
        }

        return () => mounting = false;
    }, [])
    //mounting point

    useEffect(() => {
        if (incomingLimits) {
            const data = incomingLimits.length > 0 && incomingLimits[0];
            setDepositLimit({
                depositMinLimit: data.depositMinLimit / 100,
                depositMaxLimit: data.depositMaxLimit / 100,
            });
            setWithdrawLimit({
                withdrawMinLimit: data.withdrawMinLimit / 100,
                withdrawMaxLimit: data.withdrawMaxLimit / 100,
            })
            setnftTradeFee(
                data.nftPlatformFee / 100
            )
            setTds(data.tds ? data.tds : 1)
            setIpValue(MoneyFormat1(data.platformFee))
            setTax(data.gst)
            setIsToggle(data.pfGstWaiver)
            setStakeTax(data.stakeTaxPercentage)
            setWinningTax(data.winningsTaxPercentage)
            if (disableBtn) {
                setTimeout(() => {
                    dispatch(setDisableBtn(false))
                    setDisable(false)
                }, 3000)
            }
        }
    }, [incomingLimits]);

    const tdsInputHandler = (e) => {
        const { name, value } = e.target
        if (name === "tds" && regex2.test(value)) {
            setTds(value)
        }

        if (value === '') {
            setTdsErr(emptyMsg)
        } else if (Number(value) === 0) {
            setTdsErr(zeroMsg)
        } else if (value > 0) {
            setTdsErr('')
        }

    }

    const taxHandler = (e) => {
        const { name, value } = e.target
        if (name === "tax") {
            setTax(value)
        }
        if (value === '') {
            setTaxErr(emptyMsg)
        } else {
            setTaxErr('')
        }
    }

    const stakeTaxIpHandler = (e) => {
        const { name, value } = e.target
        if (name === 'stakeTax' && only4Digit.test(value)) {
            setStakeTax(value)
            setStakeTaxErr('')
        }
        if (value === '') {
            setStakeTaxErr(emptyMsg)
        }
    }

    const winTaxIpHandler = (e) => {
        const { name, value } = e.target
        if (name === 'winningTax' && only4Digit.test(value)) {
            setWinningTax(value)
            setWinningTaxErr('')
        }
        if (value === "") {
            setWinningTaxErr(emptyMsg)
        }
    }
    const nftHandler = (e) => {
        setnftTradeFee(e.target.value)
    }

    const inputHandler = (e) => {
        const { name, value } = e.target
        if (name === "ipValue" && regex.test(value)) {
            setIpValue(value)
        }
        // if (name === "ipValue" && selected === "FIXED" && regex.test(value)) {
        //     setIpValue(value)
        // }
        // if (name === "ipValue" && selected === "PERCENTAGE" && regex2.test(value)) {
        //     setIpValue(value)
        // }
        if (value === "") {
            setEmptyErr(emptyMsg)
        }
        else {
            setEmptyErr('')
        }
    }

    const radioBtn = (e) => {
        const { value } = e.target
        setSelected(value)
        if (value !== "") {
            setEmptyErr("")
        }
    }

    const toggled = (e) => {
        setIsToggle(!isToggled)
        isToggled ? setToggle(false) : setToggle(true)

    }

    const onUpdateTax = () => {
        const absValue = tax !== "" && ipValue !== ""
        if (ipValue === "") {
            setEmptyErr(emptyMsg)
        }
        if (tax === "") {
            setTaxErr(emptyMsg)
        }

        if (absValue) {
            const payload = { platformFee: MoneyFormat2(ipValue), gst: tax, pfGstWaiver: toggle, nftPlatformFee: +nftTradeFee * 100 }
            dispatch(updatePaymentLimits(payload))
            setTimeout(() => dispatch(getPaymentLimits()), 600);
            setDisable(true)
        }
        // else if (selected === "") {
        //     setEmptyErr(btnErr)
        // } 
    }

    const onUpdateTds = () => {
        const absValue = tds !== "" && Math.abs(tds) !== 0
        if (tds === "") {
            setTdsErr(emptyMsg)
        }
        if (absValue) {
            const payload = { tds: tds }
            dispatch(updatePaymentLimits(payload))
            setTimeout(() => dispatch(getPaymentLimits()), 600);
            setDisable(true)
        }
    }

    const onUpdateStake = () => {
        if (stakeTax === '') {
            setStakeTaxErr(emptyMsg)
        } else {
            const payload = { stakeTaxPercentage: stakeTax }
            dispatch(updatePaymentLimits(payload))
            setTimeout(() => dispatch(getPaymentLimits()), 600);
            setDisable(true)
        }
    }


    const onUpdateWinning = () => {
        if (winningTax === '') {
            setWinningTaxErr(emptyMsg)
        } else {
            const payload = { winningsTaxPercentage: winningTax }
            dispatch(updatePaymentLimits(payload))
            setTimeout(() => dispatch(getPaymentLimits()), 600);
            setDisable(true)
        }
    }
    const gameTaxHandler = (e) => {
        const { name, value } = e.target
        if (name === 'gameTax' && only4Digit.test(value)) {
            setGameTax(value)
            setGameTaxErr('')
        }
        if (value === "") {
            setGameTaxErr(emptyMsg)
        }
    }
    const onSumbitGameTax = () => {
        if (winningTax === '') {
            setGameTaxErr(emptyMsg)
        } else {
            const payload = { bettingTaxPercentage: gameTax }
            dispatch(updatePaymentLimits(payload))
            setTimeout(() => dispatch(getPaymentLimits()), 600);
            setDisable(true)
        }
    }
    const handlewithdrawalPayment = (e) => {
        const { value } = e.target
        setWithdrawPayment(value)
    };
    const handlePaymentSave = (e) => {
        const payload = {
            paymentProvideId: withdrawPayment
        }
        dispatch(updateWithdrawPayment(payload))

    };
    return (
        <React.Fragment>
            <div className="CMS-tab-panel active" id="CMS-playerActivity">
                <div className="CMS-tabContent">
                    <div className="CMS-box CMS-box-content">
                        <div className='row mb-10'>
                            <div className='col-12'>
                                <div className='CMS-cardBox' style={{ paddingBottom: "20px" }}>
                                    <div className="row">
                                        {/* <div className="col-md-6 col-lg-4 col-xl-3 mt-20" style={{ padding: "0 30px" }}>
                                            <Select
                                                title={"Payment Type"}
                                                name={"type"}
                                                value={type}
                                                setValue={(event) => filedsHandler(event, "type")}
                                                options={options}
                                                errorMsg={errors.length > 0 && errors.includes("type") && "select proper Affilate"}
                                                ids={"inputType"}
                                                hideStaticOption={true}
                                            />
                                        </div> */}
                                        <div className="col-md-6 col-lg-4 col-xl-3 mt-20" style={{ padding: "0 30px" }}>
                                            <div className='CMS-formGroup'>
                                                <div className='CMS-formLabel'>
                                                    {PAYMENT_CONSTANTS.paymentType}
                                                    &nbsp;&nbsp;
                                                    < Tooltips info={Payments.Payment_limits} />
                                                </div>
                                                <div className='CMS-formControl'>
                                                    <div className='CMS-select'>
                                                        <select
                                                            name='type'
                                                            id='inputType'
                                                            value={type}
                                                            onChange={(event) => filedsHandler(event, "type")}
                                                            className="pointer"
                                                        >
                                                            <option value=''>{COMMON_CONSTANTS.ddlOpt_select}</option>
                                                            <option value="deposit">{PAYMENT_CONSTANTS.ddlOpt_depositLimit}</option>
                                                            <option value="withdrawal">{PAYMENT_CONSTANTS.ddlOpt_withdrawLimit}</option>
                                                            {tdsPermission ? <option value="TDS">{PAYMENT_CONSTANTS.ddlOpt_tds}</option> : ""}
                                                            {platformTaxPermission ? <option value="PFT">{PAYMENT_CONSTANTS.ddlOpt_platformFeeTax}</option> : ""}
                                                            <option value="methods">{PAYMENT_CONSTANTS.ddlOpt_paymentMethod}</option>
                                                            {stakeTaxPermit ? <option value="stakeTax">{PAYMENT_CONSTANTS.ddlOpt_stakeTax}</option> : ""}
                                                            {winTaxPermit ? <option value="winningTax">{PAYMENT_CONSTANTS.ddlOpt_winningTax}</option> : ""}
                                                            {<option value="gameTax">{PAYMENT_CONSTANTS.ddlOpt_gameTax}</option>}
                                                            {<option value="withdrawalPayment">{PAYMENT_CONSTANTS.withdrawl_method}</option>}
                                                        </select>
                                                    </div>
                                                </div>
                                                {errors.length > 0 && errors.includes("type") && (
                                                    <div className='text-danger'>
                                                        {PAYMENT_CONSTANTS.paymentType_ErrMsg}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        {
                                            type !== "TDS" && type !== "PFT" && type !== "methods" && type !== "stakeTax" && type !== "winningTax" && type !== "gameTax" && type !== "withdrawalPayment"
                                                ? fieldsData.map((item, index) => {
                                                    return (
                                                        <React.Fragment key={item.id}>
                                                            <div className="col-md-6 col-lg-4 col-xl-3 mt-20" style={{ padding: "0 30px" }}>
                                                                <Input
                                                                    title={item.title}
                                                                    name={item.name}
                                                                    ids={item.ids}
                                                                    value={type === "deposit" ? +depositLimit[item.name] : +withdrawLimit[item.name]}
                                                                    placeholder={item.placeholder}
                                                                    setValue={(e) => filedsHandler(e, type)}
                                                                    regex={item.regex}
                                                                    errorMsg={
                                                                        errors.length > 0 &&
                                                                            errors.includes(item.name) ?
                                                                            item.errorMsg : index === 1 && errorExist()
                                                                    }
                                                                />
                                                            </div>
                                                        </React.Fragment>
                                                    )
                                                })
                                                : ""}

                                        {/* {type === "PFT" ?
                                            <div className=" col-md-6 col-lg-4 col-xl-3 mt-20" style={{ padding: " 0px 30px" }}>
                                                <div className='CMS-formGroup'>
                                                    <div className="CMS-formLabel">
                                                        <input
                                                            type={'radio'}
                                                            name={'fixedBtn'}
                                                            id={'fixedBtn'}
                                                            value={"FIXED"}
                                                            checked={selected === "FIXED"}
                                                            onChange={(e) => radioBtn(e)}
                                                            style={{ width: "20px", height: "20px", cursor: "pointer", }}
                                                        />
                                                        &nbsp;&nbsp;
                                                        <p>Fixed</p>

                                                        &emsp;&emsp;

                                                        <input
                                                            type={'radio'}
                                                            name={'percentageBtn'}
                                                            id={'percentageBtn'}
                                                            value={"PERCENTAGE"}
                                                            checked={selected === "PERCENTAGE"}
                                                            onChange={(e) => radioBtn(e)}
                                                            style={{ width: "20px", height: "20px", cursor: "pointer", }}

                                                        />
                                                        &nbsp;&nbsp;
                                                        <p>Percentage %</p>
                                                    </div>

                                                    <div className="CMS-formControl">
                                                        <input
                                                            type={"text"}
                                                            id={"ipValue"}
                                                            name={"ipValue"}
                                                            placeholder={"Enter the value"}
                                                            value={ipValue}
                                                            onChange={(e) => inputHandler(e)}
                                                        />

                                                        {emptyErr ?
                                                            <div className='text-danger'>
                                                                {emptyErr}
                                                            </div> : ""}

                                                    </div>
                                                </div>
                                            </div> : ""} */}

                                        {type === "TDS" ?
                                            <div className='col-md-6 col-lg-4 col-xl-3 mt-20' style={{ padding: "0 30px" }}>
                                                <Input
                                                    title={"TDS %"}
                                                    placeholder={"TDS %"}
                                                    name={"tds"}
                                                    type={"text"}
                                                    ids={"tds"}
                                                    value={tds}
                                                    setValue={(e) => tdsInputHandler(e)}
                                                    errorMsg={tdsErr}
                                                    show={true}
                                                    info={Payments.TDS_Percent}
                                                />
                                            </div> : ""}

                                        {type === "PFT" ?
                                            <div className='col-md-6 col-lg-4 col-xl-3 mt-20' style={{ padding: "0 30px" }}>
                                                <Input
                                                    title={"Platform Fee"}
                                                    placeholder={"Enter the value"}
                                                    name={"ipValue"}
                                                    type={"text"}
                                                    ids={"ipValue"}
                                                    value={ipValue}
                                                    setValue={(e) => inputHandler(e)}
                                                    errorMsg={emptyErr}
                                                    info={Payments.Platform_Fee}
                                                    show={true}
                                                />
                                            </div> : ""}
                                        {type === "PFT" ?
                                            <div className='col-md-6 col-lg-4 col-xl-3 mt-20' style={{ padding: "0 30px" }}>
                                                <Input
                                                    title={"Tax %"}
                                                    placeholder={"Tax %"}
                                                    name={"tax"}
                                                    type={"text"}
                                                    ids={"tax"}
                                                    value={tax}
                                                    setValue={(e) => taxHandler(e)}
                                                    regex={regexType.oneDecimal}
                                                    errorMsg={taxErr}
                                                    info={Payments.TAX_Percent}
                                                    show={true}
                                                />
                                            </div> : ""}

                                        {type === "methods" ?
                                            <div className="col-md-6 col-lg-4 col-xl-3 mt-20" style={{ padding: "0 30px" }}>
                                                <div className='CMS-formGroup'>
                                                    <div className='CMS-formLabel'>
                                                        {PAYMENT_CONSTANTS.ddlOpt_paymentMethod}
                                                        &nbsp;&nbsp;
                                                        < Tooltips info={Payments.payment_method} />
                                                    </div>
                                                    <MethodDDL
                                                        options={paymentProvider}
                                                        selectedOption={selectedOption}
                                                    // onOptionSelected={setSelectedOption}
                                                    />

                                                </div>
                                            </div>
                                            : ''}
                                        {type === "withdrawalPayment" ?
                                            <div className="col-md-6 col-lg-4 col-xl-3 mt-20" style={{ padding: "0 30px" }}>
                                                <Select
                                                    title={"Payment Methods"}
                                                    name={"payMethods"}
                                                    value={withdrawPayment || ""}
                                                    setValue={(e) => handlewithdrawalPayment(e)}
                                                    options={withdrawPayments}
                                                    ids={"payMethods"}
                                                    info={"Showing Payment Methods"}
                                                />
                                            </div>
                                            : ''}

                                        {type === "stakeTax" ?
                                            <div className='col-md-6 col-lg-4 col-xl-3 mt-20' style={{ padding: "0 30px" }}>
                                                <Input
                                                    title={"Stake Tax %"}
                                                    placeholder={"Stake Tax %"}
                                                    name={"stakeTax"}
                                                    type={"text"}
                                                    ids={"stakeTax"}
                                                    value={stakeTax}
                                                    setValue={(e) => stakeTaxIpHandler(e)}
                                                    errorMsg={stakeTaxErr}
                                                    show={true}
                                                    info={Payments.stake_Tax}
                                                />
                                            </div> : ""}

                                        {type === "PFT" && nftTradePremission ?
                                            <div className='col-md-6 col-lg-4 col-xl-3 mt-20' style={{ padding: "0 30px" }}>
                                                <Input
                                                    title={"NFT Trade Fee"}
                                                    placeholder={"NFT Trade Fee"}
                                                    name={"NtftradeFee"}
                                                    type={"text"}
                                                    ids={"nftTrade"}
                                                    value={nftTradeFee}
                                                    setValue={(e) => nftHandler(e)}
                                                    // errorMsg={winningTaxErr}
                                                    show={true}
                                                    info={Payments.nftTradeFee}
                                                />
                                            </div> : ""}
                                        {type === "winningTax" ?
                                            <div className='col-md-6 col-lg-4 col-xl-3 mt-20' style={{ padding: "0 30px" }}>
                                                <Input
                                                    title={"Winning Tax %"}
                                                    placeholder={"Winning Tax %"}
                                                    name={"winningTax"}
                                                    type={"text"}
                                                    ids={"winningTax"}
                                                    value={winningTax}
                                                    setValue={(e) => winTaxIpHandler(e)}
                                                    errorMsg={winningTaxErr}
                                                    show={true}
                                                    info={Payments.winning_Tax}
                                                />
                                            </div> : ""}
                                        {type === "gameTax" ?
                                            <div className='col-md-6 col-lg-4 col-xl-3 mt-20' style={{ padding: "0 30px" }}>
                                                <Input
                                                    title={"Betting Tax %"}
                                                    placeholder={"Game Tax %"}
                                                    name={"gameTax"}
                                                    type={"text"}
                                                    ids={"gameTax"}
                                                    value={gameTax}
                                                    setValue={(e) => gameTaxHandler(e)}
                                                    errorMsg={gameTaxErr}
                                                    show={true}
                                                    info={Payments.bettingTax}
                                                />
                                            </div> : ""}
                                    </div>

                                    {type === "PFT" ? <div className='row' >
                                        <div className='col-12 d-flex justify-content-center'>
                                            <div className='CMS-formGroup formGroup-inline'>
                                                <div className='CMS-formLabel mb-0 mr-10'>
                                                    {PAYMENT_CONSTANTS.pf_tax_waiveOff}
                                                    &nbsp;&nbsp;
                                                    < Tooltips info={Payments.PF_Tax_Toggle} />
                                                </div>
                                                <div className='CMS-toggleSwitch'>
                                                    <input
                                                        id='toggleActive'
                                                        name='toggleActive'
                                                        value={toggle}
                                                        checked={isToggled}
                                                        type='checkbox'
                                                        onChange={toggled}
                                                    />
                                                    <label for='toggleActive'></label>
                                                </div>
                                            </div>
                                        </div>
                                    </div> : ""}
                                </div>
                            </div>
                        </div>
                        <div className='mt-20'></div>

                        <div className='row'>
                            {type !== "methods" ? <div className='col-12'>
                                <div className="CMS-btnContainer">
                                    {type !== "TDS" && type !== "PFT" && type !== "stakeTax" && type !== "winningTax" && type !== "gameTax" && type !== "withdrawalPayment" ? <button
                                        onClick={(e) => onSubmit(e)}
                                        className={`CMS-btn CMS-btnSecondary active CMS-btnMedium ${(disable || viewOnlyuser) ? 'disable-button' : ''}`}
                                        type={"button"}
                                        id={"updateLimit"}
                                    >
                                        {COMMON_CONSTANTS.update_Btn}
                                    </button> : type === "PFT" ?
                                        <button
                                            onClick={(e) => onUpdateTax(e)}
                                            className={`CMS-btn CMS-btnSecondary active CMS-btnMedium ${(disable || viewOnlyuser) ? 'disable-button' : ''}`}
                                            type={"button"}
                                            id={"updateLimit"}
                                        >
                                            {COMMON_CONSTANTS.update_Btn}
                                        </button> : type === "stakeTax" ?
                                            <button
                                                onClick={(e) => onUpdateStake(e)}
                                                className={`CMS-btn CMS-btnSecondary active CMS-btnMedium ${(disable || viewOnlyuser) ? 'disable-button' : ''}`}
                                                type={"button"}
                                                id={"updateLimit"}
                                            >
                                                {COMMON_CONSTANTS.update_Btn}
                                            </button> : type === "winningTax" ?
                                                <button
                                                    onClick={(e) => onUpdateWinning(e)}
                                                    className={`CMS-btn CMS-btnSecondary active CMS-btnMedium ${(disable || viewOnlyuser) ? 'disable-button' : ''}`}
                                                    type={"button"}
                                                    id={"updateLimit"}
                                                >
                                                    {COMMON_CONSTANTS.update_Btn}
                                                </button> : type === "gameTax" ?
                                                    <button
                                                        onClick={(e) => onSumbitGameTax(e)}
                                                        className={`CMS-btn CMS-btnSecondary active CMS-btnMedium ${(disable || viewOnlyuser) ? 'disable-button' : ''}`}
                                                        type={"button"}
                                                        id={"updateLimit"}
                                                    >
                                                        {COMMON_CONSTANTS.update_Btn}
                                                    </button> : type === "withdrawalPayment" ?
                                                        <button
                                                            onClick={(e) => handlePaymentSave(e)}
                                                            className={`CMS-btn CMS-btnSecondary active CMS-btnMedium${(viewOnlyuser) ? 'disable-button' : ''}`}
                                                            type={"button"}
                                                            id={"paymentUpdate"}
                                                        >
                                                            {COMMON_CONSTANTS.save}
                                                        </button> :
                                                        <button
                                                            onClick={(e) => onUpdateTds(e)}
                                                            className={`CMS-btn CMS-btnSecondary active CMS-btnMedium ${(disable || viewOnlyuser) ? 'disable-button' : ''}`}
                                                            type={"button"}
                                                            id={"updateLimit"}
                                                        >
                                                            {COMMON_CONSTANTS.update_Btn}
                                                        </button>}
                                </div>
                            </div> : ""}
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default PaymentLimits;
