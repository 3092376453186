import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { getUserPermission, getBrand } from "../Dashboard/dashboardSlice";
import {
  getPermissionCreate,
  getRolePermission,
  setClientData,
  setPermissionFieldData
} from "./CreatePermissionSlice";
import {
  generateKey,
  getDateTimeFormate,
} from "../../sharedfiles/helper";
import { COMMON_CONSTANTS, CREATEROLE_CONSTANTS } from "../../sharedfiles/Constants";
import No__Data__Found from "../Common/table/No__Data__Found";
import Tooltips from "../Common/Tooltip";
import { Admin, PermissionInfo } from "../../sharedfiles/tooltipMsgs";
import Skelton from "../Common/skelton/Skelton";

const CreatePermission = (props) => {
  const dispatch = useDispatch();
  const [client, setClient] = useState("");
  const [clientId, setClientId] = useState("");
  const [permissionNames, setPermissionNames] = useState("");
  const [permissionNameError, setPermissionNameError] = useState(false);

  const { isPermissionVisible } = useSelector((state) => state.dashboard);
  useEffect(() => {
    const dataExist = Object.keys(props?.permissionList).length > 0
    if (!dataExist) {
      props.dispatch(getUserPermission());
      props.dispatch(getBrand());
      props.dispatch(getRolePermission());
    }

    const clinet = props.clientData;
    setClient(clinet.name);
    setClientId(client.id);

    const permission = props.permissionFiledData;
    setPermissionNames(permission.value);
    setPermissionNameError(permission.error);
  }, []);

  const handleChangeBrand = (e) => {
    // e.target.value !== "" ? setClientIdError(false) : setClientIdError(true);
    const { value } = e.target;
    let clientid = "";
    if (value) {
      const isClientData = props.brandData && Array.isArray(props.brandData) && Object.keys(props.brandData).length > 0;
      const Client = isClientData && props.brandData.filter((item) => item.name === value)[0];
      clientid = Client.clientId;
    }
    setClient(value);
    setClientId(clientid);
    dispatch(setClientData({ id: clientid, name: value }))
  };

  const handlePermissionName = (e) => {
    const { value } = e.target;
    const err = value === "";
    setPermissionNameError(err);
    setPermissionNames(value);
    dispatch(setPermissionFieldData({ value: value, error: err }));
  };

  const validate = () => {
    const permiErr = permissionNames === "";
    setPermissionNameError(permiErr);

    dispatch(setPermissionFieldData({ value: permissionNames, error: permiErr }));

    dispatch(setClientData({ id: clientId, name: client }))
    return !permiErr;
  }

  const handleAdd = () => {
    var data = {
      permissionName: permissionNames,
      clientId: clientId,
    };
    if (validate()) {
      dispatch(getPermissionCreate(data));
      setTimeout(() => {
        props.dispatch(getRolePermission());
        setClient("");
        setClientId("");
        setPermissionNames("");
        dispatch(setPermissionFieldData({ value: "", error: "" }));
        dispatch(setClientData({ id: "", name: "" }));
      }, 500);
    }
  };

  const header = [
    "ID",
    "Permission Name",
    "Client ID",
    "Created Date",
    "Updated Date",
    "Created By",
  ]

  return (
    <>

      <div className='CMS-tab-panel active' id='CMS-betting'>
        <div className='CMS-tabContent'>
          <div className='CMS-box CMS-box-content'>
            <div className='row mb-10'>
              <div className='col-12'>
                <div className='CMS-cardBox' style={{ paddingBottom: "20px" }}>
                  <div className='row'>
                    <div className='col-md-6 col-lg-4 col-xl-4'>
                      <div className='CMS-formGroup'>
                        <div className='col-6'>
                          <div className='CMS-formLabel'>
                            {CREATEROLE_CONSTANTS.th_cletntId}
                            &nbsp;&nbsp;
                            <Tooltips info={Admin.Client_Id} />
                          </div>
                        </div>
                        <div className='col-10'>
                          <div className='CMS-formControl'>
                            <div className='CMS-number-of-files CMS-select'>
                              <select
                                name='betStatus'
                                id='clientID'
                                value={client}
                                onChange={handleChangeBrand}
                                className="pointer"
                              >
                                <option value=''>select</option>
                                {props.brandNameList?.map((client) => {
                                  return (
                                    <option
                                      key={client}
                                      value={client}
                                    >
                                      {client}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-md-6 col-lg-4 col-xl-4'>
                      <div className='CMS-formGroup'>
                        <div className='col-6'>
                          <div className='CMS-formLabel'>
                            {CREATEROLE_CONSTANTS.permission_name}
                            &nbsp;&nbsp;
                            <Tooltips info={Admin.Create_Permission_Name} />
                          </div>
                        </div>
                        <div className='col-9'>
                          <div className='CMS-formControl'>
                            <input
                              type='string'
                              id='PermissionName'
                              placeholder='Permission Name'
                              value={permissionNames}
                              onChange={handlePermissionName}
                            />
                            {permissionNameError && (
                              <div className='text-danger'>
                                {CREATEROLE_CONSTANTS.input_permissionErrMsg}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className='col-md-6 col-lg-4 col-xl-4'
                      style={{
                        position: "relative",
                        width: "100%",
                      }}
                    >
                      <div className='CMS-formGroup'>
                        <div className='col-6'>
                          <div className='CMS-formLabel'></div>
                        </div>
                        <div className='col-10 '>
                          <div
                            className='CMS-btnContainer'
                            style={{
                              position: "absolute",
                              width: "100%",
                              top: "30px",
                              left: "95px",
                              transform: "translate(-50%, -50%)",
                            }}
                          >
                            <button
                              className='CMS-btn CMS-btnSecondary active CMS-btnMedium'
                              onClick={handleAdd}
                              id={"CreatePermission"}
                            >
                              {CREATEROLE_CONSTANTS.Btn_createPermission}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='mt-20'></div>
          </div>
          <div className='mt-20'></div>
          <div className='CMS-filter-result' id='result'>
            <div className='CMS-pagination'></div>
            <div className='CMS-box'>
              {isPermissionVisible ? (
                <div className='CMS-table CMS-table-triped'>
                  <table>
                    <thead>
                      <tr>
                        {header.map((item, index) => (
                          <th key={index}>
                            <div className='d-flex justify-content-center align-items-center'>
                              <p className='mx-3'>{item}</p>
                              <Tooltips info={`${PermissionInfo[index]}`} />
                            </div>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {props.permissionList && Array.isArray(props.permissionList) && props.permissionList.length > 0 ? (
                        props.permissionList.map((item, index) => (
                          <tr key={generateKey(index)}>
                            <td>{item.id}</td>
                            <td>{item.permissionName}</td>
                            <td>{item.clientId}</td>
                            <td>{item.createdDate ? getDateTimeFormate(item.createdDate) : "-"}</td>
                            <td>{item.updatedDate ? getDateTimeFormate(item.updatedDate) : "-"}</td>
                            <td>{item.addedBy}</td>
                          </tr>
                        ))
                      ) : (
                        <No__Data__Found colspan={6} />
                      )}
                    </tbody>
                  </table>
                </div>
              ) : (
                <Skelton vLen={5} hLen={7} />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    userId: state.playersearch.userId,
    userPermission: state.dashboard.userPermission,
    brandData: state.dashboard.brandData,
    brandNameList: state.dashboard.brandNameList,
    permissionList: state.createPermission.permissionList,
    clientData: state.createPermission.clientData,
    permissionFiledData: state.createPermission.permissionFiledData,
    permissionData: state.createPermission.permissionData,
  };
};
export default connect(mapStateToProps)(CreatePermission);
