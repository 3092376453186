import { createSlice } from "@reduxjs/toolkit";
import { apiCaller } from "../../api/apiCaller";
import {
  playerapi,
  kycApi,
  resetPasswordApi,
  kycUploadEndPoint,
} from "../../sharedfiles/EndpointConfig";
import { bonusApi } from "../../sharedfiles/EndpointConfig";
import { messageEnhancer, toaster } from "../../sharedfiles/helper";
import { getPlayerGeneralDetail } from "../GeneralDetail/GeneralDetailSlice";
import { fileCallerApi } from "../../api/apiCallerNew";
export const PrimaryPlayerSlice = createSlice({
  name: "primaryplayer",
  initialState: {
    financialData: [],
    dialogopen: false,
    updateplayer: [],
    playerData: [],
    balanceData: [],
    bonusData: [],
    tagValue: [],
    // kycSuccessMsg: "",
    // kycErrorMsg: "",
    kycData: [],
    kycType: [],
    kycDoc: [],
    kycDocAdd: [],
    identityDocs: [],
    addressDocs: [],
  },
  reducers: {
    setFinancial: (state, action) => {
      state.financialData = action.payload;
    },
    setDialogopen: (state, action) => {
      state.dialogopen = action.payload;
    },
    setUpdatePlayer: (state, action) => {
      state.updateplayer = action.payload;
    },
    setUserId: (state, action) => {
      state.userId = action.payload;
    },
    setPlayerData: (state, action) => {
      state.playerData = action.payload;
    },
    setBalanceData: (state, action) => {
      state.balanceData = action.payload;
    },
    setBonusData: (state, action) => {
      state.bonusData = action.payload;
    },
    resetFinancial: (state, action) => {
      state.financialData = [];
    },
    setTagValue: (state, action) => {
      state.tagValue = action.payload;
    },
    // setKycSuccess: (state, action) => {
    //   state.kycSuccessMsg = action.payload;
    // },
    // setKycError: (state, action) => {
    //   state.kycErrorMsg = action.payload;
    // },
    setKycData: (state, action) => {
      state.kycData = action.payload;
    },
    setKycType: (state, action) => {
      state.kycType = action.payload;
    },
    setKycDoc: (state, action) => {
      state.kycDoc = action.payload;
    },
    setKycDocAdd: (state, action) => {
      state.kycDocAdd = action.payload;
    },
    setIdedntityDocs: (state, action) => {
      state.identityDocs = action.payload;
    },
    setAddressDocs: (state, action) => {
      state.addressDocs = action.payload;
    },
  },
});
export const {
  setFinancial,
  setDialogopen,
  setUpdatePlayer,
  setPlayerData,
  setBalanceData,
  resetFinancial,
  setBonusData,
  setTagValue,
  // setKycSuccess,
  // setKycError,
  setKycData,
  setKycType,
  setKycDoc,
  setKycDocAdd,
  setIdedntityDocs,
  setAddressDocs,
} = PrimaryPlayerSlice.actions;

export const getFinancials = (params) => (dispatch) => {
  dispatch(resetFinancial());
  let url = `${playerapi.getGeneralDetail}` + params.user_id + "/summary";

  if (params.user_id !== "") {
    apiCaller(url, "GET").then((response) => {
      if (response.data !== null && response.data) {
        if (response.status == 200) {
          dispatch(setFinancial(response.data));
        }
      }
    });
  }
};

export const updateplayer = (id, params) => (dispatch) => {
  let obj = {
    dob: params.dob,
    firstName: params.firstName,
    lastName: params.lastName,
    email: params.email,
    phoneCalls: params.phoneCalls,
    promotionalEmail: params.promotionalEmail,
    pushNotificationphone: params.pushNotificationphone,
    sms: params.sms,
    keepLevelSticky: params.keepLevelSticky,
    vip: params.vip,
    userType: params.userType,
    affliateBtag: params.affliateBtag
  };
  var url = `${playerapi.getUpdatePlayer}` + id;
  apiCaller(url, "PUT", obj).then((response) => {
    if (response.status == 200) {
      dispatch(setUpdatePlayer(response.data));
      dispatch(getPlayerGeneralDetail({ user_id: id }));
      dispatch(getPlayer({ user_id: id }));
      toaster.success("SUCCESS");
    }
  }).catch(err => toaster.error(messageEnhancer(err.response)));
};
export const getPlayer = (params) => (dispatch) => {
  let url = `${playerapi.getGeneralDetail}` + params.user_id;

  if (params.user_id !== "") {
    apiCaller(url, "GET").then((response) => {
      if (response.data !== null && response.data) {
        if (response.status == 200) {
          dispatch(setPlayerData(response.data));
        }
      }
    });
  }
};

export const getBonuswidgetTableList = (params) => (dispatch) => {
  let url = `${bonusApi.getBonuswidget}` + params.user_id + "/bonus-summary";

  if (params.user_id !== "") {
    apiCaller(url, "GET").then((response) => {
      if (response.data !== null && response.data) {
        if (response.status == "200") {
          dispatch(setBonusData(response.data));
        }
      }
    });
  }
};

export const getTagValue =
  (pagenumbervalue, itemperpagevalue, params) => (dispatch) => {
    const obj = {};
    let url =
      `${playerapi.getPlayer}` +
      "/" +
      params.id +
      "/tag" +
      "?pageNumber=" +
      pagenumbervalue +
      "&ItemsPerPage=" +
      itemperpagevalue;
    apiCaller(url, "GET", obj).then((response) => {
      if (response !== null && response) {
        if (response.status == 200) {
          dispatch(setTagValue(response.data.data));
        }
      }
    });
  };

export const verifyKyc = (userId, kycStatus) => (dispatch) => {
  if (userId !== "") {
    const url = `${kycApi.getKyc}` + userId + "?status=" + kycStatus;
    // const url = `${kycApi.getKyc}` + userId + "?status=" + kycStatus;
    apiCaller(url, "PUT", {})
      .then((response) => {
        toaster.success(response.msg);
      })
      .catch((error) => {
        toaster.error(error.msg);
      });
  }
};

export const rejectKyc = (comments, kycId, status) => (dispatch) => {
  const url = kycApi.playerKycReject
  const data = {
    comments: comments,
    kycId: kycId,
    status: status
  }
  apiCaller(url, "PUT", data)
    .then((res) => {
      if (res.status === 200) {
        toaster.success("SUCCESS");
      }
      else {
        toaster.error("FAILED");
      }
    }).catch((err) => toaster.error("FAILED"))
}

export const getKyc = (userId) => (dispatch) => {
  if (userId !== "") {
    const url = `${kycApi.getKyc}` + +userId;
    apiCaller(url, "GET", {}).then((response) => {
      dispatch(setKycData(response.data));
    });
  }
};

export const getKycType = () => (dispatch) => {
  const url = `${kycApi.getKycType}`;
  apiCaller(url, "GET", {}).then((response) => {
    dispatch(setKycType(response.data));
  });
};
export const reqestKyc = (id) => (dispatch) => {
  const url = `${kycApi.requestKyc(id)}`;
  apiCaller(url, "POST", {}).then((response) => {
    toaster.success(response.msg)
    dispatch(getPlayer({ user_id: id }))
  })
    .catch((err) => {
      toaster.error(err.msg)
    })
};

export const getKycDoc =
  (userId, pageNumber, itemPerPage, params) => (dispatch) => {
    if (userId !== "") {
      let obj = {
        kycType: params.kycType,
        status: params.status,
      };
      const url =
        `${kycApi.getKyc}` +
        userId +
        "?pageNumber=" +
        pageNumber +
        "&itemsPerPage=" +
        itemPerPage;
      apiCaller(url, "POST", obj).then((response) => {
        params.kycType == "ADDRESS" && dispatch(setAddressDocs(response.data));
        params.kycType == "IDENTITY" &&
          dispatch(setIdedntityDocs(response.data));
      });
    }
  };

// export const getKycDocAddress = (userId,pageNumber,itemPerPage,params) => (dispatch) => {
//   if (userId !== "") {
//   let obj = {
//     "kycType": params.kycType,
//     "status": params.status
//   };
//   const url = `${kycApi.getKyc}`+userId +"?pageNumber=" + pageNumber +"&itemsPerPage=" +itemPerPage;
//   apiCaller(url, "POST", obj).then((response) => {
//     dispatch(setKycDocAdd(response.data));
//    console.log(response.data,"doc")
//   });
// }
// }
export const resetPassword = (userId, password) => (dispatch) => {
  const url = resetPasswordApi.getResetPassword(userId);
  const data = {
    password: password,
  };
  apiCaller(url, "POST", data)
    .then((res) => {
      if (res.status == 200) {
        toaster.success("SUCCESS");
      } else {
        toaster.error("FAILED");
      }
    })
    .catch((err) => toaster.error("FAILED"));
};

export const userControl = (id, params) => (dispatch) => {
  const url = `${playerapi.getActivateDeactivate(id)}`
  console.log(url)
  const obj = {
    accountStatus: params
  }
  apiCaller(url, "PUT", obj)
    .then((res) => toaster.success("SUCCESS"))
    .catch((err) => toaster.error("FAILED"));
}

export const uploadKycDetails = (idType, kycType, userId, formData) => (dispatch) => {
  let url = kycUploadEndPoint.uploadKYC(idType, kycType, userId)
  fileCallerApi(url, formData)
    .then((res) => {
      if (res.status === 200) {
        console.log(res.data)
      }
      toaster.success(messageEnhancer(res.message))
    })
    .catch((err) => {
      toaster.error(messageEnhancer(err.message))
      console.log(err)
    });
}




export default PrimaryPlayerSlice.reducer;
