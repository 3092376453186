import React, { useEffect, useState } from 'react'
import { COMMON_MSGS, PLAYER_MODULE, communication_module } from '../../sharedfiles/tooltipMsgs'
import Input from '../Common/common_inputs/Input'
import Select from '../Common/common_inputs/Select_DDL'
import { createPromo, messageFormatType, msgFormatOpts, promopriority } from '../Common/data/mapData'
import Tooltips from '../Common/Tooltip'
import DateTime from '../Common/common_inputs/DateTime'
import Dialog__Main from '../Common/Dialog/Dialog__Main'
import { useDispatch, useSelector } from 'react-redux'
import { COMMON_CONSTANTS } from '../../sharedfiles/Constants'
import { getUTCDate } from '../../sharedfiles/helper'
import { savePromotionDeails } from '../CreatePromoMsg/CreatePromoSlice'
import PromoPreview from '../CreatePromoMsg/PromoPreview'
import WelcomeMsg from './welcomeMsg'
import { getNotyTemplateByType } from '../CreateAccountMsg/TemplateSlice'

const SystemMsg = () => {
    const dispatch = useDispatch()
    const [title, settitle] = useState('')
    const [titleErr, setTitleErr] = useState(false)
    const [msgFormatType, setMsgFormatType] = useState('')
    const [typeErr, setTypeErr] = useState(false)
    const [banner, setbanner] = useState('')
    const [fileErr, setFileErr] = useState('')
    const [shortMsg, setShortMsg] = useState('')
    const [shortMsgErr, setShortMsgErr] = useState(false)
    const [longMsg, setLongMsg] = useState('')
    const [longMsgErr, setLongMsgErr] = useState(false)
    const [openPromo, setOpenPromo] = useState(false)
    const [showBanner, setShowbanner] = useState('')
    const [selected, setSelected] = useState("maintenance")
    const viewOnlyuser = useSelector((state) => state.dashboard.viewOnlyuser)
    const [time, setTime] = useState({
        startTime: '',
        endTime: '',
        scheduledTime: ''
    })

    const [dateFields, setDateField] = useState({
        startDate: '',
        endDate: '',
        schedule: ''
    })

    const [dateErr, setDateErr] = useState({
        startDateErr: '',
        endDateErr: '',
    })
    const inputHandle = (e) => {
        const { name, value } = e.target
        if (name === "title") {
            settitle(value)
            setTitleErr(false)
        }
        if (name === "shortMsg") {
            setShortMsg(value)
            setShortMsgErr(false)
        }
        if (name === "longMsg") {
            setLongMsg(value)
            setLongMsgErr(false)
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === "msgFormatType") {
            setMsgFormatType(value)
            setTypeErr(false)
        }
    }

    const uploadIp = async (e) => {
        const { name } = e.target;
        const file = e.target.files[0];
        setbanner(file)
        if (name === "banner" && !file) {
            setFileErr('Please choose proper file');
        } else if (file && file.size > 100 * 1024) {
            setFileErr('File size exceeds 100KB limit')
        } else {
            setFileErr('');

            try {
                const dataURL = await fileToDataURL(file);
                setShowbanner(dataURL);
            } catch (error) {
                console.error('Error converting file to data URL:', error);
            }
        }
    };


    const fileToDataURL = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onloadend = () => {
                resolve(reader.result);
            };

            reader.onerror = reject;

            reader.readAsDataURL(file);
        });
    };

    const handlePickerCaller = (value, name) => {
        setDateField((prevState) => {
            return {
                ...prevState,
                [name]: value,
            }
        })
        dateErrHandle("startDateErr", '')
        dateErrHandle("endDateErr", '')
    }

    const dateErrHandle = (key, err) => {
        setDateErr((prev) => {
            return {
                ...prev,
                [key]: err
            }
        })
    }
    const onClose = () => {
        setOpenPromo(false)
    }

    const onOpen = () => {
        if (validation() && !fileErr) {
            setOpenPromo(true)
        }
    }
    const now = new Date();
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const currentTimeString = `${hours}:${minutes}`;
    const onClear = () => {
        settitle('')
        setTitleErr('')
        setMsgFormatType('')
        setTypeErr('')
        setFileErr('')
        setShortMsg('')
        setShortMsgErr('')
        setLongMsg('')
        setLongMsgErr('')
        setDateField({
            startDate: '',
            endDate: '',
            schedule: ''
        })
        setDateErr({
            startDateErr: '',
            endDateErr: ''
        })
        setTime({
            startTime: currentTimeString,
            endTime: currentTimeString,
            scheduledTime: currentTimeString
        })
        const fileField = document.getElementById("banner")
        if (fileField) {
            fileField.value = ""
            setbanner("")
        }
    }

    const validation = () => {
        let isValid = true;

        if (title === "") {
            setTitleErr(true);
            isValid = false;
        }
        if (msgFormatType === "") {
            setTypeErr(true);
            isValid = false;
        }
        if (banner === "") {
            setFileErr('Please choose proper file');
            isValid = false;
        }
        if (shortMsg === "") {
            setShortMsgErr(true);
            isValid = false;
        }
        if (longMsg === "") {
            setLongMsgErr(true);
            isValid = false;
        }
        if (dateFields.startDate === "") {
            dateErrHandle("startDateErr", "Start Date Required");
            isValid = false;
        }
        if (dateFields.endDate === "") {
            dateErrHandle("endDateErr", "End Date Required");
            isValid = false;
        }
        return isValid;
    }
    const onSubmitData = () => {

        const data = {
            "id": 0,
            "messageType": msgFormatType,
            "entityType": "MAINTENANCE",
            "notificationType": "MAINTENANCE",
            "notificationStatus": "CREATED",
            "notificationPriority": "HIGH",
            "notificationIcon": "",
            "title": title,
            "description": shortMsg,
            "message": longMsg,
            "htmlTemplate": longMsg,
            "data": "",
            "userIds": [],
            "userTags": [],
            "isScheduled": dateFields.schedule ? true : false,
            "scheduledAt": dateFields.schedule,
            "isAllUsers": true,
            "startDate": getUTCDate(dateFields.startDate, "start"),
            "endDate": getUTCDate(dateFields.endDate, "end"),
            "actionButtonUrls": [],
            "actionButtonLabels": [],
            "startTime": time.startTime,
            "endTime": time.endTime,
            "scheduledTime": time.scheduledTime
        }
        const fetchFormData = new FormData();
        fetchFormData.append("file", banner);
        fetchFormData.append("promotionDetails", JSON.stringify(data));
        dispatch(savePromotionDeails(fetchFormData));
        setOpenPromo(false)
        onClear()
    }


    const onTimeChange = (e) => {
        const { value, name } = e.target
        setTime((prevTime) => ({
            ...prevTime,
            [name]: value,
        }));
    }

    const radioBtn = (e) => {
        const { value } = e.target
        setSelected(value)
        if (value === "welcome") {
            const payload = {
                notificationType: "REGISTRATION_SUCCESS"
            }
            dispatch(getNotyTemplateByType(payload))
        }
    }
    useEffect(() => {
        const payload = {
            notificationType: "REGISTRATION_SUCCESS"
        }
        dispatch(getNotyTemplateByType(payload))
        const storedSelectedValue = localStorage.getItem('selectedRadioValue');
        if (storedSelectedValue) {
            setSelected(storedSelectedValue);
        }
    }, []);

    useEffect(() => {
        localStorage.setItem('selectedRadioValue', selected);
    }, [selected]);
    return (
        <React.Fragment>
            <Dialog__Main
                title={"SYSTEM MESSAGE PREVIEW"}
                dOpen={openPromo}
                dClose={() => onClose()}
                width={"500px"}
            // dHeight={"280px"}
            >
                <PromoPreview
                    onSubmitData={() => onSubmitData()}
                    systemMsg
                    promoTitle={title}
                    msgFormatType={msgFormatType}
                    shortMsg={shortMsg}
                    longMsg={longMsg}
                    startDate={dateFields?.startDate}
                    endDate={dateFields?.endDate}
                    startTime={time?.startTime}
                    endTime={time?.endTime}
                    promoBanner={showBanner}
                    setOpenPromo={() => setOpenPromo(false)}
                />
            </Dialog__Main>
            <div className='CMS-tab-panel active' id='CMS-betting'></div>
            <div className='CMS-tabContent'>
                <div className='CMS-box CMS-box-content'>
                    <div className='row'>
                        <div className='col-3 ml-15'>
                            <div className='CMS-formGroup'>
                                <div className="CMS-formLabel">
                                    System Message Type
                                </div>
                                <div className="CMS-formLabel">
                                    <input
                                        type={'radio'}
                                        name={'maintenanceBtn'}
                                        id={'maintenanceBtn'}
                                        value={"maintenance"}
                                        checked={selected === "maintenance"}
                                        onChange={(e) => radioBtn(e)}
                                        style={{ width: "20px", height: "20px", cursor: "pointer", }}
                                    />
                                    &nbsp;&nbsp;
                                    <p>Maintenance</p>
                                    <div className='ml-20'></div>
                                    <input
                                        type={'radio'}
                                        name={'welcomeBtn'}
                                        id={'welcomeBtn'}
                                        value={"welcome"}
                                        checked={selected === "welcome"}
                                        onChange={(e) => radioBtn(e)}
                                        style={{ width: "20px", height: "20px", cursor: "pointer", }}
                                    />
                                    &nbsp;&nbsp;
                                    <p>Welcome</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {selected !== "welcome" ? <div>
                        <div className="row">
                            <div className='col-3 ml-15'>
                                <Input
                                    title={"Title (50 Characters)"}
                                    name={"title"}
                                    value={title}
                                    setValue={(e) => inputHandle(e)}
                                    placeholder={"Enter..."}
                                    ids={"systemMsgtitle"}
                                    info={communication_module.promo_title}
                                    show={true}
                                    maxLen={50}
                                    errorMsg={titleErr && "Promo Title Required"}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className='col-3 ml-15'>
                                <Select
                                    title={"Message Format Type"}
                                    name={"msgFormatType"}
                                    ids={"msgFormatType"}
                                    value={msgFormatType}
                                    setValue={(val) => handleChange(val)}
                                    options={messageFormatType}
                                    errorMsg={typeErr && "Please select your Message format Type"}
                                    info={communication_module.msgformat_type}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className='col-3 ml-15'>
                                <Input
                                    title={"Banner"}
                                    placeholder={"upload file"}
                                    name={"banner"}
                                    type={"file"}
                                    ids={"banner"}
                                    show={true}
                                    info={communication_module.promoBanner}
                                    accept={"image/jpeg,image/png"}
                                    setValue={(e) => uploadIp(e)}
                                    errorMsg={fileErr}
                                />
                            </div>
                        </div>
                        {banner ? <div className="row">
                            <div className='col-3 ml-10'>
                                <img src={showBanner} alt="Uploaded promo banner" />
                            </div>
                        </div> : ''}


                        <div className="row">
                            <div className='col-md-6 col-lg-6 col-xl-9 ml-15'>
                                <div className='CMS-formGroup'>
                                    <div className='CMS-formLabel'>
                                        {communication_module.shortMsg}
                                        &nbsp;&nbsp;
                                        <Tooltips info={communication_module.shortMsg} />
                                    </div>
                                    <div className='CMS-formControl-group'>
                                        <div className='CMS-formControl'>
                                            <textarea
                                                rows='2'
                                                cols='50'
                                                name='shortMsg'
                                                id='shortMsg'
                                                value={shortMsg}
                                                onChange={(e) => inputHandle(e)}
                                                maxlength='144'
                                            ></textarea>
                                        </div>
                                        {shortMsgErr && (
                                            <div className='CMS-form-errorMsg'>
                                                {"Short Message required"}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-6 col-lg-6 col-xl-9 ml-15'>
                                <div className='CMS-formGroup'>
                                    <div className='CMS-formLabel'>
                                        {communication_module.longMsgIp}
                                        &nbsp;&nbsp;
                                        <Tooltips info={communication_module.longMsg} />
                                    </div>
                                    <div className='CMS-formControl-group'>
                                        <div className='CMS-formControl'>
                                            <textarea
                                                rows='2'
                                                cols='50'
                                                name='longMsg'
                                                id='longMsg'
                                                value={longMsg}
                                                maxlength='320'
                                                onChange={(e) => inputHandle(e)}
                                            ></textarea>
                                        </div>
                                        {longMsgErr && (
                                            <div className='CMS-form-errorMsg'>
                                                {"Long Message Required"}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='d-flex'>
                            {
                                createPromo.map((item, ind) => {
                                    const max = dateFields.endDate ? new Date(dateFields.endDate) : null;
                                    const min = dateFields.startDate ? dateFields.startDate : new Date();
                                    const maxDate = [max, null];
                                    const minDate = [new Date(), min];
                                    const values = [dateFields.startDate, dateFields.endDate];
                                    const dateErrs = [dateErr.startDateErr, dateErr.endDateErr];
                                    const customTime = [time.startTime, time.endTime]
                                    return (
                                        <React.Fragment key={item.id}>
                                            <div className='col-12 col-md-4'>
                                                <div className="CMS-formLabel">
                                                    {item.title}
                                                    &nbsp;&nbsp;
                                                    <Tooltips info={item.info} />
                                                </div>
                                                <DateTime
                                                    // title={item.title}
                                                    name={item.name}
                                                    datevalue={values[ind]}
                                                    setValue={(value, name) => handlePickerCaller(value, name)}
                                                    errorMsg={dateErrs[ind]}
                                                    maxDate={maxDate[ind]}
                                                    minDate={minDate[ind]}
                                                    ids={item.ids}
                                                    // info={item.info}
                                                    onTimeChange={(e) => onTimeChange(e)}
                                                    timeValue={customTime[ind]}
                                                    timeName={item.timeName}
                                                    noInfo
                                                />
                                            </div>
                                        </React.Fragment>
                                    );
                                })
                            }
                            <div className='col-12 col-md-4'>
                                <div className="CMS-formLabel">
                                    {"Schedule Send (Optional)"}
                                    &nbsp;&nbsp;
                                    <Tooltips info={communication_module.promo_schedule} />
                                </div>
                                <DateTime
                                    // title={"Schedule Send (Optional)"}
                                    name={"schedule"}
                                    datevalue={dateFields.schedule}
                                    setValue={(value, name) => handlePickerCaller(value, name)}
                                    ids={"promo_schedule"}
                                    maxDate={time.scheduledTime}
                                    minDate={new Date()}
                                    // info={communication_module.promo_schedule}
                                    onTimeChange={(e) => onTimeChange(e)}
                                    timeValue={time.scheduledTime}
                                    timeName={"scheduledTime"}
                                    noInfo
                                />
                            </div>
                        </div>
                        <div className='mt-20'></div>
                        <div className='col-12'>
                            <div className="CMS-btnContainer">
                                <button
                                    className={`CMS-btn CMS-btnQuaternary active CMS-btnMedium ${viewOnlyuser ? 'disable-button' : ''}`}
                                    onClick={() => onOpen()}
                                >
                                    Preview & Send
                                </button>
                                <button
                                    className={`CMS-btn CMS-btnSecondary active CMS-btnMedium ${viewOnlyuser ? 'disable-button' : ''}`}
                                    onClick={() => onClear()}
                                >
                                    Clear All
                                </button>

                            </div>
                        </div>
                    </div> : <WelcomeMsg selected={selected} />}
                </div>
            </div>
        </React.Fragment>
    )
}

export default SystemMsg