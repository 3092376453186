import React, { useEffect, useState } from 'react'
import { COMMON_MSGS, PLAYER_MODULE, communication_module } from '../../sharedfiles/tooltipMsgs'
import Input from '../Common/common_inputs/Input'
import Select from '../Common/common_inputs/Select_DDL'
import { createPromo, messageFormatType, msgFormatOpts, promopriority } from '../Common/data/mapData'
import Tooltips from '../Common/Tooltip'
import Dialog__Main from '../Common/Dialog/Dialog__Main'
import PromoPreview from './PromoPreview'
import { useDispatch, useSelector } from 'react-redux'
import { getAllUserTags, savePromotionDeails } from './CreatePromoSlice'
import { COMMON_CONSTANTS } from '../../sharedfiles/Constants'
import { MomentUTCDateTime, extractDate, extractedDate, extractedTime, getBrowserDate, getDateTimeFormate, getUTCDate } from '../../sharedfiles/helper'
import Datepicker from '../Common/common_inputs/DatePicker'

const CreatePromoMsg = () => {
    const dispatch = useDispatch()
    const editListData = useSelector((p) => p.PromoListSlice.editListData)
    const editId = useSelector((p) => p.PromoListSlice.editId)
    const isApiImg = useSelector((p) => p.PromoListSlice.isApiImg)

    const titleEdit = editListData.title
    const msgTypeEdit = editListData.messageType
    // const promoBannerEdit = editListData.
    const actionBtnNameEdit = editListData.actionButtonLabels
    const actionLinkEdit = editListData.actionButtonUrls
    const shortMsgEdit = editListData.description
    const longMsgEdit = editListData.message
    const isAllUsersEdit = editListData.isAllUsers
    const cidEdit = editListData.userIds
    const userTagEdit = editListData.userTags
    const priorityEdit = editListData.notificationPriority
    const startDateEdit = editListData.startDate
    const endDateEdit = editListData.endDate
    const scheduleEdit = editListData.scheduledAt
    const bannerPath = editListData.bannerPath


    const [promoTitle, setPromoTitle] = useState('')
    const [titleErr, setTitleErr] = useState(false)
    const [msgFormatType, setMsgFormatType] = useState('')
    const [typeErr, setTypeErr] = useState(false)
    const [promoBanner, setPromoBanner] = useState('')
    const [fileErr, setFileErr] = useState('')
    const [shortMsg, setShortMsg] = useState('')
    const [shortMsgErr, setShortMsgErr] = useState(false)
    const [longMsg, setLongMsg] = useState('')
    const [longMsgErr, setLongMsgErr] = useState(false)
    const [cid, setCid] = useState('')
    const [userTag, setUserTag] = useState('')
    const [userTagErr, setUserTagErr] = useState(false)
    const [priority, setPriority] = useState('')
    const [priorityErr, setPriorityErr] = useState(false)
    const [openPromo, setOpenPromo] = useState(false)
    const [showBanner, setShowbanner] = useState('')
    const [time, setTime] = useState({
        startTime: '',
        endTime: '',
        scheduledTime: ''
    })

    const [dateFields, setDateField] = useState({
        startDate: '',
        endDate: '',
        schedule: ''
    })

    const [minTime, setMinTime] = useState(new Date());
    const [maxTime, setMaxTime] = useState(new Date(new Date().setHours(23, 59)));

    const [scheduleMinTym, setScheduleMinTym] = useState(new Date());
    const [scheduleMaxTym, setScheduleMaxTym] = useState(new Date(new Date().setHours(23, 59)));

    const [dateErr, setDateErr] = useState({
        startDateErr: '',
        endDateErr: '',
    })
    const [toggle, setToggle] = useState(false)
    const [isToggled, setIsToggle] = useState("");
    const [isDisabled, setIsDisabled] = useState(false);
    const alluserTags = useSelector((p) => p.CreatePromoSlice.userTags)
    const [actionBtnName, setActionBtnName] = useState('')
    const [actionLink, setActionBtnLink] = useState('')
    const viewOnlyuser = useSelector((state) => state.dashboard.viewOnlyuser)
    // const [isApiImg, setIsApiImg] = useState(false)


    useEffect(() => {

        setPromoTitle(titleEdit)
        setMsgFormatType(msgTypeEdit)
        setActionBtnName(actionBtnNameEdit && actionBtnNameEdit[0])
        setActionBtnLink(actionLinkEdit && actionLinkEdit[0])
        setShortMsg(shortMsgEdit)
        setLongMsg(longMsgEdit)
        setIsToggle(isAllUsersEdit)
        const cidNum = cidEdit?.length === 0 ? "" : cidEdit;
        setCid(cidNum)
        const userTagDefault = userTagEdit && userTagEdit.length > 0 ? userTagEdit[0] : userTag;
        setUserTag(userTagEdit && userTagEdit[0]);
        setIsDisabled(false)
        setPriority(priorityEdit)
        setDateField({
            startDate: startDateEdit ? new Date(startDateEdit) : '',
            endDate: endDateEdit ? new Date(endDateEdit) : '',
            schedule: scheduleEdit ? new Date(scheduleEdit) : ''
        })
        setPromoBanner(bannerPath)
    }, [editListData])

    const inputHandle = (e) => {
        const { name, value } = e.target
        if (name === "promoTitle") {
            setPromoTitle(value)
            setTitleErr(false)
        }
        if (name === "shortMsg") {
            setShortMsg(value)
            setShortMsgErr(false)
        }
        if (name === "longMsg") {
            setLongMsg(value)
            setLongMsgErr(false)
        }
        if (name === "cid" && !isDisabled) {
            setCid(value)
            setUserTag('')
        }
        if (name === "actionBtnName") {
            setActionBtnName(value)
        }
        if (name === "actionLink") {
            setActionBtnLink(value)
        }

    }
    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === "msgFormatType") {
            setMsgFormatType(value)
            setTypeErr(false)
        }
        if (name === "userTag" && !isDisabled) {
            setUserTag(value)
            setUserTagErr(false)
            setCid('')
        }
        if (name === "priority") {
            setPriority(value)
            setPriorityErr(false)
        }

    }

    const uploadIp = async (e) => {
        const { name } = e.target;
        const file = e.target.files[0];
        setPromoBanner(file)
        if (name === "promoBanner" && !file) {
            setFileErr('Please choose proper file');
        } else if (file && file.size > 100 * 1024) {
            setFileErr('File size exceeds 100KB limit')
        } else {
            setFileErr('');

            try {
                const dataURL = await fileToDataURL(file);
                setShowbanner(dataURL);
            } catch (error) {
                console.error('Error converting file to data URL:', error);
            }
        }
    };

    const fileToDataURL = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onloadend = () => {
                resolve(reader.result);
            };

            reader.onerror = reject;

            reader.readAsDataURL(file);
        });
    };

    const handlePickerCaller = (value, name) => {
        setDateField((prevState) => {
            return {
                ...prevState,
                [name]: value,
            }
        })
        const initialTodayMinTime = new Date();
        const initialTodayMaxTime = new Date(new Date().setHours(23, 59));
        if (value instanceof Date && !isNaN(value)) {
            const today = new Date();
            const isToday = value.toDateString() === today.toDateString();

            if (isToday) {
                setScheduleMinTym(initialTodayMinTime);
                setScheduleMaxTym(initialTodayMaxTime);
            } else {
                setScheduleMinTym(new Date(0, 0, 0, 0, 0));
                setScheduleMaxTym(new Date(0, 0, 0, 23, 59));
            }
        } else {
            const today = new Date();
            setScheduleMinTym(today);
        }
    }

    const dateErrHandle = (key, err) => {
        setDateErr((prev) => {
            return {
                ...prev,
                [key]: err
            }
        })
    }
    const onClose = () => {
        setOpenPromo(false)
    }

    const onOpen = () => {
        if (validation() && !fileErr) {
            setOpenPromo(true)
        }
    }
    const now = new Date();
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const currentTimeString = `${hours}:${minutes}`;
    const onClear = () => {
        setPromoTitle('')
        setTitleErr('')
        setMsgFormatType('')
        setTypeErr('')
        setFileErr('')
        setShortMsg('')
        setShortMsgErr('')
        setLongMsg('')
        setLongMsgErr('')
        setCid('')
        setUserTag('')
        setActionBtnName('')
        setActionBtnLink('')
        setUserTagErr('')
        setPriority('')
        setPriorityErr('')
        setDateField({
            startDate: '',
            endDate: '',
            schedule: ''
        })
        setDateErr({
            startDateErr: '',
            endDateErr: ''
        })
        setTime({
            scheduledTime: ''
        })
        // setTime({
        //     startTime: currentTimeString,
        //     endTime: currentTimeString,
        //     scheduledTime: currentTimeString
        // })
        const fileField = document.getElementById("promoBanner")
        if (fileField) {
            fileField.value = ""
            setPromoBanner("")
        }
    }
    const validation = () => {
        let isValid = true;

        if (promoTitle === "" || promoTitle === undefined) {
            setTitleErr(true);
            isValid = false;
        }
        if (msgFormatType === "" || msgFormatType === undefined) {
            setTypeErr(true);
            isValid = false;
        }
        if (promoBanner === "" || promoBanner === undefined) {
            setFileErr('Please choose proper file');
            isValid = false;
        }
        // if (shortMsg === "") {
        //     setShortMsgErr(true);
        //     isValid = false;
        // }
        // if (longMsg === "") {
        //     setLongMsgErr(true);
        //     isValid = false;
        // }
        // if (userTag === "") {
        //     setUserTagErr(true);
        //     isValid = false;
        // }
        if (priority === "" || priority === undefined) {
            setPriorityErr(true);
            isValid = false;
        }
        if (dateFields.startDate === "") {
            dateErrHandle("startDateErr", "Start Date Required");
            isValid = false;
        }
        if (dateFields.endDate === "") {
            dateErrHandle("endDateErr", "End Date Required");
            isValid = false;
        }
        if (dateFields.startDate !== "" && dateFields.endDate !== "") {
            if (dateFields.endDate < dateFields.startDate) {
                dateErrHandle("endDateErr", "End Date Should not be less then Start Date");
                isValid = false;
            }
            const endDateTime = extractedTime(dateFields.endDate)
            const startDateTime = extractedTime(dateFields.startDate)
            const extractedStartDate = extractDate(dateFields.endDate)
            const extractedEndtDate = extractDate(dateFields.startDate)
            if (endDateTime === startDateTime && extractedStartDate === extractedEndtDate) {
                dateErrHandle("endDateErr", "End Date Time Should not be same as Start Date Time");
                isValid = false;
            }


        }
        return isValid;
    }

    const onSubmitData = () => {
        let userIds = typeof cid === 'string' && cid.trim() !== "" ?
            (isNaN(cid) ? cid.split(',').map(Number) : [Number(cid)]) :
            [];
        // let userIds = cid.trim() !== "" ? isNaN(cid) ? cid.split(',').map(Number) : [Number(cid)] : [];
        const data = {
            "id": isApiImg ? editId : 0,
            "messageType": msgFormatType,
            "entityType": "PROMOTION",
            "notificationType": "PROMOTION",
            "notificationStatus": "CREATED",
            "notificationPriority": priority,
            "notificationIcon": "",
            "title": promoTitle,
            "description": shortMsg,
            "message": longMsg,
            "htmlTemplate": "",
            "data": "",
            "userIds": userIds,
            "userTags": userTag ? [userTag] : [],
            "isScheduled": dateFields.schedule ? true : false,
            "scheduledAt": dateFields.schedule ? MomentUTCDateTime(dateFields.schedule) : null,
            "isAllUsers": toggle,
            "startDate": MomentUTCDateTime(dateFields.startDate),
            "endDate": MomentUTCDateTime(dateFields.endDate),
            "actionButtonUrls": actionLink ? [actionLink] : [],
            "actionButtonLabels": actionBtnName ? [actionBtnName] : [],
            "startTime": null,
            "endTime": null,
            "scheduledTime": null
        }
        const fetchFormData = new FormData();
        fetchFormData.append("file", promoBanner);
        fetchFormData.append("promotionDetails", JSON.stringify(data));
        dispatch(savePromotionDeails(fetchFormData));
        setOpenPromo(false)
        onClear()
    }

    useEffect(() => {
        dispatch(getAllUserTags())
    }, [])



    const onTimeChange = (e) => {
        const { value, name } = e.target
        setTime((prevTime) => ({
            ...prevTime,
            [name]: value,
        }));
    }

    const toggled = (e) => {
        setIsToggle(!isToggled)
        isToggled ? setToggle(false) : setToggle(true)
        setIsDisabled(e.target.checked);
        if (e.target.checked) {
            setCid('');
            setUserTag('');
            setToggle(true)
        }
    }


    const dateHandle = (value, name) => {
        setDateField((prevState) => {
            return {
                ...prevState,
                [name]: value,
            };
        });
        dateErrHandle("startDateErr", '')
        dateErrHandle("endDateErr", '')
        const initialTodayMinTime = new Date();
        const initialTodayMaxTime = new Date(new Date().setHours(23, 59));
        if (value instanceof Date && !isNaN(value)) {
            const today = new Date();
            const isToday = value.toDateString() === today.toDateString();

            if (isToday) {
                setMinTime(initialTodayMinTime);
                setMaxTime(initialTodayMaxTime);
            } else {
                setMinTime(new Date(0, 0, 0, 0, 0));
                setMaxTime(new Date(0, 0, 0, 23, 59));
            }
        } else {
            const today = new Date();
            setMinTime(today);
        }
    };

    return (
        <React.Fragment>
            <Dialog__Main
                title={"PROMO PREVIEW"}
                dOpen={openPromo}
                dClose={() => onClose()}
                width={"500px"}
            // dHeight={"280px"}
            >
                <PromoPreview
                    onSubmitData={() => onSubmitData()}
                    promoTitle={promoTitle}
                    msgFormatType={msgFormatType}
                    shortMsg={shortMsg}
                    longMsg={longMsg}
                    userTag={userTag}
                    startDate={dateFields?.startDate}
                    endDate={dateFields?.endDate}
                    startTime={time?.startTime}
                    endTime={time?.endTime}
                    promoBanner={showBanner}
                    apiPromoBanner={isApiImg ? promoBanner : ''}
                    setOpenPromo={() => setOpenPromo(false)}
                />
            </Dialog__Main>
            <div className='CMS-tab-panel active' id='CMS-betting'></div>
            <div className='CMS-tabContent'>
                <div className='CMS-box CMS-box-content'>
                    <div className="row">
                        <div className='col-3 ml-15'>
                            <Input
                                title={"Promo Title (50 Characters)"}
                                name={"promoTitle"}
                                value={promoTitle}
                                setValue={(e) => inputHandle(e)}
                                placeholder={"Enter..."}
                                ids={"promoTitle"}
                                info={communication_module.promo_title}
                                show={true}
                                maxLen={50}
                                errorMsg={titleErr && "Promo Title Required"}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className='col-3 ml-15'>
                            <Select
                                title={"Message Format Type"}
                                name={"msgFormatType"}
                                ids={"msgFormatType"}
                                value={msgFormatType}
                                setValue={(val) => handleChange(val)}
                                options={messageFormatType}
                                errorMsg={typeErr && "Please select your Message format Type"}
                                info={communication_module.msgformat_type}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className='col-3 ml-15'>
                            <Input
                                title={"Promo Banner"}
                                placeholder={"upload file"}
                                name={"promoBanner"}
                                type={"file"}
                                ids={"promoBanner"}
                                show={true}
                                info={communication_module.promoBanner}
                                accept={"image/jpeg,image/png"}
                                setValue={(e) => uploadIp(e)}
                                errorMsg={fileErr}
                            />
                        </div>
                    </div>
                    {promoBanner ? <div className="row">
                        <div className='col-3 ml-10'>
                            <img src={showBanner} />
                        </div>
                    </div> : ''}
                    {isApiImg ?
                        <div className='col-3 ml-10'>
                            <img src={promoBanner} />
                        </div>
                        : ''}
                    <div className="row">
                        <div className='col-3 ml-15'>
                            <Input
                                title={"Action Button Name"}
                                name={"actionBtnName"}
                                value={actionBtnName}
                                setValue={(e) => inputHandle(e)}
                                placeholder={"Enter..."}
                                ids={"actionBtnName"}
                                info={communication_module.actionbtn_Name}
                                show={true}
                            />
                        </div>
                        <div className='col-3'>
                            <Input
                                title={"Action Link"}
                                name={"actionLink"}
                                value={actionLink}
                                setValue={(e) => inputHandle(e)}
                                placeholder={"Enter..."}
                                ids={"actionLink"}
                                info={communication_module.action_Link}
                                show={true}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className='col-md-6 col-lg-6 col-xl-9 ml-15'>
                            <div className='CMS-formGroup'>
                                <div className='CMS-formLabel'>
                                    {communication_module.shortMsg}
                                    &nbsp;&nbsp;
                                    <Tooltips info={communication_module.shortMsg} />
                                </div>
                                <div className='CMS-formControl-group'>
                                    <div className='CMS-formControl'>
                                        <textarea
                                            rows='2'
                                            cols='50'
                                            name='shortMsg'
                                            id='shortMsg'
                                            value={shortMsg}
                                            onChange={(e) => inputHandle(e)}
                                            maxlength='144'
                                        ></textarea>
                                    </div>
                                    {shortMsgErr && (
                                        <div className='CMS-form-errorMsg'>
                                            {"Short Message required"}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-6 col-lg-6 col-xl-9 ml-15'>
                            <div className='CMS-formGroup'>
                                <div className='CMS-formLabel'>
                                    {communication_module.longMsgIp}
                                    &nbsp;&nbsp;
                                    <Tooltips info={communication_module.longMsg} />
                                </div>
                                <div className='CMS-formControl-group'>
                                    <div className='CMS-formControl'>
                                        <textarea
                                            rows='2'
                                            cols='50'
                                            name='longMsg'
                                            id='longMsg'
                                            value={longMsg}
                                            maxlength='320'
                                            onChange={(e) => inputHandle(e)}
                                        ></textarea>
                                    </div>
                                    {longMsgErr && (
                                        <div className='CMS-form-errorMsg'>
                                            {"Long Message Required"}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-3 ml-15'>
                            <div className='CMS-formGroup formGroup-inline'>
                                <div className='CMS-formLabel mb-0 mr-10'>
                                    All Users
                                    &nbsp;&nbsp;
                                    <Tooltips info={COMMON_MSGS.AllUsers} />
                                </div>
                                <div className='CMS-toggleSwitch'>
                                    <input
                                        id='toggleActive'
                                        name='toggleActive'
                                        value={toggle}
                                        checked={isToggled}
                                        type='checkbox'
                                        onChange={toggled}
                                    />
                                    <label for='toggleActive'></label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-3 ml-15'>
                            <Input
                                title={"Customer ID (Optional)"}
                                name={"cid"}
                                value={cid}
                                setValue={(e) => inputHandle(e)}
                                placeholder={"Enter..."}
                                ids={"promocid"}
                                info={COMMON_MSGS.cid}
                                show={true}
                                disabled={isDisabled || !!userTag}
                            />
                        </div>
                        <div className='col-3'>
                            <div className='CMS-formGroup'>
                                <div className='CMS-formLabel'>
                                    User Tag
                                    &nbsp;&nbsp;
                                    <Tooltips info={communication_module.userTag} />
                                </div>
                                <div className='CMS-dropdown CMS-formControl'>
                                    <div className='CMS-select'>
                                        <select
                                            name='userTag'
                                            value={userTag}
                                            onChange={(val) => handleChange(val)}
                                            className="pointer"
                                            disabled={isDisabled || !!cid}
                                        >
                                            <option value=''>{COMMON_CONSTANTS.ddlOpt_select}</option>
                                            {Object.keys(alluserTags).length >
                                                0 &&
                                                alluserTags
                                                    .map((item, index) => {
                                                        return (
                                                            <option
                                                                key={index}
                                                                value={item}
                                                            >
                                                                {item}
                                                            </option>
                                                        );
                                                    })}
                                        </select>
                                    </div>
                                    {userTagErr && (
                                        <div className='CMS-form-errorMsg'>
                                            {"User Tag Required"}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className='col-3'>
                            <Select
                                title={"Priority"}
                                name={"priority"}
                                ids={"promopriority"}
                                value={priority}
                                setValue={(val) => handleChange(val)}
                                options={promopriority}
                                errorMsg={priorityErr && "Please select your Priority"}
                                info={communication_module.priority}
                            />
                        </div>
                    </div>
                    {/* <div className='d-flex'>
                        {
                            createPromo.map((item, ind) => {
                                const max = dateFields.endDate ? new Date(dateFields.endDate) : null;
                                const min = dateFields.startDate ? dateFields.startDate : new Date();
                                const maxDate = [max, null];
                                const minDate = [new Date(), min];
                                const values = [dateFields.startDate, dateFields.endDate];
                                const dateErrs = [dateErr.startDateErr, dateErr.endDateErr];
                                const customTime = [time.startTime, time.endTime]
                                return (
                                    <React.Fragment key={item.id}>
                                        <div className='col-12 col-md-4'>
                                            <div className="CMS-formLabel">
                                                {item.title}
                                                &nbsp;&nbsp;
                                                <Tooltips info={item.info} />
                                            </div>
                                            <DateTime
                                                // title={item.title}
                                                name={item.name}
                                                datevalue={values[ind]}
                                                setValue={(value, name) => handlePickerCaller(value, name)}
                                                errorMsg={dateErrs[ind]}
                                                maxDate={maxDate[ind]}
                                                minDate={minDate[ind]}
                                                ids={item.ids}
                                                // info={item.info}
                                                onTimeChange={(e) => onTimeChange(e)}
                                                timeValue={customTime[ind]}
                                                timeName={item.timeName}
                                                noInfo
                                            />
                                        </div>
                                    </React.Fragment>
                                );
                            })
                        }
                        <div className='col-12 col-md-4'>
                            <div className="CMS-formLabel">
                                {"Schedule Send (Optional)"}
                                &nbsp;&nbsp;
                                <Tooltips info={communication_module.promo_schedule} />
                            </div>
                            <DateTime
                                // title={"Schedule Send (Optional)"}
                                name={"schedule"}
                                datevalue={dateFields.schedule}
                                setValue={(value, name) => handlePickerCaller(value, name)}
                                ids={"promo_schedule"}
                                maxDate={time.scheduledTime}
                                minDate={new Date()}
                                // info={communication_module.promo_schedule}
                                onTimeChange={(e) => onTimeChange(e)}
                                timeValue={time.scheduledTime}
                                timeName={"scheduledTime"}
                                noInfo
                            />
                        </div>
                    </div> */}
                    <div className='row'>
                        {createPromo.map((item, index) => {
                            const values = [dateFields.startDate, dateFields.endDate, dateFields.schedule]
                            const dateErrs = [dateErr.startDateErr, dateErr.endDateErr];

                            const today = new Date();
                            const maxEndDate = endDateEdit ? null : (dateFields.endDate ? new Date(dateFields.endDate) : null);
                            const maxDateForEndDate = endDateEdit ? today : (dateFields.startDate ? new Date(dateFields.startDate) : today);
                            const maxDate = [maxEndDate, null];
                            const minDate = [today, maxDateForEndDate];

                            return (
                                <div className='col-3 ml-15'>
                                    <Datepicker
                                        title={item.title}
                                        name={item.name}
                                        ids={item.ids}
                                        value={values[index]}
                                        setValue={(value, name) =>
                                            dateHandle(value, name)
                                        }
                                        showTimeSelect
                                        minTime={minTime}
                                        maxTime={maxTime}
                                        maxDate={maxDate[index]}
                                        minDate={minDate[index]}
                                        info={item.info}
                                        errorMsg={dateErrs[index]}
                                    />
                                </div>
                            );
                        })}
                        <div className='col-3'>
                            <div className="CMS-formLabel">
                                {"Schedule Send (Optional)"}
                                &nbsp;&nbsp;
                                <Tooltips info={communication_module.promo_schedule} />
                            </div>
                            <Datepicker
                                title={"Schedule Send (Optional)"}
                                name={"schedule"}
                                datevalue={dateFields.schedule}
                                setValue={(value, name) => handlePickerCaller(value, name)}
                                ids={"promo_schedule"}
                                maxDate={time.scheduledTime}
                                minDate={new Date()}
                                minTime={scheduleMinTym}
                                maxTime={scheduleMaxTym}
                                showTimeSelect
                                info={communication_module.promo_schedule}
                                noInfo
                            />
                        </div>
                    </div>
                    <div className='mt-20'></div>
                    <div className='col-12'>
                        <div className="CMS-btnContainer">
                            <button
                                onClick={() => onClear()}
                                className={`CMS-btn CMS-btnSecondary active CMS-btnMedium ${viewOnlyuser ? 'disable-button' : ''}`}

                            >
                                Clear All
                            </button>
                            <button
                                className={`CMS-btn CMS-btnQuaternary active CMS-btnMedium ${viewOnlyuser ? 'disable-button' : ''}`}
                                onClick={() => onOpen()}
                            >
                                Preview & Send
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default CreatePromoMsg