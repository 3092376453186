import React from 'react'
import Tooltips from '../Common/Tooltip'
import No__Data__Found from '../Common/table/No__Data__Found'
import { getDateTimeFormate } from '../../sharedfiles/helper'
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { editPromotionList, setEditId, setIsApiImg } from './promotionListSlice';

const PromoListTable = (props) => {
    const dispatch = useDispatch()
    const history = useHistory();
    const viewOnlyuser = useSelector((state) => state.dashboard.viewOnlyuser)
    const tHead = [
        "Promo Title",
        "Message Format Type",
        "User Tag",
        "Priority",
        "Status",
        "Creation Date",
        "End Date",
        "Action",
        "Updated By"
    ]

    const theadInfo = [
        "Showing Promo Title",
        "Showing Message Format Type",
        "Showing User Tag",
        "Showing Priority",
        "Showing Status",
        "Showing Creation Date",
        "Showing End Date",
        "Showing Action to be taken",
        "Showing Updated By"
    ]
    const handleEditClick = (id) => {
        dispatch(editPromotionList(id))
        history.push("/createpromomsg");
        dispatch(setEditId(id))
        dispatch(setIsApiImg(true))
    }

    return (
        <div className='CMS-box'>
            <div className="CMS-table CMS-table-triped">
                <table>
                    <thead>
                        <tr>
                            {tHead.map((item, index) => (
                                <th>
                                    <div className='d-flex justify-content-center align-items-center'>
                                        <p className='mx-3'>{item}</p>
                                        <Tooltips info={`${theadInfo[index]}`} />
                                    </div>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {props.tableData && props.tableData.length > 0
                            ? (
                                props.tableData.map((item, index) => {
                                    const stringWithBrackets = item.userTags[0];
                                    const extractedString = stringWithBrackets.replace(/{|}/g, '')
                                    return (
                                        <tr>
                                            <td>{item.title}</td>
                                            <td>{item.messageType}</td>
                                            <td>{extractedString}</td>
                                            <td>{item.notificationPriority}</td>
                                            <td>{item.notificationStatus}</td>
                                            <td>{getDateTimeFormate(item.createdDate)}</td>
                                            <td>{getDateTimeFormate(item.endDate)}</td>
                                            <td>
                                                <button
                                                    onClick={() => handleEditClick(item.id)}
                                                    className={`CMS-btn CMS-btnSecondary active CMS-btnSmall pointer ${viewOnlyuser ? 'disable-button' : ''}`}
                                                >
                                                    Edit

                                                </button>
                                            </td>
                                            <td>{item.updatedBy}</td>
                                        </tr>
                                    );
                                })
                            ) : (
                                <No__Data__Found colspan={50} />
                            )}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default PromoListTable