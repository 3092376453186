import React, { useEffect, useState } from 'react'
import Select from "../Common/common_inputs/Select_DDL";
import { cashoutOpt, paymentLimitOpt } from '../Common/PrimaryplayerTab/ComTab';
import { Payments } from '../../sharedfiles/tooltipMsgs';
import Input from '../Common/common_inputs/Input';
import { COMMON_CONSTANTS } from '../../sharedfiles/Constants';
import { MoneyFormat1, MoneyFormat2, regexType } from '../../sharedfiles/helper';
import { getUpdatedLimits, setDisableBtn, updatePlayertLimit, updateUserCashoutAvailability, userPaymentLimits } from './LimitsTabSlice';
import { useDispatch, useSelector } from 'react-redux';
import Tooltips from '../Common/Tooltip';
import { getPlayerGeneralDetail } from '../GeneralDetail/GeneralDetailSlice';
const LimitsTab = (props) => {
    const updatedCashout = useSelector((state) => state.generaldetail?.playerData)

    const withdrawEnabled = updatedCashout?.userLimit?.withdrawEnabled;
    const depositEnabled = updatedCashout?.userLimit?.depositEnabled;
    const dispatch = useDispatch()
    const [PaymnetLimits, setPaymnetLimits] = useState("")
    const [limitErr, setLimitErr] = useState(false)
    const [depositMinLimit, setDepositMinLimit] = useState("")
    const [depositMaxLimit, setDepositMaxLimit] = useState("")
    const [withdrawMinLimit, setWithdrawMinLimit] = useState("")
    const [withdrawMaxLimit, setWithdrawMaxLimit] = useState("")
    const [depoMinErr, setDepoMinErr] = useState(false)
    const [depoMaxErr, setDepoMaxErr] = useState(false)
    const [withdrawMinErr, setWithdrawMinErr] = useState(false)
    const [withdrawMaxErr, setWithdrawMaxErr] = useState(false)
    const [disable, setDisable] = useState(false)
    const [playerCashout, setPlayerCashout] = useState('')
    const [cashoutErr, setCashoutErr] = useState(false)
    const [updateDeposit, setUpdateDeposit] = useState(depositEnabled);
    const [updateWithdraw, setUpdateWithdraw] = useState(withdrawEnabled);
    const [disableUpdateButton, setDisableUpdateButton] = useState(false)

    const DepominErrMsg = "Min Deposit Limit is required"
    const DepomaxErrMsg = "Max Deposit Limit is required"
    const WidminErrMsg = "Min Withdrawal Limit is required"
    const WidmaxErrMsg = "Max Withdrawal Limit is required"
    const cashoutErrMsg = "Please choose one of the Option"

    const userId = localStorage.getItem("userId")
    const disableBtn = useSelector((state) => state.paymentLimitTab.disableBtn)
    const updatedLimits = useSelector((state) => state.paymentLimitTab.updatedLimits)
    const viewOnlyuser = useSelector((state) => state.dashboard.viewOnlyuser)
    // const updatedCashout = useSelector((state) => state.generaldetail?.playerData)
    const casoutPermission = useSelector((state) => state.dashboard?.playerCashoutPermission)

    const options = [
        { label: "Enable", value: true },
        { label: "Disable", value: false }
    ]

    const handleChange = (e) => {
        const { name, value } = e.target

        if (value === "") {
            setLimitErr(true)
        } else {
            setLimitErr(false)
            setPaymnetLimits(value)
        }
        setPaymnetLimits(value);
    }

    const cashoutUpdate = (e) => {
        const value = e.target.value
        setPlayerCashout(value)
        if (value === '') {
            setCashoutErr(true)
        } else {
            setCashoutErr(false)
        }
    }

    const handleInput = (e) => {
        const { name, value } = e.target
        switch (name) {
            case "depositMinLimit":
                setDepositMinLimit(value);
                setDepoMinErr(false)
                break;
            case "depositMaxLimit":
                setDepositMaxLimit(value);
                setDepoMaxErr(false)
                break;
            case "withdrawMinLimit":
                setWithdrawMinLimit(value);
                setWithdrawMinErr(false)
                break;
            case "withdrawMaxLimit":
                setWithdrawMaxLimit(value);
                setWithdrawMaxErr(false)
            default:
                break;
        }
    }

    const onSubmit = () => {
        if (PaymnetLimits === "DEPOSIT LIMITS") {
            if (depositMinLimit === "" || depositMaxLimit !== "") {
                setDepoMinErr(true)
                setDepoMaxErr(false)
            } else if (depositMinLimit !== "" && depositMaxLimit === "") {
                setDepoMinErr(false)
                setDepoMaxErr(true)
            }
        }
        if (PaymnetLimits === "WITHDRAW LIMITS") {
            if (withdrawMinLimit === "" || withdrawMaxLimit !== "") {
                setWithdrawMinErr(true)
                setWithdrawMaxErr(false)
            } else if (withdrawMinLimit !== "" && withdrawMaxLimit === "") {
                setWithdrawMinErr(false)
                setWithdrawMaxErr(true)
            }
        }

        if (PaymnetLimits === "DEPOSIT LIMITS" && depositMinLimit !== "" && depositMaxLimit !== "") {
            const data = {
                configType: "DEPOSIT",
                maxLimit: MoneyFormat2(depositMaxLimit),
                minLimit: MoneyFormat2(depositMinLimit),
                userId: userId
            }
            dispatch(userPaymentLimits(data))
            setDisable(true)
            setDepoMinErr(false)
        }

        if (PaymnetLimits === "WITHDRAW LIMITS" && withdrawMinLimit !== "" && withdrawMaxLimit !== "") {
            const data = {
                configType: "WITHDRAW",
                maxLimit: MoneyFormat2(withdrawMaxLimit),
                minLimit: MoneyFormat2(withdrawMinLimit),
                userId: userId
            }
            dispatch(userPaymentLimits(data))
            setDisable(true)
            setWithdrawMinErr(false)
        }

        if (PaymnetLimits === "PLAYERCASHOUT" && !cashoutErr) {
            const userId = localStorage.getItem("userId");
            const payloadVal = playerCashout === "ENABLE" ? true : false
            const data = {
                isCashoutEnabled: payloadVal,
                userId: userId
            }
            dispatch(updateUserCashoutAvailability(data))
            setDisable(true)
        }
        if (PaymnetLimits === "DEPOSIT") {
            let booleanPayload = updateDeposit

            const payload = {
                depositEnabled: booleanPayload
            }
            const userId = localStorage.getItem("userId");
            setDisableUpdateButton(true)
            dispatch(updatePlayertLimit(userId, payload));
            setTimeout(() => {
                setDisableUpdateButton(false)
            }, 3000);
        }
        if (PaymnetLimits === "WITHDRAW") {
            let booleanPayload = updateWithdraw
            const payload = {
                withdrawEnabled: booleanPayload
            }
            const userId = localStorage.getItem("userId");
            setDisableUpdateButton(true)
            dispatch(updatePlayertLimit(userId, payload));
            setTimeout(() => {
                setDisableUpdateButton(false)
            }, 3000);
        }
    }
    useEffect(() => {
        var btnTimer;
        if (disableBtn) {
            btnTimer = setTimeout(() => {
                dispatch(setDisableBtn(false))
                setDisable(false)
            }, 2500)
        };
        return () => clearTimeout(btnTimer);
    }, [disableBtn]);

    useEffect(() => {
        dispatch(getPlayerGeneralDetail({ user_id: userId }));
        // setUpdateDeposit(depositEnabled)
        // setUpdateWithdraw(withdrawEnabled)
    }, [])

    useEffect(() => {

        if (Array.isArray(updatedLimits)) {

            const depositConfig = updatedLimits.find(config => config.configType === 'DEPOSIT');
            const UpdatedDepoMinLimits = depositConfig ? depositConfig.minLimit : null;
            const UpdatedDepoMaxLimits = depositConfig ? depositConfig.maxLimit : null;
            setDepositMinLimit(MoneyFormat1(UpdatedDepoMinLimits))
            setDepositMaxLimit(MoneyFormat1(UpdatedDepoMaxLimits))

            const withdrawConfig = updatedLimits.find(config => config.configType === 'WITHDRAW');
            const UpdatedWidMinLimits = withdrawConfig ? withdrawConfig.minLimit : null;
            const UpdatedWidMaxLimits = withdrawConfig ? withdrawConfig.maxLimit : null;
            setWithdrawMinLimit(MoneyFormat1(UpdatedWidMinLimits))
            setWithdrawMaxLimit(MoneyFormat1(UpdatedWidMaxLimits))
        }
        const cashoutValue = updatedCashout.userOtherInfo?.isCashOutEnabled
        const pageLoadVal = cashoutValue === true ? "ENABLE" : "DISABLE"
        setPlayerCashout(pageLoadVal)
    }, [])
    const handleDepositAction = (e) => {
        let { name, value } = e.target;
        setUpdateDeposit(value)
    };
    const handleWithdrawAction = (e) => {
        let { name, value } = e.target;
        setUpdateWithdraw(value)
    };

    return (
        <React.Fragment>
            <div className='CMS-tabContent'>
                <div className='CMS-box-content'>
                    <div className='CMS-box CMS-box-content'>
                        <div className='row'>
                            <div className='col-md-6 col-lg-4 col-xl-3'>
                                <div className='CMS-formGroup'>
                                    <div className='CMS-formLabel'>
                                        {"Payment Limits"}
                                        &nbsp;&nbsp;
                                        <Tooltips info={Payments.Payment_limits} />
                                    </div>
                                    <div className='CMS-formControl'>
                                        <div className='CMS-select'>
                                            <select
                                                name='PaymnetLimits' casoutPermission
                                                id='paymentlimitTab'
                                                value={PaymnetLimits}
                                                onChange={(e) => handleChange(e)}
                                                className="pointer"
                                            >
                                                <option value=''>{COMMON_CONSTANTS.ddlOpt_select}</option>
                                                <option value="DEPOSIT LIMITS">{"Deposit Limits"}</option>
                                                <option value="WITHDRAW LIMITS">{"Withdraw Limits"}</option>
                                                <option value="WITHDRAW">{"Withdraw"}</option>
                                                <option value="DEPOSIT">{"Deposit"}</option>
                                                {casoutPermission ? <option value="PLAYERCASHOUT">{"Player Cashout"}</option> : ""}
                                            </select>
                                        </div>
                                    </div>
                                    {limitErr && (
                                        <div className='text-danger'>
                                            {"Please select your Payment Limits"}
                                        </div>
                                    )}
                                </div>
                            </div>
                            {PaymnetLimits === "DEPOSIT LIMITS" ?
                                <div className='col-md-6 col-lg-4 col-xl-3'>
                                    <Input
                                        title={"Min Deposit Limit"}
                                        name={"depositMinLimit"}
                                        ids={"depositMinLimit"}
                                        value={depositMinLimit}
                                        placeholder={'Min Deposit Limit'}
                                        setValue={(e) => handleInput(e)}
                                        errorMsg={depoMinErr && DepominErrMsg}
                                        info={Payments.DepoMinLimit}
                                        show={true}
                                        regex={regexType.numeric}
                                    />
                                </div>

                                : ""}
                            {PaymnetLimits === "DEPOSIT LIMITS" ?
                                <div className='col-md-6 col-lg-4 col-xl-3'>
                                    <Input
                                        title={"Max Deposit Limit"}
                                        name={"depositMaxLimit"}
                                        ids={"depositMaxLimit"}
                                        value={depositMaxLimit}
                                        placeholder={"Max Deposit Limit"}
                                        setValue={(e) => handleInput(e)}
                                        errorMsg={depoMaxErr && DepomaxErrMsg}
                                        info={Payments.DepoMaxLimit}
                                        show={true}
                                        regex={regexType.numeric}
                                    />
                                </div>
                                : ""}
                            {PaymnetLimits === "WITHDRAW LIMITS" ?
                                <div className='col-md-6 col-lg-4 col-xl-3'>
                                    <Input
                                        title={"Min Withdrawal Limit"}
                                        name={"withdrawMinLimit"}
                                        ids={"withdrawMinLimit"}
                                        value={withdrawMinLimit}
                                        placeholder={"Min Withdrawal Limit"}
                                        setValue={(e) => handleInput(e)}
                                        errorMsg={withdrawMinErr && WidminErrMsg}
                                        info={Payments.WithdrawMinLimit}
                                        show={true}
                                        regex={regexType.numeric}

                                    />
                                </div>
                                : ""}
                            {PaymnetLimits === "WITHDRAW LIMITS" ?
                                <div className='col-md-6 col-lg-4 col-xl-3'>
                                    <Input
                                        title={"Max Withdrawal Limit"}
                                        name={"withdrawMaxLimit"}
                                        ids={"withdrawMaxLimit"}
                                        value={withdrawMaxLimit}
                                        placeholder={"Max Withdrawal Limit"}
                                        setValue={(e) => handleInput(e)}
                                        errorMsg={withdrawMaxErr && WidmaxErrMsg}
                                        info={Payments.WithdrawMaxLimit}
                                        show={true}
                                        regex={regexType.numeric}

                                    />
                                </div>
                                : ""}

                            {PaymnetLimits === "PLAYERCASHOUT" ?
                                <div className='col-md-6 col-lg-4 col-xl-3'>
                                    <Select
                                        title={"Update Player Cashout"}
                                        name={"playerCashout"}
                                        ids={"playerCashout"}
                                        value={playerCashout}
                                        setValue={(e) => cashoutUpdate(e)}
                                        options={cashoutOpt}
                                        errorMsg={cashoutErr && cashoutErrMsg}
                                        info={Payments.playerCasout}
                                    />
                                </div> : ''}
                            {PaymnetLimits === "DEPOSIT" ?
                                <div className='col-md-6 col-lg-4 col-xl-3'>
                                    <Select
                                        title={"Actions"}
                                        name={"Actions"}
                                        ids={"Actions"}
                                        value={updateDeposit}
                                        setValue={(val) => handleDepositAction(val)}
                                        options={options}
                                        info={"Showing Actions"}
                                    />
                                </div>
                                : ""}
                            {PaymnetLimits === "WITHDRAW" ?
                                <div className='col-md-6 col-lg-4 col-xl-3'>
                                    <Select
                                        title={"Actions"}
                                        name={"Actions"}
                                        ids={"Actions"}
                                        value={updateWithdraw}
                                        setValue={(val) => handleWithdrawAction(val)}
                                        options={options}
                                        info={"Showing Actions"}
                                    />
                                </div>
                                : ""}
                        </div>

                        <div className='mt-20'></div>
                        {PaymnetLimits !== "" ?
                            <div className='row'>
                                <div className='col-12'>
                                    <div className="CMS-btnContainer">
                                        <button
                                            onClick={(e) => onSubmit(e)}
                                            className={`CMS-btn CMS-btnSecondary active CMS-btnMedium ${disable || disableUpdateButton || viewOnlyuser ? 'disable-button' : ''}`}
                                            type={"button"}
                                            id={"updateLimit"}
                                        >
                                            {COMMON_CONSTANTS.update_Btn}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            : ""}

                    </div>
                </div>
            </div >


        </React.Fragment >
    )
}

export default LimitsTab