import { createSlice } from "@reduxjs/toolkit";
import { playerapi } from "../../sharedfiles/EndpointConfig";
import { apiCaller } from "../../api/apiCaller";
import { messageEnhancer, toaster } from "../../sharedfiles/helper";

export const KycDocumentsSlice = createSlice({
    name: "KycDocumentsSlice",
    initialState: {
        kycDocuments: [],
        isVisible: false,
        pageOne: 1,
        pageSecond: 25,
        recordShow: [1, 25],
    },
    reducers: {
        setKycDocuments: (state, action) => {
            state.kycDocuments = action.payload;
        },
        setIsVisible: (state, action) => {
            state.isVisible = action.payload;
        },
        setPageOne: (state, action) => {
            state.pageOne = action.payload;
        },
        setPageSecond: (state, action) => {
            state.pageSecond = action.payload;
        },
        setRecordShow: (state, action) => {
            state.recordShow = action.payload;
        },
    }
})

export const {
    setKycDocuments,
    setIsVisible,
    setPageOne,
    setPageSecond,
    setRecordShow,
} = KycDocumentsSlice.actions;

export const callKycDocuments = (id, pageNum, itemsPerPage, payload) => (dispatch) => {
    const data = {
        status: payload.status
    }
    const url = playerapi.kycDocuments(id, pageNum, itemsPerPage);
    apiCaller(url, "POST", data).then((res) => {
        dispatch(setKycDocuments(res.data));
        dispatch(setIsVisible(true));
    }).catch((err) => {
        dispatch(setIsVisible(true));
        const res = err.response;
        toaster.error(messageEnhancer(res));
    });
}

export const deleteKycReport = (id) => (dispatch) => {
    const url = playerapi.deleteKycReport(id);
    apiCaller(url, "DELETE", {})
        .then((res) => {
            const userId = localStorage.getItem("userId");
            const payload = {
                status: "KYC_PASSED",
            };
            toaster.success(messageEnhancer(res.msg));
            dispatch(callKycDocuments(userId, 0, 25, payload));
        })
        .catch((err) => {
            console.error(err);
            toaster.error(messageEnhancer(err.msg));
        });
};

export default KycDocumentsSlice.reducer;
