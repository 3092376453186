import { createSlice } from "@reduxjs/toolkit";
import { apiCaller } from "../../api/apiCaller";
import {
  bettingApi,
  setBaseUrl,
} from "../../sharedfiles/EndpointConfig";

export const BetHistorySlice = createSlice({
  name: "betHistorySliceReducer",
  initialState: {
    paginationFirstValue: 1,
    paginationSecondValue: 25,
    errorMessage: "",
    userid: "",
    userData: [],
    recordsShow: [1, 25],
    openDialog: false,
    betIdRecords: [],
    isVisible: false,
    formData: {},
    docsData: [],
    reportsData: [],
    reportDocs: {
      pdf: [],
      csv: []
    },
    filterData: [],
    filterLebels: [],
    reportsLoader: false,
    casinoType: {
      "CATEGORY": [],
      "GAME_NAME": [],
      "PROVIDER_NAME": [],
      "SUB_PROVIDER_NAME": []
    },
    gamesCasinoType: {
      "CATEGORY": [],
      "GAME_NAME": [],
      "PROVIDER_NAME": [],
      "SUB_PROVIDER_NAME": [],
    },
    casinoData: [],
    betPayloadData: {},
    providerNames: [],
    virtualsProviderNames: [],
  },
  reducers: {
    setBetHistory: (state, action) => {
      state.userData = action.payload;
    },
    setPaginationFirstValue: (state, action) => {
      state.paginationFirstValue = action.payload;
    },
    setPaginationSecondValue: (state, action) => {
      state.paginationSecondValue = action.payload;
    },
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    },
    setRecordsShow: (state, action) => {
      state.recordsShow = action.payload;
    },
    setOpenDialog: (state, action) => {
      state.openDialog = action.payload;
    },
    setBetId: (state, action) => {
      state.betIdRecords = action.payload;
    },
    setIsVisible: (state, action) => {
      state.isVisible = action.payload;
    },
    setFormData: (state, action) => {
      state.formData = action.payload;
    },
    setDocsData: (state, action) => {
      state.docsData = action.payload;
    },
    setReportsData: (state, action) => {
      state.reportsData = action.payload;
    },
    setReportDocs: (state, action) => {
      state.reportDocs = action.payload;
    },
    setReportsLoader: (state, action) => {
      state.reportsLoader = action.payload;
    },
    setCasinoType: (state, action) => {
      state.casinoType = action.payload;
    },
    setGamesCasinoType: (state, action) => {
      state.gamesCasinoType = action.payload;
    },
    setCasinoData: (state, action) => {
      state.casinoData = action.payload;
    },
    setBetPayloadData: (state, action) => {
      state.betPayloadData = action.payload;
    },
    setFilterData: (state, action) => {
      state.filterData = action.payload;
    },
    setFilterLebels: (state, action) => {
      state.filterLebels = action.payload;
    },
    addSelectedLabel: (state, action) => {
      state.filterLebels.push(action.payload); // Append a new label
    },
    removeSelectedLabel: (state, action) => {
      state.filterLebels = state.filterLebels.filter(label => label !== action.payload); // Remove a label
    },
    setProviderNames: (state, action) => {
      state.providerNames = action.payload;
    },
    setVirtualsProviderNames: (state, action) => {
      state.virtualsProviderNames = action.payload;
    },
  },
});
export const {
  setBetHistory,
  setPaginationFirstValue,
  setPaginationSecondValue,
  setErrorMessage,
  setRecordsShow,
  setOpenDialog,
  setBetId,
  setIsVisible,
  setFormData,
  setDocsData,
  setReportsData,
  setReportDocs,
  setReportsLoader,
  setCasinoType,
  setGamesCasinoType,
  setCasinoData,
  setBetPayloadData,
  setFilterData,
  setFilterLebels,
  removeSelectedLabel,
  addSelectedLabel,
  setProviderNames,
  setVirtualsProviderNames,
} = BetHistorySlice.actions;

const clean = (obj) => {
  for (var propName in obj) {
    if (
      obj[propName] == null ||
      obj[propName] === undefined ||
      obj[propName] === "" ||
      obj[propName] === 0 ||
      obj[propName] == "NaN-aN-aNT23:59:59.517Z" ||
      obj[propName] == "NaN-aN-aNT00:00:00.517Z"
    ) {
      delete obj[propName];
    }
  }
  return obj;
};
export const getBetHistorytableList =
  (pagenumbervalue, itemperpagevalue, params, activeAll) =>
    (dispatch) => {
      setBaseUrl();
      let obj = {};
      if (
        (params && params?.betId) ||
        params?.gameType ||
        params?.betType ||
        params?.betSize ||
        params?.betStatus ||
        params?.sport ||
        params?.userId ||
        params?.placedAfter ||
        params.placedBefore ||
        params.minStake ||
        params.maxStake ||
        params.minPotentialPayout ||
        params?.maxPotentialPayout ||
        params?.settledAfter ||
        params?.settledBefore ||
        params?.role ||
        params?.userName ||
        params?.parentAgentUserName ||
        params?.bookingCode
      ) {
        var numberUserId = parseInt(params.userId ? params.userId : 0);

        var data = {
          ...(params.gameType != " " && { gameType: params.gameType }),
          betType: params.betType,
          betId: params.betId,
          betSize: params.betSize,
          betStatus: params.betStatus,
          placedAfter: params.placedAfter,
          placedBefore: params.placedBefore,
          sport: params.sport,
          userId: numberUserId,
          minStake: params.minStake,
          maxStake: params.maxStake,
          minPotentialPayout: params.minPotentialPayout,
          maxPotentialPayout: params.maxPotentialPayout,
          settledAfter: params.settledAfter,
          settledBefore: params.settledBefore,
          category: params.category,
          gameCode: params.gameCode,
          gameName: params.gameName,
          providerName: params.providerName,
          subProviderName: params.subProviderName,
          role: params.role,
          userName: params.userName,
          parentAgentUserName: params.parentAgentUserName,
          bookingCode: params.bookingCode,
        };

        obj = clean(data);
      }

      var url =
        `${bettingApi().getBetHistoryApi}` +
        "?pageNumber=" +
        pagenumbervalue +
        "&itemPerPage=" +
        itemperpagevalue;
      apiCaller(url, "POST", obj).then((response) => {
        if (response !== null && response) {
          if (response.status == 200) {
            if (activeAll) {
              dispatch(setReportsData(response.data.data));
              dispatch(setReportsLoader(false));
            } else {
              dispatch(setBetHistory(response.data));
              dispatch(setIsVisible(true));
            }
          }
          dispatch(setErrorMessage(response.message));
        }
      }).catch(err => {
        dispatch(setBetHistory([]));
        dispatch(setIsVisible(true));
      });
    };

export const betIdRecords = (id) => (dispatch) => {
  setBaseUrl();
  var url = `${bettingApi().betId}` + id + "/betSelections";
  apiCaller(url, "GET").then((response) => {
    if (response !== null && response) {
      if (response.status == 200) {
        dispatch(setBetId(response.data));
      }
      dispatch(setErrorMessage(response.message));
    }
  });
};

export const getCasinoDropdown = (type, gameType) => async (dispatch) => {
  let url = `${bettingApi().getCasinoDropdowns(type)}`;

  if (gameType) {
    url += `&gameType=${gameType}`;
  }

  try {
    const response = await apiCaller(url, "GET");
    if (response !== null && response.status === 200) {
      switch (type) {
        case "CATEGORY":
          return response.data.category
            .filter(item => item.category !== null)
            .map((item, index) => ({
              id: index + 1,
              label: item.category,
              value: item.category
            }));
        case "GAME_NAME":
          return response.data.gameName
            .filter(item => item.gameName !== null)
            .map((item, index) => ({
              id: index + 1,
              label: item.gameName,
              value: item.gameName
            }));
        case "PROVIDER_NAME":
          return response.data.providerName
            .filter(item => item.providerName !== null)
            .map((item, index) => ({
              id: index + 1,
              label: item.providerName,
              value: item.providerName
            }));
        case "SUB_PROVIDER_NAME":
          return response.data.subProviderName
            .filter(item => item.subProviderName !== null)
            .map((item, index) => ({
              id: index + 1,
              label: item.subProviderName,
              value: item.subProviderName
            }));
        default:
          return [];
      }
    } else {
      dispatch(setErrorMessage(response.message));
      throw new Error(response.message);
    }
  } catch (err) {
    console.log("err in getting dropdowns", err);
    throw err;
  }
};


export const getAllCasinoDropdowns = () => async (dispatch) => {
  try {
    const CATEGORY = await dispatch(getCasinoDropdown("CATEGORY"));
    const GAME_NAME = await dispatch(getCasinoDropdown("GAME_NAME"));
    const VIRTUALS_PROVIDER_NAME = await dispatch(getCasinoDropdown("PROVIDER_NAME", "VIRTUALS"));
    const PROVIDER_NAME = await dispatch(getCasinoDropdown("PROVIDER_NAME"));
    const SUB_PROVIDER_NAME = await dispatch(getCasinoDropdown("SUB_PROVIDER_NAME"));

    const data = {
      CATEGORY,
      GAME_NAME,
      PROVIDER_NAME,
      SUB_PROVIDER_NAME,
      // VIRTUALS_PROVIDER_NAME
    };
    dispatch(setCasinoType(data));
    dispatch(setGamesCasinoType(data));
    dispatch(setVirtualsProviderNames(VIRTUALS_PROVIDER_NAME));
  } catch (error) {
    console.error('Error fetching casino dropdowns', error);
  }
};

export const getProviderNamesDropdown = () => async (dispatch) => {
  try {
    const PROVIDER_NAME = await dispatch(getCasinoDropdown("PROVIDER_NAME"));
    const providerNamesWithValue = PROVIDER_NAME.map(provider => ({
      ...provider,
      value: provider.value
    }));

    dispatch(setProviderNames(providerNamesWithValue));
  } catch (error) {
    console.error('Error fetching provider names dropdown', error);
  }
};
export const getVirtualsProviderNameDropdown = () => async (dispatch) => {
  try {
    const VIRTUALS_PROVIDER_NAME = await dispatch(getCasinoDropdown("PROVIDER_NAME", "VIRTUALS"));
    dispatch(setVirtualsProviderNames(VIRTUALS_PROVIDER_NAME));
  } catch (error) {
    console.error('Error fetching virtuals provider name dropdown', error);
  }
};

export default BetHistorySlice.reducer;
