import React, { Component } from "react";
import { connect } from "react-redux";
import GeneralDetail from "../GeneralDetail/GeneralDetail";
import AccountHistory from "../AccountHistory/accountHistory";
import LoginHistory from "../LoginHistory/loginHistory";
import PaymentHistory from "../paymentHistory/paymentHistory";
import BettingHistory from "../BettingHistory/bettingHistory";
import Bonus from "../Bonuses/Bonus";
// import Award from "../Award/Award";
import Notes from "../AddNote/addNote";
import Tags from "../Tags/Tags";
import BankDetails from "../BankDetails/Bankdetails";
import BonusTransaction from "../BonusTransaction/BonusTransaction";
import FraudScrub from "../FraudScrub/FraudScrub";
import { PrimaryPlayerData } from "../Common/PrimaryplayerTab/ComTab";
import ResponsibleGambling from "../ResponsibleGambling/ResponsibleGambling";
import LimitsTab from "../PaymentLimitTab/LimitsTab";
import UserStake from "../UserStakeSetting/UserStake";
import KycDocumnets from "../KYCDocuments/KycDocSlice";
import KycDocumemts from "../KYCDocuments/KycDocumemts";
//import BetAudit from "../BetAudit/betAduit";
class PrimaryTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "generaldetail",
      gamblingTab: false,
      bankDetailsTab: false,
      kycDocuments: false,
      primaryFilteredPlayerData: [],
    };
  }

  handleTabClickChange = (tab) => {
    this.setState({ activeTab: tab });
  };
  filterTabs = (gamblingTab, bankDetailsTab, kycDocuments) => {
    let filteredTabs = PrimaryPlayerData.filter((tab) => {
      if (!gamblingTab && !bankDetailsTab && !kycDocuments) {
        return (tab.id !== 12 && tab.id !== 2)
      } else if (!gamblingTab) {
        return tab.id !== 12
      }
      else if (!bankDetailsTab) {
        return tab.id !== 2
      }
      else if (!kycDocuments) {
        return tab.id !== 14
      }
      else {
        return tab
      }
    })
    return filteredTabs
  }

  componentDidMount() {
    let gamblingTab = false;
    let bankDetailsTab = false;
    let kycDocuments = false;
    if (localStorage.getItem("Gambling") === "ADMIN_RESPONSIBLE_GAMBLING") {
      gamblingTab = true
      this.setState({ gamblingTab: true })
    } else {
      gamblingTab = false
      this.setState({ gamblingTab: false })
    }
    if (localStorage.getItem("bankDetails") === "ADMIN_BANK_DETAILS") {
      bankDetailsTab = true
      this.setState({ bankDetailsTab: true })
    } else {
      bankDetailsTab = false
      this.setState({ bankDetailsTab: false })
    }
    if (localStorage.getItem("KYCDocumentDetails") === "ADMIN_KYC_DOCUMENTS") {
      kycDocuments = true
      this.setState({ kycDocuments: true })
    } else {
      kycDocuments = false
      this.setState({ kycDocuments: false })
    }
    const data = this.filterTabs(gamblingTab, bankDetailsTab, kycDocuments)
    this.setState({ primaryFilteredPlayerData: data })
  }



  render() {
    return (
      <>
        <div className='CMS-box'>
          <div className='CMS-page-subTabs'>
            <ul>
              {this.state.primaryFilteredPlayerData.length > 0 && this.state.primaryFilteredPlayerData.map((items, index) => {
                return (
                  <li key={items.id} className={this.state.activeTab === items.value ? "active" : ""}>
                    <a
                      id='defaultOpen'
                      onClick={() => this.handleTabClickChange(items.value)}
                    >
                      {items.title}
                    </a>
                  </li>
                )
              })}
            </ul>
          </div>
          <div className='CMS-tabs-content'>
            {this.state.activeTab === "generaldetail" ? (
              <GeneralDetail />
            ) : this.state.activeTab === "accounthistory" ? (
              <AccountHistory />
            ) : this.state.activeTab === "loginhistory" ? (
              <LoginHistory />
            ) : this.state.activeTab === "bankdetails" && this.state.bankDetailsTab ? (
              <BankDetails />
            ) : this.state.activeTab === "payment" ? (
              <PaymentHistory />
            ) : this.state.activeTab === "betting" ? (
              <BettingHistory />
            ) : this.state.activeTab === "bonuses" ? (
              <Bonus />
            ) : this.state.activeTab === "bonusTransaction" ? (
              <BonusTransaction />
            ) : this.state.activeTab === "tags" ? (
              <Tags />
            ) : this.state.activeTab === "notes" ? (
              <Notes />
            ) : this.state.activeTab === "fraudScrub" ? (
              <FraudScrub />
            ) : this.state.activeTab === "responsibleGambling" && this.state.gamblingTab ? (
              <ResponsibleGambling />
            ) : this.state.activeTab === "playerLimits" ? (
              <LimitsTab />
            ) : this.state.activeTab === "userStakes" ? (
              <UserStake />
            ) : this.state.activeTab === "kycDocuments" && this.state.kycDocuments ? (
              <KycDocumemts />
            ) : (
              ""
            )}
          </div>
        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  return {};
}
export default connect(mapStateToProps)(PrimaryTabs);
