import { createSlice } from "@reduxjs/toolkit";
import { brandApi, configApi } from "../../sharedfiles/EndpointConfig";
import { apiCaller } from "../../api/apiCaller";
import { toaster } from "../../sharedfiles/helper";
const BrandConfigSlice = createSlice({
  name: "brandconfig",
  initialState: {
    brandData: [],
    configValue: [],
    addDetail: [],
    dialogOpen: false,
    brandConfig: [],
    brandList: [],
    smsProviderList: [],
    providerList: [],
    brandPaginationPage: 1,
    brandPaginationPageCount: 25,
    brandRecordShow: [1, 25],
    brandPrevPayload: {},
    isVisible: false,
    brandTotalRecord: 0,
    isBrandKeyVisible: false,
  },
  reducers: {
    setBrandData: (state, action) => {
      state.brandData = action.payload;
    },
    setConfigValue: (state, action) => {
      state.configValue = action.payload;
    },
    setAddDetail: (state, action) => {
      state.addDetail = action.payload;
    },
    setDialogOpen: (state, action) => {
      state.dialogOpen = action.payload;
    },
    setBrandConfig: (state, action) => {
      state.brandConfig = action.payload;
    },
    setBrandList: (state, action) => {
      state.brandList = action.payload;
    },
    setSmsProviderList: (state, action) => {
      state.smsProviderList = action.payload;
    },
    setProviderList: (state, action) => {
      state.providerList = action.payload;
    },
    setBrandPaginationPage: (state, action) => {
      state.brandPaginationPage = action.payload;
    },
    setBrandPaginationPageCount: (state, action) => {
      state.brandPaginationPageCount = action.payload;
    },
    setBrandRecordShow: (state, action) => {
      state.brandRecordShow = action.payload;
    },
    setBrandPrevPayload: (state, action) => {
      state.brandPrevPayload = action.payload;
    },
    setIsVisible: (state, action) => {
      state.isVisible = action.payload;
    },
    setBrandTotalRecord: (state, action) => {
      state.brandTotalRecord = action.payload;
    },
    setIsBrandKeyVisible: (state, action) => {
      state.isBrandKeyVisible = action.payload;
    },
  },
});

export const {
  setBrandData,
  setConfigValue,
  setAddDetail,
  setDialogOpen,
  setBrandConfig,
  setBrandList,
  setSmsProviderList,
  setProviderList,
  setBrandPaginationPage,
  setBrandPaginationPageCount,
  setBrandRecordShow,
  setBrandPrevPayload,
  setIsVisible,
  setBrandTotalRecord,
  setIsBrandKeyVisible,
} = BrandConfigSlice.actions;

export const getBrandConfigKey = () => (dispatch) => {
  //dev.platform-ups.sportsit-tech.net:443/config/details
  const url = `${brandApi.getBrandConfig}`;
  apiCaller(url, "GET", {})
    .then((res) => {
      dispatch(setIsBrandKeyVisible(true));
      dispatch(setBrandConfig(res.data))
    })
    .catch((err) => console.log(err));
};

export const getBrandConfig = () => (dispatch) => {
  //dev.platform-ups.sportsit-tech.net:443/config/details
  const url = `${configApi.getConfig}`;
  apiCaller(url, "GET", {})
    .then((res) => dispatch(setBrandData(res.data)))
    .catch((err) => console.log(err));
};

export const updateBrandConfig = (data) => (dispatch) => {
  const url = `${configApi.getConfig}` + "/" + data.id;
  const body = {
    key: data.key,
    value: data.value ? "YES" : "NO",
  };
  apiCaller(url, "PUT", body)
    .then((response) => {
      response.status == 200 && toaster.success("SUCCESS");
      [400, 401, 403, 404, 500].includes(response.status) &&
        toaster.error("FAILED");
    })
    .catch((error) => toaster.error("FAILED"));
};

export const getConfigValues = () => (dispatch) => {
  // https://dev.platform-ups.sportsit-tech.net:443/config/details/values
  const url = `${configApi.getConfigValues}`;
  apiCaller(url, "GET", {})
    .then((res) => dispatch(setConfigValue(res.data)))
    .catch((err) => console.log(err));
};

export const addConfig = (param) => (dispatch) => {
  var obj = {
    value: param.value,
    key: param.key,
  };
  const url = `${configApi.getConfig}`;
  apiCaller(url, "POST", obj)
    .then((response) => {
      dispatch(setAddDetail(response.data));
      response.status == 200 && toaster.success("SUCCESS");
      [400, 401, 403, 404, 500].includes(response.status) &&
        toaster.error("FAILED");
    })
    .catch((error) => toaster.error("FAILED"));
};

export const getBrandList = (params) => (dispatch) => {
  const url = brandApi.getByBrand(params);
  apiCaller(url, "GET", {})
    .then((res) => {
      dispatch(setBrandList(res.data.data))
      dispatch(setIsVisible(true))
      dispatch(setBrandTotalRecord(res.data.totalRecords))
    })
    .catch((err) => console.log(err));
};

export const addBrandKey = (params) => (dispatch) => {
  const url = brandApi.addBrandKey;
  apiCaller(url, "POST", params)
    .then((response) => {
      response.status == 200 && toaster.success("SUCCESS");
      [400, 401, 403, 404, 500].includes(response.status) &&
        toaster.error("FAILED");
    })
    .catch((error) => toaster.error("FAILED"));
};

export const updateKey = (data) => (dispatch) => {
  const url = brandApi.getUpdateKey(data.apiKey);
  const body = {
    name: data.name,
    status: data.status,
  }
  apiCaller(url, "PUT", body)
    .then((response) => {
      response.status == 200 && toaster.success("SUCCESS");
      [400, 401, 403, 404, 500].includes(response.status) &&
        toaster.error("FAILED");
    })
    .catch((error) => toaster.error("FAILED"));
};

export const getSmsProvideList = () => (dispatch) => {
  const url = brandApi.getSmsProviderList;
  apiCaller(url, "GET", {})
    .then((res) => dispatch(setSmsProviderList(res.data)))
    .catch((err) => console.log(err));
};

export const getProviderList = (msgType) => (dispatch) => {
  const url = brandApi.getProviderList(msgType)
  apiCaller(url, "POST", {})
    .then((response) => {
      if (response.status === 200) {
        dispatch(setProviderList(response.data))
      }
    })
    .catch((error) => toaster.error("FAILED"));
}

export default BrandConfigSlice.reducer;
