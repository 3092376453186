import React, { useState } from "react";
import { getDateTimeFormate } from "../../sharedfiles/helper";
import { COMMON_MSGS, NoteInfo } from "../../sharedfiles/tooltipMsgs";
import Tooltips from "../Common/Tooltip";
import { deleteNotes, editNotes } from "./addNoteSlice";
import { useDispatch } from "react-redux";
import Dialog__Main from "../Common/Dialog/Dialog__Main";
import No__Data__Found from "../Common/table/No__Data__Found";

const NotesTable = ({ data, viewOnlyuser, headers }) => {
    const dispatch = useDispatch();

    const [openEdit, setopenEdit] = useState(false);
    const [currentNote, setCurrentNote] = useState(null);
    const [editedNote, setEditedNote] = useState("");
    const [editedCategory, setEditedCategory] = useState("");

    const [isSaving, setIsSaving] = useState(false);
    const [deletingIds, setDeletingIds] = useState([]);

    const cooldownDuration = 2000;

    const handleEdit = (item) => {
        setCurrentNote(item);
        setEditedNote(item.note);
        setEditedCategory(item.category);
        setopenEdit(true);
    };

    const handleDelete = (item) => {
        if (!deletingIds.includes(item.id)) {
            setDeletingIds((prev) => [...prev, item.id]);
            dispatch(deleteNotes(item.playerId, item.id));
            setTimeout(() => {
                setDeletingIds((prev) => prev.filter((id) => id !== item.id));
            }, cooldownDuration);
        }
    };

    const handleSave = () => {
        if (!isSaving && currentNote && editedNote.trim() && editedCategory.trim()) {
            setIsSaving(true);

            const payload = {
                category: editedCategory,
                note: editedNote,
            };

            dispatch(editNotes(currentNote.playerId, currentNote.id, payload));
            setTimeout(() => {
                setIsSaving(false);
                setopenEdit(false);
            }, cooldownDuration);
        }
    };

    const handleCloseModal = () => {
        setopenEdit(false);
    };

    const isSaveDisabled =
        !editedNote.trim() ||
        !editedCategory.trim() ||
        (editedNote === currentNote?.note && editedCategory === currentNote?.category)
    return (
        <>
            <Dialog__Main
                title={"Edit Notes"}
                dOpen={openEdit}
                dClose={handleCloseModal}
                width={"700px"}
            >
                <div className="CMS-box CMS-box-content">
                    <textarea
                        value={editedNote}
                        onChange={(e) => setEditedNote(e.target.value)}
                        rows="4"
                        cols="50"
                    />
                    <div className="row">
                        <div className="col-12">
                            <div className="CMS-btnContainer">
                                <button
                                    onClick={handleSave}
                                    className={`CMS-btnStatus CMS-statusSuspended active CMS-btnMedium pointer ${isSaveDisabled ? "disabled-button" : ""}`}
                                    type="button"
                                    disabled={isSaveDisabled || isSaving}
                                >
                                    {isSaving ? "Saving..." : "SAVE"}
                                </button>

                                <button
                                    onClick={handleCloseModal}
                                    className="CMS-btnStatus CMS-btnSecondary active CMS-btnMedium pointer"
                                    type="button"
                                >
                                    CANCEL
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog__Main>

            <div className="CMS-box">
                <div className="CMS-table CMS-table-triped">
                    <table>
                        <thead>
                            <tr>
                                {headers.map((header, ind) => (
                                    <th key={header.key}>
                                        <div className="d-flex justify-content-center align-items-center">
                                            <p className="mx-3">{header.label}</p>
                                            <Tooltips info={NoteInfo[ind]} />
                                        </div>
                                    </th>
                                ))}
                                <th>
                                    <div className="d-flex justify-content-center align-items-center">
                                        <p className="mx-3">Actions</p>
                                        <Tooltips info={COMMON_MSGS.Actions} />
                                    </div>
                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            {data && Array.isArray(data) && data.length > 0 ? (
                                data.map((item, index) => (
                                    <tr key={`${index}_${item.playerId}`}>
                                        <td>{item.platFormUser}</td>
                                        <td>{item.playerId}</td>
                                        <td>{getDateTimeFormate(item.createdDate) || "-"}</td>
                                        <td>{item.category}</td>
                                        <td>{item.note}</td>
                                        <td>{item.platFormUserId}</td>
                                        <td>{getDateTimeFormate(item.updatedDate) || '-'}</td>
                                        <td>
                                            <div className="CMS-btnContainer">
                                                <button
                                                    className={`CMS-btnStatus CMS-statusActive CMS-btnSmall pointer ${viewOnlyuser ? "disabled-button" : ""}`}
                                                    type="button"
                                                    onClick={() => handleEdit(item)}
                                                >
                                                    Edit
                                                </button>
                                                <button
                                                    className={`CMS-btnStatus CMS-statusClosed CMS-btnSmall pointer ${viewOnlyuser || deletingIds.includes(item.id) ? "disabled-button" : ""
                                                        }`}
                                                    type="button"
                                                    onClick={() => handleDelete(item)}
                                                    disabled={deletingIds.includes(item.id)}
                                                >
                                                    {deletingIds.includes(item.id) ? "Deleting..." : "Delete"}
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <No__Data__Found colspan={22} />
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};

export default NotesTable;


