import { biReportLinks } from "../../../sharedfiles/EndpointConfig";

export const BiReportsDev = [
    {
        ReportCatagory: "GGR Report",
        Reports: [
            {
                id: 1,
                name: "Monthly Revenue Report",
                url: biReportLinks.ggrMonthlyRevenue
            },
            {
                id: 2,
                name: "Daily Revenue Report",
                url: biReportLinks.ggrDailyRevenue
            },
            // {
            //     id: 3,
            //     name: "Daily Revenue Report-Gameworx",
            //     url: biReportLinks.ggrDailyRevGameworx
            // }
        ]
    },
    {
        ReportCatagory: "Sports Report",
        Reports: [
            {
                id: 4,
                name: "Sport League Report",
                url: biReportLinks.sportLeague
            },
            {
                id: 5,
                name: "Event Market Report",
                url: biReportLinks.sportEventMarket
            },
            {
                id: 6,
                name: "Monthly Bet Counts",
                url: biReportLinks.sportMonthlyBetCount
            },
            {
                id: 7,
                name: "Daily Bet Counts",
                url: biReportLinks.sportDailyBetCount
            },
            {
                id: 8,
                name: "Betfold Report Daily",
                url: biReportLinks.sportBetfoldDaily
            },
            {
                id: 9,
                name: "Betfold Report Monthly",
                url: biReportLinks.sportBetfoldMonthly
            },
            {
                id: 10,
                name: "Profit by Event Type",
                url: biReportLinks.sportProfitByEvent
            },
            {
                id: 11,
                name: "Trailing 7 Day Cash Stakes​",
                url: biReportLinks.trailing7DayCashStakes
            },
            {
                id: 12,
                name: "Cash Stakes Market Overview",
                url: biReportLinks.cashStakesMarketOverview
            },
            {
                id: 13,
                name: "Highest Profit Loss Making Events",
                url: biReportLinks.highestProfitLoss
            },
            // {
            //     id: 11,
            //     name: "Renvue by Market",
            //     url: biReportLinks.sportRevenueByMarket
            // }
        ]
    },
    {
        ReportCatagory: "User Reports",
        Reports: [
            // {
            //     id: 12,
            //     name: "Daily Betting Channel",
            //     url: biReportLinks.userDailyBettingChannel
            // },
            // {
            //     id: 13,
            //     name: "Monthly Betting Channel",
            //     url: biReportLinks.userMonthlyBettingChannel
            // },
            {
                id: 14,
                name: "Bonus Reporting",
                url: biReportLinks.userMonthlyBettingChannel
            },
            {
                id: 144,
                name: "Betting History Reporting",
                url: biReportLinks.bettingHistoryReport
            },
            {
                id: 144,
                name: "Affiliate Report",
                url: biReportLinks.affiliateReport
            },
            {
                id: 144,
                name: "Daily User Analysis",
                url: biReportLinks.dailyUserAnalysis
            },
            {
                id: 145,
                name: "Player Search Report",
                url: biReportLinks.playerSearchReport
            },
            // {
            //     id: 14,
            //     name: "Betting Channels-Gameworx",
            //     url: biReportLinks.userBettingChnlGameworx
            // }
        ]
    },
    {
        ReportCatagory: "Games Reports",
        Reports: [
            {
                id: 15,
                name: "Daily Summary Report",
                url: biReportLinks.gamesDailySummary
            },
            {
                id: 16,
                name: "Top 20 Customers by Stake & Loss",
                url: biReportLinks.gamesTopCustomers
            },
            {
                id: 17,
                name: "Games Report Daily",
                url: biReportLinks.gamesDaily
            },
            {
                id: 18,
                name: "Customer Bets Report Daily",
                url: biReportLinks.gamesCustomerBetsDaily
            },
            {
                id: 18,
                name: "Game Provider GGR",
                url: biReportLinks.gameProviderGGR
            }
        ]
    },
    {
        ReportCatagory: "Payments Report",
        Reports: [
            {
                id: 1,
                name: "Average Daily Deposit",
                url: biReportLinks.averageDailyDeposit
            },
            {
                id: 2,
                name: "Average Monthly Deposit",
                url: biReportLinks.averageMonthlyDeposit
            },
            {
                id: 3,
                name: "Weekly User Analysis Report",
                url: biReportLinks.weeklyUserAnalysis
            }
        ]
    },
    {
        ReportCatagory: "Regulatory Report",
        Reports: [
            {
                id: 1,
                name: "Monthly State Sales",
                url: biReportLinks.monthlyStateSales
            },
            {
                id: 2,
                name: "Daily State Sales",
                url: biReportLinks.dailyStateSales
            },
            {
                id: 3,
                name: "Stake > 10K NGN",
                url: biReportLinks.staKe10K_NGN
            }
        ]
    },
]