import React, { useEffect, useState } from 'react'
import Select from '../Common/common_inputs/Select_DDL'
import { Betting, COMMON_MSGS } from '../../sharedfiles/tooltipMsgs'
import { BetStatus, BetType, Betfold, CasinoType, GamesbetSortables, gamesGameType, userRole, gamesTableHeaders } from '../Common/data/BettingHistory/BettinghistoryData'
import { BettingHistoryData, GamesBetDateFields, GamesBetHistoryData, GamesBetSettleDateFields } from '../Common/data/mapData'
import Input from '../Common/common_inputs/Input'
import MultipleSelectDDL from '../Common/common_inputs/MultipleSelectDDL'
import Tooltips from '../Common/Tooltip'
import Datepicker from '../Common/common_inputs/DatePicker'
import AmountRangeIp from '../Common/common_inputs/AmountRangeIp'
import { Currency, MomentUTCDateTimeEnd, MomentUTCDateTimeToday, cleanObject, extractedDate, formatMoney, generateKey, getDateTimeFormate, getFuturedDate, sortingFilter } from '../../sharedfiles/helper'
import ButtonGroup from '../Common/Buttons/ButtonGroup'
import { useDispatch, useSelector } from 'react-redux'
import { getAllCasinoDropdowns, getBetHistorytableList } from '../BetHistory/betHistorySlice'
import GamesBetTable from './GamesBetTable'
import {
    getGamesBetHistory,
    setPaginationFirstValue,
    setPaginationSecondValue,
    setRecordsShow,
    setIsVisible,
    setPayloadData,
    setPrevFormData,
    setDocsData,
    setGlobalPdfData,
} from './GamesHistorySlice'
import Skelton from '../Common/skelton/Skelton'
import Vpagination from '../../sharedfiles/Vpagination'
import { exportPDFC } from '../../sharedfiles/jsPdfCommon'

const GamesBetHistory = () => {
    const dispatch = useDispatch()
    const [formData, setFormData] = useState({
        gamingType: '',
        bettingType: '',
        customerId: '',
        betId: '',
        userName: '',
        betStatus: '',
        shouldReset: false,
        betType: '',
        stakeMinAmnt: '',
        stakeMaxAmnt: '',
        payoutMinAmnt: '',
        payoutMaxAmnt: '',
        stakeRangeErr: false,
        payoutRangeErr: false,
        placedAfter: '',
        placedBefore: '',
        settledAfter: '',
        settledBefore: '',
        role: '',
        CATEGORY: [],
        GAME_NAME: [],
        PROVIDER_NAME: [],
        SUB_PROVIDER_NAME: [],
        casinoClearAll: false,
        gameTypeReset: false,
        selectionId: '',
        agentName: '',
    })

    const gamesSortState = {
        "Bet Status": false,
        "Age Of Account": false,
        "Customer Id": false,
        "Currency": false,
        "Odds": false,
        "Stake": false,
        "Bonus Amount": false,
        "Payout": false,
        "Placed On": false,
        "Settled On": false,
        key: "",
        direction: "",
        keyType: ""
    }

    const [pdfData, setPdfData] = useState([])
    const [csvData, setCsvData] = useState([])
    const [sortFields, setSortFields] = useState({ ...gamesSortState })
    const [header, setHeader] = useState({ ...gamesTableHeaders })

    const [placeDateErr, setPlaceDateErr] = useState('')
    const [settleDateErr, setSettleDateErr] = useState('')

    const { gamesCasinoType, userData, casinoType, casinoData } = useSelector(p => p.betHistorySliceReducer)
    const { gamesBetData, paginationFirstValue, paginationSecondValue, recordsShow, isVisible, payloadData, prevFormData, docsData } = useSelector(p => p.GamesBetHistorySlice)
    const { stakeTaxPermission, winTaxPermission } = useSelector(p => p.dashboard)
    const globalPdfData = useSelector((p) => p.GamesBetHistorySlice.pdfData)

    const showPermission = stakeTaxPermission && winTaxPermission

    const stateSetter = () => {
        const isFormDataExist = Object.keys(prevFormData).length
        if (isFormDataExist) {
            Object.entries(prevFormData).map(([key, value]) => setFormData((prevState) => {
                return {
                    ...prevState,
                    [key]: value,
                }
            }))
        }
    }


    const formDataToSliceSetter = (key, val, multiEntries) => {
        const newEntry = { ...prevFormData, [key]: val }
        const newPayload = multiEntries ? { ...prevFormData, ...multiEntries } : newEntry
        dispatch(setPrevFormData(newPayload));
    }
    const handleChange = (e) => {
        const { name, value } = e.target
        setFormData({ ...formData, [name]: value })
        // if (name === "gamingType" && value === "CASINO") {
        //     dispatch(getAllCasinoDropdowns());
        // }
        formDataToSliceSetter(name, value)
        const updatedFormData = { ...prevFormData, [name]: value }
        dispatch(setPrevFormData(updatedFormData))
    }

    const handleSelectedValues = (selected) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            betStatus: selected,
            shouldReset: false
        }));
        formDataToSliceSetter("betStatus", selected);
    }

    const gameTypeHandle = (selected) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            gamingType: selected
        }));
        formDataToSliceSetter("gamingType", selected);
    }
    const setDateHandler = (value, name) => {
        formDataToSliceSetter(name, value);
        setFormData((prevState) => {
            return {
                ...prevState,
                [name]: value,
            };
        });
        if (name === "placedAfter" && value !== '') {
            setPlaceDateErr('')
        }
        if (name === "settledAfter" && value !== '') {
            setSettleDateErr('')
        }
    }

    const stakeRangeHandle = (e) => {
        const { name, value } = e.target;
        const sanitizedValue = value.replace(/[^0-9.]/g, '');

        setFormData((prevState) => {
            if (name === "stakeMinAmnt" || name === "stakeMaxAmnt") {
                const newStakeValue = name === 'stakeMinAmnt' ? sanitizedValue : prevState.stakeMinAmnt;
                const newMaxStakeValue = name === 'stakeMaxAmnt' ? sanitizedValue : prevState.stakeMaxAmnt;

                const stakeRangeErr =
                    (name === "stakeMinAmnt" && newMaxStakeValue !== '' && parseFloat(newStakeValue) > parseFloat(newMaxStakeValue)) ||
                        (name === "stakeMaxAmnt" && prevState.stakeMinAmnt !== '' && parseFloat(prevState.stakeMinAmnt) > parseFloat(sanitizedValue))
                        ? 'Max amount should not be less than min amount'
                        : '';

                return {
                    ...prevState,
                    [name]: sanitizedValue,
                    stakeRangeErr: stakeRangeErr,
                    minStake: newStakeValue,
                    maxStake: newMaxStakeValue,
                };
            } else {
                return {
                    ...prevState,
                    [name]: sanitizedValue,
                };
            }
        });
    };

    const payoutRangeHandle = (e) => {
        const { name, value } = e.target;
        const sanitizedValue = value.replace(/[^0-9.]/g, '');

        setFormData((prevState) => {
            if (name === "payoutMinAmnt" || name === "payoutMaxAmnt") {
                const newPayoutValue = name === 'payoutMinAmnt' ? sanitizedValue : prevState.payoutMinAmnt;
                const newMaxPayoutValue = name === 'payoutMaxAmnt' ? sanitizedValue : prevState.payoutMaxAmnt;

                const payoutRangeErr =
                    (name === "payoutMinAmnt" && newMaxPayoutValue !== '' && parseFloat(newPayoutValue) > parseFloat(newMaxPayoutValue)) ||
                        (name === "payoutMaxAmnt" && prevState.payoutMinAmnt !== '' && parseFloat(prevState.payoutMinAmnt) > parseFloat(sanitizedValue))
                        ? 'Max amount should not be less than min amount'
                        : '';

                return {
                    ...prevState,
                    [name]: sanitizedValue,
                    payoutRangeErr: payoutRangeErr,
                    minPotentialPayout: newPayoutValue,
                    maxPotentialPayout: newMaxPayoutValue,
                };
            } else {
                return {
                    ...prevState,
                    [name]: sanitizedValue,
                };
            }
        });
    };


    function validator() {
        let err = false;

        if (formData.placedBefore !== "" && (formData.placedAfter === '' || formData.placedAfter === null)) {
            setPlaceDateErr("Placed After is required");
            err = true;
        }
        if (formData.placedBefore === null && formData.placedAfter === null) {
            setPlaceDateErr("");
            err = false;
        }

        if (formData.settledBefore !== "" && (formData.settledAfter === '' || formData.settledAfter === null)) {
            setSettleDateErr("Settled After is required");
            err = true;
        }
        if (formData.settledBefore === null && formData.settledAfter === null) {
            setSettleDateErr("");
            err = false;
        }

        return err;
    }


    const onSubmit = (e) => {
        e.preventDefault();
        const dateError = validator();

        const placedAfter = extractedDate(formData.placedAfter)
        const placedBefore = extractedDate(formData.placedBefore)
        const settleAfter = extractedDate(formData.settledAfter)
        const settledBefore = extractedDate(formData.settledBefore)

        const payload = {
            gameType: formData.gamingType,
            betType: formData.bettingType,
            betId: formData.betId,
            betSelectionId: formData.selectionId,
            betSize: formData.betType,
            betStatus: formData.betStatus,
            placedAfter: formData.placedAfter ? MomentUTCDateTimeToday(formData.placedAfter) : '',
            placedBefore: formData.placedBefore ? (placedAfter === placedBefore ? MomentUTCDateTimeEnd(formData.placedBefore) : MomentUTCDateTimeEnd(formData.placedBefore)) : '',
            settledAfter: formData.settledAfter ? MomentUTCDateTimeToday(formData.settledAfter) : '',
            settledBefore: formData.settledBefore ? (settleAfter === settledBefore ? MomentUTCDateTimeEnd(formData.settledBefore) : MomentUTCDateTimeEnd(formData.settledBefore)) : '',
            userId: formData.customerId,
            minStake: formData.stakeMinAmnt !== undefined ? formData.stakeMinAmnt * 100 : '',
            maxStake: formData.stakeMaxAmnt !== undefined ? formData.stakeMaxAmnt * 100 : '',
            minPotentialPayout: formData.payoutMinAmnt !== undefined ? formData.payoutMinAmnt * 100 : '',
            maxPotentialPayout: formData.payoutMaxAmnt !== undefined ? formData.payoutMaxAmnt * 100 : '',
            category: formData.CATEGORY,
            gameName: formData.GAME_NAME,
            providerName: formData.PROVIDER_NAME,
            subProviderName: formData.SUB_PROVIDER_NAME,
            role: formData.role,
            userName: formData.userName,
            parentAgentUserName: formData.agentName
        }
        const finalPayload = cleanObject(payload);
        if (Object.keys(finalPayload).length > 0) {
            if (!dateError && !formData.payoutRangeErr && !formData.stakeRangeErr) {
                dispatch(setIsVisible(false))
                dispatch(setPaginationFirstValue(1))
                dispatch(setPaginationSecondValue(25))
                dispatch(setRecordsShow([1, 25]))
                dispatch(setPayloadData(finalPayload))
                callApi(1, 25, finalPayload);
            }
        }

    }
    const resetButton = () => {
        setFormData({
            gamingType: ["CASINO", "NE_GAMES", "LOTTERY"],
            bettingType: '',
            customerId: '',
            betId: '',
            userName: '',
            betStatus: '',
            shouldReset: true,
            betType: '',
            stakeMinAmnt: '',
            stakeMaxAmnt: '',
            payoutMinAmnt: '',
            payoutMaxAmnt: '',
            stakeRangeErr: false,
            payoutRangeErr: false,
            placedAfter: '',
            placedBefore: '',
            settledAfter: '',
            settledBefore: '',
            role: '',
            CATEGORY: [],
            GAME_NAME: [],
            PROVIDER_NAME: [],
            SUB_PROVIDER_NAME: [],
            casinoClearAll: true,
            selectionId: '',
            agentName: '',
            // gameTypeReset: true
        })
        setPlaceDateErr('')
        setSettleDateErr('')
        dispatch(setPrevFormData({}));
        dispatch(setPaginationFirstValue(1));
        dispatch(setPaginationSecondValue(25));
        dispatch(setRecordsShow([1, 25]));
        dispatch(setIsVisible(false));
        dispatch(setPayloadData({}));
        dispatch(getGamesBetHistory(0, 25, {
            gameType: ["CASINO", "NE_GAMES", "LOTTERY"]
        }));
    };

    const getCategory = (cat) => CasinoType.find(e => e.value === cat);

    // const getInputValue = (key) => {
    //     return Array.isArray(gamesCasinoType) ? gamesCasinoType.find((element) => element[key]) : null;
    // };

    const setCasinoSelected = (value, key) => {
        setFormData((prevState) => {
            return {
                ...prevState,
                [key]: value,
            };
        });
        formDataToSliceSetter(key, value)
    };

    // const setCasinoClear = () => {
    //     setFormData((prevState) => ({
    //         ...prevState,
    //         casinoClearAll: false,
    //     }));
    // };

    const handleSortFields = (item, key) => {
        const intializing = {
            ...gamesSortState
        }
        const direction = !sortFields[key] ? "ASCENDING" : "DESCENDING";
        setSortFields(prevState => ({
            ...intializing,
            [key]: !sortFields[key],
            key: item.key,
            direction: direction,
            keyType: item.type,
        }));

        if (!showPermission) {
            const v = gamesTableHeaders.filter((name, index) => name != `Stake Tax ${Currency()}` && name != `Stake After Tax ${Currency()}` && name != `Stake Tax Amount  ${Currency()}` && name != `Winnings Tax ${Currency()}` && name != `Winnings Tax Amount ${Currency()}`);
            // this.setState({ headers: v });
            setHeader(v)
            update(false, "", "", "", v);
        } else {
            update(false, "", "", "", gamesTableHeaders);
        }
    }

    const update = (allRecords, key = "", sortType = "", keyType = "", headers) => {
        const tbData = gamesBetData.data && Array.isArray(gamesBetData.data) ? gamesBetData.data : []

        const data = sortingFilter(tbData, { key, sortType, keyType }).map((p) => {
            let mainHeaderStack = [
                p.betId,
                p.betStatus,
                p.ageOfAccount,
                p.userId,
                p.role,
                p.userName,
                p.firstName,
                p.lastName,
                p.surName,
                p.phone,
                p.gameType,
                p.providerName ? p.providerName : "-",
                p.subProviderName ? p.subProviderName : "-",
                p.gameName ? p.gameName : "-",
                p.category ? p.category : "-",
                p.fixtureName ? p.fixtureName : "Multibet",
                p.placedDate ? getDateTimeFormate(p.placedDate) : "-",
                p.settledDate ? getDateTimeFormate(p.settledDate) : "-",
                p.currencyCode,
                formatMoney(p.parlayWinning),
                formatMoney(p.payout),
                formatMoney(p.stake),
                p.stakeTax,
                formatMoney(p.stakeAfterTax),
                formatMoney(p.stakeTaxAmount),
                p.winningsTax,
                formatMoney(p.winningsTaxAmount),
                formatMoney(p.totalPayout),
                formatMoney(p.totalPayoutWithBonus),
                formatMoney(p.bonusMaxWinAmount),
                p.bonusMaxWinPercentage,
                p.placeBetType,
                p.parentAgentUserId || '-',
                p.parentAgentUserName || '-',
            ]

            return mainHeaderStack;
        })
        const filterData = data.map((record) => record.filter((name, ind) => name !== `Stake Tax ${Currency()}` && name !== `Stake After Tax ${Currency()}` && name !== `Stake Tax Amount  ${Currency()}` && name !== `Winnings Tax ${Currency()}` && name !== `Winnings Tax Amount ${Currency()}`))
        const opData = showPermission ? data : filterData;
        const csvDataa = opData && Array.isArray(opData) && opData.length > 0 ? [headers, ...opData] : [headers, []];
        setHeader(headers)
        setCsvData(csvDataa)
        dispatch(setDocsData(csvDataa));
        setPdfData(opData)
        dispatch(setGlobalPdfData(opData));
    }

    useEffect(() => {
        const dataExist = Object.keys(gamesBetData).length === 0
        setPrevFormData(prevFormData)
        const systemPayload = {
            placedAfter: MomentUTCDateTimeToday(new Date()),
            placedBefore: MomentUTCDateTimeEnd(getFuturedDate(1)),
            betPayloadData: payloadData,
            gameType: ["CASINO", "NE_GAMES", "LOTTERY"],
        };
        if (dataExist) {
            dispatch(getAllCasinoDropdowns());
            dispatch(setIsVisible(false));
            dispatch(setPrevFormData({
                ...prevFormData,
                placedBefore: getFuturedDate(1),
                placedAfter: new Date(),
                gamingType: ["CASINO", "NE_GAMES", "LOTTERY"],
            }))
            setFormData({
                placedBefore: getFuturedDate(1),
                placedAfter: new Date(),
                gamingType: ["CASINO", "NE_GAMES", "LOTTERY"]
            })
            callApi(1, 25, systemPayload)
        } else {
            setPdfData(globalPdfData);
            setCsvData(docsData);
        }

        if (!showPermission) {
            const v = gamesTableHeaders.filter((name, index) => name != `Stake Tax ${Currency()}` && name != `Stake After Tax ${Currency()}` && name != `Stake Tax Amount  ${Currency()}` && name != `Winnings Tax ${Currency()}` && name != `Winnings Tax Amount ${Currency()}`);
            // this.setState({ headers: v });
            setHeader(v)
            update(false, "", "", "", v);
        } else {
            update(false, "", "", "", gamesTableHeaders);
        }
        stateSetter()
    }, [gamesBetData.data])

    const callApi = (pageNumber, itemsPerPage, data = payloadData) => {
        const placedAfter = extractedDate(formData.placedAfter)
        const placedBefore = extractedDate(formData.placedBefore)
        const settleAfter = extractedDate(formData.settledAfter)
        const settledBefore = extractedDate(formData.settledBefore)
        const payload = {
            placedAfter: formData.placedAfter ? MomentUTCDateTimeToday(formData.placedAfter) : '',
            placedBefore: formData.placedBefore ? (placedAfter === placedBefore ? MomentUTCDateTimeEnd(formData.placedBefore) : MomentUTCDateTimeEnd(formData.placedBefore)) : "",
            settledAfter: formData.settledAfter ? MomentUTCDateTimeToday(formData.settledAfter) : '',
            settledBefore: formData.settledBefore ? (settleAfter === settledBefore ? MomentUTCDateTimeEnd(formData.settledBefore) : MomentUTCDateTimeEnd(formData.settledBefore)) : '',
            gameType: ["CASINO", "NE_GAMES", "LOTTERY"],
            ...data,
        }
        dispatch(setIsVisible(false));
        dispatch(getGamesBetHistory(pageNumber - 1, itemsPerPage, payload))
    }

    const exportPDF = (totalRecords) => {
        const title = "Games Bet History Report";

        const styles = {
            10: {
                halign: "right",
                valign: "middle",
            },
            12: {
                halign: "right",
                valign: "middle",
            },
        };
        const size = "A2"
        exportPDFC(title, [header], pdfData, styles, "", size);
    }

    return (
        <React.Fragment>
            <div className='CMS-tab-panel active' id='CMS-games-betting'>
                <div className='CMS-tabContent'>
                    <div className='CMS-box CMS-box-content'>
                        <div className='row'>
                            <div className='col-md-4 col-lg-3 col-xl-2'>

                                <div className='CMS-formGroup'>
                                    <div className='CMS-formLabel'>
                                        {"Game Type"}
                                        &nbsp;&nbsp;
                                        <Tooltips info={Betting.Game_Type} />
                                    </div>
                                    <MultipleSelectDDL
                                        options={gamesGameType}
                                        onSelect={(value) => gameTypeHandle(value)}
                                        // shouldReset={formData.gameTypeReset}
                                        initialSelected={prevFormData?.gamingType || []}
                                    />
                                </div>
                            </div>
                            {Array.isArray(prevFormData.gamingType) && prevFormData.gamingType.includes('NE_GAMES') &&
                                <div className='col-md-4 col-lg-3 col-xl-2'>
                                    <Select
                                        title={"Bet Type"}
                                        name={"bettingType"}
                                        ids={"BetType"}
                                        value={formData.bettingType}
                                        setValue={(val) => handleChange(val)}
                                        options={BetType}
                                        // errorMsg={
                                        //     this.state.typeErrorMsg
                                        //         ? "Please select betting Type"
                                        //         : ""
                                        // }
                                        // onSubmit={(e) => this.onSubmit(e)}
                                        info={Betting.Bet_Type}
                                    />
                                </div>
                            }
                            {GamesBetHistoryData.map((item, ind) => {
                                const values = [formData.customerId, formData.betId, formData.userName];
                                return (
                                    <React.Fragment key={item.ids}>
                                        <div className='col-md-4 col-lg-3 col-xl-2' key={item.id}>
                                            <Input
                                                title={item.title}
                                                name={item.name}
                                                value={values[ind]}
                                                placeholder={item.placeholder}
                                                setValue={(e) => handleChange(e)}
                                                regex={item.regex}
                                                ids={item.ids}
                                                info={item.info}
                                                show={true}
                                            />
                                        </div>
                                    </React.Fragment>
                                );
                            })}
                            <div className='col-md-4 col-lg-3 col-xl-2'>
                                <div className='CMS-formGroup'>
                                    <div className='CMS-formLabel'>
                                        {"Bet Status"}
                                        &nbsp;&nbsp;
                                        <Tooltips info={Betting.Bet_Status} />
                                    </div>
                                    <MultipleSelectDDL
                                        options={BetStatus}
                                        onSelect={(value) => handleSelectedValues(value)}
                                        shouldReset={formData.shouldReset}
                                        initialSelected={prevFormData?.betStatus || []}
                                    />
                                </div>
                            </div>
                            {
                                GamesBetDateFields.map((item, index) => {
                                    const maxDate = formData.placedBefore
                                        ? new Date(formData.placedBefore)
                                        : new Date();
                                    const minDate = formData.placedAfter;
                                    const maxDateArr = [maxDate, new Date()];
                                    const minDateArr = [null, minDate];
                                    return (
                                        <div className='col-md-4 col-lg-3 col-xl-2' key={generateKey(index)}>
                                            <Datepicker
                                                title={item.title}
                                                name={item.name}
                                                ids={item.ids}
                                                value={formData[item.name]}
                                                setValue={(date, value) =>
                                                    setDateHandler(date, value)
                                                }
                                                maxDate={maxDateArr[index]}
                                                minDate={minDateArr[index]}
                                                errorMsg={index === 0 ? placeDateErr : ''}
                                                info={COMMON_MSGS.Start_Date}
                                            />
                                        </div>
                                    );
                                })
                            }
                            <div className='col-md-4 col-lg-3 col-xl-2'>
                                <Select
                                    title={"Betfold"}
                                    name={"betType"}
                                    ids={"betFold"}
                                    value={formData.betType}
                                    setValue={(val) => handleChange(val)}
                                    options={Betfold}
                                    // onSubmit={(e) => this.onSubmit(e)}
                                    info={Betting.Bet_fold}
                                />
                            </div>
                            <div className='col-md-4 col-lg-3 col-xl-2'>
                                <AmountRangeIp
                                    title={"Stake Range"}
                                    amountMin={formData.stakeMinAmnt}
                                    amountMax={formData.stakeMaxAmnt}
                                    rangeHandle={(e) => stakeRangeHandle(e)}
                                    rangeErr={formData.stakeRangeErr}
                                    minName={"stakeMinAmnt"}
                                    maxName={"stakeMaxAmnt"}
                                    info={Betting.stakeRange}
                                />
                            </div>
                            <div className='col-md-4 col-lg-3 col-xl-2'>
                                <AmountRangeIp
                                    title={"Potential Payout Range"}
                                    amountMin={formData.payoutMinAmnt}
                                    amountMax={formData.payoutMaxAmnt}
                                    rangeHandle={(e) => payoutRangeHandle(e)}
                                    rangeErr={formData.payoutRangeErr}
                                    minName={"payoutMinAmnt"}
                                    maxName={"payoutMaxAmnt"}
                                    info={Betting.payoutRange}
                                />
                            </div>
                            {
                                GamesBetSettleDateFields.map((item, index) => {
                                    const maxDate = formData.settledBefore
                                        ? new Date(formData.settledBefore)
                                        : new Date();
                                    const minDate = formData.settledAfter;
                                    const maxDateArr = [maxDate, new Date()];
                                    const minDateArr = [null, minDate];
                                    return (
                                        <div className='col-md-4 col-lg-3 col-xl-2' key={generateKey(index)}>
                                            <Datepicker
                                                title={item.title}
                                                name={item.name}
                                                ids={item.ids}
                                                value={formData[item.name]}
                                                setValue={(date, value) =>
                                                    setDateHandler(date, value)
                                                }
                                                maxDate={maxDateArr[index]}
                                                minDate={minDateArr[index]}
                                                errorMsg={index === 0 ? settleDateErr : ''}
                                                info={COMMON_MSGS.Start_Date}
                                            />
                                        </div>
                                    );
                                })
                            }
                            <div className='col-md-4 col-lg-3 col-xl-2'>
                                <Select
                                    title={"User Role"}
                                    name={"role"}
                                    ids={"userRole"}
                                    value={formData.role}
                                    setValue={(val) => handleChange(val)}
                                    options={userRole}
                                    // onSubmit={(e) => this.onSubmit(e)}
                                    info={Betting.userRole}
                                />
                            </div>
                            <div className='col-md-4 col-lg-3 col-xl-2'>
                                <Input
                                    title={"Selection ID"}
                                    name={"selectionId"}
                                    value={formData.selectionId}
                                    placeholder={'Selection ID'}
                                    setValue={(e) => handleChange(e)}
                                    ids={"selectionId"}
                                    info={"Filter out the data as per Selection ID"}
                                    show={true}
                                />
                            </div>
                            <div className='col-md-4 col-lg-3 col-xl-2'>
                                <Input
                                    title={"Agent Name/ID"}
                                    name={"agentName"}
                                    value={formData.agentName}
                                    placeholder={'Agent Name'}
                                    setValue={(e) => handleChange(e)}
                                    ids={"agentName"}
                                    info={"Filter out the data as per Agent Name/Id"}
                                    show={true}
                                />
                            </div>
                            {
                                Array.isArray(prevFormData.gamingType) && prevFormData.gamingType.includes('CASINO') &&
                                Object.entries(gamesCasinoType).map(([key, value], ind) => {
                                    const category = getCategory(key);
                                    // const inputValue = getInputValue(key) || [];
                                    return (
                                        <div key={key} className="col-md-4 col-lg-3 col-xl-2">
                                            <div className="CMS-formGroup">
                                                <div className="CMS-formLabel">
                                                    {category?.label}
                                                    &nbsp;&nbsp;
                                                    <Tooltips info={category?.tooltip} />
                                                </div>
                                                <MultipleSelectDDL
                                                    options={value || []}
                                                    onSelect={(val) => setCasinoSelected(val, key)}
                                                    shouldReset={formData.casinoClearAll}
                                                    initialSelected={prevFormData[key]}
                                                />
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </div>
                        <div className='row'>
                            <ButtonGroup
                                groupNames={["Search", "Reset"]}
                                idsearch={"BettingHistory"}
                                idreset={"BettingReset"}
                                submit={(e) => onSubmit(e)}
                                reset={(e) => resetButton(e)}
                            />
                        </div>

                    </div>
                    <div className='CMS-filter-result' id='result'>
                        <div className='CMS-pagination'>
                            <Vpagination
                                data={gamesBetData.data}
                                totalRecords={gamesBetData.totalRecords}
                                paginationFirstValue={paginationFirstValue}
                                paginationSecondValue={paginationSecondValue}
                                setPaginationFirstValue={setPaginationFirstValue}
                                setPaginationSecondValue={setPaginationSecondValue}
                                recordsShow={recordsShow}
                                setRecordsShow={setRecordsShow}
                                isVisible={isVisible}
                                setIsVisible={setIsVisible}
                                csvData={csvData}
                                exportPDF={(val) => exportPDF(val)}
                                callApi={callApi}
                                csvFileName={"Games Bet History Report"}
                                payloadData={payloadData}
                                enableAll={false}
                            // reportsLoader={{ loader: this.props.reportsLoader, setLoader: setReportsLoader }}
                            />
                        </div>
                        {!isVisible ?
                            (<Skelton vLen={5} hLen={7} />) :
                            (<GamesBetTable
                                fetchedData={gamesBetData.data}
                                tableData={csvData}
                                sortables={GamesbetSortables}
                                sortingOrder={sortFields}
                                handleSortFields={(item, key) => handleSortFields(item, key)}
                                stakeTaxPermit={stakeTaxPermission}
                                winTaxPermit={winTaxPermission}
                                gamingType={formData.gamingType}
                                potentialPayoutData={gamesBetData.data}
                            />)}
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default GamesBetHistory