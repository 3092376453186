import React, { useEffect, useState } from "react";
import Tooltips from "../Tooltip";

const Select = (props) => {
  const {
    title,
    name,
    value,
    setValue,
    options,
    errorMsg,
    onSubmit,
    setCouponCode,
    ids,
    onFocus,
    info,
    noSelectOpt,
    disabled,
    ...rest
  } = props;
  const [selected, setSelected] = useState("");
  const handleChange = (e) => {
    const { value, name } = e.target;
    setSelected(value);
    setValue(e);
    if (name === "bonusType") {
      setCouponCode();
    }
  };

  useEffect(() => {
    setSelected(value);
  }, [value]);
  
  const truncateLabel = (label) => {
    const maxLength = rest.lengthyTitle ? 60 : 25;
    return label.length > maxLength ? `${label.substring(0, maxLength)}...` : label;
  };

  return (
    <div className="CMS-formGroup">
      <div className="CMS-formLabel">
        {title}
        &nbsp;&nbsp;
        <Tooltips info={info} />
      </div>
      <div className="CMS-dropdown CMS-formControl">
        <div className="CMS-select">
          <select
            id={ids}
            name={name}
            value={selected}
            onChange={handleChange}
            className="pointer"
            onFocus={onFocus}
            disabled={disabled}
          >
            {!noSelectOpt ? <option value="">{rest.sportsFreez ? "All Sports" : "Select"}</option> : null}
            {name === "sport" && <option value=" ">All</option>}
            {options?.map((option) => (
              <option key={option.id} id={option.id} value={option.value}>
                {option.value === selected ? truncateLabel(option.label) : option.label}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="CMS-form-errorMsg">{errorMsg}</div>
    </div>
  );
};

export default Select;
