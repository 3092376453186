import React from 'react'
import Select from '../Common/common_inputs/Select_DDL'
import { BONUS_CONSTANTS } from '../../sharedfiles/Constants'
import { bettingTypeOpt, bonusAppforOpt, cashbackFrequencyOpt, cashbackTypeOpt } from '../Common/data/createBonus/createBonusData'

const BonusDDLs = (props) => {
    const {
        bonusAppFor,
        bettingType,
        cashbackFrequency,
        cashbackType,
        handleChange = () => { },
        onCheckboxChange = () => { },
        isChecked,
    } = props;

    const onSelect = (name, value) => {
        handleChange(name, value);
        if (name === 'cashbackType' && value === 'BONUS_CASH') {
            handleChange('bettingType', 'PER_BET');
        }
    }

    return (
        <React.Fragment>
            <div className='col-md-4 col-lg-3 col-xl-2'>
                <Select
                    title={"Bonus Applicable For"}
                    name={"bonusAppFor"}
                    value={bonusAppFor}
                    setValue={(event) => onSelect("bonusAppFor", event.target.value)}
                    options={bonusAppforOpt}
                    ids={"bonusAppFor"}
                    info={BONUS_CONSTANTS.bonusAppFor}
                />
            </div>
            <div className='col-md-4 col-lg-3 col-xl-2'>
                <Select
                    title={"Cashback Type"}
                    name={"cashbackType"}
                    value={cashbackType}
                    setValue={(event) => onSelect("cashbackType", event.target.value)}
                    options={cashbackTypeOpt}
                    ids={"cashbackType"}
                    info={BONUS_CONSTANTS.cashbackType}
                />
            </div>
            <div className='col-md-4 col-lg-3 col-xl-2'>
                <Select
                    title={"Betting Type"}
                    name={"bettingType"}
                    value={cashbackType === "BONUS_CASH" ? "PER_BET" : bettingType}
                    setValue={(event) => onSelect("bettingType", event.target.value)}
                    options={cashbackType === "BONUS_CASH" ? bettingTypeOpt.slice(1) : bettingTypeOpt}
                    ids={"bettingType"}
                    info={BONUS_CONSTANTS.bettingType}
                    disabled={cashbackType === "BONUS_CASH"}
                />
            </div>
            {bettingType === "CUMULATIVE" &&
                <div className='col-md-4 col-lg-3 col-xl-2'>
                    <Select
                        title={"Cashback Frequency"}
                        name={"cashbackFrequency"}
                        value={cashbackFrequency}
                        setValue={(event) => onSelect("cashbackFrequency", event.target.value)}
                        options={cashbackFrequencyOpt}
                        ids={"cashbackFrequency"}
                        info={BONUS_CONSTANTS.cashbackFrequency}
                    />
                </div>
            }
            {cashbackType === "REAL_CASH" &&
                <div className='CMS-checkbox d-flex justify-content-center align-items-center mx_center'>
                    <>
                        <input
                            onChange={onCheckboxChange}
                            checked={isChecked}
                            style={{ zoom: 1.5 }}
                            id='checkboxAff'
                            type='checkbox'
                        />
                        <label className="ml-10" htmlFor='PE'>
                            <span style={{ whiteSpace: "nowrap", fontSize: "16px" }}>
                                <p>{"Add to withdrawable balance"}</p>
                            </span>
                        </label>
                    </>
                </div>
            }
        </React.Fragment>
    );
};

export default BonusDDLs;