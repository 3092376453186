import React, { useState, useEffect } from "react";
import MultipleSelectDDL from "../Common/common_inputs/MultipleSelectDDL";
import Tooltips from "../Common/Tooltip";
import {
  getAllCountriesBySport,
  getEventsBySportCountryLeague,
  getLeaguesBysportAndCountry,
  setCountries,
  setEvents,
  setIsLeagueLoading,
  setLeagues,
  setIsEventLoading,
  setIsCountriesLoading,
  setEventBySportCountryLeague,
  setLeaguesInBonus,
  setCountryInBonus,
  setOnlySportsSelected,
  setSportsSelectedInDialog,
  setEventsOpt,
} from "../BoostedOdds/boostedOddsSlice";
import { useDispatch, useSelector } from "react-redux";
import { boosted_odds } from "../../sharedfiles/tooltipMsgs";
import Dialog__Main from "../Common/Dialog/Dialog__Main";
import Select from "../Common/common_inputs/Select_DDL";
import {
  addTbeaderInfo,
  addTbheader,
} from "../Common/data/createBonus/createBonusData";
import { setOpenDialog } from "./createbonusnewslice";

const BonusUsageDDL = ({
  sportId,
  sportList,
  bonusSportSelection,
  handleEventsSelected,
  setInputs,
  handleSportsSelected,
}) => {
  const dispatch = useDispatch();
  const [sports, setSports] = useState([]);
  const [countryOpt, setCountryOpt] = useState({ id: "", name: "", label: "" });
  const [leagueOpt, setLeagueOpt] = useState({ id: "", name: "", label: "" });
  // const [eventsOpt, setEventsOpt] = useState("");
  const [disable, setDisable] = useState(false);
  const [addTbData, setAddTbData] = useState([]);
  const [selectedEventIds, setSelectedEventIds] = useState([]);
  const [tableShow, setTableShow] = useState(false);
  const [hasSelection, setHasSelection] = useState(false);

  const {
    eventBySportCountryLeague,
    leaguesInBonus,
    countryInBonus,
    events,
    leagues,
    countries,
    isCountriesLoading,
    isLeagueLoading,
    isEventLoading,
    countryInBonusPopup,
    eventsOpt,
  } = useSelector((p) => p.BoostedOddsSlice);
  const { openDialog } = useSelector((p) => p.bonus);

  const errMessage = "The Maximum Number of selection is 20";

  const allStates = { sports, countryOpt, leagueOpt, eventsOpt };

  const newCountryArray = countryInBonus.countries
    ? countryInBonus.countries.map((country) => ({
        id: country.countryId,
        label: country.countryName,
        value: country.countryId,
      }))
    : [];

  const newContryArrayForPopup = countryInBonusPopup?.countries
    ? countryInBonusPopup.countries.map((country) => ({
        id: country.countryId,
        label: country.countryName,
        value: country.countryId,
      }))
    : [];

  const LeagueArray = leaguesInBonus.leagues
    ? leaguesInBonus.leagues.map((league) => ({
        id: league.leagueId,
        label: league.leagueName,
        value: league.leagueId,
      }))
    : [];

  const EventArray = eventBySportCountryLeague?.events
    ? eventBySportCountryLeague?.events.map((event) => ({
        id: event.eventId,
        label: event.eventName,
        value: event.eventName,
        eventTime: event.eventStartDate,
      }))
    : [];

  const handleChange = (type, value) => {
    const findSelectedCountry = (value) => {
      return countryInBonusPopup?.countries?.find(
        (c) => c.countryId === value[0]
      );
    };

    switch (type) {
      case "countries": {
        if (value.length === 0) {
          dispatch(setEvents([]));
          dispatch(setLeagues([]));
        }
        setInputs("events", []);
        if (
          (value.length >= 1 && sportId.length >= 2) ||
          (value.length >= 2 && sportId.length >= 1)
        ) {
          dispatch(setOpenDialog(true));
        } else {
          dispatch(setOpenDialog(false));
        }
        dispatch(setCountries(value));
        const selectedCountry = findSelectedCountry(value);
        if (value.length === 1 && sportId.length === 1) {
          dispatch(
            getLeaguesBysportAndCountry(sportId, selectedCountry?.countryId)
          );
        } else {
          dispatch(setLeaguesInBonus([]));
          dispatch(setEventBySportCountryLeague([]));
          dispatch(setIsLeagueLoading(true));
          dispatch(setIsEventLoading(true));
        }
        break;
      }
      case "leagues": {
        setInputs("events", []);
        if (value.length === 0) {
          dispatch(setEvents([]));
        }
        if (value.length > 1) {
          dispatch(setOpenDialog(true));
        } else {
          dispatch(setOpenDialog(false));
        }
        dispatch(setLeagues(value));
        const selectedCountry = findSelectedCountry(countries);
        if (value.length === 1 && sportId.length === 1) {
          dispatch(
            getEventsBySportCountryLeague(
              sportId,
              selectedCountry?.countryId,
              value
            )
          );
        } else {
          dispatch(setIsEventLoading(true));
          dispatch(setEventBySportCountryLeague([]));
        }
        break;
      }
      case "events":
        dispatch(setEvents(value));
        setInputs("events", value);
        break;
      default:
        break;
    }
  };

  const onClose = () => {
    dispatch(setOpenDialog(false));
    resetDialougeFields();
    setAddTbData([]);
    setSports([]);
    setCountryOpt({ id: "", name: "", label: "" });
    setLeagueOpt({ id: "", name: "", label: "" });
    dispatch(setEventsOpt({}));
    dispatch(setOnlySportsSelected(false));
    dispatch(setCountries([]));
    dispatch(setLeagues([]));
    dispatch(setEvents([]));
    dispatch(setCountryInBonus({ countries: [] }));
    dispatch(setLeaguesInBonus({ leagues: [] }));
    dispatch(setEventBySportCountryLeague({ events: [] }));
    setSelectedEventIds([]);
    setTableShow(false);
    setHasSelection(false);
  };

  const selectedSports = sportList
    .filter((sport) => sportId.includes(sport.value))
    .map((sport) => ({
      id: sport.id,
      label: sport.label,
      value: sport.value,
    }));

  const onSelection = (e) => {
    const { name, value } = e.target;
    const selectedOption = e.target.options[e.target.selectedIndex];
    const selectedLabel =
      selectedOption.text === "Select" ? "" : selectedOption.text;
    setHasSelection(true);

    switch (name) {
      case "sports":
        dispatch(setLeaguesInBonus([]));
        dispatch(setEventBySportCountryLeague([]));
        dispatch(setEventsOpt({}));
        setLeagueOpt({ id: "", name: "", label: "" });
        setCountryOpt({ id: "", name: "", label: "" });
        setEvents([]);
        setSports({ id: value, name: selectedLabel, label: selectedLabel });
        dispatch(getAllCountriesBySport(value));
        break;
      case "countryOpt":
        dispatch(setEventsOpt({}));
        dispatch(setEventBySportCountryLeague([]));
        setLeagueOpt({ id: "", name: "", label: "" });
        setEvents([]);
        setCountryOpt({ id: value, name: selectedLabel, label: selectedLabel });
        dispatch(getLeaguesBysportAndCountry(sports.id, value));
        break;
      case "leagueOpt":
        dispatch(setEventsOpt({ id: "", name: "", label: "" }));
        setLeagueOpt({ id: value, name: selectedLabel, label: selectedLabel });
        dispatch(
          getEventsBySportCountryLeague(sports.id, countryOpt.id, value)
        );
        break;
      case "eventsOpt":
        setInputs("eventsOpt", value);
        dispatch(
          setEventsOpt({ id: value, name: selectedLabel, label: selectedLabel })
        );
        break;
      default:
        break;
    }
  };

  const resetDialougeFields = () => {
    setSports([]);
    setCountryOpt({ id: "", name: "", label: "" });
    setLeagueOpt({ id: "", name: "", label: "" });
    dispatch(setEventsOpt({}));
    // dispatch(setIsLeagueLoading(true));
    // dispatch(setCountries([]));
    // dispatch(setLeagues([]));
    dispatch(setEvents([]));
  };
  const hasDuplicateSelections = () => {
    const newSelection = {
      sportId: sports.id,
      countryId: countryOpt.id,
      leagueId: leagueOpt.id,
      eventId: eventsOpt.id,
    };

    return addTbData.some(
      (item) =>
        item.sports.id === newSelection.sportId &&
        item.countryOpt.id === newSelection.countryId &&
        item.leagueOpt.id === newSelection.leagueId &&
        item.eventsOpt.id === newSelection.eventId
    );
  };
  function hasEvent(array) {
    return array.some((item) => item.eventsOpt && item.eventsOpt.id);
  }
  const onAdd = () => {
    if (addTbData.length >= 19 || hasDuplicateSelections()) {
      setDisable(true);
    } else {
      setDisable(false);
    }
    setTableShow(true);
    setAddTbData((prevData) => [...prevData, allStates]);
    setSelectedEventIds((prevIds) => [...prevIds, events]);
    setCountryOpt({ id: "", name: "", label: "" });
    setLeagueOpt({ id: "", name: "", label: "" });
    dispatch(setEventsOpt({}));
    setSports([]);
    dispatch(setCountries([]));
    dispatch(setLeagues([]));
    dispatch(setEvents([]));
    dispatch(setCountryInBonus({ countries: [] }));
    dispatch(setLeaguesInBonus({ leagues: [] }));
    dispatch(setEventBySportCountryLeague({ events: [] }));
    setHasSelection(false);
  };
  const isButtonDisabled = () => {
    return (
      !hasSelection ||
      (sports.length !== 0 &&
        (!sports.id || sports.name === "") &&
        !countryOpt.id &&
        !leagueOpt.id &&
        !eventsOpt) ||
      hasDuplicateSelections()
    );
  };
  const hasData = Object.values(sports).some((value) => value !== "");
  const isDoneButtonDisabled = addTbData.length > 1;
  const onDelete = (ind) => {
    let deletedItems = selectedEventIds.filter((ele) => ele === ind);
    setSelectedEventIds(deletedItems);
    setAddTbData((prevData) => prevData.filter((item, index) => index !== ind));
  };

  const onSelectionsDone = () => {
    dispatch(setOpenDialog(false));

    const formatData = (item) => {
      const { countryOpt, eventsOpt, leagueOpt, sports } = item;
      return {
        countryId: parseInt(countryOpt.id, 10),
        countryName: countryOpt.name,
        eventId: eventsOpt ? parseInt(eventsOpt.id, 10) : 0,
        eventName: eventsOpt ? eventsOpt.name : "",
        leagueId: parseInt(leagueOpt.id, 10),
        leagueName: leagueOpt.name,
        sportId: parseInt(sports.id, 10),
        sportName: sports.name,
      };
    };

    const isItemEmpty = (item) =>
      !item.countryOpt.id && !item.leagueOpt.id && !item.eventsOpt.id;

    const expectedData = addTbData.map((item) => {
      const data = formatData(item);
      return Object.keys(data).reduce((acc, key) => {
        if (data[key]) acc[key] = data[key];
        return acc;
      }, {});
    });

    const isOnlySportsSelected = addTbData.every(isItemEmpty);
    const sportsSelectedSet = new Set(
      addTbData.map((item) => parseInt(item.sports.id, 10))
    );
    const sportsSelected = Array.from(sportsSelectedSet);

    dispatch(setOnlySportsSelected(isOnlySportsSelected));
    bonusSportSelection(isOnlySportsSelected ? sportsSelected : expectedData);
    dispatch(setSportsSelectedInDialog(sportsSelected));
    dispatch(setIsCountriesLoading(true));
    dispatch(setIsLeagueLoading(true));
    dispatch(setIsEventLoading(true));
    let eventExists = hasEvent(addTbData);
    handleEventsSelected(eventExists);
    const sportsSelectedInPopup = expectedData.map((item) => item.sportId);
    handleSportsSelected(sportsSelectedInPopup, "popup");
  };

  return (
    <React.Fragment>
      <Dialog__Main
        title={"SELECTIONS"}
        dOpen={openDialog}
        dClose={() => onClose()}
        width={"700px"}
      >
        <div className="CMS-box CMS-box-content">
          <div className="row">
            <div className="col-md-4 col-lg-3">
              <Select
                title={"Sports"}
                name={"sports"}
                ids={"boostedSports"}
                value={sports.id || ""}
                setValue={(val) => onSelection(val)}
                options={selectedSports}
                info={boosted_odds.Sports}
              />
            </div>
            <div className="col-md-4 col-lg-3">
              <Select
                title={"Country"}
                name={"countryOpt"}
                ids={"countryOpt"}
                value={countryOpt.id || ""}
                setValue={(val) => onSelection(val)}
                options={newCountryArray}
                info={boosted_odds.Countries}
              />
            </div>
            <div className="col-md-4 col-lg-3">
              <Select
                title={"Leagues"}
                name={"leagueOpt"}
                ids={"leagueOpt"}
                value={leagueOpt.id || ""}
                setValue={(val) => onSelection(val)}
                options={LeagueArray}
                info={boosted_odds.Leagues}
              />
            </div>
            <div className="col-md-4 col-lg-3">
              <Select
                title={"Events"}
                name={"eventsOpt"}
                ids={"eventsOpt"}
                value={eventsOpt.id || ""}
                setValue={(val) => onSelection(val)}
                options={EventArray}
                info={boosted_odds.Events}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="CMS-btnContainer">
                <button
                  onClick={() => onAdd()}
                  className={`CMS-btn CMS-btnSecondary active CMS-btnMedium ${
                    disable ||
                    isButtonDisabled() ||
                    hasDuplicateSelections() ||
                    !hasData
                      ? "disable-button"
                      : ""
                  }`}
                  type={"button"}
                >
                  Add
                </button>

                <button
                  onClick={() => onSelectionsDone()}
                  className={`CMS-btn CMS-btnSecondary active CMS-btnMedium ${
                    !isDoneButtonDisabled ? "disable-button" : ""
                  }`}
                  type={"button"}
                  disabled={!isDoneButtonDisabled}
                >
                  Done
                </button>
              </div>
              {disable && (
                <div
                  className="CMS-form-errorMsg mt-10"
                  style={{
                    justifyContent: "center",
                    display: "flex",
                    color: "#d92100",
                    minHeight: "20px",
                    marginTop: "-5px",
                  }}
                >
                  {errMessage}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="mt-30"></div>
        {tableShow && addTbData.length > 0 ? (
          <div className="row">
            <div className="CMS-table CMS-table-triped CMS-combi-table">
              <table>
                <thead>
                  <tr>
                    {addTbheader.map((item, index) => (
                      <th>
                        <div className="d-flex justify-content-center align-items-center">
                          <p className="mx-3">{item}</p>
                          <Tooltips info={`${addTbeaderInfo[index]}`} />
                        </div>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {addTbData.map((item, ind) => {
                    return (
                      <tr>
                        <td>{item.sports?.name}</td>
                        <td> {item.countryOpt?.name}</td>
                        <td>{item.leagueOpt?.name}</td>
                        <td>{item.eventsOpt?.name}</td>
                        <td>
                          <span
                            className="material-icons edit pointer"
                            style={{ color: "red" }}
                            onClick={() => onDelete(ind)}
                          >
                            delete
                          </span>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          ""
        )}
      </Dialog__Main>
      {["countries", "leagues", "events"].map((type, index) => {
        const isLoading =
          (type === "countries" && isCountriesLoading) ||
          (type === "leagues" && isLeagueLoading) ||
          (type === "events" && isEventLoading);

        return (
          <div
            key={index}
            className={`col-md-4 col-lg-3 col-xl-2 ${
              isLoading ? "dropdownDisabled" : ""
            }`}
          >
            <div className="CMS-formGroup">
              <div className="CMS-formLabel">
                {type.charAt(0).toUpperCase() + type.slice(1)}
                &nbsp;&nbsp;
                <Tooltips
                  info={
                    boosted_odds[type.charAt(0).toUpperCase() + type.slice(1)]
                  }
                />
              </div>
              <MultipleSelectDDL
                options={
                  type === "countries"
                    ? newContryArrayForPopup
                    : type === "leagues"
                    ? LeagueArray
                    : EventArray
                }
                onSelect={(value) => handleChange(type, value)}
                initialSelected={
                  type === "countries"
                    ? countries || []
                    : type === "leagues"
                    ? leagues || []
                    : events || []
                }
              />
            </div>
          </div>
        );
      })}
    </React.Fragment>
  );
};

export default BonusUsageDDL;
