import React, { useState } from "react";
import { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { AFFILIATE_CONSTANTS } from "../../sharedfiles/Constants";
import {
  getDateTimeFormate,
  getFromLocalStorage,
  formatMoney,
  getUTCDate,
  MomentUTCDateTimeToday,
  MomentUTCDateTimeEnd,
} from "../../sharedfiles/helper";
import { exportPDFC } from "../../sharedfiles/jsPdfCommon";
import Vpagination from "../../sharedfiles/Vpagination";
import {
  AddCampaign,
  listCampaigns,
  setIsToShow,
  setItems,
  setPage,
  setVisibleRecords,
  setDocsData_campaign,
  setCampaignField,
} from "../Affiliate/AffiliateSlice";
import Input from "../Common/common_inputs/Input";
import CampaignTable from "./CampaignTable";
import ButtonGroup from "../Common/Buttons/ButtonGroup";
import { affiliateDates } from "../Common/data/mapData";
import Datepicker from "../Common/common_inputs/DatePicker";

function Campaign(props) {
  const dispatch = useDispatch();
  const [campaign, setCampaign] = useState("");
  const [capmaignErr, setCapmaignErr] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [pdfBody, setPdfBody] = useState([]);
  const [dateFields, setDateFields] = useState({
    regDateFrom: '',
    regDateTo: '',
  })
  const [dateErr, setDateErr] = useState("")

  const campaignList = useSelector(
    (st) => st.affiliateSlice.campaignList?.data
  );
  const viewOnlyuser = useSelector((state) => state.dashboard.viewOnlyuser)

  const platformId = useSelector(st => st.affiliateSlice.selected_AffiliateId);
  const userId = platformId || getFromLocalStorage("AffliateUserId");
  const headers = [
    "Id",
    "Campaign Name",
    // "AffiliateId",
    "Created Date",
    "Updated Date",
    // "View Campaign",
    "Total Registrations",
    "Deposited Customers",
    "Total Deposits",
    "New Customers",
    "Total Stakes",
    "Total Payout",
    "GGR",
    "Total Bonus Amount",
    "Total Number of Bets",
    "Total Withdrawals",
    "URL Hits Count",
  ];
  const filedsHandler = (e) => {
    const { name, value } = e.target;
    const campaignErr = value === "";
    setCampaign(value);
    setCapmaignErr(campaignErr);
    dispatch(setCampaignField({ value: value, error: campaignErr }));
  };
  const submit = (e) => {
    e.preventDefault();
    const payload = {
      userId: userId,
      body: { campaignName: campaign },
    };
    const isValid = campaign === "" ? true : false;
    setCapmaignErr(isValid);
    dispatch(setCampaignField({ value: campaign, error: isValid }))
    if (!isValid && userId) {
      dispatch(AddCampaign(payload));
      setTimeout(() => {
        dispatch(listCampaigns(userId, 0, 25, {}));
        // callApi(1, 25, userId);
        setCampaign("");
      }, 500);
    }
  };

  const exportPDF = () => {
    const title = "Campaign Report";
    exportPDFC(title, [headers], pdfBody);
  };

  function callApi(page, items, id) {
    dispatch(setIsToShow(false));
    dispatch(listCampaigns(id, page - 1, items, {}));
  };

  useEffect(() => {
    const dataExist = Object.keys(props?.campaignList).length > 0 && props.campaignList?.data?.length > 0
    if (!dataExist) {
      // callApi(1, 25, userId);
      dispatch(listCampaigns(userId, 0, 25, {}));


    } else {
      const csvDataExist = props?.docsData_campaign && Array.isArray(props.docsData_campaign) && props.docsData_campaign.length > 0
      let pdfData = [];
      if (csvDataExist) {
        pdfData = props?.docsData_campaign.slice(1, props?.docsData_campaign?.length);
      }
      setTableData(props.docsData_campaign);
      setPdfBody(pdfData);
      setCampaign(props.campaignField.value)
      setCapmaignErr(props.campaignField.error);
    }
  }, []);
  const updateTableData = () => {
    let tbData = [];
    if (Array.isArray(campaignList) && campaignList.length > 0) {
      const data = campaignList && campaignList.map((item) => [
        item.id,
        item.name,
        // item.affiliateId,
        item.createdDate ? getDateTimeFormate(item.createdDate) : "-",
        item.updatedDate ? getDateTimeFormate(item.updatedDate) : "-",
        item.totalRegistration,
        item.depositedCustomers,
        item.totalDeposit ? formatMoney(item.totalDeposit) : "0",
        item.newCustomers,
        item.totalStake ? formatMoney(item.totalStake) : "0",
        item.totalPayout ? formatMoney(item.totalPayout) : "0",
        item.totalGgr ? formatMoney(item.totalGgr) : "0",
        item.totalBonus,
        item.betCount,
        item.totalWithdraw ? formatMoney(item.totalWithdraw) : "0",
        item.campaignClickCount,
      ]);
      const tbData = [headers, ...data]
      setPdfBody(data);
      setTableData(tbData);
      dispatch(setDocsData_campaign(tbData))
    }
  };
  useEffect(() => {
    campaignList && updateTableData();
  }, [campaignList]);

  const setDateHandler = (value, name) => {
    setDateFields((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
    if (name === "regDateFrom" && value !== '') {
      setDateErr('')
    }
  }

  const onSearch = () => {
    const payload = {
      endDate: dateFields.regDateTo ? MomentUTCDateTimeEnd(dateFields.regDateTo) : '',
      startDate: dateFields.regDateFrom ? MomentUTCDateTimeToday(dateFields.regDateFrom) : ''
    }
    if (dateFields.regDateFrom === '' || dateFields.regDateFrom === null) {
      setDateErr("Start Date is required");
    } else if (dateFields.regDateFrom !== '') {
      setDateErr('');
      dispatch(listCampaigns(userId, 0, 25, payload))
    }
  }
  const onResetBtn = () => {
    setDateFields({
      regDateFrom: '',
      regDateTo: ''
    })
    setDateErr('')
    dispatch(listCampaigns(userId, 0, 25, {}))
  }

  return (
    <React.Fragment>
      <div className='CMS-tab-panel active' id='CMS-playerActivity'>
        <div className='CMS-tabContent'>
          <div className='CMS-box CMS-box-content'>
            <div className='row'>
              <div className='col-3'>
                <Input
                  title={"Campaign Name"}
                  name={"campaign"}
                  ids={"campaign"}
                  value={campaign}
                  placeholder={"Campaign Name"}
                  setValue={(e) => filedsHandler(e)}
                  //   regex={item.regex}
                  errorMsg={capmaignErr && "Campaign Name is required"}
                  info={"Provide Campaign Name"}
                  show={true}
                />
              </div>
            </div>
            {/* <div className='mt-20'></div> */}
            <div className='row mt-20 d-flex justify-content-center'>
              <button
                onClick={(e) => submit(e)}
                type={"button"}
                id={"addCampaign"}
                className={`CMS-btn CMS-btnSecondary active CMS-btnMedium ${viewOnlyuser ? 'disable-button' : ''}`}
              >
                {AFFILIATE_CONSTANTS.addCampaign}
              </button>
            </div>
          </div>
          {/*  */}

          <div className="mt-50"></div>
          <div className="CMS-box CMS-box-content">
            <div className='row'>
              {affiliateDates.map((item, ind) => {
                const minDate = [null, dateFields.regDateFrom];
                const max = dateFields.regDateTo ? dateFields.regDateTo : new Date();
                const maxDate = [max, new Date()];
                const values = [dateFields.regDateFrom, dateFields.regDateTo];
                return (
                  <div className='col-3' key={ind}>
                    <Datepicker
                      title={item.title}
                      name={item.name}
                      value={values[ind]}
                      setValue={(date, value) => setDateHandler(date, value)}
                      errorMsg={ind === 0 ? dateErr : ""}
                      minDate={minDate[ind]}
                      maxDate={maxDate[ind]}
                      ids={item.ids}
                      info={item.info}
                    />
                  </div>
                );
              })}
              <div className="ml-15"></div>
              <ButtonGroup
                groupNames={["Search", "Reset"]}
                idsearch={"searchCampaign"}
                idreset={"campaignReset"}
                submit={(e) => onSearch(e)}
                reset={(e) => onResetBtn(e)}
              />
            </div>
          </div>

          <div className='CMS-filter-result' id='result'>
            <div className='CMS-pagination'>
              {Array.isArray(campaignList) && campaignList.length > 0 && (
                <Vpagination
                  data={campaignList}
                  totalRecords={props.campaignList?.totalRecords}
                  paginationFirstValue={props.page}
                  paginationSecondValue={props.items}
                  setPaginationFirstValue={setPage}
                  setPaginationSecondValue={setItems}
                  recordsShow={props.visibleRecords}
                  setRecordsShow={setVisibleRecords}
                  isVisible={props.isToShow}
                  setIsVisible={setIsToShow}
                  csvData={tableData}
                  exportPDF={exportPDF}
                  payloadData={userId}
                  callApi={callApi}
                  csvFileName={"Campaign Report"}
                />
              )}
            </div>
            <CampaignTable data={campaignList} viewTable={props.isToShow} />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    campaignList: state.affiliateSlice.campaignList,
    page: state.affiliateSlice.page,
    items: state.affiliateSlice.items,
    visibleRecords: state.affiliateSlice.visibleRecords,
    isToShow: state.affiliateSlice.isToShow,
    docsData_campaign: state.affiliateSlice.docsData_campaign,
    campaignField: state.affiliateSlice.campaignField
  };
};

export default connect(mapStateToProps)(Campaign);
