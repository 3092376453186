import { Currency } from "../../../../sharedfiles/helper";

export const currency = [
  { id: 1, label: "EUR", value: "EUR" },
  { id: 2, label: "INR", value: "INR" },
  { id: 3, label: "USD", value: "USD" },
  { id: 4, label: "GBP", value: "GBP" },
  { id: 5, label: "KSH", value: "KSH" },
  { id: 6, label: "UGX", value: "UGX" },
  { id: 7, label: "NGN", value: "NGN" },
  { id: 8, label: "TZS", value: "TZS" },
  { id: 9, label: "XWM", value: "XWM" },
];

export const AccountStatus = [
  { id: 1, label: "ACTIVE", value: "ACTIVE" },
  { id: 2, label: "LAPSED_14", value: "LAPSED_14" },
  { id: 3, label: "LAPSED_30", value: "LAPSED_30" },
  { id: 4, label: "LAPSED_90", value: "LAPSED_90" },
  { id: 5, label: "TIME_OUT", value: "TIME_OUT" },
  { id: 6, label: "SELF_EXCLUDED", value: "SELF_EXCLUDED" },
  { id: 7, label: "DEACTIVATED", value: "SUSPENDED" },
  { id: 8, label: "DEACTIVATED PENDING USERS", value: "WITHDRAW_DEACTIVATED" }
];

export const TagNames = [
  { id: 1, label: "Deposit_first", value: "deposit_first" },
  { id: 2, label: "Deposit_fourth", value: "deposit_fourth" },
  { id: 3, label: "Deposit_no", value: "deposit_no" },
  { id: 4, label: "Deposit_reload", value: "deposit_reload" },
  { id: 5, label: "Deposit_second", value: "deposit_second" },
  { id: 6, label: "Deposit_third", value: "deposit_third" },
  { id: 7, label: "Deposit_yes", value: "deposit_yes" },
  { id: 8, label: "Register_dir", value: "register_dir" },
  { id: 9, label: "Register_refer", value: "register_refer" },
  { id: 10, label: "Frequent_Deposits", value: "frequent_depositor" },
  { id: 11, label: "Frequent_Bettors", value: "frequent_bet" },
  { id: 12, label: "Most_Betted_Sports", value: "most_bet_sports" },
  { id: 13, label: "Most_Betted_Game", value: "most_bet_game" },
  { id: 14, label: "Consistent_Losers", value: "consistent_loser" },
  { id: 15, label: "Highest_Stakes", value: "highest_stake" },
  { id: 16, label: "High_deposit_10k", value: "high_deposit_10k" },
  { id: 17, label: "High_deposit_20k", value: "high_deposit_20k" },
  { id: 18, label: "High_deposit_50k", value: "high_deposit_50k" },
  { id: 19, label: "High_deposit_100K", value: "high_deposit_100K" },
  { id: 20, label: "withdraw_no", value: "withdraw_no" },
  { id: 21, label: "withdraw_yes", value: "withdraw_yes" },
  { id: 22, label: "withdraw_first", value: "withdraw_first" },
  { id: 23, label: "withdraw_second", value: "withdraw_second" },
  { id: 24, label: "withdraw_third", value: "withdraw_third" },
  { id: 25, label: "withdraw_fourth", value: "withdraw_fourth" },
  { id: 26, label: "withdraw_many", value: "withdraw_many" },
]

export const headers = [
  "Registration Date",
  "Customer ID",
  "User Name",
  "First Name",
  "Last Name",
  "Roles",
  "Affiliate Btag",
  "Email",
  "DOB",
  "Last LoginIP",
  "Referred By",
  "Phone",
  "State",
  "Country",
  "Currency",
  "Player Segment",
  "StakeFactor",
  `Deposits ${Currency()}`,
  "GGR",
  "Updated By",
  "Account Status",
];

export const info = [
  "Showing Registration Date",
  "Showing Customer Id",
  "Showing User Name",
  "Showing First Name",
  "Showing Last Name",
  "Showing Role of the Player",
  "Showing Affiliate Btag",
  "Showing Email",
  "Showing DOB",
  "Showing Last LoginIP",
  "Showing Referred By",
  "Showing Phone Number",
  "Showing Users State",
  "Showing Users Country",
  "Showing Currency",
  "Showing Player Segment",
  "Showing StakeFactor",
  "Showing Deposits",
  "Showing the GGR",
  "Showing Updated By",
  "Showing Users Account Status",
]

export const sortable = {
  "Customer ID": {
    key: "userId",
    type: "num"
  },
  "DOB": {
    key: "dob",
    type: "date"
  },
  "Referred By": {
    key: "referralCode",
    type: "str"
  },
  "Country": {
    key: "country",
    type: "str"
  },
  "Currency": {
    key: "currency",
    type: "str"
  },
  "Player Segment": {
    key: "playerSegment",
    key: "str"
  },
  "StakeFactor": {
    key: "stakeFactor",
    type: "num"
  },
  [`Deposits ${Currency()}`]: {
    key: "deposit",
    type: "num"
  },
  "GGR": {
    key: "ggr",
    type: "num"
  },
  "Registration Date": {
    key: "registrationDate",
    type: "date"
  },
  "Account Status": {
    key: "accountStatus",
    type: "str"
  }
}


export const npSotables = {
  "Registration Date": {
    key: "registrationDate",
    type: "date"
  },
  "Affiliate Btag": {
    key: "affiliateBtag",
    type: "str"
  },
  "Affiliate Name": {
    key: "affiliateName",
    type: "str"
  },
  "Referred By": {
    key: "refCode",
    type: "str"
  },
  "Brand": {
    key: "brand",
    type: "str"
  },
  "Country": {
    key: "country",
    key: "str"
  },
  [`First Deposit Amount ${Currency()}`]: {
    key: "firstDeposit",
    type: "num"
  },
  "Bet Count": {
    key: "betCount",
    type: "num"
  },
  "Account Status": {
    key: "accountStatus",
    type: "str"
  }
}

export const KycStatus = [
  { id: 1, label: "Verified Users", value: "VERIFIED" },
  { id: 2, label: "Un Verified Users ", value: "NON_VERIFIED" },
]
export const userType = [
  { id: 1, label: "Real User", value: "REAL_USER" },
  { id: 2, label: "Test User", value: "TEST_USER" },
  { id: 3, label: "Fraud User", value: "FRAUD_USER" },
  { id: 4, label: "Watchlist Users", value: "WATCHLIST_USER" },
]

export const userStatus = [
  { id: 1, label: "Activate", value: "ACTIVE" },
  { id: 2, label: "Pending Deactivation", value: "WITHDRAW_DEACTIVATED" },
  { id: 3, label: "Deactivated", value: "SUSPENDED" },
]

export const roleData = [
  { id: 1, label: "Agent", value: "AGENT" },
  { id: 2, label: "Cashier", value: "CASHIER" },
  { id: 3, label: "Online Player", value: "ONLINE_PLAYER" },
  { id: 4, label: "Player", value: "PLAYER" },
  { id: 5, label: "Business Manager", value: "BUSINESS_MANAGER" },
  { id: 6, label: "Super Agent", value: "SUPER_AGENT" },
  { id: 7, label: "Shop Agent", value: "SHOP_AGENT" },
]

export const PlayerSearchFilter = [
  {
    id: 1,
    ids: "playerSearchDateFrom",
    title: "Registration Date From",
    placeholder: "DD/MM/YYYY",
    name: "fromDate",
    info: "Select Date From",
  },
  {
    id: 2,
    ids: "playerSearchDateTo",
    title: "Registration Date To",
    placeholder: "DD/MM/YYYY",
    name: "toDate",
    info: "Select Date To",
  },
]
export const tagTypes = [
  { id: 1, label: "Custom", value: "Custom" },
  { id: 2, label: "System", value: "System" },
]