import React from "react";
import * as RiIcons from "react-icons/ri";

const getUserId = () => {
  const randomId = Math.floor(Math.random() * 1000000);
  return randomId;
};
export const SidebarData = [
  {
    id: 1,
    title: "MANAGEMENT REPORTING",
    path: "/",
    icon: "person",
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    children: function () {
      return this.subNav
        .map((v) => v.permissionName)
        .filter((v) => v !== undefined);
    },
    subNav: [
      {
        subtitle: "DASHBOARD",
        path: "/dashboard",
        isVisible: true,
        permissionName: "REPORTING_DASHBOARD",
      },
      {
        subtitle: "DASHBOARD V2.0",
        path: "/newDashboard",
        isVisible: true,
        permissionName: "REPORTING_NEW_DASHBOARD",
      },
      {
        subtitle: "BI DASHBOARD",
        path: "/bidashboard",
        isVisible: true,
        permissionName: "ADMIN_BI_DASHBOARD",
      },
      {
        subtitle: "OVERVIEW",
        path: "/bidashboard/overview",
        isVisible: false,
      },
      {
        subtitle: "SPORTS OVERVIEW COUNTS",
        path: "/bidashboard/sports-overview-counts",
        isVisible: false,
      },
      {
        subtitle: "SPORTS OVERVIEW AMOUNTS",
        path: "/bidashboard/sports-overview-amounts",
        isVisible: false,
      },
      {
        subtitle: "CASINO OVERVIEW",
        path: "/bidashboard/casino-overview",
        isVisible: false,
      },
      {
        subtitle: "NE GAMES OVERVIEW",
        path: "/bidashboard/ne-games-overview",
        isVisible: false,
      },
      {
        subtitle: "FINANCE OVERVIEW",
        path: "/bidashboard/finance-overview",
        isVisible: false,
      },
      // {
      //   subtitle: "REPORTS",
      //   path: "/report",
      //   isVisible: true,
      //   permissionName: "REPORTING_REPORTS",
      // },
      {
        subtitle: "BI REPORTS",
        path: "/bireports",
        isVisible: true,
        permissionName: "ADMIN_BI_REPORTS",
      },
      {
        subtitle: "VIP BI REPORTS",
        path: "/vip-reports",
        isVisible: true,
        permissionName: "VIP_BI_REPORTS",
      },
      {
        subtitle: "VIP REPORTS",
        path: "/vip-reports/vip-reports",
        isVisible: false,
      },
      {
        subtitle: "DASHBOARD FASFAS",
        path: "/vip-reports/dashboard-fasfas",
        isVisible: false,
      },
      {
        subtitle: "LOYALTY VIP",
        path: "/vip-reports/loyalty-vip",
        isVisible: false,
      },
      {
        subtitle: "FAILED REPORTS",
        path: "/falied-reports",
        isVisible: true,
        permissionName: "ADMIN_FAILED_REPORTS",
      },
      {
        subtitle: "SPORTS OVERVIEWS",
        path: "/bisport",
        isVisible: false,
      },
      {
        subtitle: "CUSTOMER OVERVIEWS",
        path: "/bicustomer",
        isVisible: false,
      },
      {
        subtitle: "SPORTS OVERVIEW",
        path: "/report/sports",
        isVisible: false,
      },
      {
        subtitle: "CUSTOMER OVERVIEW",
        path: "/report/customer",
        isVisible: false,
      },
      {
        subtitle: "PAYMENT OVERVIEW",
        path: "/report/payment",
        isVisible: false,
      },
      {
        subtitle: "MANUAL OVERVIEW",
        path: "/report/manual",
        isVisible: false,
      },
      {
        subtitle: "MULTIPLE OVERVIEW",
        path: "/report/deposit",
        isVisible: false,
      },
      {
        subtitle: "REFER FRIEND",
        path: "/report/referfriend",
        isVisible: false,
      },
      {
        subtitle: "WITHDRAWAL REPORT",
        path: "/report/withdrawalreport",
        isVisible: false,
      },
      {
        subtitle: "DEPOSIT REPORT",
        path: "/report/depositreport",
        isVisible: false,
      },
      {
        subtitle: "PLACEBET REPORT",
        path: "/report/placebetreport",
        isVisible: false,
      },
      {
        subtitle: "LIABILITY REPORT",
        path: "/report/liabilityreport",
        isVisible: false,
      },
      {
        subtitle: "MONTHLY REVENUE REPORT",
        path: "/bireports/ggr-report/monthly-revenue-report",
        isVisible: false,
      },
      {
        subtitle: "DAILY REVENUE REPORT",
        path: "/bireports/ggr-report/daily-revenue-report",
        isVisible: false,
      },
      // {
      //   subtitle: "DAILY REVENUE REPORT - GAMEWORX",
      //   path: "/bireports/ggr-report/daily-revenue-report-gameworx",
      //   isVisible: false,
      // },
      {
        subtitle: "SPORT LEAGUE REPORT",
        path: "/bireports/sports-report/sport-league-report",
        isVisible: false,
      },
      {
        subtitle: "EVENT MARKET REPORT",
        path: "/bireports/sports-report/event-market-report",
        isVisible: false,
      },
      {
        subtitle: "MONTHLY BETCOUNT REPORT",
        path: "/bireports/sports-report/monthly-bet-counts",
        isVisible: false,
      },
      {
        subtitle: "DAILY BETCOUNT REPORT",
        path: "/bireports/sports-report/daily-bet-counts",
        isVisible: false,
      },
      {
        subtitle: "BETFOLD REPORT DAILY",
        path: "/bireports/sports-report/betfold-report-daily",
        isVisible: false,
      },
      {
        subtitle: "BETFOLD REPORT MONTHLY ",
        path: "/bireports/sports-report/betfold-report-monthly",
        isVisible: false,
      },
      {
        subtitle: "PROFIT BY EVENT TYPE",
        path: "/bireports/sports-report/profit-by-event-type",
        isVisible: false,
      },
      {
        subtitle: "TRAILING 7-DAYS CASH STAKES",
        path: "/bireports/sports-report/trailing-7-day-cash-stakes​",
        isVisible: false,
      },
      {
        subtitle: "CASH STAKES MARKET OVERVIEW",
        path: "/bireports/sports-report/cash-stakes-market-overview",
        isVisible: false,
      },
      {
        subtitle: "HIGHEST PROFIT LOSS MAKING EVENTS",
        path: "/bireports/sports-report/highest-profit-loss-making-events",
        isVisible: false,
      },

      // {
      //   subtitle: "REVENUE BY MARKET",
      //   path: "/bireports/sports-report/renvue-by-market",
      //   isVisible: false,
      // },
      // {
      //   subtitle: "DAILY BETTING CHANNEL",
      //   path: "/bireports/user-reports/daily-betting-channel",
      //   isVisible: false,
      // },
      // {
      //   subtitle: "MONTHLY BETTING CHANNEL",
      //   path: "/bireports/user-reports/monthly-betting-channel",
      //   isVisible: false,
      // },
      {
        subtitle: "BONUS REPORTING",
        path: "/bireports/user-reports/bonus-reporting",
        isVisible: false,
      },
      {
        subtitle: "BETTING HISTORY REPORTING",
        path: "/bireports/user-reports/betting-history-reporting",
        isVisible: false,
      },
      {
        subtitle: "AFFILIATE REPORT",
        path: "/bireports/user-reports/affiliate-report",
        isVisible: false,
      },
      {
        subtitle: "DAILY USER ANALYSIS",
        path: "/bireports/user-reports/daily-user-analysis",
        isVisible: false,
      },
      {
        subtitle: "PLAYER SEARCH REPORT",
        path: "/bireports/user-reports/player-search-report",
        isVisible: false,
      },
      // {
      //   subtitle: "BETTING CHANNELS - GAMEWORX",
      //   path: "/bireports/user-reports/betting-channels-gameworx",
      //   isVisible: false,
      // },
      {
        subtitle: "DAILY SUMMARY REPORT",
        path: "/bireports/games-reports/daily-summary-report",
        isVisible: false,
      },
      {
        subtitle: "TOP 20 CUSTOMERS BY STAKE & LOSS",
        path: "/bireports/games-reports/top-20-customers-by-stake-&-loss",
        isVisible: false,
      },
      {
        subtitle: "GAMES REPORT DAILY",
        path: "/bireports/games-reports/games-report-daily",
        isVisible: false,
      },
      {
        subtitle: "CUSTOMER BETS REPORT DAILY",
        path: "/bireports/games-reports/customer-bets-report-daily",
        isVisible: false,
      },
      {
        subtitle: "GAME PROVIDER GGR",
        path: "/bireports/games-reports/game-provider-ggr",
        isVisible: false,
      },
      {
        subtitle: "AVERAGE DAILY DEPOSIT",
        path: "/bireports/payments-report/average-daily-deposit",
        isVisible: false,
      },
      {
        subtitle: "AVERAGE MONTHLY DEPOSIT",
        path: "/bireports/payments-report/average-monthly-deposit",
        isVisible: false,
      },
      {
        subtitle: "WEEKLY USER ANALYSIS REPORT",
        path: "/bireports/payments-report/weekly-user-analysis-report",
        isVisible: false,
      },
      {
        subtitle: "MONTHLY STATE SALES",
        path: "/bireports/regulatory-report/monthly-state-sales",
        isVisible: false,
      },
      {
        subtitle: "DAILY STATE SALES",
        path: "/bireports/regulatory-report/daily-state-sales",
        isVisible: false,
      },
      {
        subtitle: "STAKE > 10K NGN",
        path: "/bireports/regulatory-report/stake->-10k-ngn",
        isVisible: false,
      },
    ],
  },
  {
    id: 2,
    title: "OPERATIONS",
    path: "/",
    icon: "person",
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    children: function () {
      return this.subNav
        .map((v) => v.permissionName)
        .filter((v) => v !== undefined);
    },
    subNav: [
      {
        subtitle: "OPERATION SUMMARY",
        path: "/operationSummary",
        isVisible: true,
        permissionName: "OPERATION_SUMMARY",
      },
      // {
      //   subtitle: "OPERATION REPORT",
      //   path: "/operationReport",
      //   isVisible: false,
      //   permissionName: "OPERATION_REPORT",
      // },
    ],
  },
  {
    id: 3,
    title: "PLAYERS",
    path: "/",
    icon: "people_outline",
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    children: function () {
      return this.subNav
        .map((v) => v.permissionName)
        .filter((v) => v !== undefined);
    },
    subNav: [
      {
        subtitle: "PLAYER SEARCH",
        path: "/playersearch",
        isVisible: true,
        permissionName: "PLAYERS_SEARCH",
      },
      {
        subtitle: "NEW PLAYERS",
        path: "/newplayer",
        isVisible: true,
        permissionName: "PLAYERS_NEW",
      },
      {
        subtitle: "PLAYER ACTIVITY",
        path: "/playerActivity",
        isVisible: true,
        permissionName: "PLAYER_ACTIVITY",
      },
      {
        subtitle: "KYC USERS",
        path: "/kycusers",
        isVisible: true,
        permissionName: "PLAYERS_KYC_VERIFICATION",
      },
      {
        subtitle: "REFERRALS",
        path: "/playerreferrals",
        isVisible: true,
        permissionName: "PLAYERS_REFERRALS",
      },
      {
        subtitle: "ACTIVE PLAYERS",
        path: "/activeplayer",
        isVisible: true,
        permissionName: "ADMIN_ACTIVE_PLAYERS",
      },
      {
        subtitle: "REGISTRATION TICKER",
        path: "/registrationTicker",
        isVisible: true,
        permissionName: "REGISTRATION_TICKER",
      },
      {
        subtitle: "CUSTOMER ID",
        path: `/customerid/${getUserId()}`,
      },
    ],
  },
  {
    id: 4,
    title: "TAGGING / SEGMENTATION",
    path: "/",
    icon: "tag_faces",
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    children: function () {
      return this.subNav
        .map((v) => v.permissionName)
        .filter((v) => v !== undefined);
    },
    subNav: [
      {
        subtitle: "CREATE TAGS",
        path: "/createTags",
        isVisible: true,
        permissionName: "TAG_CREATE",
      },
      {
        subtitle: "ASSIGN TAGS",
        path: "/AssignTags",
        isVisible: true,
        permissionName: "TAG_ASSIGN",
      },
    ],
  },
  {
    id: 5,
    title: "BONUSING",
    path: "/",
    icon: "card_giftcard",

    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    children: function () {
      return this.subNav
        .map((v) => v.permissionName)
        .filter((v) => v !== undefined);
    },
    subNav: [
      {
        subtitle: "CREATE BONUS",
        path: "/createbonus",
        isVisible: true,
        permissionName: "BONUS_CREATE",
      },
      {
        subtitle: "APPLY BONUS",
        path: "/assignbonus",
        isVisible: true,
        permissionName: "BONUS_APPLY",
      },
      {
        subtitle: "BONUS TICKER",
        path: "/bonusTicker",
        isVisible: true,
        permissionName: "BONUS_TICKER",
      },
      {
        subtitle: "BOOSTED ODDS",
        path: "/boostedodds",
        isVisible: true,
        permissionName: "BOOSTED_ODDS",
      },
    ],
  },
  {
    id: 6,
    title: "PAYMENTS",
    path: "/",
    icon: "manage_accounts",

    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    children: function () {
      return this.subNav
        .map((v) => v.permissionName)
        .filter((v) => v !== undefined);
    },
    subNav: [
      {
        subtitle: "PAYMENT SEARCH",
        path: "/paymentsearch",
        isVisible: true,
        permissionName: "PAYMENT_SEARCH",
      },
      {
        subtitle: "PAYMENT TICKER",
        path: "/paymentticker",
        isVisible: true,
        permissionName: "PAYMENT_TICKER",
      },
      {
        subtitle: "PENDING DEPOSITS",
        path: "/pendingdeposits",
        isVisible: true,
        permissionName: "PAYMENT_PENDING_DEPOSIT",
      },
      {
        subtitle: "PENDING WITHDRAWAL",
        path: "/pendingwithdrawalsoffline",
        isVisible: true,
        permissionName: "PAYMENT_PENDING_WITHDRAWALS_OFFLINE",
      },
      {
        subtitle: "PENDING WITHDRAWALS",
        path: "/pendingwithdrawals",
        isVisible: true,
        permissionName: "PAYMENT_PENDING_WITHDRAWAL",
      },
      {
        subtitle: "WITHDRAWAL LIMIT",
        path: "/withdrawallimit",
        isVisible: true,
        permissionName: "PAYMENT_WITHDRAWAL_LIMIT",
      },
      {
        subtitle: "PAYMENT LIMITS",
        path: "/paymentLimits",
        isVisible: true,
        permissionName: "PAYMENT_LIMITS",
      },
      {
        subtitle: "DEPOSIT PROOF",
        path: "/depositproof",
        isVisible: true,
        permissionName: "PAYMENTS_DEPOSIT_PROOF",
      },
    ],
  },
  {
    id: 7,
    title: "BETTING",
    path: "/",
    icon: "attach_money",
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    children: function () {
      return this.subNav
        .map((v) => v.permissionName)
        .filter((v) => v !== undefined);
    },
    subNav: [
      {
        subtitle: "SPORTS BET HISTORY",
        path: "/betHistory",
        isVisible: true,
        permissionName: "BETTING_HISTORY",
      },
      {
        subtitle: "GAMES BET HISTORY",
        path: "/gamesBetHistory",
        isVisible: true,
        permissionName: "GAMES_BET_HISTORY",
      },
      {
        subtitle: "BETTING TICKER",
        path: "/betTicker",
        isVisible: true,
        permissionName: "BETTING_TICKER",
      },
      {
        subtitle: "MULTIBET CONFIGURATION",
        path: "/multibetconfiguration",
        isVisible: true,
        permissionName: "BETTING_MULTIBET_CONFIG",
      },
      {
        subtitle: "MULTIBET ACCOUNTING",
        path: "/multibetaccount",
        isVisible: true,
        permissionName: "BETTING_MULTIBET_ACCOUNTING",
      },
      {
        subtitle: "COMBI SUGGESTIONS",
        path: "/combisuggestions",
        isVisible: true,
        permissionName: "ADMIN_COMBI_SUGGESTIONS",
      },
      {
        subtitle: "VIEW COMBI",
        path: "/viewcombi",
        isVisible: true,
        permissionName: "ADMIN_VIEW_COMBI",
      },
    ],
  },
  {
    id: 44,
    title: "TAXATION",
    path: "/",
    icon: "manage_accounts",
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    children: function () {
      return this.subNav
        .map((v) => v.permissionName)
        .filter((v) => v !== undefined);
    },
    subNav: [
      {
        subtitle: "SPORTS TAXES",
        path: "/sports-taxes",
        isVisible: true,
        permissionName: "SPORTS_TAXES",
      },
      {
        subtitle: "GAMES TAXES",
        path: "/games-taxes",
        isVisible: true,
        permissionName: "GAMES_TAXES",
      },
    ]
  },
  {
    id: 8,
    title: "ADMIN",
    path: "/",
    icon: "manage_accounts",
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    children: function () {
      return this.subNav
        .map((v) => v.permissionName)
        .filter((v) => v !== undefined);
    },
    subNav: [
      {
        subtitle: "CREATE USER",
        path: "/createuser",
        isVisible: true,
        permissionName: "ADMIN_USER",
      },
      {
        subtitle: "CREATE ROLE",
        path: "/createrole",
        isVisible: true,
        permissionName: "ADMIN_ROLE",
      },
      {
        subtitle: "CREATE PERMISSION",
        path: "/createpermission",
        isVisible: true,
        permissionName: "ADMIN_PERMISSION",
      },
      {
        subtitle: "CURRENCY CONVERSION",
        path: "/currncyconversion",
        isVisible: true,
        permissionName: "ADMIN_CURRENCY_CONVERSION",
      },

    ],
  },
  {
    id: 9,
    title: "BRAND CONFIG",
    path: "/",
    icon: "settings",
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    children: function () {
      return this.subNav
        .map((v) => v.permissionName)
        .filter((v) => v !== undefined);
    },
    subNav: [
      {
        subtitle: "BRAND CONFIG",
        path: "/brandconfig",
        isVisible: true,
        permissionName: "BRAND_CONFIG",
      },
      {
        subtitle: "BRAND KEY",
        path: "/brandkey",
        isVisible: true,
        permissionName: "BRAND_KEYS",
      },
      {
        subtitle: "CREATE CLIENT",
        path: "/createclient",
        isVisible: true,
        permissionName: "ADMIN_CREATE_CLIENT",
      },
      {
        subtitle: "CREATE BRAND",
        path: "/createbrand",
        isVisible: true,
        permissionName: "ADMIN_CREATE_BRAND",
      },
    ],
  },
  {
    id: 9,
    title: "RISK MANAGEMENT",
    path: "/",
    icon: "gpp_maybe",
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    children: function () {
      return this.subNav
        .map((v) => v.permissionName)
        .filter((v) => v !== undefined);
    },
    subNav: [
      {
        subtitle: "USERS WITH SAME IP",
        path: "/usersIp",
        isVisible: true,
        permissionName: "RISK_MANAGMEMENT_USERS_SAME_IP",
      },
      {
        subtitle: "USERS WITH SAME MAC ADRESS & IP ADDRESS",
        path: "/usersMac&Ip",
        isVisible: true,
        permissionName: "RISK_MANAGMEMENT_USERS_SAME_ADDRESS",
      },
      {
        subtitle: "SYNDICATE BETTING",
        path: "/syndicateBet",
        isVisible: true,
        permissionName: "RISK_MANAGMEMENT_SYNDICATE_BETTING",
      },
      {
        subtitle: "USERS WITH SAME DETAILS",
        path: "/userswithsamedetails",
        isVisible: true,
        permissionName: "RISK_MANAGMEMENT_ARBITRAGE_BETTING",
      },
    ],
  },
  {
    id: 10,
    title: "COMMUNICATION",
    path: "/",
    icon: "message",
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    children: function () {
      return this.subNav
        .map((v) => v.permissionName)
        .filter((v) => v !== undefined);
    },
    subNav: [
      {
        subtitle: "PROMO",
        isVisible: true,
        permissionName: "ADMIN_COMMUNICATION_PROMO",
        subTabs: [
          {
            subtitle: "CREATE PROMO MESSAGE",
            path: "/createpromomsg",
            isVisible: true,
            permissionName: "ADMIN_COMMUNICATION_CREATEPROMO",
            isSubItem: true,
          },
          {
            subtitle: "PROMOTION LIST",
            path: "/promotionlist",
            isVisible: true,
            permissionName: "ADMIN_COMMUNICATION_PROMOLIST",
            isSubItem: true,
          },
        ],
      },
      {
        subtitle: "TEMPLATES",
        isVisible: true,
        permissionName: "ADMIN_COMMUNICATION_PROMO",
        subTabs: [
          {
            subtitle: "CREATE ACCOUNT MESSAGE",
            path: "/createaccountmsg",
            isVisible: true,
            permissionName: "ADMIN_COMMUNICATION_CREATEPROMO",
            isSubItem: true,
          },
          {
            subtitle: "CREATE SYSTEM MESSAGE",
            path: "/createsystemmsg",
            isVisible: true,
            permissionName: "ADMIN_COMMUNICATION_PROMOLIST",
            isSubItem: true,
          },
        ],
      },
    ],
  },
  {
    id: 11,
    title: "AFFILIATE",
    path: "/",
    icon: "man",
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    children: function () {
      return this.subNav
        .map((v) => v.permissionName)
        .filter((v) => v !== undefined);
    },
    subNav: [
      {
        subtitle: "BECOME AN AFFILIATE",
        path: "/becomeaffiliate",
        isVisible: true,
        permissionName: "ADMIN_BECOME_AFFILIATE",
      },
      {
        subtitle: "AFFILIATE",
        path: "/affiliate",
        isVisible: true,
        permissionName: "ADMIN_AFFILIATE",
      },
      {
        subtitle: "CREATE SUB AFFILIATE",
        path: "/subaffiliate",
        isVisible: true,
        permissionName: "ADMIN_SUB_AFFILIATE",
      },
      {
        subtitle: "CAMPAIGN",
        path: "/campaign",
        isVisible: true,
        permissionName: "ADMIN_CAMPAIGN",
      },
      {
        subtitle: "CAMPAIGN-URL",
        path: "/campaignUrls",
        isVisible: false,
      },
      {
        subtitle: "CHANNEL",
        path: "/channel",
        isVisible: true,
        permissionName: "ADMIN_CHANNEL",
      },

      {
        subtitle: "AFFILIATE SUMMARY",
        path: "/affiliatesummary",
        isVisible: true,
        permissionName: "ADMIN_AFFILIATE_SUMMARY",
      },
    ],
  },
  {
    id: 11,
    title: "CYRUS",
    path: "/",
    icon: "gpp_maybe",
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    children: function () {
      return this.subNav
        .map((v) => v.permissionName)
        .filter((v) => v !== undefined);
    },
    subNav: [
      // {
      //   subtitle: "BET CONFIG",
      //   path: "/betconfig",
      //   isVisible: true,
      //   permissionName: "RISK_MANAGMEMENT_USERS_SAME_IP",
      // },
      {
        subtitle: "BET SETTLEMENT",
        path: "/betsettlement",
        isVisible: true,
        permissionName: "CYRUS_BET_SETTLEMENT",
      },
      {
        subtitle: "BET DETAILS",
        path: "/betdetails",
        isVisible: false,
        permissionName: "CYRUS_BET_DETAILS",
      },

    ]
  },
  {
    id: 12,
    title: "PLAYERS WATCHLIST",
    path: "/",
    icon: "tag_faces",
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    children: function () {
      return this.subNav
        .map((v) => v.permissionName)
        .filter((v) => v !== undefined);
    },
    subNav: [
      {
        subtitle: "CREATE WATCHLIST",
        path: "/createwatchlist",
        isVisible: true,
        permissionName: "ADMIN_CREATE_WATCHLIST",
      },
      {
        subtitle: "USERS WATCHLIST",
        path: "/userswatchlist",
        isVisible: true,
        permissionName: "ADMIN_USERS_WATCHLIST",
      },

    ]
  }
];

export const sidebarNavTabs = SidebarData.map((tab) => tab.subNav).flat();

export const extractedSubTabs = SidebarData.flatMap((nav) =>
  nav.subNav
    ? nav.subNav.flatMap((subNav) => (subNav.subTabs ? subNav.subTabs : []))
    : []
);
