import { toast } from "react-toastify"
import { getSymbolFromCode } from "currency-code-symbol-map";
import { notificationSubscribe, notificationUnSubscribe } from "../components/AppSlice";
import { apiCaller } from "../api/apiCaller";
import { userApi } from "./EndpointConfig";
import moment from 'moment-timezone'

export const getBrowserDate = (date) => {
  var senddate = ''
  if (date != null || (undefined && date.indexOf('UTC') !== -1)) {
    senddate = date.replace('[UTC]', '')
  } else {
    senddate = date
  }
  let placeddate = new Date(senddate)

  var setTime =
    placeddate.getHours() +
    ':' +
    placeddate.getMinutes() +
    ':' +
    placeddate.getSeconds()
  var fullTime = placeddate.toLocaleDateString() + ' ' + setTime
  return fullTime
}
export const getUtcDate = (date) => {
  var utcDate = new Date(date).toISOString()
  return utcDate
}

export const getDate = (date) => {
  let dateString = new Date(date.getTime() - date.getTimezoneOffset() * 60000)
    .toISOString()
    .split('T')[0]
}

export const getDecimalNumber = (number) => {
  if (number != null || undefined) {
    return Number(number).toFixed(2)
  } else {
    return 0
  }
}
export const getDateFormate = (birth_date) => {
  if (birth_date != null || undefined) {
    let birthdate = new Date(birth_date)
    let getMonth = Number(birthdate.getMonth()) + 1
    let getFullMonth = ''
    if (getMonth.toString().length < 2) {
      getFullMonth = '0' + getMonth
    } else {
      getFullMonth = getMonth
    }

    let getDay = ''
    if (birthdate.getDate().toString().length < 2) {
      getDay = '0' + birthdate.getDate()
    } else {
      getDay = birthdate.getDate()
    }
    var fullDate = getDay + '/' + getFullMonth + '/' + birthdate.getFullYear()

    return fullDate
  } else {
    return '-'
  }
}

export const getBirthDate = (birth_date) => {
  if (birth_date != null || undefined) {
    let birthdate = new Date(birth_date)
    let getMonth = Number(birthdate.getMonth()) + 1
    let getFullMonth = ''
    if (getMonth.toString().length < 2) {
      getFullMonth = '0' + getMonth
    } else {
      getFullMonth = getMonth
    }

    let getDay = ''
    if (birthdate.getDate().toString().length < 2) {
      getDay = '0' + birthdate.getDate()
    } else {
      getDay = birthdate.getDate()
    }
    // var fullDate = getDay + "/" + getFullMonth + "/" + birthdate.getFullYear();
    var fullDate = birthdate.getFullYear() + '-' + getFullMonth + '-' + getDay
    return fullDate
  } else {
    return 0
  }
}

export const formatMoney = (number) => {
  return (number / 100).toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
}

export const roundDecimalnum = (number) => {
  return number.toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
}

export const MoneyFormat1 = (number) => {
  return (number / 100)
}
export const MoneyFormat2 = (number) => {
  return (number * 100)
}

export const getDateTimeFormate = (createdDate) => {

  const inputDate = new Date(createdDate);
  const options = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  };
  const dateFormatter = new Intl.DateTimeFormat('en-GB', options);
  const formattedDate = dateFormatter.format(inputDate);
  return formattedDate;

  // const dateComponents = createdDate && createdDate.split('T');
  // const dateParts = dateComponents && dateComponents[0].split('-');
  // const date = `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
  // const time = dateComponents && dateComponents[1]?.slice(0, -1); // Removing 'Z' at the end


  // const formattedDateTime = `${date}, ${time}`;

  // return formattedDateTime;

}
export const getDateTimeFormateInvalidDate = (createdDate) => {
  const inputDate = new Date(createdDate);
  if (!createdDate) {
    return "-";
  }
  if (isNaN(inputDate)) {
    return "-";
  }
  const options = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  };
  const dateFormatter = new Intl.DateTimeFormat('en-GB', options);
  const formattedDate = dateFormatter.format(inputDate);
  return formattedDate;
}


export const getTimeFormat = (time) => {

  if (time >= 60) {
    const fullTime = (time / 60)
    return fullTime
  } else {
    return time
  }
}

export const getTimeinMinutes = (time) => {
  const fullTime = (time * 60)
  return fullTime
}


export const getnumberWithCommas = (number) => {
  if (number !== null && number !== undefined) {
    return Number(number)
      .toString()
      .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
  } else {
    return '0';
  }
};

export const convertEndDatePickerTimeToMySQLTime = (str) => {
  if (str !== null && str !== undefined && str !== '') {
    var date = new Date(str)
    date.setHours(23, 59, 59)
    return date.toISOString()
  }
}

export const convertStartDatePickerTimeToMySQLTime = (str, page = 'common') => {
  if (str !== null && str !== undefined && str !== '') {
    var date = new Date(str)
    const inputDate = new Date(str)
    var tempDate = new Date()
    const isToday = date.getDate() === tempDate.getDate()
    date.setHours(
      tempDate.getHours(),
      tempDate.getMinutes(),
      tempDate.getSeconds(),
    )

    const utcTime = isToday ? date.toISOString() : inputDate.toISOString()

    // return utcTime;
    if (page === 'bonus') {
      return utcTime
    } else {
      var month, day, hours, minutes, seconds
      var date = new Date(str)
      month = ('0' + (date.getMonth() + 1)).slice(-2)
      day = ('0' + date.getDate()).slice(-2)
      hours = '00'
      minutes = '00'
      seconds = '00'
      var mySQLDate = [date.getFullYear(), month, day].join('-')
      var mySQLTime = [hours, minutes, seconds].join(':')
      return [mySQLDate + 'T', mySQLTime + '.517Z'].join('')
    }
  }
}

// export const convertStartDatePickerTimeToMySQLTime = (str) => {
//   var month, day, hours, minutes, seconds;
//   var date = new Date(str);
//   month = ("0" + (date.getMonth() + 1)).slice(-2);
//   day = ("0" + date.getDate()).slice(-2);
//   hours = "00";
//   minutes = "00";
//   seconds = "00";
//   var mySQLDate = [date.getFullYear(), month, day].join("-");
//   var mySQLTime = [hours, minutes, seconds].join(":");
//   return [mySQLDate + "T", mySQLTime + ".517Z"].join("");
// };

export const getFormateDate = (birth_date) => {
  if (birth_date != null || undefined) {
    let birthdate = new Date(birth_date)
    let getMonth = Number(birthdate.getMonth()) + 1
    let getFullMonth = ''
    if (getMonth.toString().length < 2) {
      getFullMonth = '0' + getMonth
    } else {
      getFullMonth = getMonth
    }

    let getDay = ''
    if (birthdate.getDate().toString().length < 2) {
      getDay = '0' + birthdate.getDate()
    } else {
      getDay = birthdate.getDate()
    }
    // var fullDate = getDay + "/" + getFullMonth + "/" + birthdate.getFullYear();
    var fullDate = birthdate.getFullYear() + '-' + getFullMonth + '-' + getDay

    return fullDate
  } else {
    return '-'
  }
}

export const hideInfo = (str, upto, flag) => {
  if (str !== '' && str !== null && str !== undefined) {
    const input = str !== '' && str ? str.trim() : str
    const outArr = []
    for (let i = 0; i < 5; i++) {
      if (flag != true && i <= upto) {
        outArr.push(input[i])
      }
      if (flag != true && i > upto && i !== '@') {
        outArr.push('*')
      }
      if (flag != true && i === '@') {
        outArr.push(input[i])
      }
      if (flag == true) {
        return str
      }
    }
    return outArr.join('')
  }
}

// Masking the whole data //
export const hideData = (str, temp) => {
  if (str !== '' && str !== null && str !== undefined && temp != true) {
    const input = str
    const mask = '*'
    let maskedName = input.substring(0, 0) + mask.repeat(input.length)
    return maskedName
  }
  if (temp == true) {
    return str
  }
}
// Masking the whole data //

// For Bank IFSC code masking //
export const showfirst4 = (str, temp) => {
  if (str !== '' && str !== null && str !== undefined && temp != true) {
    const input = str
    const mask = '*'
    let maskedName = input.substring(0, 4) + mask.repeat(input.length)
    return maskedName
  }
  if (temp == true) {
    return str
  }
}
// For Bank IFSC code masking //

// For Bank Account-No masking //
export const hideAccNo = (num, temp) => {
  if (num !== '' && num !== null && num !== undefined && temp != true) {
    let subNum = num.toString().replace(/\d(?=\d{4})/g, '*')
    return subNum
  }
}
// For Bank Account-No masking //

export const hideEmail = (str, temp) => {
  if (str !== '' && str !== null && str !== undefined && temp != true) {
    const input = str
    const mask = '*'
    let maskedName = input.substring(0, 3) + mask.repeat(input.length)
    return maskedName
  }
  if (temp == true) {
    return str
  }
}

export const hideMobile = (str, temp) => {
  // const toFix = (inp) => {
  //   let fix = 0;
  //   inp.length == 10 && (fix = 2);
  //   inp.length == 11 && (fix = 3);
  //   inp.length == 12 && (fix = 4);
  //   inp.length == 13 && (fix = 5);
  //   return fix;
  // };

  if (str !== '' && str !== null && str !== undefined && temp != true) {
    const input = str
    return input.replace(/\d(?=\d{4})/g, '*')
  }
  if (temp == true) {
    return str
  }
}

// export const hideMobile = (str, upto) => {
//   const stoping = str.length - 2;

//   const input = str.trim();
//   const outArr = [];
//   for (let i = 0; i < input.length; i++) {
//     if (i <= upto) {
//       outArr.push(input[i]);
//     }
//     if (i > upto && i < stoping) {
//       outArr.push("*");
//     }
//     if (i >= stoping) {
//       outArr.push(input[i]);
//     }
//   }
//   return outArr.join("");
// };

export const fixedDigit = (number, digit) => {
  const nums = ['0', '1', '2', '3', '4', '5', '6', ' 7', '8', '9', '.']

  if (number != null && undefined && nums.includes(number)) {
    return Number(number).toFixed(digit)
  } else {
    return number
  }
}

export const get2DigitAfterDecimal = (num) => {
  if (num != null && num != undefined) {
    return Number(num).toFixed(2)
  } else {
    return num
  }
}

export const usFormat = (num) => {
  if (num != null && num != undefined) {
    // return num.toLocaleString("en-US", {});
    const inp = +(num / 100).toFixed(2)
    // return inp.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return inp.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
  } else {
    return '0'
  }
}

export const colorArray = [
  '#FF6633',
  '#FFB399',
  '#FF33FF',
  // "#FFFF99",
  '#00B3E6',
  '#E6B333',
  '#3366E6',
  '#999966',
  '#99FF99',
  '#B34D4D',
  // "#80B300",
  //"#809900",
  // "#E6B3B3",
  '#6680B3',
  // "#66991A",
  // "#FF99E6",
  '#CCFF1A',
  '#FF1A66',
  '#E6331A',
  '#33FFCC',
  '#66994D',
  '#B366CC',
  '#4D8000',
  '#B33300',
  '#CC80CC',
  '#66664D',
  '#991AFF',
  '#E666FF',
  '#4DB3FF',
  '#1AB399',
  '#E666B3',
  '#33991A',
  '#CC9999',
  '#B3B31A',
  '#00E680',
  '#4D8066',
  '#809980',
  '#E6FF80',
  '#1AFF33',
  '#999933',
  '#FF3380',
  '#CCCC00',
  '#66E64D',
  '#4D80CC',
  '#9900B3',
  '#E64D66',
  '#4DB380',
  '#FF4D4D',
  '#99E6E6',
  '#6666FF',
]

export const getDob = (dob) => {
  if (dob != null && dob != undefined) {
    const dateArray = dob.split('-')
    const fullDob = dateArray[2] + '/' + dateArray[1] + '/' + dateArray[0]
    return fullDob
  }
}

// export const sortingArray = (arr, key) => {
//   [
//     ...new Map(props.userPermission.map((item) => [item.id, item])).values(),
//   ].sort((a, b) => a.id - b.id);
// }
export const regexType = {
  alphanospace: /^[A-Za-z0-9]+$/,
  alpha: /^[a-zA-Z ]*$/,
  numeric: /^[0-9\b]*$/,
  validNumeric: /^[1-9][0-9]*$/,
  decimalNumeric: /^[0-9.\b]*$/,
  decimalPercentage: /^[0-9.%]*$/,
  alphaNumeric: /^[a-zA-Z0-9" "]*$/,
  multiWord: /^[a-z](?:_?[a-z0-9" "-_]+)*$/i,
  multiNumeric: /^[0-9]+(\.[0-9]{1,2})?$/,
  decimalNumber: /^(([0-9])(\.[0-0]{0,2})?$|([0-9])(\.[0-9]{1,2})?$)/,
  maxNumber: (max) => `/^[1-9][0-9]?$|^${max}$/`,
  // /^[1-9][0-9]?$|^100$/
  email: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
  // email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  simplePassword: /^[a-zA-Z0-9@$.!#%&*]*$/,
  password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
  phone: /^[0-9]{10}$/,
  name: /^[a-zA-Z ]{2,30}$/,
  number: /^[0-9]{1,10}$/,
  address: /^[a-zA-Z0-9\s,.'-]{2,100}$/,
  city: /^[a-zA-Z\s]{2,30}$/,
  state: /^[a-zA-Z\s]{2,30}$/,
  zip: /^[0-9]{5,10}$/,
  country: /^[a-zA-Z\s]{2,30}$/,
  date: /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/,
  time: /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/,
  dateTime: /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2} ([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/,
  dateTime24: /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2} ([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/,
  ipAddress: /^[\0-9.]*$/,
  decimalafternum: /^(?!0{2,})(?!\.$)[0-9]{0,13}(?:\.(?!0{4,})[0-9]{0,20})?$/,
  numericPercentage: /^[0-9%]*$/,
  oneDecimal: /^(\d*)\.{0,1}(\d){0,9}$/,
  upto100: /^([1-9]|[1-9][0-9]|100)$/,
  notZero: /^[^a-zA-Z!@#$%^&*()_+=[\]{}|;':",./<>?]*$/,
  numOnly4Digit: /^\d{0,4}(\.\d{0,1})?$/,
  numOnly2Digit: /^\d{0,6}(\.\d{0,2})?$/,
}

export const FalsyCleanObject = (obj) => {
  for (var propName in obj) {

    if (
      obj[propName] == null ||
      obj[propName] === undefined ||
      obj[propName] === ''
      // obj[propName] == ''
      // obj[propName] === 0 ||
      // obj[propName].length === 0
      // obj[propName] == 'NaN-aN-aNT23:59:59.517Z' ||
      // obj[propName] == 'NaN-aN-aNT00:00:00.517Z'
    ) {
      delete obj[propName]
    }
  }
  return obj
}
// clearing Function that removes faslsy values from the payload
export const cleanObject = (obj) => {
  for (var propName in obj) {
    if (
      obj[propName] == null ||
      obj[propName] === undefined ||
      obj[propName] === '' ||
      obj[propName] == '' ||
      obj[propName] === 0 ||
      obj[propName].length === 0 ||
      (Array.isArray(obj[propName]) &&
        obj[propName].length === 1 &&
        typeof obj[propName][0] === 'object' &&
        Object.keys(obj[propName][0]).length === 0)
    ) {
      delete obj[propName];
    }
  }
  return obj;
};

// Cleaning Function that removes empty values from the payload (flase will not be removed)
export const cleanPayload = (payload) => {
  return Object.fromEntries(
    Object.entries(payload).filter(([key, value]) => value !== "" && value !== null && value !== undefined)
  );
};

export const generateCode = (length) => {
  var result1 = ''
  var characters = 'abcdefghijklmnopqrstuvwxyz'
  var charactersLength = characters.length
  for (var i = 0; i < length; i++) {
    result1 += characters.charAt(Math.floor(Math.random() * charactersLength))
  }

  return result1
}

export const getBaseUrl = (cyrusApi, upsApi) => {
  var mainBaseUrl = ''
  // const brandID = `${localStorage.getItem("brandId")}`;
  const isExist = localStorage.getItem('isExist')
  switch (isExist) {
    case 'GAMEWORX_PERMISSION':
      mainBaseUrl = cyrusApi
      break
    // case "53":
    //   mainBaseUrl = "https://dev.platform-ups.sportsit-tech.net/";
    //   break;
    default:
      mainBaseUrl = upsApi
  }
  return mainBaseUrl
}

export function getPath(param) {
  const urlPath = window.location.pathname
  const urlParams = urlPath.split('/')
  function checker(checker) {
    return urlParams.some((val) => val === checker)
  }
  const isPresent = checker(param)
  var mainPathObject = {
    isExist: isPresent,
    urlParams: urlParams,
    urlPath: urlPath,
    searchKey: param,
  }
  return mainPathObject
}

export function Currency() {
  const selected = localStorage.getItem('currency');
  const Default = "INR"   //'₹';
  const code = selected || Default;
  return getSymbolFromCode(code)
}

export function Country() {
  return localStorage.getItem('currency');
}
export const getUTCDate = (date, type) => {
  if (date !== undefined && date !== null && date !== '') {
    let utcFullDate = new Date(date)
    if (type === 'start') {
      utcFullDate.setHours(0, 0, 0, 0)
    } else {
      utcFullDate.setHours(23, 59, 59)
    }
    return utcFullDate.toISOString()
  } else {
    return null
  }
}

export const setToLocalStorage = (key, value) => {
  const encodedData = btoa(value)
  localStorage.setItem(key, encodedData)
}

export const getFromLocalStorage = (key) => {
  const data = localStorage.getItem(key)
  return atob(data)
}
export const removeFromLocalStorage = (key) => {
  localStorage.removeItem(key)
}

export const generateKey = (dem) => {
  return `${dem}_${Date.now()}`
}

export function sortingFilter(data, { key, sortType, keyType }) {
  // console.log("{key, sortType, keyType }: ", {key, sortType, keyType });
  const fetchedData = [...data]
  const sortData = fetchedData.sort((a, b) => {
    if (keyType === 'num') {
      if (Number(a[key]) > Number(b[key])) return 1
      else if (Number(a[key]) < Number(b[key])) return -1

      return 0
    }
    if (keyType === 'date') {
      const date1 = new Date(a[key]).getTime()
      const date2 = new Date(b[key]).getTime()
      return date1 - date2
    }
    if (keyType === 'str') {
      // return a[key] - b[key];
      if (a[key] > b[key]) return 1
      else if (a[key] < b[key]) return -1

      return 0
    }
    // else{
    //   if (a[key] > b[key]) return 1;
    //   else if (a[key] < b[key]) return -1;

    //   return 0;
    // }
  })

  if (sortType !== '') {
    return sortType === 'ASCENDING' ? sortData.reverse() : sortData
  } else {
    return data
  }
}
export const replaceWithSpace = (value) => {
  if (value != null) {
    return value.replace(/_/g, ' ')
  }
}

export function messageEnhancer(msg) {
  if (msg) {
    const checkISRawString = [...msg].some(l => l == "_");
    if (checkISRawString) {
      const splitted = msg.split('_')
      const loweredArr = splitted.map(
        (v) => `${v[0]}${v.slice(1, v.length).toLowerCase()}`,
      )
      const output = loweredArr.join(' ')
      return output
    } else {
      return msg
    }
  } else {
    return "";
  }
}

export const toaster = {
  success: (msg) => toast.success(msg),
  error: (msg) => toast.error(msg),
  info: (msg) => toast.info(msg),
  warning: (msg) => toast.warning(msg),
  warn: (msg) => toast.warn(msg),
  dark: (msg) => toast.dark(msg),
}

export const notySubscribe = () => (dispatch) => {
  const auth = localStorage.getItem("auth")
  const brandId = localStorage.getItem("brandId")
  const endPoint = localStorage.getItem("endpoint")
  const key = localStorage.getItem("Publickey")
  const token = localStorage.getItem("token")
  const id = localStorage.getItem("logUserId")
  dispatch(notificationSubscribe(auth, brandId, endPoint, key, token, id))
}

export const notyUnSubscribe = () => (dispatch) => {
  const auth = localStorage.getItem("auth")
  const brandId = localStorage.getItem("brandId")
  const endPoint = localStorage.getItem("endpoint")
  const key = localStorage.getItem("Publickey")
  const token = localStorage.getItem("token")
  const id = localStorage.getItem("logUserId")
  dispatch(notificationUnSubscribe(auth, brandId, endPoint, key, token, id))
}

export const urlB64ToUint8Array = (base64String) => {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding)
    .replace(/-/g, '+')
    .replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }

  return outputArray;
}

export const divideAmountBy100 = (paymentDesc) => {
  const amountRegex = /Amount (\d+)/;
  const match = paymentDesc?.match(amountRegex);
  if (!match) {
    return paymentDesc;
  }
  const originalAmount = parseInt(match[1]);
  const newAmount = originalAmount / 100;
  const modifiedPaymentDesc = paymentDesc.replace(amountRegex, `Amount ${newAmount}`);
  return modifiedPaymentDesc;
}

export const refreshTokenApi = () => {
  const url = userApi.refreshToken
  const refreshToken = localStorage.getItem("refreshToken")
  const payload = {
    refreshToken: refreshToken
  }
  apiCaller(url, "POST", payload).then(
    res => {
      if (res.status === 200 && res.msg === "LOGIN_SUCCESS") {
        console.log("success");
        localStorage.setItem("token", res.data.accessToken)
        // localStorage.setItem("newRefreshToken", res.data.refreshToken)
        window.location.href = '/'
      } else {
        localStorage.clear();
        window.location.href = '/login'
      }
    }).catch(err => {
      console.log("err.msg: ", err);
      // window.location.href = '/login'
    }
    )
}

export const extractedDateTime = (date) => { //extracting both date and time
  const propDate = new Date(date);
  const day = propDate.getDate()
  const month = propDate.getMonth()
  const year = propDate.getFullYear()
  const hours = propDate.getHours();
  const minutes = propDate.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  const formattedHours = hours % 12 || 12;

  const finalDateTime = `${day}/${month}/${year} ${formattedHours}:${minutes} ${ampm}`;
  return finalDateTime;
}

export const MomentUTCDateTime = (dateString) => {
  const localTime = moment(dateString, 'ddd MMM DD YYYY HH:mm:ss [GMT]Z (z)', 'en');
  const utcTime = localTime.utc();
  return utcTime.format();
}

export const MomentUTCDateTimeConcat = (dateString) => {
  const date = dateString && new Date(dateString);
  const utcTime = date && new Date(date.getTime() + (date.getTimezoneOffset() * 60000));

  if (!(utcTime instanceof Date && !isNaN(utcTime))) {
    return null; // or any other appropriate value indicating failure
  }

  if (utcTime.getUTCHours() < date.getUTCHours()) {
    utcTime.setUTCDate(utcTime.getUTCDate() + 1);
  }

  const year = utcTime.getUTCFullYear();
  const month = String(utcTime.getUTCMonth() + 1).padStart(2, '0');
  const day = String(utcTime.getUTCDate()).padStart(2, '0');
  const hours = String(utcTime.getUTCHours()).padStart(2, '0');
  const minutes = String(utcTime.getUTCMinutes()).padStart(2, '0');
  const seconds = String(utcTime.getUTCSeconds()).padStart(2, '0');

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.000Z`;
}
export const MomentUTCDateTimeToday = (selectedDate) => {
  const parsedDate = new Date(selectedDate);

  if (!isNaN(parsedDate.getTime())) {
    parsedDate.setHours(0, 0, 0, 0);

    const adjustedUTCDate = new Date(parsedDate.getTime() - parsedDate.getTimezoneOffset() * 60000);

    const payloadDate = adjustedUTCDate.toISOString();
    return payloadDate;
  } else {
    console.error('Invalid date string:', selectedDate);
    return '';
  }
}


export const MomentUTCDateTimeEnd = (dateString) => {
  const parsedDate = new Date(dateString);

  if (!isNaN(parsedDate.getTime())) {
    parsedDate.setHours(23, 59, 59, 0);

    const adjustedUTCDate = new Date(parsedDate.getTime() - parsedDate.getTimezoneOffset() * 60000);

    const formattedEndDate = adjustedUTCDate.toISOString();

    return formattedEndDate;
  } else {
    console.error('Invalid date string:', dateString);
    return '';
  }
};

export const SyndicateTimeStart = (selectedDate) => {
  const parsedDate = new Date(selectedDate);
  if (isNaN(parsedDate.getTime())) {
    console.error('Invalid date string:', selectedDate);
    return null;
  }
  const startOfDay = new Date(parsedDate.getFullYear(), parsedDate.getMonth(), parsedDate.getDate(), 0, 0, 0, 0);
  const utcStartOfDay = new Date(startOfDay.getTime() - startOfDay.getTimezoneOffset() * 60000);
  return utcStartOfDay;
}

export const SyndicateTimeEnd = (selectedDate) => {
  const parsedDate = new Date(selectedDate);
  if (isNaN(parsedDate.getTime())) {
    console.error('Invalid date string:', selectedDate);
    return null;
  }
  const endOfDay = new Date(parsedDate.getFullYear(), parsedDate.getMonth(), parsedDate.getDate(), 23, 59, 59, 999);
  const utcEndOfDay = new Date(endOfDay.getTime() - endOfDay.getTimezoneOffset() * 60000);
  return utcEndOfDay;
};

export const extractedDate = (date) => {  //extracting only date
  const propDate = new Date(date);
  const day = propDate.getDate()
  const month = propDate.getMonth()
  const year = propDate.getFullYear()

  const finalDate = `${day}/${month + 1}/${year}`
  return finalDate
}

export const extractedTime = (date) => {
  const propDate = new Date(date);
  const hours = propDate.getHours();
  const minutes = propDate.getMinutes();

  // Convert to 24-hour format
  const formattedHours = hours.toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0');

  const finalTime = `${formattedHours}:${formattedMinutes}`;
  return finalTime;
}

export const extractDate = (dateString) => {
  const date = new Date(dateString);

  const extractedDate = date.getDate();
  const extractedMonth = date.toLocaleString('default', { month: 'short' });
  const extractedYear = date.getFullYear();

  const extractedDateString = `${extractedDate} ${extractedMonth} ${extractedYear}`;
  return extractedDateString;
}

export const getFormattedDate = (daysAgo) => {
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() - daysAgo);
  const formattedDate = currentDate.toISOString().split('T')[0];
  return new Date(formattedDate);
};

export const getFuturedDate = (daysAfter) => {
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() + daysAfter);
  const formattedDate = currentDate.toISOString().split('T')[0];
  return new Date(formattedDate);
};

export const formatNumberWithK = (number) => {
  const shortenNumber = (num) => {
    const shortNum = (num / 1e9).toFixed(1);
    console.log(shortNum, "shortNum");
    return `${shortNum}K`;
  };

  const numString = number.toString();
  if (numString.length >= 13) {
    return shortenNumber(number);
  }
}


// const dateTime = new Date(createdDate);

//   // Extract date components
//   const day = dateTime.getUTCDate();
//   const month = dateTime.getUTCMonth() + 1; // Months are zero-based
//   const year = dateTime.getUTCFullYear();

//   // Pad single digits with leading zero
//   const formattedDate = `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`;

//   // Extract time components
//   const hours = dateTime.getUTCHours();
//   const minutes = dateTime.getUTCMinutes();
//   const seconds = dateTime.getUTCSeconds();

//   // Format time
//   const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

//   return `${formattedDate}, ${formattedTime}`;