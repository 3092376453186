import React, { Component } from "react";
import { connect } from "react-redux";
import {
  fetchTagData,
  fetchTagsList,
  postUserTag,
  setPaginationFirstValue,
  setPaginationSecondValue,
  setViewRecords,
  setRecordsShow,
  deactiveTag,
} from "./TagSlice";
import { bindActionCreators } from "redux";
import { getDateTimeFormate } from "../../sharedfiles/helper";
import Vpagination from "../../sharedfiles/Vpagination";
import { exportPDFC } from "../../sharedfiles/jsPdfCommon";
import ButtonGroup from "../Common/Buttons/ButtonGroup";
import Skelton from "../Common/skelton/Skelton";
import { COMMON_CONSTANTS, TAG_CONSTANTS } from "../../sharedfiles/Constants";
import No__Data__Found from "../Common/table/No__Data__Found";
import Select from "../Common/common_inputs/Select_DDL";
import { playerTagData } from "../Common/data/mapData";
import Tooltips from "../Common/Tooltip";
import { TagInfo, Tagging_Segmentation } from "../../sharedfiles/tooltipMsgs";
class Tags extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: localStorage.getItem("userId"),
      errorControl: false,
      tagType: "",
      tagName: "",
      tagId: "",
      id: "",
      itemToDeleteId: "",
      tagTypeList: JSON.parse(localStorage.getItem("tagTypeList")),
      deactiveTag: "",
      activeStatus: "",
      tagNameError: "",
      tagTypeError: "",
      csvData: [],
      pdfBody: [],
      resetCall: false,
    };
    this.changeHandler = this.changeHandler.bind(this);
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.handleActivation = this.handleActivation.bind(this);
    this.setDeleteTag = this.setDeleteTag.bind(this);
    this.addTag = this.addTag.bind(this);
    this.exportPDF = this.exportPDF.bind(this);
    this.updateTableToDocs = this.updateTableToDocs.bind(this);
    this.callApi = this.callApi.bind(this);
    this.handleTagStatus = this.handleTagStatus.bind(this);
  }
  changeHandler(e) {
    e.target.value !== "" && this.setState({ tagTypeError: false });
    this.setState({
      [e.target.name]: e.target.value,
    });
    // this.setState({ id: e.target.id });
    e.target.value !== "" && this.setState({ controlError: false });
    e.target.value && this.getTags();
  }
  handleTagStatus(item) {
    this.props.dispatch(deactiveTag(item))
  }
  onChangeHandler(e) {
    this.setState({ tagName: e.target.value });
    e.target.value !== "" && this.setState({ tagNameError: false });
    const id = this.props?.getTagsList &&
      Object.keys(this.props.getTagsList).length > 0 &&
      this.props?.getTagsList?.data &&
      Array.isArray(this.props?.getTagsList?.data) &&
      this.props.getTagsList.data.filter((item) =>
        item.tagName === e.target.value
      );
    this.setState({ id: id[0].id });
  }

  callApi(pageNumber, itemPerPage, playerId = localStorage.getItem("userId")) {
    this.props.dispatch(setViewRecords(false));
    this.props.dispatch(
      fetchTagData(
        playerId,
        pageNumber - 1,
        itemPerPage,
      )
    );
    this.setState({ resetCall: false });
  }

  headers = [["TAG TYPE", "TAG NAME", "APPLIED ON", "TAG STATUS"]];
  exportPDF() {
    const title = "Add Tags Report";
    exportPDFC(title, this.headers, this.state.pdfBody);
  }

  updateTableToDocs() {
    const data =
      this.props.tagsData.data &&
      this.props.tagsData.data.map((p) => [
        p.type,
        p.tagName,
        p.appliedOn ? getDateTimeFormate(p.appliedOn) : "-",
        p.tagId,
      ]);
    this.setState({ pdfBody: data });
    this.setState({
      csvData:
        data.length > 0 ? [this.headers[0], ...data] : [this.headers[0], []],
    });
  }

  handleActivation(e) {
    localStorage.setItem(`${e.target.id}`, `${e.target.value}`);
    this.setState({ activeStatus: e.target.value });
    this.setState({ itemToDeleteId: e.target.id });
  }

  addTag() {
    const userId = localStorage.getItem("userId");
    if (this.state.tagType === "") {
      this.setState({ tagTypeError: true });
    }
    if (this.state.tagName === "") {
      this.setState({ tagNameError: true });
    }
    if (this.state.tagType !== "" && this.state.tagName !== "") {
      localStorage.setItem(
        "tagTypeList",
        JSON.stringify(this.state.tagTypeList)
      );
      this.props.dispatch(postUserTag(userId, this.state.id));

      this.emptyState();
    }
  }
  setDeleteTag = (index) => {
    this.setState({ deactiveTag: index });
  };

  getTags() {
    this.props.dispatch(fetchTagsList(0, 1000));
  }
  emptyState() {
    this.setState({
      tagType: "",
      tagName: "",
      tagNameError: "",
      tagTypeError: "",
    });
  }

  reset() {
    this.emptyState();
    this.props.dispatch(setPaginationFirstValue(1));
    this.props.dispatch(setPaginationSecondValue(25));
    this.props.dispatch(setViewRecords(false));
    this.props.dispatch(setRecordsShow([1, 25]));
    this.callApi(1, 25, localStorage.getItem("userId"));
  }
  fetchTagsAdded() {
    let playerId = localStorage.getItem("userId");
    this.callApi(
      this.props.paginationFirstValue,
      this.props.paginationSecondValue,
      playerId
    );
  }

  mountingFetcher() {
    this.fetchTagsAdded();
    const data = JSON.parse(localStorage.getItem("tagTypeList"));
    this.setState({ tagTypeList: data });
  }
  componentDidMount() {
    this.mountingFetcher();
  }

  componentDidUpdate(prevProps) {

    if (prevProps.tagsData !== this.props.tagsData) {
      this.updateTableToDocs();
    }

    if (prevProps.userId != localStorage.getItem("userId")) {
      this.callApi(
        this.props.paginationFirstValue,
        this.props.paginationSecondValue,
        localStorage.getItem("userId")
      );
    }
  }


  render() {
    let tagNames = localStorage.getItem("tagTypeList");
    let tagNamesArray = JSON.parse(tagNames);
    return (
      <>
        <div className='CMS-tabContent'>
          <div className='CMS-box-content'>
            <div className='CMS-tabs-content'>
              <div className='CMS-tab-panel active' id='CMS-codedBonus'>
                <div className='CMS-tabContent'>
                  <div className='CMS-box CMS-box-content'>
                    <div className='row'>
                      <div className='col-md-6 col-lg-4 col-xl-4'>
                        <Select
                          title={"Tag Type"}
                          name={"tagType"}
                          ids={"tagType"}
                          value={this.state.tagType}
                          setValue={(val) => this.changeHandler(val)}
                          options={playerTagData}
                          info={Tagging_Segmentation.Tag_Type}
                          errorMsg={this.state.tagTypeError &&
                            "Please select tag type"}
                        />
                      </div>
                      <div className='col-md-6 col-lg-4 col-xl-4'>
                        <div className='CMS-formGroup'>
                          <div className='CMS-formLabel'>
                            {TAG_CONSTANTS.header_tagName}
                            &nbsp;&nbsp;
                            <Tooltips info={Tagging_Segmentation.Assign_tagName} />
                          </div>
                          <div className='CMS-dropdown CMS-formControl'>
                            <div className='CMS-select'>
                              <select
                                name='tagName'
                                id={this.state.id}
                                value={this.state.tagName}
                                onChange={this.onChangeHandler}
                                className="pointer"
                              // name={this.state.itemId}
                              >
                                <option value=''>{COMMON_CONSTANTS.ddlOpt_select}</option>
                                {Object.keys(this.props.getTagsList).length >
                                  0 &&
                                  this.props?.getTagsList?.data
                                    .filter((item) =>
                                      item.tagType.includes(
                                        this.state.tagType.toUpperCase()
                                      )
                                    )
                                    .map((item, index) => {
                                      return (
                                        <option
                                          key={index}
                                          value={item.tagName}
                                          id={item.id}
                                          name={item.id}
                                        >
                                          {item.tagName}
                                        </option>
                                      );
                                    })}
                              </select>
                            </div>
                            <div className='CMS-form-errorMsg'>
                              {this.state.tagNameError &&
                                "Please select tag name"}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='mt-20'></div>
                    <div className='row'>
                      <ButtonGroup
                        freezButton={this.props.viewOnlyuser}
                        groupNames={["Add", "Reset"]}
                        idsearch={"AddTag"}
                        idreset={"Tagreset"}
                        submit={(e) => this.addTag(e)}
                        reset={(e) => this.reset(e)}
                      />
                    </div>
                    <div className='mt-20'></div>
                  </div>
                </div>
              </div>
            </div>

            <div className='CMS-filter-result' id='result'>
              <div className='CMS-pagination'>
                <Vpagination
                  data={this.props.tagsData.data}
                  totalRecords={this.props.tagsData.totalRecords}
                  paginationFirstValue={this.props.paginationFirstValue}
                  paginationSecondValue={this.props.paginationSecondValue}
                  setPaginationFirstValue={setPaginationFirstValue}
                  setPaginationSecondValue={setPaginationSecondValue}
                  recordsShow={this.props.recordsShow}
                  setRecordsShow={setRecordsShow}
                  isVisible={this.props.viewRecords}
                  setIsVisible={setViewRecords}
                  csvData={this.state.csvData}
                  exportPDF={this.exportPDF}
                  callApi={this.callApi}
                  csvFileName={"Add Tags report"}
                  resetCall={this.state.resetCall}
                />
              </div>
              {!this.props.viewRecords ? (
                <Skelton />
              ) : (
                <div className='CMS-box CMS-table CMS-table-triped'>
                  <table>
                    <thead>
                      <tr>
                        <th>
                          <div className='d-flex justify-content-center align-items-center'>
                            <p className='mx-3'>{TAG_CONSTANTS.th_tagType}</p>
                            <Tooltips info={TagInfo.tagType} />
                          </div></th>
                        <th>
                          <div className='d-flex justify-content-center align-items-center'>
                            <p className='mx-3'>{TAG_CONSTANTS.header_tagName}</p>
                            <Tooltips info={TagInfo.tagName} />
                          </div></th>
                        <th>
                          <div className='d-flex justify-content-center align-items-center'>
                            <p className='mx-3'>{TAG_CONSTANTS.th_appliedOn}</p>
                            <Tooltips info={TagInfo.Applied_On} />
                          </div>
                        </th>
                        <th>
                          <div className='d-flex justify-content-center align-items-center'>
                            <p className='mx-3'>{TAG_CONSTANTS.status}</p>
                            <Tooltips info={TagInfo.status} />
                          </div>
                        </th>
                        <th>
                          <div className='d-flex justify-content-center align-items-center'>
                            <p className='mx-3'>{TAG_CONSTANTS.action}</p>
                            <Tooltips info={TagInfo.action} />
                          </div>
                        </th>
                        {/* <th>Tag Status</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.tagsData &&
                        Object.keys(this.props.tagsData).length > 0 &&
                        this.props.tagsData.data &&
                        Array.isArray(this.props.tagsData.data) &&
                        this.props.tagsData.data.length > 0 ? (
                        this.props.tagsData.data.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>{item.type}</td>
                              <td>{item.tagName}</td>
                              <td>{item.appliedOn ? getDateTimeFormate(item.appliedOn) : "-"}</td>
                              <td>{item.status ? "Active" : "Inactive"}</td>
                              <td>
                                <div className="CMS-btnContainer">
                                  <button
                                    className={item.status ? 'CMS-btnStatus CMS-statusActive CMS-btnSmall pointer disabled-button' : 'CMS-btnStatus CMS-statusActive CMS-btnSmall pointer '}
                                    type='button'
                                    id='bankactivesave'
                                    onClick={() => this.handleTagStatus(item)}
                                  >
                                    {COMMON_CONSTANTS.active}
                                  </button>
                                  <button
                                    className={item.status === false ? 'CMS-btnStatus CMS-btnSmall  CMS-btn CMS-statusClosed disabled-button' : "CMS-btnStatus CMS-btnSmall pointer CMS-btn CMS-statusClosed "}
                                    type='button'
                                    id='bankactivecancel'
                                    onClick={() => this.handleTagStatus(item)}
                                  >
                                    {COMMON_CONSTANTS.deactive}
                                  </button>
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <No__Data__Found colspan={3} />
                      )}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    displayValue: state.sidebar.displayValue,
    tagsData: state.tags.tagsData,
    paginationFirstValue: state.tags.paginationFirstValue,
    paginationSecondValue: state.tags.paginationSecondValue,
    recordsShow: state.tags.recordsShow,
    userId: state.playersearch.userId,
    getTagsList: state.tags.getTagsList,
    TagPostData: state.tags.TagPostData,
    tagId: state.tags.tagId,
    viewRecords: state.tags.viewRecords,
    viewOnlyuser: state.dashboard.viewOnlyuser,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    actions: bindActionCreators({}, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Tags);
