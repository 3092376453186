import { regexType, Currency } from "../../../sharedfiles/helper";
import { boosted_odds } from "../../../sharedfiles/tooltipMsgs";

export const PlayerSearchData = [
  {
    id: 1,
    ids: "PlayerUserName",
    title: "User Name",
    name: "username",
    type: "text",
    placeholder: "User Name",
    regex: /^[a-zA-Z0-9^-_@$.!#%&*-]*$/,
    // regex: regexType.alphaNumeric,
    maxLength: 15,
    info: "Helps you to filter out the players by their User Name"
  },
  {
    id: 2,
    ids: "PlayerFirstName",
    title: "First Name",
    name: "firstName",
    type: "text",
    placeholder: "First Name",
    regex: /^[a-zA-Z0-9]*$/,
    maxLength: 15,
    info: "Helps you to filter out the players by their First Name"
  },
  {
    id: 3,
    ids: "PlayerLastName",
    title: "Last Name",
    name: "lastName",
    type: "text",
    placeholder: "Last Name",
    regex: /^[a-zA-Z0-9]*$/,
    maxLength: 15,
    info: "Helps you to filter out the players by their Last Name"
  },
  {
    id: 4,
    ids: "PlayerEmail",
    title: "Email",
    name: "email",
    type: "text",
    placeholder: "Email",
    regex: /^[a-zA-Z0-9@#.$]*$/,
    maxLength: 50,
    info: "Helps you to filter out the players by their Email Id"
    // regex: "",
  },
];

export const playerSearchDataTwo = [
  {
    id: 1,
    ids: "PlayerCID",
    title: "Customer ID",
    name: "customerId",
    type: "text",
    placeholder: "Customer ID",
    regex: /^[0-9\b]+$/,
    maxLength: 7,
    info: "Helps you to filter out the players by their Customer ID"
  },
  {
    id: 2,
    ids: "PlayerIP",
    title: "IP Address",
    name: "ipAddress",
    type: "text",
    placeholder: "IP Address",
    regex: /^[\0-9.]*$/,
    maxLength: 15,
    info: "Helps you to filter out the players by their IP Address"
  },
  {
    id: 3,
    ids: "PlayerRefcode",
    title: "Referred By",
    name: "referralCode",
    type: "text",
    placeholder: "Referred By",
    regex: /^[a-zA-Z0-9@$.!#%&*-_]*$/,
    maxLength: 50,
    info: "Helps you to filter out the players by their Referred By"
  },
  {
    id: 4,
    ids: "PlayerPhnNum",
    title: "Phone Number",
    name: "phoneNumber",
    type: "text",
    placeholder: "Phone Number",
    regex: /^[0-9\b]+$/,
    info: "Helps you to filter out the players by their Phone Number"
  },
];

//PlayerActivity Data//
export const playerActivityData = [
  {
    id: 1,
    ids: "PlayerActivityUser",
    title: "User Name",
    name: "username",
    type: "text",
    placeholder: "User Name",
  },
];

export const DateFieldData = [
  {
    id: 1,
    ids: "playeractivityStartDate",
    title: "Start Date",
    name: "startDate",
    placeholder: "DD/MM/YYYY",
  },
  {
    id: 2,
    ids: "playeractivityEndDate",
    title: "End Date",
    name: "endDate",
    placeholder: "DD/MM/YYYY",
  },
];

export const selectFieldActivityData = [
  {
    id: 1,
    title: "Tag Type",
    name: "tagType",
    options: [
      {
        id: 1,
        label: "Custom",
        value: "Custom",
      },
      {
        id: 2,
        label: "System",
        value: "System",
      },
    ],
  },
  {
    id: 2,
    title: "Sub Type",
    name: "subType",
    options: [
      { id: 1, label: "Promotion", value: "Promotion" },
      { id: 2, label: "Payments", value: "Payment" },
      { id: 3, label: "Content", value: "Content" },
      { id: 4, label: "Customer Support", value: "Customer_Support" },
      { id: 5, label: "VIP", value: "VIP" },
      { id: 6, label: "Marketing", value: "MARKETING" },
    ],
  },
];

export const plActivityInfo = [
  "Showing Customer Id",
  "Showing User Name",
  "Showing Role of the User",
  "Showing Brand",
  "Showing Country",
  "Showing Currency",
  "Showing Registration date",
  `Showing Turnover`,
  "Showing the GGR",
  "Showing the Margin %",
  "Showing Bet Count",
  `Showing Average Bet Stake`,
  "Showing Segment",
  "Showing Age Of Account",
]
export const ActivePlActivityInfo = [
  "Showing Customer Id",
  "Showing User Name",
  "Showing Role of the User",
  "Showing Registration date",
  "Showing Real Balance",
  `Showing Turnover`,
  "Showing the GGR",
  "Showing the Margin %",
  "Showing Bet Count",
  `Showing Average Bet Stake`,
  "Showing Segment",
]
//PlayerActivity Data//

export const createTagData = [
  {
    id: 1,
    ids: "createTagname",
    title: "Tag Name",
    name: "tagName",
    type: "text",
    placeholder: "Tag Name",
    errorMsg: "Please select proper tag Name",
    info: "Name of the tag to which user will be segmented into"
  },
  {
    id: 2,
    ids: "createTagDesc",
    title: "Tag Description",
    name: "tagDescription",
    type: "text",
    placeholder: "Tag Description",
    info: "Short description of the tag"
  },
];

export const createTagInfo = [
  " Showing Tag Type", "Showing Tag Name", "Showing Tag Description", "Showing Creation Date", "Showing tag Created By"
]

export const BankDetailsData = [
  {
    id: 1,
    ids: "accountholder",
    title: "Account Holder",
    name: "accountHolderName",
    type: "text",
    placeholder: "Account Holder",
    errorMsg: "Enter Proper Account Holder Name",
    info: "Enter the Account Holder Name"
  },
  {
    id: 2,
    ids: "bankname",
    title: "Bank Name",
    name: "bankName",
    type: "text",
    placeholder: "Bank Name",
    errorMsg: "Enter Proper Bank Name",
    info: "Enter the Bank Name"
  },
  {
    id: 3,
    ids: 'branchname',
    title: "Branch Name",
    name: "branchName",
    type: "text",
    placeholder: "Branch Name",
    errorMsg: "Enter Proper Branch Name",
    info: "Enter the Bank Branch Name"
  },
  {
    id: 4,
    ids: "accnumber",
    title: "Account Number",
    name: "accountNumber",
    type: "text",
    placeholder: "Account Number",
    errorMsg: "Enter Proper Account Number",
    info: "Enter the Proper Account Number"

  },
  {
    id: 5,
    ids: 'ifsc',
    title: "Bank Code",
    name: "ifscCode",
    type: "text",
    placeholder: "Bank Code",
    info: "Enter the Proper Bank Code"
  },
];

export const bankErrors = [
  "accountHolderName",
  "bankName",
  "branchName",
  "accountNumber",
];

export const LoginHistoryData = [
  {
    id: 1,
    ids: "loginhistoryIP",
    title: "IP Address",
    name: "ipAddress",
    type: "text",
    placeholder: "IP Address",
    regex: /^[\0-9.]*$/,
    info: "Helps you to filter out the results based on Ip addresses"
  },
];

export const FreeBetData = [
  {
    id: 1,
    title: "Free Bet Name",
    name: "",
    type: "text",
    placeholder: "Free Bet Name",
  },
  {
    id: 2,
    title: "Free Bet Amount ",
    name: "",
    type: "text",
    placeholder: "Free Bet Amount",
  },
];

export const FreeBetStatus = [
  { id: 1, label: "ENABLE", value: "ACTIVE" },
  { id: 2, label: "DISABLE", value: "DEACTIVE" },
];

// custmor ID addNotes //
export const AddNotesDDL = [
  { id: 1, label: "Deposit", value: "DEPOSIT" },
  { id: 2, label: "withdrawal", value: "WITHDRAW" },
  { id: 3, label: "Bet Related", value: "BET_RELATED" },
  { id: 4, label: "Others", value: "OTHERS" },
];
// custmor ID addNotes //

// PaymentSearch Data //
export const PaymentsearchData1 = [
  {
    id: 1,
    ids: "PaymnetsearchTID",
    title: "Transaction ID",
    name: "transactionId",
    type: "text",
    placeholder: "Transaction ID",
    info: 'Filters the results based on Transaction ID'
  },
];
export const PaymentsearchData2 = [
  {
    id: 1,
    ids: "PaymentsearchCID",
    title: "Customer ID",
    name: "customerId",
    type: "text",
    placeholder: "Customer ID",
    info: 'Filters the results based on CID'
  },
];

export const PaymentsearchUser = [
  {
    id: 2,
    ids: "PaymentsearchCustomer",
    title: "Username",
    name: "customerName",
    type: "text",
    placeholder: "Username",
    info: 'Filters the results based on User Name'
  },
]
export const PaymentsearchData3 = [
  {
    id: 3,
    ids: "PaymentsearchAmount",
    title: `Amount ${Currency()}`,
    name: "amount",
    type: "text",
    placeholder: "Amount",
    maxLength: 5,
    regex: /^[-+0-9]+$/,
    info: "Filters the results as per the given Amount"
  },
];
export const PaymentsearchData4 = [
  {
    id: 1,
    ids: "PaymentsearchTIP",
    title: " Transaction IP Address",
    name: "ipAddress",
    type: "text",
    placeholder: "Transaction IP Address",
    regex: /^[\0-9.]*$/,
    info: 'Filters the results as per the Ip adresses'
  },
  {
    id: 2,
    ids: "PaymentsearchTRef",
    title: "Transaction Ref",
    name: "transactionRef",
    type: "text",
    placeholder: "Transaction Ref",
    info: 'Filters the results as per transaction Reference Id'
  },
];
// PaymentSearch Data //

//Betting History Data //
export const BettingHistoryData = [
  {
    id: 1,
    ids: "BettingCID",
    title: "Customer ID",
    name: "customerId",
    type: "text",
    placeholder: "Customer ID",
    regex: /^[0-9\b]+$/,
    info: "Helps you to filter the results as per the Customer Id"
  },
  {
    id: 2,
    ids: "BettingID",
    title: "Bet ID",
    name: "betId",
    type: "text",
    placeholder: "Bet ID",
    info: "Helps you to filter out the recods based on the Bet Id"
  },
];
//Betting History Data //

//Newplayer Data //
export const inputFieldsData = [
  {
    id: 1,
    ids: "newPlayerUser",
    title: "Username",
    name: "username",
    placeholder: "Username",
    error: "please enter a valid Username",
    info: "Helps you to filter out the players by their User Name"
  },
  {
    id: 2,
    ids: "newplayerFirstName",
    title: "First Name",
    name: "firstName",
    placeholder: "First Name",
    errorMsg: "please enter a valid first name",
    info: "Helps you to filter out the players by their First Name"
  },
  {
    id: 3,
    ids: "newplayerLastName",
    title: "Last Name",
    name: "lastName",
    placeholder: "Last Name",
    errorMsg: "please enter a valid last name",
    info: "Helps you to filter out the players by their Last Name"
  },
  {
    id: 4,
    ids: "newplayerRefCode",
    title: "Referred By",
    name: "referCode",
    placeholder: "Referred By",
    errorMsg: "please enter a valid Referred By",
    info: "Helps you to filter out the players by their Referred By"
  },
  {
    id: 5,
    ids: "newplayerAffBTAG",
    title: "Affiliate BTAG",
    name: "affiliateBTAG",
    placeholder: "Affiliate BTAG",
    errorMsg: "please enter a valid affiliate BTAG",
    info: "Provides access to a list of players who have registered through an affiliate with affiliate code. Eg: AB2RY"
  },
  {
    id: 6,
    ids: "newplayerAffName",
    title: "Affiliate Name",
    name: "affiliateName",
    placeholder: "Affiliate Name",
    errorMsg: "",
    info: "Provides access to a list of players with an affiliate name Eg: John"
  },
];

export const newplayerInfo = [
  "Showing Customer ID",
  "Showing UserName",
  "Showing FirstName",
  "Showing LastName",
  "Showing Role of the Player",
  "Showing Registration Date",
  "Showing Affiliate Btag",
  "Showing Affiliate Name",
  "Showing Referred By",
  "Showing Brand",
  "Showing Country",
  `Showing First Deposit Amount`,
  "Showing Bet Count",
  "Showing User Account Status",
]

export const DateInputFieldsData = [
  {
    id: 1,
    ids: "newplayerDateFrom",
    title: "Registration Date From",
    placeholder: "DD/MM/YYYY",
    name: "FromDate",
    info: "Provides list of players according to the Registration Date"
  },
  {
    id: 2,
    ids: "newplayerToDate",
    title: "Registration Date To",
    placeholder: "DD/MM/YYYY",
    name: "ToDate",
    info: "Provides list of players according to the Registration Date"
  },
];
//Newplayer Data //

// DASHBOAD SIDEBAR TABLES //

const options = [
  {
    id: 1,
    name: "This Month",
    value: "THIS_MONTH",
  },
  {
    id: 11,
    name: "Previous Month",
    value: "LAST_MONTH",
  },
  {
    id: 2,
    name: "This Week",
    value: "THIS_WEEK",
  },
  {
    id: 3,
    name: "Today",
    value: "TODAY",
  },
  {
    id: 4,
    name: "Yesterday",
    value: "YESTERDAY",
  },
];
const styles = {
  1: "text-right",
};
export const sideTableData = [
  {
    id: 1,
    ids: "profitSportsDDL",
    title: "Most Profitable Sports",
    name: "profitSports",
    headers: ["Sport", "Win", "Margin"],
    info: [
      "Represents the name of the Sports",
      "Represents the profit earned on the specific sport",
      "Gives the Percentage of revenue generated"
    ],
    styles,
    options: options,
  },
  {
    id: 2,
    ids: "turnoverSportsDDL",
    title: "Highest Turnover Sports",
    name: "turnoverSports",
    headers: ["Sport", "Turnover", "Margin"],
    info: [
      "Represents the name of the Sports",
      "Gives the total amount wagered by the users",
      "Gives the Percentage of revenue generated"
    ],
    styles,
    options: options,
  },
  {
    id: 3,
    ids: "topWinnersDDL",
    title: "Top 10 Winners",
    name: "topWinners",
    headers: ["Username", "Win", "Brand"],
    info: [
      "Provides the Username of the player based on the winning amount",
      "Provides the winning amount of the user",
      "Gives you the brand's name"
    ],
    styles: {
      1: "text-right CMS-negative-value",
    },
    options: options,
  },
  {
    id: 4,
    ids: "topLosersDDL",
    title: "Top 10 Losers",
    name: "topLosers",
    headers: ["Username", "Loss", "Brand"],
    info: [
      "Provides the Username of the player based on the losing amount",
      "Provides the user's lost money amount",
      "Gives you the brand's name"
    ],
    styles,
    options: options,
  },
];

// DASHBOAD SIDEBAR TABLES //

// CreateUser Data //
export const CreateUserData = [
  {
    id: 1,
    ids: "CreateuserName",
    title: "User Name",
    name: "userName",
    type: "text",
    placeholder: "User Name",
    regex: regexType.alphanospace,
    errorMsg: "User Name is required",
    info: "Permits the creation of a new user to access the site."
  },
  {
    id: 2,
    ids: "CreateuserFName",
    title: "First Name",
    name: "firstName",
    type: "text",
    placeholder: "First Name",
    regex: regexType.alpha,
    errorMsg: "First Name is required",
    info: "Needs to give the First Name of the  user"
  },
  {
    id: 3,
    ids: "CreateUserLName",
    title: "Last Name",
    name: "lastName",
    type: "text",
    placeholder: "Last Name",
    regex: regexType.alpha,
    errorMsg: "Last Name is required",
    info: "Needs to give the Last Name of the  user"
  },
  // {
  //   id: 4,
  //   ids: "CreateuserPassword",
  //   title: "Password",
  //   name: "loginPin",
  //   type: "text",
  //   placeholder: "Password",
  //   // regex: regexType.simplePassword,
  //   errorMsg: "Password is required",
  //   errorMsg: passwordValdiation(),
  // },
  // {
  //   id: 5,
  //   title: "BTag",
  //   name: "btag",
  //   type: "text",
  //   placeholder: "BTag",
  //   regexType: regexType.alphaNumeric,
  //   errorMsg: "BTag is required",
  // },
];

const userErrors = ["userName", "firstName", "lastName", "loginPin", "btag"];

// Affiliate Data //
export const AffiliateData = [
  {
    id: 1,
    ids: "AffiliateUser",
    title: "User Name",
    name: "userName",
    type: "text",
    placeholder: "User Name",
    regex: regexType.alphaNumeric,
    errorMsg: "User Name is required",
    info: "Need to give affiliate User Name"
  },
  {
    id: 2,
    ids: "AffiliateFirstname",
    title: "First Name",
    name: "firstName",
    type: "text",
    placeholder: "First Name",
    regex: regexType.alpha,
    errorMsg: "First Name is required",
    info: "Need to give affiliate First Name"

  },
  {
    id: 3,
    ids: "AffiliateLastName",
    title: "Last Name",
    name: "lastName",
    type: "text",
    placeholder: "Last Name",
    regex: regexType.alpha,
    errorMsg: "Last Name is required",
    info: "Need to give affiliate Last Name"
  },
  {
    id: 4,
    ids: "AffiliatePassword",
    title: "Password",
    name: "loginPin",
    type: "text",
    placeholder: "Password",
    regex: regexType.simplePassword,
    errorMsg: "Password is required",
    info: "Need to create a appropriate password"
  },
  // {
  //   id: 5,
  //   title: "BTag",
  //   name: "btag",
  //   type: "text",
  //   placeholder: "BTag",
  //   regexType: regexType.alphaNumeric,
  //   errorMsg: "BTag is required",
  // },
  // {
  //   id: 6,
  //   title: "Clientid",
  //   name: "clientId",
  //   type: "text",
  //   placeholder: "Client Id",
  //   // regex: /^[a-z](?:_?[a-z0-9" "]+)*$/i,
  //   regex: regexType.number,
  //   errorMsg: "Client Id is required",
  // },
  {
    id: 6,
    ids: "commission%",
    title: "Commission %",
    name: "commissionPercentage",
    type: "text",
    placeholder: "Commission %",
    regex: regexType.number,
    errorMsg: "Commission % is required",
    info: "Specifies the percentage to be given to the affiliate in accordance with the revenue generated"

  },
  {
    id: 7,
    ids: "PayoutCycle",
    title: "Payout Cycle (Days)",
    name: "payoutCycle",
    type: "text",
    placeholder: "Payout Cycle (Days)",
    regex: regexType.number,
    errorMsg: "Payout Cycle is required",
    info: "Allows you to define the time period for making payout. Eg: The payment can be made once every seven days if the Payment Cycle is specified as seven days"
  },
  {
    id: 8,
    ids: "setupCost%",
    title: "Setup Cost %",
    name: "setupCost",
    type: "text",
    placeholder: "Setup Cost %",
    regex: regexType.number,
    errorMsg: "Setup Cost is required",
    info: "Sets the percentage to be collected for site setup"
  },
];

export const BecomeAfiliateData = [
  {
    id: 1,
    title: "Commission%",
    name: "commissionPercentage",
    type: "text",
    // placeholder: "Commission %",
    regex: regexType.numeric,
    errorMsg: "Commission % is required",
    info: "Specifies the percentage to be given to the affiliate in accordance with the revenue generated"
  },
  {
    id: 2,
    title: "Payout Cycle",
    name: "payoutCycle",
    type: "text",
    // placeholder: "Payout Cycle (Days)",
    regex: regexType.numeric,
    errorMsg: "Payout Cycle is required",
    info: "Allows you to define the time period for making payout. Eg: The payment can be made once every seven days if the Payment Cycle is specified as seven days"
  },
  {
    id: 3,
    title: "Setup Cost %",
    name: "setupCost",
    type: "text",
    // placeholder: "Setup Cost %",
    regex: regexType.numeric,
    errorMsg: "Setup Cost is required",
    info: "Sets the percentage to be collected for site setup"

  },

]

export const AffiliateHeaders = [
  "ID",
  "User Name",
  "First Name",
  "Last Name",
  "Affiliate Type",
  "Btag",
  "Payout Cycle",
  "Setup Cost",
  "Commission %",
  "Created Date",
  "Updated Date",
];

export const AffiliateDropdown = [
  { id: 1, label: "Fixed Revenue", value: "FIXED_REVENUE" },
  { id: 2, label: "Revenue Share-GGR", value: "REVENUE_SHARE_GGR" },
  { id: 2, label: "Revenue Share-NGR", value: "REVENUE_SHARE_NGR" },
];

// PLAYER BET HISTORY AUDIT TABLE //

export const auditTableHeaders = [
  "User ID",
  "Bet Selection",
  "Bet Status",
  "Odds",
  "Payout",
  "Updated",
];

// PLAYER BET HISTORY AUDIT TABLE //

export const multipleDepositReport = [
  {
    id: 234475,
    ids: "MDstartdate",
    title: "Start Date",
    placeholder: "Start Date",
    name: "startDate",
    errorMsg: "Start Date is required",
    type: "date",
  },
  {
    id: 235,
    ids: "MDendDate",
    title: "End Date",
    placeholder: "End Date",
    name: "endDate",
    errorMsg: "End Date is required",
    type: "date",
  },
  {
    id: 237,
    ids: "MDoddThreshold",
    title: "Odds Threshold",
    placeholder: "Odds Threshold",
    name: "oddThreshold",
    errorMsg: "Odds Threshold is required",
    type: "text",
  },
  {
    id: 236,
    ids: "MDamount",
    title: "Minimum Deposit Amount",
    placeholder: "Minimum Deposit Amount",
    name: "minDeposit",
    errorMsg: "Minimum Deposit Amount is required",
    type: "text",
  },

]

export const manualDropdown = [

  {
    id: 1,
    name: "MANUAL SETTLEMENT",
    value: "MANUAL_SETTLEMENT",
    isDeposit: true
  },
  {
    id: 2,
    name: "SPORTSBET ADJUSTMENT",
    value: "SPORTS_BET_ADJUSTMENT",
    isDeposit: true
  },
  {
    id: 3,
    name: "PAYMENTS RECONCILIATION",
    value: "PAYMENTS_RECONCILIATION",
    isDeposit: true
  },
  {
    id: 4,
    name: "GOODWILL GESTURE",
    value: "GOODWILL_GESTURE",
    isDeposit: true
  },
  {
    id: 5,
    name: "BALANCE RESET-FRAUD",
    value: "BALANCE_RESET_FRAUD",
    isDeposit: false
  },
  {
    id: 6,
    name: "BALANCE RESET-MANUAL SETTLEMENT",
    value: "MANUAL_SETTLEMENT",
    isDeposit: false
  },
  {
    id: 7,
    name: "SPORTSBET ADJUSTMENT",
    value: "SPORTS_BET_ADJUSTMENT",
    isDeposit: false
  },
  {
    id: 8,
    name: "PAYMENTS RECONCILIATION",
    value: "PAYMENTS_RECONCILIATION",
    isDeposit: false
  },
  {
    id: 9,
    name: "WITHDRAWABLE DEPOSIT - REWARD",
    value: "WITHDRAWABLE_DEPOSIT_REWARD",
    isDeposit: true
  },
  {
    id: 9,
    name: "MV_DEPOSIT_TO_WTH_AMT",
    value: "MV_DEPOSIT_TO_WTH_AMT",
    isMvDeposit: true
  },

]

export const withdrawLimitOP = [
  { id: 1, label: "Automated withdrawal Amount", value: "AUTOWITHDRAW" },
  { id: 2, label: "No. of Automated withdrawals Per Day", value: "WITHDRAWPERDAY" },
  { id: 3, label: "Max Withdrawal Amount per transaction", value: "MAX_WITHDRAW_AMOUNT_PER_TRANSACTION" },
];

export const paymentHeaders = [
  "Transaction ID",
  "Created On",
  "Updated On",
  "Created By",
  "Updated By",
  `Amount ${Currency()}`,
  "State",
  `TDS Amount ${Currency()}`,
  "TDS Percentage",
  "Method",
  "Payment Type",
  "Payment Method",
  "Payment Description",
  "Bank Name",
  "Reference ID",
]

export const applyBonusType = [
  { id: 1, label: "Freebet", value: "FREE_BET" },
  { id: 2, label: "Direct Bonus", value: "GOODWILL" },
]

export const playerTagData = [
  { id: 1, label: "Content", value: "CONTENT" },
  { id: 2, label: "Customer Support", value: "CUSTOMER_SUPPORT" },
  { id: 3, label: "Payments", value: "PAYMENTS" },
  { id: 4, label: "Promotion", value: "PROMOTION" },
  { id: 5, label: "VIP", value: "VIP" },
  { id: 6, label: "Marketing", value: "MARKETING" },
]

export const AfftbHeader = [
  "ID",
  "User Name",
  "First Name",
  "Last Name",
  "Affiliate Type",
  "Btag",
  "Payout Cycle",
  "Setup Cost %",
  "Setup Cost",
  "Gross Commission",
  "Net Commission",
  "Commission %",
  "Total Registrations",
  "Deposited Customers",
  "Total Deposits",
  "Total Stakes",
  "Total Payout",
  "GGR",
  "NGR",
  "Total Bonus Amount",
  "Total Number of Bets",
  "Total Withdrawals",
  "First Time Deposited Customer",
  "Created Date",
  "Updated Date",
  "Media",
  "Action",
  "URL Hits Count",
  "Edit User",
  "Mark As Paid",
  "Pay"
]
export const subAfftbHeader = [
  "ID",
  "User Name",
  "First Name",
  "Last Name",
  "Affiliate Type",
  "Btag",
  "Payout Cycle",
  "Setup Cost %",
  "Setup Cost",
  "Gross Commission",
  "Net Commission",
  "Commission %",
  "Total Registrations",
  "Deposited Customers",
  "Total Deposits",
  "Total Stakes",
  "Total Payout",
  "GGR",
  "NGR",
  "Total Bonus Amount",
  "Total Number of Bets",
  "Total Withdrawals",
  "First Time Deposited Customer",
  "Created Date",
  "Updated Date",
  "Media",
  "Action",
  "URL Hits Count",
  "Edit User",
]

export const paymentLogsData = [
  {
    id: 1,
    ids: "paymentlogcid",
    title: "Customer ID",
    name: "cid",
    placeholder: "Customer ID",
    error: "please enter a valid Customer ID",
    info: "Helps you to filter out the players by their Customer ID"
  },
  {
    id: 3,
    ids: "paymentlogcid",
    title: "Transaction ID",
    name: "tnxId",
    placeholder: "Transaction ID",
    error: "please enter a valid Transaction ID",
    info: "Helps you to filter out the players by their Transaction ID"
  },
]

export const msgFormatOpts = [
  { id: 1, label: "Verified Users", value: "VERIFIED" },
  { id: 2, label: "Un Verified Users ", value: "NON_VERIFIED" },
]
export const playerReferralData = [
  {
    id: 1,
    ids: "refCID",
    title: "Customer ID",
    name: "cid",
    type: "text",
    placeholder: "Customer ID",
    regex: /^[0-9\b]+$/,
    maxLength: 7,
    info: "Helps you to filter out the players by their Customer ID"
  },
  {
    id: 2,
    ids: "paymentlogcid",
    title: "Referral Code",
    type: "text",
    name: "refCode",
    placeholder: "Referral Code",
    info: "Helps you to filter out the players by their Referral Code"
  },
]

export const RefDateInput = [
  {
    id: 1,
    ids: "newplayerDateFrom",
    title: "Date From",
    placeholder: "DD/MM/YYYY",
    name: "FromDate",
    info: "Provides list of players according to the Start Date"
  },
  {
    id: 2,
    ids: "newplayerToDate",
    title: "Date To",
    placeholder: "DD/MM/YYYY",
    name: "ToDate",
    info: "Provides list of players according to the End Date"
  },
];

export const promotionListSts = [
  { id: 1, label: "Active", value: "ACTIVE" },
  { id: 2, label: "Completed", value: "COMPLETED" },
  { id: 3, label: "Created", value: "CREATED" },
  { id: 4, label: "Deactivated", value: "DEACTIVATED" },
  { id: 5, label: "Ongoing", value: "ONGOING" },
  { id: 6, label: "Published", value: "PUBLISHED" },
  { id: 7, label: "Schedule", value: "SCHEDULED" },
]

export const messageFormatType = [
  { id: 1, label: "Email", value: "EMAIL" },
  { id: 2, label: "In App Message", value: "IN_APP_MESSAGE" },
  { id: 3, label: "Push Notification", value: "PUSH_NOTIFICATION" },
  { id: 4, label: "SMS", value: "SMS" },
]

export const promopriority = [
  { id: 1, label: "Low", value: "LOW" },
  { id: 2, label: "Medium", value: "MEDIUM" },
  { id: 3, label: "High", value: "HIGH" },
]

export const createPromo = [
  {
    id: 1,
    ids: "promostartDate",
    title: "Start Date/Time",
    placeholder: "DD/MM/YYYY",
    name: "startDate",
    info: "Select Start Date and Time",
    timeName: "startTime"
  },
  {
    id: 2,
    ids: "promoEndDate",
    title: "End Date/Time",
    placeholder: "DD/MM/YYYY",
    name: "endDate",
    info: "Select End Date and Time",
    timeName: "endTime"
  },
]

export const affiliateDates = [
  {
    id: 1,
    ids: "regDateFrom",
    title: "Date From",
    placeholder: "DD/MM/YYYY",
    name: "regDateFrom",
    info: "Select Start Date From",
  },
  {
    id: 2,
    ids: "regDateTo",
    title: "Date To",
    placeholder: "DD/MM/YYYY",
    name: "regDateTo",
    info: "Select Start Date To",
  },
]
export const failedReportsDate = [
  {
    id: 1,
    ids: "fromDate",
    title: "Date From",
    placeholder: "DD/MM/YYYY",
    name: "fromDate",
    info: "Select Start Date From",
  },
  {
    id: 2,
    ids: "toDate",
    title: "Date To",
    placeholder: "DD/MM/YYYY",
    name: "toDate",
    info: "Select Start Date To",
  },
]
export const chanelDates = [
  {
    id: 1,
    ids: "endDateFrom",
    title: "Date From",
    placeholder: "DD/MM/YYYY",
    name: "startDate",
    info: "Select Start Date From",
  },
  {
    id: 2,
    ids: "StartDateFrom",
    title: "Date To",
    placeholder: "DD/MM/YYYY",
    name: "endDate",
    info: "Select Start Date To",
  },
]
export const activePlayerDateFields = [
  {
    id: 1,
    ids: "activePlayerDateFrom",
    title: "Date From",
    placeholder: "DD/MM/YYYY",
    name: "FromDate",
    info: "Select Date From",
  },
  {
    id: 2,
    ids: "activePlayerDateTo",
    title: "Date To",
    placeholder: "DD/MM/YYYY",
    name: "ToDate",
    info: "Select Date To",
  },
]



export const boostedOddsInputs1 = [
  {
    id: 1,
    ids: "boostedPercent",
    title: "% Boosted",
    name: "boosted",
    type: "text",
    placeholder: "% Boosted",
    // regex: /^[0-9\b]+$/,
    regex: /^\d+(\.\d{0,2})?$/,
    maxLength: 7,
    errorMsg: "Please enter % Boosted",
    info: boosted_odds.Boosted_percent
  },
  {
    id: 2,
    ids: "boostedoddsval",
    title: "Boosted Odds Value",
    name: "boostedOdds",
    type: "text",
    placeholder: "Boosted Odds Value",
    regex: regexType.decimalNumber,
    maxLength: 7,
    disabled: true,
    errorMsg: "Please enter Boosted Odds Value",
    info: boosted_odds.Boosted_odds
  },
  {
    id: 3,
    ids: "boostedminlimit",
    title: "Minimum Limit",
    name: "minLimit",
    type: "text",
    placeholder: "Minimum Limit",
    regex: /^\d+(\.\d{0,2})?$/,
    maxLength: 7,
    errorMsg: "Please enter Minimum Limit",
    info: boosted_odds.Minimum_limit
  },
  {
    id: 4,
    ids: "boostedmaxlimit",
    title: "Maximum Limit",
    name: "maxLimit",
    type: "text",
    placeholder: "Maximum Limit",
    regex: /^\d+(\.\d{0,2})?$/,
    maxLength: 7,
    errorMsg: "Please enter Maximum Limit",
    info: boosted_odds.Maximum_limit
  },

]

export const boostedOddsInputs2 = [
  {
    id: 5,
    ids: "boostedliabilitylimit",
    title: "Liability Limit",
    name: "liabilityLimit",
    type: "text",
    placeholder: "Liability Limit",
    regex: /^[0-9\b]+$/,
    maxLength: 7,
    errorMsg: "Please enter Liability Limit",
    info: boosted_odds.Liability_limit
  },
  {
    id: 6,
    ids: "boostedmaxstake",
    title: "Max Stake",
    name: "maxStake",
    type: "text",
    placeholder: "Max Stake",
    regex: /^[0-9\b]+$/,
    maxLength: 7,
    errorMsg: "Please enter Max Stake",
    info: boosted_odds.Maximum_Stake
  }
]

export const boostedOddsDates = [
  {
    id: 1,
    ids: "boostedStartDate",
    title: "Start Date & Time Range",
    placeholder: "DD/MM/YYYY",
    name: "startDate",
    info: boosted_odds.Start_DateTime,
  },
  {
    id: 2,
    ids: "boostedEndDate",
    title: "End Date & Time Range",
    placeholder: "DD/MM/YYYY",
    name: "endDate",
    info: boosted_odds.End_DateTime,
  },
]


export const boostedOddsErrs = [
  "boosted",
  // "boostedOdds",
  "minLimit",
  "maxLimit",
  "liabilityLimit",
  "maxStake"
]

export const accMsgContentType = [
  { id: 1, label: "Withdraw Failed", value: "WITHDRAW_FAILED" },
  { id: 2, label: "Withdraw Success", value: "WITHDRAW_SUCCESS" },
  { id: 3, label: "Deposit Failed", value: "DEPOSIT_FAILED" },
  { id: 4, label: "Deposit Success", value: "DEPOSIT_SUCCESS" },
  { id: 5, label: "Bonus Applied", value: "BONUS_APPLIED" },
  { id: 6, label: "Bonus Unlocked", value: "BONUS_UNLOCKED" },
  { id: 7, label: "Affiliate Request", value: "AFFILIATE_REQUEST" },
  { id: 8, label: "KYC Rejected", value: "KYC_REJECTED" },
  { id: 9, label: "KYC Approved", value: "KYC_APPROVED" },
]

export const userWatchListData = [
  {
    id: 1,
    ids: "userWatchListCID",
    title: "Customer ID",
    name: "customerId",
    type: "text",
    placeholder: "Customer ID",
    regex: /^[0-9\b]+$/,
    maxLength: 7,
    info: "Helps you to filter out the players by their Customer ID"
  },
  {
    id: 2,
    ids: "userwatchListUserName",
    title: "User Name",
    name: "userName",
    type: "text",
    placeholder: "User Name",
    regex: /^[a-zA-Z0-9^-_@$.!#%&*-]*$/,
    // regex: regexType.alphaNumeric,
    maxLength: 15,
    info: "Helps you to filter out the players by their User Name"
  }
]
export const betConfigData = [
  {
    id: 1,
    ids: "DefaultStake",
    title: "DefaultStake",
    name: "DefaultStake",
    type: "text",
    placeholder: "Enter Deafult Stake",
    // regex: /^[a-zA-Z0-9^-_@$.!#%&*-]*$/,
    // regex: regexType.alphaNumeric,
    // maxLength: 15,
    // info: "Helps you to filter out the players by their User Name"
  },
  {
    id: 2,
    ids: "Minstake",
    title: "Minstake",
    name: "Minstake",
    type: "text",
    placeholder: "Enter Min Stake",
    // regex: /^[a-zA-Z0-9]*$/,
    // maxLength: 15,
    // info: "Helps you to filter out the players by their First Name"
  },
  {
    id: 3,
    ids: "Max stake",
    title: "Max stake",
    name: "Max stake",
    type: "text",
    placeholder: "Enter Max Stake",
    // regex: /^[a-zA-Z0-9]*$/,
    // maxLength: 15,
    // info: "Helps you to filter out the players by their Last Name"
  },
  {
    id: 4,
    ids: "MinPayout",
    title: "MinPayout",
    name: "MinPayout",
    type: "text",
    placeholder: "Enter Min Payout",
    // regex: /^[a-zA-Z0-9@#.$]*$/,
    // maxLength: 50,
    // info: "Helps you to filter out the players by their Email Id"
    // regex: "",
  },
];

export const userIpDates = [
  {
    id: 1,
    ids: "startDate",
    title: "Date From",
    placeholder: "DD/MM/YYYY",
    name: "startDate",
    info: "Select Start Date From",
  },
  {
    id: 2,
    ids: "endDate",
    title: "Date To",
    placeholder: "DD/MM/YYYY",
    name: "endDate",
    info: "Select Start Date To",
  },
]
export const SyndicateBetDates = [
  {
    id: 1,
    ids: "startDate",
    title: "Date From",
    placeholder: "DD/MM/YYYY",
    name: "startDate",
    info: "Select Start Date From",
  },
  {
    id: 2,
    ids: "endDate",
    title: "Date To",
    placeholder: "DD/MM/YYYY",
    name: "endDate",
    info: "Select Start Date To",
  },
]
export const AccountHistoryDates = [
  {
    id: 1,
    ids: "startDate",
    title: "Date From",
    placeholder: "DD/MM/YYYY",
    name: "startDate",
    info: "Select Start Date From",
  },
  {
    id: 2,
    ids: "endDate",
    title: "Date To",
    placeholder: "DD/MM/YYYY",
    name: "endDate",
    info: "Select Start Date To",
  },
]

export const combiStatus = [
  { id: 1, label: "Active", value: "ACTIVE" },
  { id: 2, label: "Inactive", value: "IN_ACTIVE" },
  { id: 3, label: "Expired", value: "EXPIRED" },
]

export const combiSuggestionIP = [
  {
    id: 1,
    ids: "minOdds",
    title: "Min Odd",
    placeholder: "Min Odds",
    name: "minOdds",
    regex: regexType.decimalNumber,
    info: "Enter Min Odds",
  },
  {
    id: 2,
    ids: "minStake",
    title: "Min Stake",
    placeholder: "Min Stake",
    name: "minStake",
    regex: /^[0-9\b]+$/,
    info: "Enter Min Stake",
  },
  {
    id: 3,
    ids: "liabilityLimit",
    title: "Liability Limit",
    placeholder: "Liability Limit",
    name: "liabilityLimit",
    regex: /^[0-9\b]+$/,
    info: "Enter Liability Limit",
    errorMsg: "Liability Limit is Required"
  },
  {
    id: 4,
    ids: "maxStakeviewcombi",
    title: "Max Stake",
    placeholder: "Max Stake",
    name: "maxStake",
    regex: /^[0-9\b]+$/,
    info: "Enter Max Stake",
    errorMsg: "Max Stake is Required"
  },
]

export const combiDateIps = [
  {
    id: 1,
    ids: "startDate",
    title: "Date From",
    placeholder: "DD/MM/YYYY",
    name: "startDate",
    info: "Select Start Date From",
    errorMsg: "Start Date is required",
  },
  {
    id: 2,
    ids: "endDate",
    title: "Date To",
    placeholder: "DD/MM/YYYY",
    name: "endDate",
    info: "Select End Date To",
    errorMsg: "End Date is required",
  },
]

export const affilatePayData = [
  {
    id: 1,
    ids: "amount",
    title: "Amount",
    placeholder: "amount",
    name: "amount",
    info: "Showing Amount",
  },
  {
    id: 2,
    ids: "description",
    title: "Description",
    placeholder: "description",
    name: "description",
    info: "Showing Description",
  },
]
export const GamesBetHistoryData = [
  {
    id: 1,
    ids: "BettingCID",
    title: "Customer ID",
    name: "customerId",
    type: "text",
    placeholder: "Customer ID",
    regex: /^[0-9\b]+$/,
    info: "Helps you to filter the results as per the Customer Id"
  },
  {
    id: 2,
    ids: "BettingID",
    title: "Bet ID",
    name: "betId",
    type: "text",
    placeholder: "Bet ID",
    info: "Helps you to filter out the recods based on the Bet Id"
  },
  {
    id: 3,
    ids: "gamesBetuserName",
    title: "User Name",
    name: "userName",
    type: "text",
    placeholder: "User Name",
    info: "Helps you to filter out Bets as per User Name"
  },
];

export const GamesBetDateFields = [
  {
    id: 1,
    ids: "gamesBetPlacedAfter",
    title: "Placed After",
    name: "placedAfter",
    placeholder: "DD/MM/YYYY",
  },
  {
    id: 2,
    ids: "gamesBetPlacedBefore",
    title: "Placed Before",
    name: "placedBefore",
    placeholder: "DD/MM/YYYY",
  },
]
export const GamesBetSettleDateFields = [
  {
    id: 1,
    ids: "gamesBetPlacedAfter",
    title: "Settled After",
    name: "settledAfter",
    placeholder: "DD/MM/YYYY",
  },
  {
    id: 2,
    ids: "gamesBetPlacedBefore",
    title: "Settled Before",
    name: "settledBefore",
    placeholder: "DD/MM/YYYY",
  },
]

export const createWtchListData1 = [
  {
    id: 1,
    ids: "depoWithdrawRatio",
    title: "Deposit to Withdraw Ratio",
    name: "depoWithdrawRatio",
    type: "text",
    placeholder: "Deposit to Withdraw Ratio",
    regex: regexType.oneDecimal,
    // maxLength: 6,
    info: "Enter Deposit to withdraw ratio"
  },
  {
    id: 2,
    ids: "totalLiability",
    title: "Total Liability to stake",
    name: "totalLiability",
    type: "text",
    placeholder: "Total Liability to stake",
    maxLength: 10,
    info: "Enter Total Liability to stake",
    regex: regexType.oneDecimal,
  },
  {
    id: 3,
    ids: "cashoutPerDay",
    title: "Cashout Per Day",
    name: "cashoutPerDay",
    type: "text",
    placeholder: "Cashout Per Day",
    maxLength: 10,
    info: "Enter Cashout Per Day",
    regex: regexType.oneDecimal,
  },
  {
    id: 4,
    ids: "totalCashoutBets",
    title: "Total Cashout Bets (%)",
    name: "totalCashoutBets",
    type: "text",
    placeholder: "Total Cashout Bets (%)",
    regex: regexType.upto100,
    maxLength: 3,
    info: "Enter Total Cashout Bets (%)"
  },
]
export const createWtchListData2 = [
  {
    id: 1,
    ids: "averageOdds",
    title: "Average Odds (<)",
    name: "averageOdds",
    type: "text",
    placeholder: "Average Odds (<)",
    regex: regexType.decimalNumber,
    maxLength: 6,
    info: "Enter average odds",
    errorMsg: "Average Odds Required"
  },
  {
    id: 2,
    ids: "WatchlistStake",
    title: "Stake (>)",
    name: "stake",
    type: "text",
    placeholder: "Stake (>)",
    maxLength: 10,
    info: "Enter the stake",
    errorMsg: "Stake is Required",
    regex: regexType.oneDecimal,

  },
]

export const taxGameType = [
  { id: 1, label: "NFT", value: "NFT" },
  { id: 2, label: "SPORTS", value: "SPORTS" },
  { id: 3, label: "VIRTUALS", value: "VIRTUALS" },
]
export const gamesType = [
  { id: 1, label: "CASINO", value: "CASINO" },
  { id: 2, label: "LOTTERY", value: "LOTTERY" },
  { id: 3, label: "NE_GAMES", value: "NE_GAMES" },
]

export const taxSportsType = [
  { id: 1, label: "TRIDENT", value: "TRIDENT" },
  // { id: 2, label: "SR VIRTUALS", value: "SR_VIRTUALS" },
]

export const limitsData = [
  { id: 1, label: "TDS", value: "TDS" },
  { id: 2, label: "Platform Fee & tax", value: "platform_fee_tax" },
]
export const LimitData = [
  { id: 1, label: "Stake Tax", value: "Stake_Tax" },
  { id: 2, label: "Winning Tax", value: "Winning_Tax" },
]