import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { setUserId } from "../playerTable/playerSearchTableSlice";
import {
    formatMoney,
    generateKey,
} from "../../sharedfiles/helper";
import BetId from "../BetId/BetId";
import { setOpenDialog, betIdRecords, setBetId } from "../BetHistory/betHistorySlice";
import No__Data__Found from "../Common/table/No__Data__Found";
import { bettingHistoryInfo, gamesHistoryInfo } from "../Common/data/BettingHistory/BettinghistoryData";
import Tooltips from "../Common/Tooltip";
const GamesBetTable = (props) => {
    const { stakeTaxPermit, winTaxPermit, gamingType } = props;
    const { tableData, sortingOrder, sortables, handleSortFields, potentialPayoutData } = props;
    const [potentialPayout, setPotentialPayout] = useState('')
    const [toolTip, setToolTip] = useState([]);
    const betIDRecords = useSelector(
        (state) => state.betHistorySliceReducer.betIdRecords
    );
    const tabsChange = (item) => {
        props.dispatch(setUserId(item));
    };
    const findPotentialPayout = (betId) => {
        const selectedRow = potentialPayoutData.find(row => row.betId === betId);
        let finalPayout = formatMoney(selectedRow.potentialPayout)
        if (selectedRow) {
            setPotentialPayout(finalPayout);
        }
    };
    const openDialogHandler = (id, payout) => {
        props.dispatch(setBetId([]));
        props.dispatch(setOpenDialog(true));
        props.dispatch(betIdRecords(id));
        findPotentialPayout(id)
    };

    useEffect(() => {
        const showPermissions = stakeTaxPermit && winTaxPermit;
        if (!showPermissions) {
            let v = []
            v = gamesHistoryInfo.filter((name, index) => name != "Showing Stake Tax" && name != "Showing Winnings Tax Amount" && name != "Showing Stake After Tax" && name != "Showing Stake Tax Amount" && name != "Showing Winnings Tax");
            setToolTip(v)
        }
        else {
            setToolTip(gamesHistoryInfo)
        }
    }, [stakeTaxPermit, winTaxPermit])

    return (
        <>
            <BetId data={betIDRecords} setBack={setBetId} potentialPayout={potentialPayout} />
            <div className='CMS-box'>
                <div className='CMS-table CMS-table-triped'>
                    <table>
                        <thead>
                            <tr>
                                {tableData[0]?.map((item, ind) => (
                                    <th
                                        key={generateKey(item)}
                                        style={{ whiteSpace: "nowrap" }}
                                        className={sortables && Object.keys(sortables).includes(item) ? "pointer" : ""}
                                        onClick={() => sortables && Object.keys(sortables).includes(item) ? handleSortFields(sortables[item], item) : null}
                                    >
                                        <div className='d-flex justify-content-center align-items-center'>
                                            <p className='mx-3'>{item}</p>
                                            <Tooltips info={toolTip[ind]} />
                                            {
                                                sortables && Object.keys(sortables).includes(item) &&
                                                <span
                                                    className='material-icons md-18'
                                                    data-icon={
                                                        sortingOrder[item] ? "north" : "south"
                                                    }
                                                ></span>
                                            }
                                        </div>
                                    </th>
                                ))}
                            </tr>
                        </thead>

                        <tbody>
                            {tableData !== null &&
                                tableData !== undefined &&
                                Array.isArray(tableData) &&
                                tableData?.length > 0 &&
                                tableData.slice(1, tableData.length)[0] !== undefined &&
                                tableData.slice(1, tableData.length)[0] !== null &&
                                tableData.slice(1, tableData.length)[0].length > 0 ? (
                                tableData.slice(1, tableData.length).map((item, index) => {
                                    // const potentialPayout = item[25]
                                    return (
                                        <tr key={generateKey(index)}>
                                            {item.map((item, tdIndex) => {
                                                if (tdIndex === 0) {
                                                    return (
                                                        <td key={generateKey(tdIndex)}>
                                                            <Link
                                                                to={{}}
                                                                onClick={() => openDialogHandler(item, potentialPayout)}
                                                            >
                                                                {item}
                                                            </Link>
                                                        </td>
                                                    )
                                                }
                                                else if (tdIndex === 3) {
                                                    return (
                                                        <td key={generateKey(tdIndex)}>
                                                            <Link
                                                                to={`/customerid/${item}`}
                                                                onClick={() => tabsChange(item)}
                                                            >
                                                                {item}
                                                            </Link>
                                                        </td>
                                                    )
                                                } else {
                                                    return (
                                                        <td
                                                            // className={styles ? styles[tdIndex] : ""}
                                                            key={generateKey(tdIndex)}
                                                        >
                                                            {item}
                                                        </td>
                                                    )
                                                }
                                            }
                                            )}
                                        </tr>
                                    );
                                })
                            ) : (
                                <No__Data__Found colspan={20} />
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};
function mapStateToProps(state) {
    return {
        displayValue: state.sidebar.displayValue,
        betIdRecords: state.betHistorySliceReducer.betIdRecords,
        tabStack: state.sidebar.tabStack
    };
}
export default connect(mapStateToProps)(GamesBetTable);
