import { createSlice } from "@reduxjs/toolkit";
import { apiCaller } from "../../api/apiCaller";
import { paymentSearchApi } from "../../sharedfiles/EndpointConfig";
import { toaster } from "../../sharedfiles/helper";
export const PaymentLimits = createSlice({
    name: "paymentLimit",
    initialState: {
        paymentsLimit: [],
        updatedLimits: {},
        disableBtn: false,
        withdrawPayments: []
    },
    reducers: {
        setPaymentsLimit: (state, action) => {
            state.paymentsLimit = action.payload;
        },
        setUpdatedLimits: (state, action) => {
            state.updatedLimits = action.payload;
        },
        setDisableBtn: (state, action) => {
            state.disableBtn = action.payload;
        },
        setWithdrawPayments: (state, action) => {
            state.withdrawPayments = action.payload;
        }
    }
});

export const { setPaymentsLimit, setUpdatedLimits, setDisableBtn, setWithdrawPayments } = PaymentLimits.actions;

export const getPaymentLimits = () => dispatch => {
    const url = paymentSearchApi.paymentLimitsUrl;
    apiCaller(url, "GET").then(res => {
        dispatch(setPaymentsLimit(res.data));
    }).catch(err => console.log(err));
}

export const updatePaymentLimits = (payload) => dispatch => {
    const url = paymentSearchApi.paymentLimitsUrl;
    apiCaller(url, "PUT", payload).then(res => {
        dispatch(setDisableBtn(true));
        dispatch(setUpdatedLimits(res.data));
        res.msg && toaster.success("SUCCESS");
    }).catch(err => {
        dispatch(setDisableBtn(true));
        console.log(err);
        toaster.error("ERROR");
    })
}
export const withdrawPaymentMethods = (payload) => (dispatch) => {
    const url = paymentSearchApi.withdrawPayment;
    apiCaller(url, "GET", payload)
        .then((res) => {
            const formattedData = res.data.map((item, index) => ({
                id: index + 1,
                label: item.payment_provider_id.replace(/_/g, " ").replace(/\b\w/g, char => char.toUpperCase()),
                value: item.payment_provider_id,
                isActive: item.isActive,
            }));

            dispatch(setWithdrawPayments(formattedData));
        })
        .catch((err) => {
            console.log(err);
        });
};
export const updateWithdrawPayment = (payload) => (dispatch) => {
    const url = paymentSearchApi.saveWithdrawPayment;
    apiCaller(url, "POST", payload)
        .then((res) => {
            console.log(res);
            toaster.success(res.msg);
        })
        .catch((err) => {
            console.log(err);
            toaster.error(err.msg);
        });
};

export default PaymentLimits.reducer;